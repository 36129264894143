import {
  ContractDiscount,
  ContractDiscountResponse,
  ContractDiscountViewItem,
  ContractPriceComponent,
  ProductDetailsStaticDataDiscountUnit,
  ProductDetailsStaticDataDiscountUnitCode,
} from '@mwe/models';
import { formatAmount, isArrayWithMinOneItem, sumReducer } from '../common/mwe-util';
import dayjs from 'dayjs';
import { formatDate } from '../dates/date.utils';

export const mapContractDiscountResponse = (data: ContractDiscountResponse): ContractDiscount => {
  if (!data) {
    return null;
  }

  return {
    type: data.typ,
    operand: data.operand,
    description: data.bezeichnung,
    factor: parseFloat(data.faktor),
    validFrom: data.gueltigVon,
    validTo: data.gueltigBis,
  };
};

export const hasContractActiveDiscount = (discounts: ContractDiscount[]): boolean => {
  return isArrayWithMinOneItem(discounts) && !!discounts.find(d => isContractDiscountActive(d));
};

export const isContractDiscountActive = (discount: ContractDiscount): boolean => {
  if (!discount) {
    return false;
  }

  const validTo = dayjs(discount.validTo);
  const today = dayjs();
  return today.isBefore(validTo, 'day') || today.isSame(validTo, 'day');
};

export const filterActiveContractDiscounts = (discounts: ContractDiscount[]): ContractDiscount[] => {
  if (!isArrayWithMinOneItem(discounts)) {
    return [];
  }

  return discounts.filter(d => isContractDiscountActive(d));
};

export const filterInactiveContractDiscounts = (discounts: ContractDiscount[]): ContractDiscount[] => {
  if (!isArrayWithMinOneItem(discounts)) {
    return [];
  }

  return discounts.filter(d => !isContractDiscountActive(d));
};

export const checkIfAllActiveDiscountsAreWhitelisted = (discounts: ContractDiscount[], whiteList: string[]): boolean => {
  // having no discounts is also valid
  const activeDiscounts = filterActiveContractDiscounts(discounts);
  if (!isArrayWithMinOneItem(activeDiscounts)) {
    return true;
  }

  // whitelist check
  return activeDiscounts.every(d => {
    return !!whiteList?.find(ad => ad.toLowerCase() === d.operand.toLowerCase());
  });
};

// grundpreis has only fet disocunts, no cent
export const getDiscountedGrundPreis = (
  price: number,
  discounts: ContractDiscount[],
  units: ProductDetailsStaticDataDiscountUnit[],
): number => {
  const activeDiscounts = filterActiveContractDiscounts(discounts);
  if (!isArrayWithMinOneItem(activeDiscounts)) {
    return price;
  }
  const days = getSummedFactorFor(activeDiscounts, 'fet', units);

  const newPrice = price * (1 - days / 365);
  return parseFloat(newPrice.toFixed(2));
};

// verbrauchspreis has fet and cent discounts
export const getDiscountedVerbrauchsPreis = (
  price: ContractPriceComponent,
  discounts: ContractDiscount[],
  units: ProductDetailsStaticDataDiscountUnit[],
  isAddressInMundlCity: boolean,
): number => {
  const activeDiscounts = filterActiveContractDiscounts(discounts);
  if (!isArrayWithMinOneItem(activeDiscounts)) {
    return price?.wert;
  }

  const fetPrice = getDiscountedGrundPreis(price?.wert, discounts, units);
  const centSum = getSummedFactorFor(activeDiscounts, 'cent', units);

  // cent discount is netto, so add brutto
  let centSumMagic = centSum;

  if (!price?.inkludiertGebrauchsabgabe && price?.inkludiertUmsatzsteuer) {
    centSumMagic = centSumMagic * 1.2;
  }
  // and some extra amount for vienna
  if (isAddressInMundlCity && price?.inkludiertGebrauchsabgabe && price?.inkludiertUmsatzsteuer) {
    centSumMagic = centSumMagic * 1.2 * 1.06;
  }

  const newPrice = fetPrice - centSumMagic;
  return parseFloat(newPrice.toFixed(2));
};

function getSummedFactorFor(
  discounts: ContractDiscount[],
  code: ProductDetailsStaticDataDiscountUnitCode,
  units: ProductDetailsStaticDataDiscountUnit[],
): number {
  return discounts
    .filter(d => getDiscountUnitCode(d, units) === code)
    .map(d => d.factor || 0)
    .reduce(sumReducer, 0);
}

export const getDiscountUnitCode = (
  discount: ContractDiscount,
  units: ProductDetailsStaticDataDiscountUnit[],
): ProductDetailsStaticDataDiscountUnitCode => {
  return units?.find(u => discount?.operand.includes(u.key))?.code;
};

export const getContractDiscountViewItemFrom = (
  discount: ContractDiscount,
  unitMapping: ProductDetailsStaticDataDiscountUnit[],
): ContractDiscountViewItem => {
  if (!discount) {
    return null;
  }

  const unit = unitMapping.find(m => discount.operand.startsWith(m.key));
  const value = unit?.code === 'cent' ? formatAmount(discount?.factor) : discount?.factor + '';

  // 9999-12-31 -> there is no end
  const validTo = discount.validTo === '9999-12-31' ? null : formatDate(discount.validTo);
  return {
    name: discount.description,
    value,
    unit: { ...unit },
    validFrom: formatDate(discount.validFrom),
    validTo,
  } as ContractDiscountViewItem;
};
