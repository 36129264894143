import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import {
  ClearingAccount,
  CustomerSupportCallbackSuggestion,
  IAddress,
  IPersonalData,
  parseAddress,
  PaymentSolutionType,
  Ratenplan,
  Sparte,
  TeilbetragsUebersicht,
  UnpaidInvoice,
  UnpaidInvoicesSelectionViewModel,
} from '@mwe/models';

const unpaidInvoicesSelectionViewModel = 'findPaymentSolution_unpaidInvoicesSelectionViewModel';
const clearingAccount = 'findPaymentSolution_clearingAccount';
const categoriesKey = 'findPaymentSolution_categories';
const paymentSolutionTypeKey = 'findPaymentSolution_paymentSolutionType';
const newDueDateKey = 'findPaymentSolution_newDueDate';
const lastOrderIdKey = 'findPaymentSolution_lastOrderId';
const retriedOnErrorKey = 'findPaymentSolution_retriedOnError';
const submitIdKey = 'findPaymentSolution_submitId';
const personalDataKey = 'findPaymentSolution_personalData';
const paymentPlanKey = 'findPaymentSolution_paymentPlan';
const planUnpaidInvoicesKey = 'findPaymentSolution_paymentPlanUnpaidInvoices';
const addressKey = 'findPaymentSolution_address';
const noPaymentPlanAvailableKey = 'findPaymentSolution_noPaymentPlanAvailable';
const noPaymentExtensionAvailableKey = 'findPaymentSolution_noPaymentExtensionAvailable';
const customerSupportCallbackSuggestionKey = 'findPaymentSolution_customerSupportCallbackSuggestion';
const invoicePartsKey = 'findPaymentSolution_invoiceParts';
const invoiceDetails = 'findPaymentSolution_invoiceDetails';

type InvoiceDetails = Omit<ClearingAccount, 'unpaidInvoices' | 'accountInfo'>;

@Injectable({ providedIn: 'root' })
export class FindPaymentSolutionStateService {
  keepStorageOnce: boolean;

  constructor(private storage: SessionStorageService) {}

  // only used UnpaidInvoicesSelectionComponent
  @SessionStorage(unpaidInvoicesSelectionViewModel)
  private _unpaidInvoicesSelectionViewModel: UnpaidInvoicesSelectionViewModel;

  get unpaidInvoicesSelectionViewModel(): UnpaidInvoicesSelectionViewModel {
    if (!this._unpaidInvoicesSelectionViewModel) {
      return null;
    }

    const parsedAddress = parseAddress(this._unpaidInvoicesSelectionViewModel.addressProducts.address);
    this._unpaidInvoicesSelectionViewModel.addressProducts.address = parsedAddress;

    return this._unpaidInvoicesSelectionViewModel;
  }

  set unpaidInvoicesSelectionViewModel(data: UnpaidInvoicesSelectionViewModel) {
    if (!data) {
      this.storage.clear(unpaidInvoicesSelectionViewModel);
    } else {
      this._unpaidInvoicesSelectionViewModel = data;
    }
  }

  @SessionStorage(invoiceDetails)
  private _invoiceDetails: InvoiceDetails;

  get invoiceDetails(): InvoiceDetails {
    if (!this._invoiceDetails) {
      return null;
    }
    return this._invoiceDetails;
  }

  set invoiceDetails(data: InvoiceDetails) {
    if (!data) {
      this.storage.clear(invoiceDetails);
    } else {
      this._invoiceDetails = data;
    }
  }

  @SessionStorage(clearingAccount)
  private _clearingAccount: ClearingAccount;

  get clearingAccount(): ClearingAccount {
    return this._clearingAccount;
  }

  set clearingAccount(data: ClearingAccount) {
    if (!data) {
      this.storage.clear(clearingAccount);
    } else {
      this._clearingAccount = data;
    }
  }

  @SessionStorage(categoriesKey)
  private _categories: Sparte[];

  get categories(): Sparte[] {
    return this._categories;
  }

  set categories(categories: Sparte[]) {
    this._categories = categories;
  }

  @SessionStorage(paymentSolutionTypeKey)
  private _paymentSolutionType: PaymentSolutionType;

  get paymentSolutionType(): PaymentSolutionType {
    return this._paymentSolutionType;
  }

  set paymentSolutionType(type: PaymentSolutionType) {
    this._paymentSolutionType = type;
  }

  @SessionStorage(newDueDateKey)
  private _newDueDate: string;

  get newDueDate(): string {
    return this._newDueDate;
  }

  set newDueDate(value: string) {
    if (value) {
      this._newDueDate = value;
    } else {
      this.storage.clear(newDueDateKey);
    }
  }

  @SessionStorage(paymentPlanKey)
  private _paymentPlan: Ratenplan;

  get paymentPlan(): Ratenplan {
    return this._paymentPlan || undefined;
  }

  set paymentPlan(value: Ratenplan) {
    if (value) {
      this._paymentPlan = value;
    } else {
      this.storage.clear(paymentPlanKey);
    }
  }

  @SessionStorage(planUnpaidInvoicesKey)
  private _paymentPlanUnpaidInvoices: UnpaidInvoice[];

  get paymentPlanUnpaidInvoices(): UnpaidInvoice[] {
    return this._paymentPlanUnpaidInvoices || undefined;
  }

  set paymentPlanUnpaidInvoices(paymentPlanUnpaidInvoices: UnpaidInvoice[]) {
    if (paymentPlanUnpaidInvoices) {
      this._paymentPlanUnpaidInvoices = paymentPlanUnpaidInvoices;
    } else {
      this.storage.clear(planUnpaidInvoicesKey);
    }
  }

  @SessionStorage(lastOrderIdKey)
  private _lastOrderId: string;

  get lastOrderId(): string {
    return this._lastOrderId || undefined;
  }

  set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this._lastOrderId = lastOrderId;
    } else {
      this.storage.clear(lastOrderId);
    }
  }

  @SessionStorage(retriedOnErrorKey)
  private _retriedOnError: boolean;

  get retriedOnError(): boolean {
    return this._retriedOnError || undefined;
  }

  set retriedOnError(retriedOnError: boolean) {
    if (retriedOnError) {
      this._retriedOnError = retriedOnError;
    } else {
      this.storage.clear(retriedOnErrorKey);
    }
  }

  @SessionStorage(submitIdKey)
  private _submitId: number;

  get submitId(): number {
    return this._submitId || undefined;
  }

  set submitId(submitId: number) {
    if (submitId) {
      this._submitId = submitId;
    } else {
      this.storage.clear(submitIdKey);
    }
  }

  @SessionStorage(personalDataKey)
  private _personalData: IPersonalData;

  get personalData(): IPersonalData {
    return this._personalData || undefined;
  }

  set personalData(personalData: IPersonalData) {
    if (personalData) {
      this._personalData = personalData;
    } else {
      this.storage.clear(personalDataKey);
    }
  }

  @SessionStorage(addressKey)
  private _address: IAddress;

  get address(): IAddress {
    return this._address;
  }

  set address(address: IAddress) {
    if (address) {
      this._address = address;
    } else {
      this.storage.clear(addressKey);
    }
  }

  @SessionStorage(noPaymentPlanAvailableKey)
  private _noPaymentPlanAvailable: boolean;

  get noPaymentPlanAvailable(): boolean {
    return this._noPaymentPlanAvailable;
  }

  set noPaymentPlanAvailable(value: boolean) {
    this._noPaymentPlanAvailable = value;
  }

  @SessionStorage(noPaymentExtensionAvailableKey)
  private _noPaymentExtensionAvailable: boolean;

  get noPaymentExtensionAvailable(): boolean {
    return this._noPaymentExtensionAvailable;
  }

  set noPaymentExtensionAvailable(value: boolean) {
    this._noPaymentExtensionAvailable = value;
  }

  @SessionStorage(customerSupportCallbackSuggestionKey)
  private _customerSupportCallbackSuggestion: CustomerSupportCallbackSuggestion;

  get customerSupportCallbackSuggestion(): CustomerSupportCallbackSuggestion {
    return this._customerSupportCallbackSuggestion;
  }

  set customerSupportCallbackSuggestion(customerSupportCallbackSuggestion: CustomerSupportCallbackSuggestion) {
    if (customerSupportCallbackSuggestion) {
      this._customerSupportCallbackSuggestion = customerSupportCallbackSuggestion;
    } else {
      this.storage.clear(customerSupportCallbackSuggestionKey);
    }
  }

  @SessionStorage(invoicePartsKey)
  private _invoiceParts: TeilbetragsUebersicht;

  get invoiceParts(): TeilbetragsUebersicht {
    return this._invoiceParts;
  }

  set invoiceParts(invoiceParts: TeilbetragsUebersicht) {
    if (invoiceParts) {
      this._invoiceParts = invoiceParts;
    } else {
      this.storage.clear(invoicePartsKey);
    }
  }

  clear(): void {
    this.storage.clear(unpaidInvoicesSelectionViewModel);
    this.storage.clear(paymentSolutionTypeKey);
    this.storage.clear(newDueDateKey);
    this.storage.clear(lastOrderIdKey);
    this.storage.clear(retriedOnErrorKey);
    this.storage.clear(submitIdKey);
    this.storage.clear(personalDataKey);
    this.storage.clear(paymentPlanKey);
    this.storage.clear(planUnpaidInvoicesKey);
    this.storage.clear(noPaymentPlanAvailableKey);
    this.storage.clear(noPaymentExtensionAvailableKey);
    this.storage.clear(customerSupportCallbackSuggestionKey);
    this.storage.clear(invoicePartsKey);
    this.storage.clear(invoiceDetails);

    if (this.keepStorageOnce) {
      this.keepStorageOnce = false;
      return;
    }

    this.storage.clear(addressKey);
    this.storage.clear(clearingAccount);
    this.storage.clear(categoriesKey);
  }
}
