<footer class="bg-gray--400">
  <div class="py-5 py-lg-9 container wide">
    <div class="row mx-auto">
      <div class="col-lg">
        <div class="row">
          <div class="col-6 col-lg-auto">
            <p class="d-block font-size-16 mb-2 text-secondary fw-semibold">
              <a
                (click)="navigateToB2C('')"
                class="pointer text-decoration-none clickable-secondary"
                href="javascript:;"
                translate="global.footer.privat"
              ></a>
            </p>
            <ul class="list-unstyled font-size-14">
              <li class="mb-2">
                <a
                  (click)="orderProducts('privat')"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.orderProducts"></span>
                </a>
              </li>
              <li class="mb-2">
                <a
                  (click)="navigateToB2C(links.b2c.tariffChange)"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.tariffChange"></span>
                </a>
              </li>
              <li class="mb-2">
                <mwe-link
                  linkClass="clickable-secondary"
                  href="https://www.netigate.se/a/s.aspx?s=1186172X408938294X65929 "
                  labelKey="global.footer.feedback"
                  target="_blank"
                ></mwe-link>
              </li>
              <li class="mb-2">
                <a
                  (click)="navigateToB2C(links.b2c.goodies)"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.goodies"></span>
                </a>
              </li>
              <li class="mb-2">
                <mwe-link
                  linkClass="clickable-secondary"
                  href="https://www.wienenergie.at/privat/hilfe-und-kontakt/kontakt-servicezentren/ "
                  labelKey="global.footer.supportAndContact"
                  target="_blank"
                ></mwe-link>
              </li>
              <li class="mb-2">
                <a
                  (click)="navigateToB2C(links.b2c.newsletter)"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.newsletter"></span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-lg-auto">
            <p class="d-block font-size-16 mb-2 text-secondary fw-semibold">
              <a
                (click)="navigateToB2B('')"
                class="pointer text-decoration-none clickable-secondary"
                href="javascript:;"
                translate="global.footer.business"
              ></a>
            </p>
            <ul class="list-unstyled font-size-14">
              <li class="mb-2">
                <a
                  (click)="orderProducts('business')"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.orderProducts"></span>
                </a>
              </li>
              <li class="mb-2">
                <a
                  (click)="navigateToB2B(links.b2b.tariffChange)"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.tariffChange"></span>
                </a>
              </li>
              <li class="mb-2">
                <mwe-link
                  linkClass="clickable-secondary"
                  href="https://www.netigate.se/a/s.aspx?s=1186180X408938438X60251 "
                  labelKey="global.footer.feedback"
                  target="_blank"
                ></mwe-link>
              </li>
              <li class="mb-2">
                <a
                  (click)="navigateToB2B(links.b2b.goodies)"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.goodies"></span>
                </a>
              </li>
              <li class="mb-2">
                <mwe-link
                  linkClass="clickable-secondary"
                  href="https://www.wienenergie.at/business/hilfe-und-kontakt/kontakt/ "
                  labelKey="global.footer.supportAndContact"
                  target="_blank"
                ></mwe-link>
              </li>
              <li class="mb-2">
                <a
                  (click)="navigateToB2B(links.b2b.newsletter)"
                  class="pointer fw-normal clickable-secondary text-decoration-none text-none"
                  href="javascript:;"
                >
                  <span translate="global.footer.newsletter"></span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-lg-auto">
            <p class="d-block font-size-16 mb-2 text-secondary fw-semibold" translate="global.footer.links"></p>
            <ul class="list-unstyled font-size-14">
              <li class="mb-2">
                <mwe-link
                  linkClass="clickable-secondary"
                  href="https://www.wienenergie.at/privat/hilfe-und-kontakt/meine-wien-energie/#MWEservices"
                  labelKey="global.footer.upcomingFeatures"
                  target="_blank"
                ></mwe-link>
              </li>
              <li class="mb-2">
                <mwe-link
                  linkClass="clickable-secondary"
                  href="https://www.wienenergie.at/privat/produkte/energiesparservice/energiesparcheck/"
                  labelKey="global.footer.energySavingCheck"
                  target="_blank"
                ></mwe-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-auto">
        <div class="row gx-3 justify-content-center justify-content-lg-end align-items-center mb-5 mb-lg-4">
          <div class="col col-auto">
            <a
              class="d-block fs-5 lh-1 text-focus-ring text-secondary"
              href="https://www.facebook.com/WienEnergie"
              rel="noopener noreferrer"
              target="_blank"
              title="{{ 'global.footer.socialMedia.facebook' | translate }}"
            >
              <i class="fa-brands fa-facebook"></i>
            </a>
          </div>
          <div class="col col-auto">
            <a
              class="d-block fs-5 lh-1 text-focus-ring text-secondary"
              href="https://www.instagram.com/wienenergie"
              rel="noopener noreferrer"
              target="_blank"
              title="{{ 'global.footer.socialMedia.instagram' | translate }}"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
          <div class="col col-auto">
            <a
              class="d-block fs-5 lh-1 text-focus-ring text-secondary"
              href="https://www.tiktok.com/@wienenergie"
              rel="noopener noreferrer"
              target="_blank"
              title="{{ 'global.footer.socialMedia.tiktok' | translate }}"
            >
              <i class="fa-brands fa-tiktok"></i>
            </a>
          </div>
          <div class="col col-auto">
            <a
              class="d-block fs-5 lh-1 text-focus-ring text-secondary"
              href="https://twitter.com/wienenergie"
              rel="noopener noreferrer"
              target="_blank"
              title="{{ 'global.footer.socialMedia.x' | translate }}"
            >
              <svg fill="none" style="width: 1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.2437 2.25H21.5531L14.325 10.5094L22.8281 21.75H16.1719L10.9547 14.9344L4.99216 21.75H1.6781L9.40779 12.9141L1.25623 2.25H8.08122L12.7922 8.47969L18.2437 2.25ZM17.0812 19.7719H18.914L7.08279 4.125H5.11404L17.0812 19.7719Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
          <div class="col col-auto">
            <a
              class="d-block fs-5 lh-1 text-focus-ring text-secondary"
              href="https://www.youtube.com/WienEnergieGmbH"
              rel="noopener noreferrer"
              target="_blank"
              title="{{ 'global.footer.socialMedia.youtube' | translate }}"
            >
              <i class="fab fa-youtube"></i>
            </a>
          </div>
          <div class="col col-auto">
            <a
              class="d-block fs-5 lh-1 text-focus-ring text-secondary"
              href="http://blog.wienenergie.at"
              rel="noopener noreferrer"
              target="_blank"
              title="{{ 'global.footer.socialMedia.blog' | translate }}"
            >
              <i class="fas fa-newspaper"></i>
            </a>
          </div>
          <div class="col col-auto">
            <a
              class="d-block fs-5 lh-1 text-focus-ring text-secondary"
              href="https://www.linkedin.com/company/wien-energie-gmbh"
              rel="noopener noreferrer"
              target="_blank"
              title="{{ 'global.footer.socialMedia.linkedin' | translate }}"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
        <ul class="row gx-3 gy-2 list-unstyled font-size-14 justify-content-center mb-0">
          <li class="col-auto">
            <a [routerLink]="links.termsOfUseRoute" class="fw-normal clickable-secondary text-decoration-none text-none">
              <span translate="global.footer.termsOfUse"></span>
            </a>
          </li>
          <li class="col-auto">
            <mwe-link
              linkClass="clickable-secondary"
              href="https://www.wienenergie.at/datenschutz"
              labelKey="global.footer.privacyPolicy"
              target="_blank"
            ></mwe-link>
          </li>
          <li class="col-auto">
            <mwe-link
              linkClass="clickable-secondary"
              href="https://www.wienenergie.at/impressum"
              labelKey="global.footer.legalInformation"
              target="_blank"
            ></mwe-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="border-top border-white py-5">
    <div class="container">
      <p class="mb-0 font-size-14 fw-semibold text-center text-lg-start">
        © {{ currentYear }} {{ 'global.footer.companyInformation' | translate }}
      </p>
    </div>
  </div>
</footer>
