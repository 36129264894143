import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notIn(forbidden: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const badValue = { equal: false };
    if (!!control.value) {
      return forbidden.includes(control.value.trim()) ? badValue : null;
    }
    return badValue;
  };
}
