<mwe-basic-card>
  <h6 translate="summary.additionalDetails"></h6>
  <div class="row w-100">
    <div class="col-12">
      <ul class="list-separated spacing-4" data-test="products-summary-additionalDetails">
        <li *ngIf="showMeterReading" data-test="products-summary-additionalDetails-showMeterReading">
          <p class="text--big mb-0">
            <strong><span translate="summary.meterReading.title"></span></strong><br />
            <span translate="summary.meterReading.description"></span>
          </p>
        </li>
        <li *ngIf="voucherInfoSignal()" data-test="products-summary-additionalDetails-voucher">
          <p class="text--big mb-0">
            <strong
              ><span
                translate="summary.voucherInfo.title"
                [translateParams]="{ voucherCode: voucherInfoSignal().aktionscode }"
              ></span></strong
            ><br />
            <span *ngIf="voucherStrom()?.length > 0" [innerHTML]="voucherStrom() + '<br/>'"></span>
            <span *ngIf="voucherGas()?.length > 0" [innerHTML]="voucherGas() + '<br/>'"></span>
          </p>
        </li>
        <li *ngIf="mgmCode" data-test="products-summary-additionalDetails-mgmCode">
          <p class="text--big mb-0">
            <strong><span translate="newClient.mgm.summaryTitle"></span></strong><br />
            <span> {{ mgmCode }}</span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</mwe-basic-card>
