import { ChangeDetectionStrategy, Component, input, OnInit, output, TemplateRef } from '@angular/core';
import { TextNodeType } from '@mwe/models';
import { HeadlineService } from '../headline/headline.service';

@Component({
  selector: 'mwe-tooltip-card',
  templateUrl: './tooltip-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeadlineService],
})
export class TooltipCardComponent implements OnInit {
  header = input<string>();
  cardClass = input<{ [value: string]: boolean }>();
  isHeaderTitle = input<boolean>();
  content = input<string>();
  iconClass = input<string>();
  hasTooltipBody = input<boolean>();

  isHeaderBold = input<boolean>(false);
  isClickable = input<boolean>(false);
  orangeInfoTooltipText = input<string | TemplateRef<string>>(null);
  disabled = input<boolean>(false);
  textNodeType = input<TextNodeType>('p');
  onClick = output();

  constructor(private headlineService: HeadlineService) {}

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.textNodeType());
  }
}
