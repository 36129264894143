import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IFormInput, IFormModel } from '@mwe/models';
import { formatISODate, isValidDate } from '@mwe/utils';
import dayjs, { Dayjs } from 'dayjs';
import { FormComponent } from '../../form.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mwe-birthdate-input',
  templateUrl: './birthdate-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BirthdateInputComponent,
      multi: true,
    },
  ],
})
export class BirthdateInputComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  // hidden input which holds current-date (YYYY-MM-DD) and is used to communicate with parent-form
  @ViewChild(FormControlDirective) dateValueControl: FormControlDirective;
  @ViewChild('dateInputForm') dateInputFormRef: FormComponent;
  @ViewChild('datePickerForm') datePickerFormRef: FormComponent;

  @Input() parentForm: UntypedFormGroup;
  @Input() inputElem: IFormInput;
  @Input() isLoading = false;

  // day, month, year input fields for user. user-inputs is converted to hidden input (dateControl)
  dateInputFormModel: IFormModel;
  dateInputFormFields = {
    group: 'mwe-order-personal-data-birthday_group',
    day: 'mwe-order-personal-data-birthday_dd',
    month: 'mwe-order-personal-data-birthday_dm',
    year: 'mwe-order-personal-data-birthday_dy',
  };
  // datepicker form
  datePickerFormModel: IFormModel;
  private formValidationSubscription = new Subscription();
  private datePickerFormFieldName = 'mwe-order-personal-data-birthday-date-picker';

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.createDateForm();
  }

  ngOnDestroy(): void {
    this.formValidationSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    // syncs internal validation errors with parent form
    setTimeout(() => {
      this.formValidationSubscription = this.dateInputFormRef.form.statusChanges.subscribe(() => {
        this.parentForm.get(this.inputElem.name).setErrors(this.getFormValidationErrors());
      });
    });
  }

  onDateInputFormChanged() {
    const data = this.dateInputFormRef?.form?.value;
    if (!data) {
      return;
    }

    const day = data[this.dateInputFormFields.day];
    const month = data[this.dateInputFormFields.month];
    const year = data[this.dateInputFormFields.year];
    if (!day || !month || !year) {
      return;
    }

    const isoDate = formatISODate(year, month, day);

    if (!isValidDate(isoDate)) {
      this.parentForm.controls[this.inputElem.name].markAsDirty();
      this.dateInputFormRef.setError(this.dateInputFormFields.day, { incorrect: true });
      return;
    }

    this.dateInputFormRef.setError(this.dateInputFormFields.day, null);

    const date = dayjs(isoDate);
    this.setValue(date);
    this.updateDatePickerForm();
  }

  onDatePickerFormChanged() {
    // datepicker - DD.MM.YYYY
    const rawValue = this.datePickerFormRef?.form?.value?.[this.datePickerFormFieldName];
    if (!rawValue) {
      return;
    }

    const date = dayjs(rawValue, 'DD.MM.YYYY');
    this.setValue(date);
    this.updateDateInputForm();
  }

  // ControlValueAccessor start
  registerOnTouched(fn: any): void {
    this.dateValueControl?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.dateValueControl?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(data: any): void {
    this.dateValueControl?.valueAccessor?.writeValue(data);
    this.updateDateInputForm();
    this.updateDatePickerForm();
  }

  setDisabledState(isDisabled: boolean): void {
    this.dateValueControl?.valueAccessor?.setDisabledState(isDisabled);
  }

  // ControlValueAccessor end

  getFormValidationErrors() {
    let controlErrors: ValidationErrors;
    Object.keys(this.dateInputFormRef.form.controls).forEach(key => {
      if (this.dateInputFormRef.form.get(key).errors != null) {
        controlErrors = { ...controlErrors, ...this.dateInputFormRef.form.get(key).errors };
      }
    });

    return controlErrors;
  }

  private createDateForm() {
    // at ngOnInit this.formControlDirective.control is undefined, use this workaround
    const control = this.controlContainer?.control?.get(this.inputElem.name);
    const initialDate = isValidDate(control?.value) ? dayjs(control.value) : null;

    this.dateInputFormModel = {
      inputs: [
        {
          name: this.dateInputFormFields.group,
          labelKey: 'newClient.personalDetails.birthday.label',
          rowClass: 'g-0',
          inputs: [
            {
              name: this.dateInputFormFields.day,
              initialValue: initialDate?.format('DD'),
              validators: [Validators.required, Validators.minLength(2), Validators.maxLength(2), Validators.min(1), Validators.max(31)],
              placeholder: 'newClient.personalDetails.birthday.placeholders.birthday_dd',
              inputClasses: 'col-auto',
              validate: false,
              dataType: 'text',
              maxLength: 2,
              directiveType: 'integer; leadingZero; autoFocusElement(mwe-order-personal-data-birthday_dm)',
              isDisabled: this.inputElem.isDisabled,
              ariaLabel: 'newClient.personalDetails.birthday.labels.birthday_dd',
              inputStyle: 'width:22px',
              inputCssClass: 'field-sizing-content',
              noAutocomplete: true,
              content: {
                after: '.',
              },
            },
            {
              name: 'mwe-order-personal-data-birthday_dm',
              initialValue: initialDate?.format('MM'),
              validators: [Validators.required, Validators.minLength(2), Validators.maxLength(2), Validators.min(1), Validators.max(12)],
              placeholder: 'newClient.personalDetails.birthday.placeholders.birthday_dm',
              inputClasses: 'col-auto',
              validate: false,
              dataType: 'text',
              maxLength: 2,
              directiveType: 'integer; leadingZero; autoFocusElement(mwe-order-personal-data-birthday_dy)',
              isDisabled: this.inputElem.isDisabled,
              ariaLabel: 'newClient.personalDetails.birthday.labels.birthday_dm',
              inputStyle: 'width:28px',
              inputCssClass: 'field-sizing-content',
              noAutocomplete: true,
              content: {
                after: '.',
              },
            },
            {
              name: 'mwe-order-personal-data-birthday_dy',
              initialValue: initialDate?.format('YYYY'),
              validators: [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
              placeholder: 'newClient.personalDetails.birthday.placeholders.birthday_dy',
              inputClasses: 'col-auto',
              validate: false,
              dataType: 'text',
              maxLength: 4,
              directiveType: 'integer',
              isDisabled: this.inputElem.isDisabled,
              ariaLabel: 'newClient.personalDetails.birthday.labels.birthday_dy',
              inputStyle: 'width:48px',
              inputCssClass: 'field-sizing-content',
              noAutocomplete: true,
            },
          ],
        },
      ],
    };

    this.datePickerFormModel = {
      inputs: [
        {
          name: this.datePickerFormFieldName,
          componentType: 'datepicker',
          initialValue: initialDate?.format('DD.MM.YYYY') ?? '',
          datepickerConfig: {
            ...this.inputElem.datepickerConfig,
          },
        },
      ],
    };
  }

  private setValue(date: Dayjs): void {
    this.dateValueControl?.control?.setValue(date?.format('YYYY-MM-DD'));
  }

  private getValue(): Dayjs {
    const currentValue = this.dateValueControl?.control.getRawValue();
    return isValidDate(currentValue) ? dayjs(currentValue) : null;
  }

  private updateDateInputForm(): void {
    const currentDate = this.getValue();
    if (!currentDate) {
      return;
    }

    const data = {};
    data[this.dateInputFormFields.day] = currentDate.format('DD');
    data[this.dateInputFormFields.month] = currentDate.format('MM');
    data[this.dateInputFormFields.year] = currentDate.format('YYYY');
    this.dateInputFormRef.form.patchValue(data, { emitEvent: false });
  }

  private updateDatePickerForm(): void {
    // datepicker - DD.MM.YYYY
    const data = {};
    data[this.datePickerFormFieldName] = this.getValue() ? dayjs(this.getValue()).format('DD.MM.YYYY') : null;
    this.datePickerFormRef.form.patchValue(data, { emitEvent: false });
  }
}
