import { Component, computed, input, OnInit, signal } from '@angular/core';
import { ApprovalFormItem } from '@mwe/models';
import { isArrayWithMinOneItem } from '@mwe/utils';

@Component({
  selector: 'mwe-approval-form-item',
  templateUrl: './approval-form-item.component.html',
})
export class ApprovalFormItemComponent implements OnInit {
  item = input.required<ApprovalFormItem>();
  isApprovalFirmDirty = input.required<boolean>();
  isItemDirty = signal<boolean>(false);
  isChecked = signal<boolean>(false);

  isErrorVisible = computed<boolean>(() => {
    if (!this.isApprovalFirmDirty() && !this.isItemDirty()) {
      return false;
    }

    return !this.isValid();
  });
  isValid = computed<boolean>(() => {
    return !this.isCheckboxMandatory() || this.isChecked();
  });

  labelId = computed<string>(() => {
    return `${this.item().id}-checkbox`;
  });

  hasCheckbox = computed<boolean>(() => {
    return !!this.item().checkbox;
  });
  hasBulletPoints = computed<boolean>(() => {
    return isArrayWithMinOneItem(this.item().bulletPoints);
  });
  hasText = computed<boolean>(() => {
    return !!this.item().text;
  });
  isCheckboxMandatory = computed<boolean>(() => {
    return this.hasCheckbox() && this.item().checkbox.isMandatory;
  });

  ngOnInit(): void {
    this.isChecked.set(this.item().checkbox?.initialValue);
  }

  onCheckBoxChanged(checked: boolean) {
    this.isChecked.set(checked);
    this.isItemDirty.set(true);
  }
}
