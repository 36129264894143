import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { ContractDiscount, ContractInfo, ContractPrices, Product } from '@mwe/models';
import { isArrayWithMinOneItem, mapContractDiscountResponse } from '@mwe/utils';
import dayjs from 'dayjs';
import { lastValueFrom } from 'rxjs';
import { ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';

@Injectable({ providedIn: 'root' })
export class ContractService {
  resourceUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/contract/';
  }

  async getPrices(product: Product): Promise<ContractPrices[]> {
    const url = this.resourceUrl + 'prices';
    const params = new HttpParams()
      .set('clearingAccountId', product.accountNumber)
      .set('anlageId', product.anlageId)
      .set('systemId', product.systemId);

    return lastValueFrom(this.http.get<ContractPrices[]>(url, { params }));
  }

  async getContractPriceComponent(product: Product): Promise<ContractPrices> {
    const data = await this.getPrices(product);
    return data?.find(item => item.anlageId === product.anlageId);
  }

  async getContractInfo(contractNumber: string, product: Product): Promise<ContractInfo> {
    const url = this.resourceUrl + 'info';
    const params = new HttpParams()
      .set('contractNumber', contractNumber)
      .set('systemId', product.systemId)
      .set('category', product.category);

    return lastValueFrom(this.http.get<ContractInfo>(url, { params }));
  }

  async getDiscounts(contractNumber: string, product: Product): Promise<ContractDiscount[]> {
    const data = await this.getContractInfo(contractNumber, product);

    if (!isArrayWithMinOneItem(data?.abrechnungsKonstrukt?.konditionen)) {
      return [];
    }

    const discounts = data.abrechnungsKonstrukt.konditionen.map(k => mapContractDiscountResponse(k));
    // remove discounts where end-date is more then two years in the past or faktor === 0
    const filteredDiscounts = discounts.filter(d => {
      const twoYearsAgo = dayjs().subtract(2, 'years');
      const validTo = dayjs(d.validTo);
      const hasValidAge = twoYearsAgo.isBefore(validTo) || twoYearsAgo.isSame(validTo, 'day');
      const hasValidData = d.factor !== 0;

      return hasValidAge && hasValidData;
    });

    return filteredDiscounts.sort((a, b) => (dayjs(a.validFrom).isBefore(dayjs(b.validFrom)) ? 1 : -1));
  }
}
