@if (!useRedesignedCard) {
  <mwe-tooltip-card
    iconClass="fas fa-user"
    [header]="tooltipHeader"
    [content]="tooltipContent"
    [isClickable]="isClickable"
    [cardClass]="tooltipCardClass"
    (onClick)="emitOnClickEvent()"
    [hasTooltipBody]="hasContent"
  >
    <ng-content slot="tooltip-body"></ng-content>
  </mwe-tooltip-card>
}

@if (useRedesignedCard) {
  <mwe-selection-card
    iconClass="fa-solid fa-user"
    [header]="tooltipHeader"
    [content]="tooltipContent"
    [isClickable]="isClickable"
    (onClick)="emitOnClickEvent()"
  />
}
