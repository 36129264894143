<mwe-basic-card [cardTitle]="cardTitle" [hasBackground]="hasBackground">
  <ng-container *ngFor="let item of items; let last = last">
    <div class="d-flex align-items-center" [attr.data-test]="'radio-button-item-' + item.id">
      <div class="custom-control custom-radio form-check mr-2">
        <input
          type="radio"
          [id]="item.id"
          class="form-check-input"
          [value]="item.value"
          [disabled]="isDisabled"
          (change)="updateSelectedOption($event)"
          [attr.name]="groupName"
          [checked]="selectedOption === item.value"
        />
      </div>
      <div class="flex-fill">
        <label [attr.for]="item.id">
          <h6 *ngIf="item.title" [translate]="item.title"></h6>
          <p *ngIf="item.text" class="mb-0" [innerHTML]="item.text | translate | safeHtml"></p>
        </label>
      </div>
    </div>
    <hr *ngIf="!last" />
  </ng-container>
</mwe-basic-card>
