<button
  [id]="buttonId"
  [attr.data-test]="buttonId"
  [attr.type]="buttonType"
  [attr.title]="title || null"
  [ngClass]="btnCssClasses"
  (click)="onClick($event)"
  (keydown.enter)="onClick($event)"
  (keydown.space)="onClick($event)"
  [disabled]="disabled"
  #btnElement
>
  @if (prependIconClass) {
    <i class="{{ prependIconClass }} mr-2"></i>
  }
  <ng-container *ngIf="showHoverEffect && category === 'primary'">
    <div [ngClass]="['wave-1', 'wave--effect', waveCssClass]"></div>
    <div [ngClass]="['wave-2', 'wave--effect', waveCssClass]"></div>
  </ng-container>
  @if (!showSpinner) {
    <span [style.pointer-events]="disabled ? 'none' : ''">{{ labelKey | translate }}</span>
    <ng-content />
  }
  @if (showSpinner) {
    <span class="loading-spinner"></span>
  }
</button>
