<div class="form-floating">
  <select
    class="form-select"
    [ngClass]="{
      'is-invalid': parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine,
    }"
    [attr.id]="inputElem.name"
    [attr.name]="inputElem.name"
    [attr.data-test]="'form-input-' + inputElem.name"
    [attr.disabled]="isLoading || inputElem.isDisabled ? '' : null"
    (change)="selectChanged.emit({ option: getOptionByValue($event.target.value), name: inputElem.name })"
    [attr.aria-describedby]="ariaDescribedBy || null"
    [attr.aria-invalid]="!isValid"
    [required]="isRequired || null"
  >
    <option *ngIf="inputElem.placeholder" [attr.disabled]="isRequired ? 'disabled' : null" [attr.hidden]="isRequired ? 'disabled' : null">
      {{ inputElem.placeholder | translate }}
    </option>
    <option
      *ngFor="let option of inputElem.options"
      [value]="option.value"
      [attr.data-test]="inputElem.name + '-option-' + option.value"
      [selected]="isSelected(option)"
    >
      {{ option.text | translate }}
    </option>
  </select>
  <label
    [attr.for]="inputElem.name"
    class="form-label"
    [ngClass]="inputElem.labelCss"
    [translate]="inputElem.labelKey"
    *ngIf="inputElem.labelKey"
  ></label>
</div>
