import { Component, input, viewChildren } from '@angular/core';
import { TariffSelectionCardData } from '@mwe/models';
import { TariffSelectionCardCollapseItemComponent } from './tariff-selection-card-collapse-item.component';

@Component({
  selector: 'mwe-tariff-selection-card-back-ui',
  templateUrl: './tariff-selection-card-back-ui.component.html',
})
export class TariffSelectionCardBackUiComponent {
  selectedTariff = input.required<TariffSelectionCardData>();
  collapseItems = viewChildren(TariffSelectionCardCollapseItemComponent);

  onCollapseToggleClicked() {
    this.collapseItems().forEach(item => item.collapse());
  }
}
