<div [attr.data-test]="testId()" (click)="onCardClick()" class="card-basic card-clickable" tabindex="0">
  <div class="card-basic-body">
    <div class="row">
      @if (isLoading()) {
        <div class="col">
          <i class="fas fa-chart-mixed text-secondary fa-2x mb-3 placeholder placeholder-wave"></i>
          <div class="h6"><span class="placeholder placeholder-wave w-100"></span></div>
        </div>
        <div class="col-auto align-self-center">
          <i class="fas fa-chevron-right text-secondary"></i>
        </div>
      } @else {
        <div class="col">
          <i class="fas {{ cardIcon() }} text-secondary fa-2x mb-3"></i>
          <div class="h6" [translate]="cardTitle()"></div>
        </div>
        <div class="col-auto align-self-center">
          <i class="fas fa-chevron-right text-secondary"></i>
        </div>
      }
    </div>
  </div>
</div>
