import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperOptions } from 'swiper/types/swiper-options';
import Swiper from 'swiper';
import { A11y, Keyboard, Navigation, Pagination } from 'swiper/modules';

const DEFAULT_SWIPER_CONFIG: SwiperOptions = {
  direction: 'horizontal',
  modules: [Pagination, Navigation, A11y, Keyboard],
  slidesPerView: 2.2,
  a11y: {
    enabled: true,
  },
  keyboard: true,
  initialSlide: 0,
  spaceBetween: 20,
  touchEventsTarget: 'container',
  breakpoints: {
    992: {
      slidesPerView: 2.2,
    },
    850: {
      spaceBetween: 30,
      slidesPerView: 2,
    },
    650: {
      slidesPerView: 1.8,
    },
    500: {
      slidesPerView: 1.4,
    },
    400: {
      slidesPerView: 1.1,
    },
    0: {
      slidesPerView: 1,
    },
  },
};

@Component({
  selector: 'mwe-horizontal-swiper',
  templateUrl: './horizontal-swiper.component.html',
})
export class HorizontalSwiperComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper') swiper: ElementRef;
  @Input() swiperId = 'mwe-swiper-id';
  @Input() additionalConfig: SwiperOptions = {};
  config: SwiperOptions;
  swiperInstance: Swiper;

  ngOnInit(): void {
    this.config = {
      ...DEFAULT_SWIPER_CONFIG,
      navigation: {
        prevEl: `[data-swiper-btn="prev"].${this.swiperId}`,
        nextEl: `[data-swiper-btn="next"].${this.swiperId}`,
      },
      ...this.additionalConfig,
    };
  }

  ngAfterViewInit(): void {
    this.swiperInstance = new Swiper(this.swiper.nativeElement, this.config);
  }
}
