<ng-container *ngIf="isErrorVisible()">
  <mwe-info-text colorClass="text-danger" [infoTextCode]="inputElem.infoTextCode" *ngIf="inputElem.infoTextCode">
    <ng-container *ngTemplateOutlet="errorMsg"></ng-container>
  </mwe-info-text>

  <ng-container *ngIf="!inputElem.infoTextCode">
    <ng-container *ngTemplateOutlet="errorMsg"></ng-container>
  </ng-container>

  <ng-template #errorMsg>
    <div
      [attr.id]="'form-input-desc--' + inputElem.name"
      [attr.data-test]="'form-error-' + inputElem.name"
      class="row gx-1 font-size-12 text-danger mt-1"
    >
      <div class="col-auto"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
      <div class="col"><span [translate]="getErrorText()" [translateParams]="inputElem.validationErrorLabelParams"></span></div>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="!isErrorVisible() && inputElem.helpLabelKey">
  <div [attr.id]="'form-input-desc--' + inputElem.name" class="row gx-1 font-size-12 text-neutral-600 mt-1">
    <div class="col-auto"><i class="fa fa-info-circle" aria-hidden="true"></i></div>
    <div class="col"><span [translate]="inputElem.helpLabelKey"></span></div>
  </div>
</ng-container>
