<ng-container *ngIf="!hideHeader">
  <header
    class="bg-primary"
    [ngClass]="{ 'header-height-outer': isLongHeader, 'header-bg': !isOrangeHeader, 'mb-5 mb-lg-6': withBottomMargin }"
  >
    <div
      class="container header-height-inner"
      [ngClass]="{ 'container-wide': wideHeader, limited: !wideHeader, 'not-md-limited': !wideHeader }"
    >
      <ng-content></ng-content>
      <div class="mt-md-8 mt-sm-4 pt-5" *ngIf="title && isLongHeader">
        <h1 [translate]="title" class="text-white"></h1>
      </div>
    </div>
    <div class="container">
      <mwe-association-suggestion-notification></mwe-association-suggestion-notification>
    </div>
  </header>
</ng-container>
