import { IAddressProducts, parseAddress } from 'libs/models/src/lib/address/address.model';
import { AddressGroup } from 'libs/models/src/lib/address/address-group.model';
import { parseProduct } from '@mwe/models';

export function checkAddressProductsForAddressGroupUpdates(addressProducts: IAddressProducts[], updates: AddressGroup[]): void {
  const addresses = addressProducts.map(addressProduct => addressProduct.address);

  updates.forEach((update: AddressGroup) => {
    const addressProduct = addresses.find(elem => elem.addressGroup.addressCode === update.addressCode);

    if (addressProduct) {
      addressProduct.addressGroup = update;
      addressProduct.addressGroup.matchedGroup = true;
    }
  });
}

// todo remove this temp solution once every Address/Product-Class is refactored to interface
export function copyAddressGroupMapImmutable(addressProductsMap: Map<string, IAddressProducts>): Map<string, IAddressProducts> {
  const newAddressProductsMap = new Map<string, IAddressProducts>();

  Array.from(addressProductsMap.values()).forEach(oldAp => {
    const addressProduct: IAddressProducts = {
      address: parseAddress(oldAp.address),
      products: oldAp.products.map(oldP => parseProduct(oldP)),
      areSomeProductsWhiteListed: oldAp.areSomeProductsWhiteListed,
    };
    newAddressProductsMap.set(addressProduct.address.getHashCode(), addressProduct);
  });

  return newAddressProductsMap;
}
