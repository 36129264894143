@if (personalBillingTitle) {
  <h6 [translate]="personalBillingTitle"></h6>
}
<div class="row w-100">
  <div class="col-12">
    <ul class="list-separated spacing-4" data-test="products-summary-personalBilling">
      <li data-test="products-summary-personalBilling-payment" *ngIf="isPaymentInfoVisible">
        <strong [ngClass]="paymentDataLabelClass"><span translate="summary.paymentData"></span></strong>
        <ng-container *ngIf="paymentIcon">
          <div class="row mb-1 mt-1">
            <div class="col col-auto">
              <div class="payment-badge">
                <img class="img-fluid" src="/assets/icons/payment/{{ paymentIcon }}.svg" [alt]="paymentText" />
              </div>
            </div>
            <div class="col">
              <p class="text--big mb-0" [innerHTML]="paymentText"></p>
            </div>
          </div>
        </ng-container>
      </li>
      <li data-test="products-summary-personalBilling-eBilling">
        <p class="text--big mb-0">
          <strong [ngClass]="eBillingLabelClass" class="d-inline-block mb-2"><span [translate]="summaryBillingTitleText"></span></strong
          ><br />
          <strong *ngIf="billingAddressTitleText"><span innerHTML="{{ billingAddressTitleText | translate }} "></span></strong>
          <span class="d-inline-block mb-2" data-test="products-summary-personalBilling-invoice-address"> {{ formattedAddress }}</span
          ><br />
          <strong *ngIf="eRechnungInfo?.isERechnungsEmpfaenger && shippingEmailTitleText">
            <span innerHTML="{{ shippingEmailTitleText | translate }} "></span>
          </strong>
          <span *ngIf="eRechnungInfo?.isERechnungsEmpfaenger && shippingEmailTitleText">{{ eRechnungInfo.rechnungEmail }}</span>
          <span
            *ngIf="eRechnungInfo?.isERechnungsEmpfaenger && !shippingEmailTitleText"
            data-test="products-summary-personalBilling-email"
            translate="summary.invoiceAddress.shipping.email"
            [translateParams]="{ email: eRechnungInfo?.rechnungEmail }"
          ></span>
          <span *ngIf="!eRechnungInfo?.isERechnungsEmpfaenger" translate="summary.invoiceAddress.shipping.post"></span>
        </p>
      </li>
      <li data-test="products-summary-contactData-email" *ngIf="contactDataEmailAddress">
        <p class="text--big mb-0">
          <strong [ngClass]="contactDataEmailAddressLabelClass"><span translate="summary.contactDataEmailAddress"></span></strong><br />
          <span data-test="products-summary-contactData-email-address">
            {{ contactDataEmailAddress }}
          </span>
        </p>
      </li>
      <ng-container *ngIf="additionalBulletPoints">
        <li *ngFor="let point of additionalBulletPoints" [attr.data-test]="point.id">
          <p class="text--big mb-0">
            <strong><span [translate]="point.title"></span></strong><br />
            {{ point.text | translate }}
          </p>
        </li>
      </ng-container>
    </ul>
    <mwe-new-bank-account-info-texts
      *ngIf="areBankAccountInfoTextsVisible"
      [productCategories]="productCategories"
    ></mwe-new-bank-account-info-texts>
  </div>
</div>
<div class="row w-100 mt-3" *ngIf="invoiceInfoAlertMessage" data-test="products-summary-personalBilling-eBillingAlert">
  <div class="col-12">
    <mwe-alert [messageKey]="invoiceInfoAlertMessage"></mwe-alert>
  </div>
</div>
<mwe-info-text-card [text]="infoTextCardLabel" *ngIf="infoTextCardLabel" class="d-block mt-5"></mwe-info-text-card>
