<div class="{{ marginTopCss }}">
  <p
    #collapsible
    class="{{ textCss || 'text--large' }} mb-1"
    [ngClass]="showMoreText ? 'clamp-2' : ''"
    [innerHTML]="textKey | translate"
    [id]="uuid"
    [attr.aria-expanded]="!showMoreText"
  ></p>
  <p class="mb-0">
    <button
      class="btn btn-clean text-secondary"
      style="text-transform: none; letter-spacing: 0"
      mweClickPreventDefault
      (click)="toggleText()"
      [attr.aria-expanded]="!showMoreText"
      [attr.aria-controls]="uuid"
    >
      {{ (showMoreText ? showMoreKey : showLessKey) | translate }}
    </button>
  </p>
</div>
