import { Injectable } from '@angular/core';
import {
  containsFernwaermeProducts,
  containsStromOrGasProducts,
  CryptographyUtils,
  getClearingAccountsFrom,
  getUniqueValues,
  hasSameClearingAccount,
  isArrayWithMinOneItem,
  sortCustomerNumberInfos,
  sortProductsByCategory,
  translateCategory,
} from '@mwe/utils';
import {
  ClearingAccount,
  GetRatenPlanResponse,
  IAddress,
  IAddressProducts,
  ICustomerBankKonto,
  ICustomerNumberInfo,
  IGeschaeftspartnerBeziehung,
  Product,
  Ratenplan,
  Stundung,
  UnpaidInvoices,
  Verrechnungskonto,
  VerrechnungskontoResponse,
} from '@mwe/models';
import { MemoryCacheItem, MemoryCacheService } from '../../cache/memory-cache.service';
import { ClearingAccountService } from './clearing-account.service';
import { LoggingService } from '../../logging/logging.service';
import { ProductsWhiteListLogic } from '../../products/products-white-list.logic';
import { TranslateService } from '@ngx-translate/core';
import { ProductLogic } from '../../products/product.logic';
import { CAInformationRequestStateService } from './ca-information-request-state.service';
import { Router } from '@angular/router';
import { CLEARING_ACCOUNT_INFORMATION_REQUEST_INFORMATION_ROUTE, CLEARING_ACCOUNT_INFORMATION_REQUEST_ROUTE } from '@mwe/constants';
import { AccountLogic } from '../../account/account.logic';

@Injectable({ providedIn: 'root' })
export class ClearingAccountLogic {
  private areAllUnpaidInvoicesInMemory = false;

  constructor(
    private memoryCacheService: MemoryCacheService,
    private translateService: TranslateService,
    private clearingAccountService: ClearingAccountService,
    private caInfoStateService: CAInformationRequestStateService,
    private productLogic: ProductLogic,
    private productWhiteListLogic: ProductsWhiteListLogic,
    private loggingService: LoggingService,
    private router: Router,
    private accountLogic: AccountLogic,
  ) {}

  getClearingAccountsForProducts(products: Product[]): Promise<ClearingAccount[]> {
    const unique: Product[] = [];
    products.forEach(p => {
      if (unique.find(u => hasSameClearingAccount(p, u))) {
        return;
      }
      unique.push(p);
    });

    const promises = unique.map(product => {
      return this.getUnpaidInvoicesForProduct(product).then(invoices => {
        return this.getClearingAccountFromProductAndInvoice(product, invoices);
      });
    });

    return Promise.all(promises);
  }

  getClearingAccountFromProductAndInvoice(product: Product, invoices: UnpaidInvoices): ClearingAccount {
    return {
      accountId: product.accountNumber,
      businessPartnerId: product.businessPartnerNumber,
      systemId: product.systemId,
      unpaidInvoices: invoices,
    };
  }

  getUnpaidInvoicesForProduct(product: Product): Promise<UnpaidInvoices> {
    return this.getUnpaidInvoices(product.accountNumber, product.businessPartnerNumber, product.systemId);
  }

  reloadUnpaidInvoices(clearingAccount: ClearingAccount): Promise<UnpaidInvoices> {
    const aId = clearingAccount.accountId;
    const bId = clearingAccount.businessPartnerId;
    const sId = clearingAccount.systemId;
    this.memoryCacheService.delete(MemoryCacheItem.UnpaidInvoices, aId, bId, sId);
    return this.getUnpaidInvoices(clearingAccount.accountId, clearingAccount.businessPartnerId, clearingAccount.systemId);
  }

  // verrechnungsKontoId === vertragsKontoNummer === vertragsKontoId === accountId
  async getUnpaidInvoices(accountId: string, businessPartnerId: string, systemId: string): Promise<UnpaidInvoices> {
    if (this.memoryCacheService.has(MemoryCacheItem.UnpaidInvoices, accountId, businessPartnerId, systemId)) {
      return Promise.resolve(this.memoryCacheService.get(MemoryCacheItem.UnpaidInvoices, accountId, businessPartnerId, systemId));
    }

    const response = await this.clearingAccountService.getUnpaidInvoices(accountId, businessPartnerId, systemId);
    this.memoryCacheService.set(MemoryCacheItem.UnpaidInvoices, response, accountId, businessPartnerId, systemId);
    return response;
  }

  getAllUnpaidInvoices(addressProducts: IAddressProducts[]): Promise<ClearingAccount[]> {
    if (this.areAllUnpaidInvoicesInMemory) {
      return Promise.resolve(this.loadAllUnpaidInvoicesFromMemory(addressProducts));
    }

    return this.loadAllUnpaidInvoicesFromServer(addressProducts);
  }

  async getActivePaymentPlan(products: Product[]): Promise<Ratenplan> {
    if (!isArrayWithMinOneItem(products)) {
      return null;
    }

    if (!containsStromOrGasProducts(products) && !containsFernwaermeProducts(products)) {
      return null;
    }

    try {
      const response = await this.getPaymentPlans(products[0].accountNumber, products[0].businessPartnerNumber, products[0].systemId);
      if (!isArrayWithMinOneItem(response?.ratenplaene)) {
        return null;
      }

      return response.ratenplaene.find(rp => rp.status === 'aktiv' || rp.status === 'offen');
    } catch (e) {
      // ignore error
      return null;
    }
  }

  // verrechnungsKontoId === vertragsKontoNummer === vertragsKontoId === accountId
  async getPaymentPlans(accountId: string, businessPartnerId: string, systemId: string): Promise<GetRatenPlanResponse> {
    return await this.clearingAccountService.getPaymentPlans(accountId, businessPartnerId, systemId);
  }

  // verrechnungsKontoId === vertragsKontoNummer === vertragsKontoId === accountId
  async getPaymentPlan(accountId: string, businessPartnerId: string, systemId: string): Promise<GetRatenPlanResponse> {
    return await this.clearingAccountService.getPaymentPlan(accountId, businessPartnerId, systemId);
  }

  // verrechnungsKontoId === vertragsKontoNummer === vertragsKontoId === accountId
  async getPaymentExtension(accountId: string, businessPartnerId: string, systemId: string): Promise<Stundung> {
    return await this.clearingAccountService.getPaymentExtension(accountId, businessPartnerId, systemId);
  }

  async getAllAccountInfosIgnoringErrors(products: Product[]): Promise<VerrechnungskontoResponse[]> {
    const resultList = await Promise.allSettled(this.loadAllAccountInfos(products));
    return resultList.filter(r => r.status === 'fulfilled').map(r => (r as PromiseFulfilledResult<VerrechnungskontoResponse>).value);
  }

  getAllAccountInfos(products: Product[]): Promise<VerrechnungskontoResponse[]> {
    return Promise.all(this.loadAllAccountInfos(products));
  }

  async getAccountInfo(accountNumber: string, businessPartnerNumber: string, systemId: string): Promise<VerrechnungskontoResponse> {
    if (this.memoryCacheService.has(MemoryCacheItem.ClearingAccountDetails, accountNumber, businessPartnerNumber, systemId)) {
      return this.memoryCacheService.get(MemoryCacheItem.ClearingAccountDetails, accountNumber, businessPartnerNumber, systemId);
    }

    const response = await this.clearingAccountService.getAccountInfo(accountNumber, systemId);
    const responseDecrypted = await this.getDecryptedIBAN(response);
    this.memoryCacheService.set(MemoryCacheItem.ClearingAccountDetails, responseDecrypted, accountNumber, businessPartnerNumber, systemId);
    return responseDecrypted;
  }

  deleteAccountInfo(accountNumber: string, businessPartnerNumber: string, systemId: string): void {
    this.memoryCacheService.delete(MemoryCacheItem.ClearingAccountDetails, accountNumber, businessPartnerNumber, systemId);
  }

  async getCustomerNumberInfoFrom(products: Product[]): Promise<ICustomerNumberInfo> {
    try {
      const customerInfos = await this.loadCustomerInformationsFor(products);
      return customerInfos[0];
    } catch (error) {
      this.loggingService.logError(error, 'getCustomerNumberInfoFrom.getCustomerNumberInfoFrom');
      return null;
    }
  }

  clearProductDetails(): void {
    this.memoryCacheService.clear(MemoryCacheItem.ClearingAccountDetails);
  }

  async getDecryptedIBAN(response: VerrechnungskontoResponse): Promise<VerrechnungskontoResponse> {
    const clone = structuredClone(response);

    const passPhrase = this.accountLogic.getAccount()?.id + '@' + response.verrechnungskonto.verrechnungskontoId;
    const cu = new CryptographyUtils();
    if (clone.verrechnungskonto?.eingangskonto?.iban) {
      await this.decryptAccountIban(clone.verrechnungskonto.eingangskonto, cu, passPhrase);
    }

    if (clone.verrechnungskonto?.ausgangskonto?.iban) {
      await this.decryptAccountIban(clone.verrechnungskonto.ausgangskonto, cu, passPhrase);
    }
    return clone;
  }

  async getCustomerAccountInfo(
    clearingAccount: Verrechnungskonto,
    allProducts: Product[],
    translateService: TranslateService,
  ): Promise<ICustomerNumberInfo> {
    const productsForClearingAccount = allProducts.filter(p => p.accountNumber === clearingAccount.verrechnungskontoId);
    sortProductsByCategory(productsForClearingAccount);
    const customerNumber = productsForClearingAccount[0].businessPartnerNumber;
    const systemId = clearingAccount.systemId;
    const geschaeftsPartner = clearingAccount.geschaeftspartnerBeziehungen.find(gp => gp.geschaeftsPartnerId === customerNumber);

    let productCategories = productsForClearingAccount.map(product => product.category);
    productCategories = Array.from(new Set(productCategories));
    const translatedProductCategories = await Promise.all(productCategories.map(category => translateCategory(category, translateService)));

    return this.createCustomerNumberInfo(
      geschaeftsPartner,
      clearingAccount.verrechnungskontoId,
      customerNumber,
      systemId,
      translatedProductCategories,
      productCategories,
    );
  }

  async getCustomerNumberInformationsFromAllProducts(): Promise<ICustomerNumberInfo[]> {
    try {
      const allProducts = await this.productLogic.getProducts();
      return await this.loadCustomerInformationsFor(allProducts);
    } catch (error) {
      this.loggingService.logError(error, 'error at getCustomerNumberInformationsFromAllProducts');
      return [];
    }
  }

  startInformationRequestProcess(customerId: string, accountId: string, categories: string[], address: IAddress): void {
    this.caInfoStateService.directAddressSelection = true;
    this.caInfoStateService.customerId = customerId;
    this.caInfoStateService.accountId = accountId;
    this.caInfoStateService.categories = categories;
    this.caInfoStateService.address = address;
    this.router.navigateByUrl(`/${CLEARING_ACCOUNT_INFORMATION_REQUEST_ROUTE}/${CLEARING_ACCOUNT_INFORMATION_REQUEST_INFORMATION_ROUTE}`);
  }

  private loadAllAccountInfos(products: Product[]): Promise<VerrechnungskontoResponse>[] {
    const items = products.map(p => {
      return { accountNumber: p.accountNumber, businessPartnerNumber: p.businessPartnerNumber, systemId: p.systemId };
    });
    const uniqueItems = getUniqueValues(items);
    return uniqueItems.map(ui => this.getAccountInfo(ui.accountNumber, ui.businessPartnerNumber, ui.systemId));
  }

  private async loadCustomerInformationsFor(products: Product[]): Promise<ICustomerNumberInfo[]> {
    products = this.productWhiteListLogic.removeNotWhiteListedProducts(products);
    const partnerDetails = await this.getAllAccountInfosIgnoringErrors(products);
    const unsortedList = await Promise.all(
      partnerDetails.map(detail => this.getCustomerAccountInfo(detail.verrechnungskonto, products, this.translateService)),
    );

    return sortCustomerNumberInfos(unsortedList);
  }

  private async decryptAccountIban(account: ICustomerBankKonto, cu: CryptographyUtils, passPhrase: string) {
    if (account.iban.indexOf(':') > -1) {
      account.iban = await cu.decrypt(account.iban, passPhrase);
    }
  }

  private createCustomerNumberInfo = (
    geschaeftsPartner: IGeschaeftspartnerBeziehung,
    accountNumber: string,
    customerNumber: string,
    systemId: string,
    translatedProductCategories: string[],
    productCategories: string[],
  ): ICustomerNumberInfo => {
    return {
      firstName: geschaeftsPartner.physischePerson.vorname,
      lastName: geschaeftsPartner.physischePerson.nachname,
      customerNumber,
      businessPartnerNumber: geschaeftsPartner.geschaeftsPartnerId,
      systemId,
      accountNumber,
      productCategories: translatedProductCategories,
      rawProductCategories: productCategories,
      geschaeftsPartner,
    };
  };

  private async loadAllUnpaidInvoicesFromServer(addressProducts: IAddressProducts[]): Promise<ClearingAccount[]> {
    const response = await this.clearingAccountService.getAllUnpaidInvoices(addressProducts);
    this.areAllUnpaidInvoicesInMemory = true;

    response.forEach(item => {
      this.memoryCacheService.set(
        MemoryCacheItem.UnpaidInvoices,
        item.unpaidInvoices,
        item.accountId,
        item.businessPartnerId,
        item.systemId,
      );
    });

    return response;
  }

  private loadAllUnpaidInvoicesFromMemory(addressProducts: IAddressProducts[]): ClearingAccount[] {
    const accounts = getClearingAccountsFrom(addressProducts);

    accounts.forEach(account => {
      account.unpaidInvoices = this.memoryCacheService.get(
        MemoryCacheItem.UnpaidInvoices,
        account.accountId,
        account.businessPartnerId,
        account.systemId,
      );
    });

    return accounts;
  }
}
