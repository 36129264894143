import { inject, Injectable } from '@angular/core';
import { CAInformationRequestStateService } from './ca-information-request-state.service';

@Injectable({ providedIn: 'root' })
export class CAInformationRequestLogic {
  informationRequestStateService = inject(CAInformationRequestStateService);

  hasAllNeededDataForConfirmation(): boolean {
    if (
      this.informationRequestStateService.kindOfInformation === 'Buchungen' &&
      this.informationRequestStateService.timeRangeType === 'Zeitraum' &&
      (!this.informationRequestStateService.timeRangeFrom || !this.informationRequestStateService.timeRangeTo)
    ) {
      return false;
    }
    return !!(
      this.informationRequestStateService.customerId &&
      this.informationRequestStateService.accountId &&
      this.informationRequestStateService.kindOfInformation
    );
  }
}
