import { Component, EventEmitter, Host, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { IFormInput } from '@mwe/models';
import { getNumberFrom, isFormErrorVisible } from '@mwe/utils';
import { FormInputLoadingSpinnerComponent } from '../form-input/form-input-loading-spinner/form-input-loading-spinner.component';
import { FormComponent } from '../../form.component';

@Component({
  selector: 'mwe-form-textarea',
  templateUrl: './form-textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormTextareaComponent,
      multi: true,
    },
  ],
})
export class FormTextareaComponent implements ControlValueAccessor, OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() inputElem: IFormInput;
  @Input() isLoading = false;
  @Input() isInline = false;

  @Output() inputBlurred = new EventEmitter<void>();
  @ViewChild(FormInputLoadingSpinnerComponent, { static: false })
  loadingSpinner: FormInputLoadingSpinnerComponent;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;
  toggleIcon = false;
  isRequired: boolean;

  get currentValue(): any {
    return this.formControlDirective?.control?.value;
  }

  get isValid(): any {
    return this.formControlDirective?.control?.valid;
  }

  constructor(@Optional() @Host() private formComponent?: FormComponent) {}

  ngOnInit(): void {
    this.isRequired = this.inputElem.validators?.indexOf(Validators.required) > -1;
    this.formControlDirective.name = this.inputElem.name;
    this.checkInitialValue();
  }

  onEnterPressed(evt: KeyboardEvent): void {
    if (this.inputElem.loadingSpinnerOptions?.enterInInputTriggersSpinnerButton) {
      evt.preventDefault();
      this.loadingSpinner.submit();
    }
  }

  clearInput(): void {
    this.formControlDirective.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  togglePassword(event: any): void {
    event.stopPropagation();
    this.toggleIcon = !this.toggleIcon;
    const iconElement = event.target.querySelector('i');
    if (this.toggleIcon) {
      this.inputElem.dataType = 'text';
      iconElement.classList.remove('fa-eye');
      iconElement.classList.add('fa-eye-slash');
    } else {
      this.inputElem.dataType = 'password';
      iconElement.classList.add('fa-eye');
      iconElement.classList.remove('fa-eye-slash');
    }
  }

  // from model to ui
  writeValue(value: any): void {
    let uiValue = value;

    if (this.inputElem.directiveType === 'amount' && value) {
      uiValue = (value + '').replace('.', ',');
    }

    this.updateFormInput(uiValue, value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  // from ui to model
  handleInput($event: any) {
    let value = $event.target.value;

    if (this.inputElem.directiveType === 'amount' && value) {
      value = value.replace(',', '.');
      value = getNumberFrom(value);
      this.updateFormInput($event.target.value, value);
    }
  }

  private checkInitialValue(): void {
    if (this.inputElem.directiveType !== 'amount') {
      return;
    }

    if (this.inputElem.initialValue) {
      setTimeout(() => {
        this.writeValue(this.inputElem.initialValue);
      }, 100);
    }
  }

  private updateFormInput(uiValue: string, ctrValue: any): void {
    this.formControlDirective.valueAccessor.writeValue(uiValue);
    this.formControlDirective.control.setValue(ctrValue, {
      emitModelToViewChange: false,
    });
  }

  get ariaDescribedBy(): string | undefined {
    const control = this.parentForm.get(this.inputElem.name);
    const hasError = isFormErrorVisible(this.inputElem, control);

    if (!!this.inputElem.helpLabelKey || hasError) {
      return `form-input-desc--${this.inputElem.name}`;
    }
    return undefined;
  }
}
