import { Directive, HostListener, inject, input } from '@angular/core';
import { InfoText } from '@mwe/models';
import { InfoTextPopupComponent } from '../info-text-popup.component';
import { PopupService } from '@mwe/services';

@Directive({
  selector: '[mweInfoTextPopupFromLink]',
})
export class InfoTextPopupFromLinkDirective {
  mweInfoTextPopupFromLink = input.required<string[]>();
  infoTexts = input.required<InfoText[]>();
  modelSize = input<'sm' | 'lg' | 'xl' | string>('');

  private popupService = inject(PopupService);

  @HostListener('click', ['$event'])
  @HostListener('touchstart', ['$event.srcElement'])
  onClick(event: MouseEvent): void {
    const clickTarget = event.target as HTMLInputElement;
    const targetId = this.mweInfoTextPopupFromLink().find(id => id === clickTarget?.id);
    const popupData = this.infoTexts().find(item => item.code === targetId);

    if (!targetId || !popupData) {
      return;
    }

    event.preventDefault();
    this.popupService.open({
      id: 'info-text-icon-clicked',
      titleKey: '',
      messageKey: '',
      showSubmitButton: false,
      showCancelButton: false,
      component: InfoTextPopupComponent,
      componentData: popupData,
      dismissable: true,
      modalSize: this.modelSize(),
      modalOpts: {
        ariaLabelledBy: 'mwe_info-text-popup-title',
      },
    });
  }
}
