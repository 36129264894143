import { Directive, DoCheck, OnInit } from '@angular/core';
import { GOODIE_ROUTE, PRODUCTS_ROUTE, PROFILE_ROUTE } from '@mwe/constants';
import { AccountLogic, EnvironmentService, HelperService, NavigationLogic, NavigationStateService, ProfileService } from '@mwe/services';
import { NavbarConfig } from '@mwe/models';

@Directive()
export abstract class NavbarComponent implements OnInit, DoCheck {
  isProductPath: boolean;
  isGoodiePath: boolean;
  isProfilePath: boolean;
  productsRoute = `/${this.environmentService.getPortalUrlPrefix()}/${PRODUCTS_ROUTE}`;
  profileRoute = `/${this.environmentService.getPortalUrlPrefix()}/${PROFILE_ROUTE}`;
  goodieRoute = `/${this.environmentService.getPortalUrlPrefix()}/${GOODIE_ROUTE}`;
  isLoggedIn = false;

  protected constructor(
    protected helperService: HelperService,
    protected profileService: ProfileService,
    protected environmentService: EnvironmentService,
    protected accountLogic: AccountLogic,
    protected navigationStateService: NavigationStateService,
    protected navigationLogic: NavigationLogic,
  ) {}

  get config(): NavbarConfig {
    return this.navigationStateService.navbarConfig;
  }

  async ngOnInit() {
    this.setIsPaths();
    this.isLoggedIn = this.accountLogic.isLoggedIn();
  }

  ngDoCheck(): void {
    this.setIsPaths();
  }

  switchPortal(): void {
    this.navigationLogic.switchPortal();
  }

  private setIsPaths(): void {
    this.isProductPath = this.helperService.isProductPath();
    this.isGoodiePath = this.helperService.isGoodiePath();
    this.isProfilePath = this.helperService.isProfilePath();
  }
}
