import { IAddressProducts } from '../address/address.model';
import { ILSystem } from '../integrationlayer/api.model';

export interface Account {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  impersonator?: string;
  salutation?: string;
}

export interface IConfirmationStatus {
  isLoggedIn: boolean;
  hasAccount: boolean;
  isPartner: boolean;
}

export interface IMigrationResult {
  migrationResultStatus: string;
  email: string;
  keycloakId: string;
  actionToken: string;
}

export interface IPersonalInfoModel {
  firstName: string;
  lastName: string;
}

export interface AccountSelectedEvent {
  addressProducts: IAddressProducts;
  systemId: ILSystem;
  accountId: string;
}
