<div class="d-flex">
  <ng-content />
  @if (!isInPopup()) {
    <mwe-info-text-icon
      [colorClass]="colorClass()"
      [infoTextCode]="infoTextCode()"
      [infoTextDescription]="infoTextDescription()"
      [infoTextLink]="infoTextLink()"
      [infoTextTitle]="infoTextTitle()"
      [customUrlOverride]="customUrlOverride()"
    />
  }
</div>
