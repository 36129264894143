import { DetailsLinkTypeEnum } from '@mwe/constants';
import { IMultiProductCard, Product } from '../products/product.model';
import { AddressGroup } from './address-group.model';

export interface IAddress {
  id: any;
  street: string;
  streetNumber: string;
  block: string;
  doorNumber: string;
  hint?: string;
  postcode: string;
  city: string;
  country: string;
  addressGroup: AddressGroup;

  calculateHashCode(): void;

  getHashCode(): string;

  getGroupName(): string;

  setGroupName(groupName: string): IAddress;

  getGroupFormatted(): string;

  getLineFormatted(): string;

  getStreetFormatted(): string;

  getPostcodeFormatted(): string;

  getPostCodeAndStreet(): string;

  getdetailsLinkType(): string;

  hashValue(value: string): string;

  isInMoveOut(): boolean;

  isInRelocation(): boolean;
}

export class Address implements IAddress {
  private hashCode: string;
  addressGroup: AddressGroup;

  constructor(
    public id: any,
    public street: string,
    public streetNumber: string,
    public block: string,
    public doorNumber: string,
    public hint: string,
    public postcode: string,
    public city: string,
    public country: string,
  ) {
    this.id = id;
    this.street = street;
    this.streetNumber = streetNumber;
    this.block = block;
    this.doorNumber = doorNumber;
    /* MWE-4939-ignore hint: */
    //this.hint = hint;
    this.postcode = postcode;
    this.city = city;
    this.country = country;
    this.calculateHashCode();
    this.addressGroup = {
      id: null,
      userId: null,
      addressCode: this.getHashCode(),
      groupName: null,
      detailsLinkType: null,
      customerType: null,
      createdAt: null,
      modifiedAt: null,
    };
  }

  calculateHashCode(): void {
    this.hashCode =
      this.hashValue(this.postcode) + '_' + this.hashValue(this.street) + '_' + this.hashValue(this.streetNumber).replace(/\s*\/\s*/g, '/');
    if (this.block) {
      this.hashCode = this.hashCode + '/' + this.hashValue(this.block).replace(/\s*\/\s*/g, '/');
    }
    if (this.doorNumber) {
      this.hashCode = this.hashCode + '/' + this.hashValue(this.doorNumber).replace(/\s*\/\s*/g, '/');
    }
    /* MWE-4939-ignore hint: */
    //if (this.hint) {
    //  this.hashCode = this.hashCode + '/' + this.hashValue(this.hint).replace(/\s*\/\s*/g, '/');
    //}
  }

  getGroupName(): string {
    return this.addressGroup.groupName;
  }

  setGroupName(groupName: string): Address {
    this.addressGroup.groupName = groupName;
    return parseAddress({
      ...this,
      addressGroup: { ...this.addressGroup },
    });
  }

  getdetailsLinkType(): string {
    return this.addressGroup.detailsLinkType;
  }

  hashValue(value: string): string {
    if (value) {
      return value;
    } else {
      return '@';
    }
  }

  getHashCode(): string {
    return this.hashCode;
  }

  getGroupFormatted(withCountryCode = false): string {
    let addressString = `${this.postcode} ${this.city}, ${this.street}`;
    if (this.streetNumber) {
      addressString += ` ${this.streetNumber}`;
    }
    if (this.block) {
      addressString += `/${this.block}`;
    }
    if (this.doorNumber) {
      addressString += `/${this.doorNumber}`;
    }
    /* MWE-4939-ignore hint: */
    //if (this.hint) {
    //  addressString += `/${this.hint}`;
    //}
    if (withCountryCode) {
      addressString = `${this.country}, ${addressString}`;
    }
    return addressString;
  }

  getLineFormatted(): string {
    let addressString = this.getStreetFormatted();
    addressString += `, ` + this.getPostcodeFormatted();
    return addressString;
  }

  getStreetFormatted(): string {
    let addressString = this.street;
    if (this.streetNumber) {
      addressString += ` ${this.streetNumber}`;
    }
    if (this.block) {
      addressString += `/${this.block}`;
    }
    if (this.doorNumber) {
      addressString += `/${this.doorNumber}`;
    }
    /* MWE-4939-ignore hint: */
    //if (this.hint) {
    //  addressString += `/${this.hint}`;
    //}
    return addressString;
  }

  getPostcodeFormatted(): string {
    return ` ${this.postcode} ${this.city}`;
  }

  getPostCodeAndStreet(): string {
    return `${this.postcode} ${this.city}, ${this.getStreetFormatted()}`;
  }

  getStreetAndPostCode(): string {
    return `${this.getStreetFormatted()},${this.getPostcodeFormatted()}`;
  }

  isInRelocation(): boolean {
    return this.addressGroup?.detailsLinkType === DetailsLinkTypeEnum.RELOCATION;
  }

  isInMoveOut(): boolean {
    return (
      this.addressGroup?.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT ||
      this.addressGroup?.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR
    );
  }

  isInRelocationOrMoveOut(): boolean {
    return this.isInRelocation() || this.isInMoveOut();
  }

  isInNoProcess(): boolean {
    return !this.isInRelocation() && !this.isInMoveOut();
  }
}

export const parseAddress = (json?: any): Address => {
  if (!json) {
    return null;
  }

  let objectIn: IAddress = json;
  if (typeof json === 'string') {
    objectIn = JSON.parse(json);
  }
  const address = new Address(
    objectIn.id,
    objectIn.street,
    objectIn.streetNumber,
    objectIn.block,
    objectIn.doorNumber,
    objectIn.hint,
    objectIn.postcode,
    objectIn.city,
    objectIn.country,
  );
  if (json.addressGroup) {
    address.addressGroup.id = json.addressGroup.id;
    address.addressGroup.userId = json.addressGroup.userId;
    address.addressGroup.addressCode = json.addressGroup.addressCode;
    address.addressGroup.groupName = json.addressGroup.groupName;
    address.addressGroup.detailsLinkType = json.addressGroup.detailsLinkType;
    address.addressGroup.customerType = json.addressGroup.customerType;
    address.addressGroup.createdAt = json.addressGroup.createdAt;
    address.addressGroup.modifiedAt = json.addressGroup.modifiedAt;
    address.addressGroup.matchedGroup = json.addressGroup.matchedGroup;
  }
  return address;
};

export interface IOlavEntry {
  plz: string;
  plzOrt?: string[];
  gemeinde: string;
  isuKZS: string;
  strasse: string;
  identStrasse?: string;
  gebadr1: string;
  identGebadr1?: string;
  anladr1: string;
  tuerNr?: string;
  gebaeudeKey: string;
  gebaeudeKeyIdent: string;
  anlageAdressKey: string;
  anlageKey: string;
  olavKey?: string;
  origOlavKey?: string;
  vstelle?: string;
  identAdresse?: IOlavEntry;
  tuerNrZusatz?: string;
  staat?: string;
  alleEintraegeKZ?: boolean;
  anlagenSucheKZ?: boolean;
  internetTarifKZ?: boolean;
  _version_?: number;
}

export interface IOlavKey {
  gebaeudeKey: string;
  gebaeudeKeyIdent: string;
  anlageAdressKey: string;
  anlageKey: string;
}
export interface ISolrResponse {
  responseHeader: any;
  grouped: {
    groupValue?: string;
    gemeinde?: ISolrGroup;
    strasse?: ISolrGroup;
    gebadr1?: ISolrGroup;
    tuerNr?: ISolrGroup;
    anladr1?: ISolrGroup;
    plz?: ISolrGroup;
  };
  facet_counts?: {
    facet_pivot: {
      'strasse,gemeinde,plz': ISolrPivot[];
    };
  };
}

export interface ISolrPivot {
  count: number;
  field: string;
  value: string;
  pivot: ISolrPivot[];
}

export interface ISolrGroup {
  matches: number;
  groups: {
    groupValue: string;
    doclist: ISolrDocList;
  }[];
}

export interface ISolrDocList {
  numFound: number;
  identNumFound?: number;
  start: number;
  docs: IOlavEntry[];
  groupValue?: string;
}

export interface ISolrOlavKeyAddress {
  numFound: number;
  docs: IOlavEntry[];
  start: number;
}

export interface AddressSelectionEntry {
  text: string;
  state: SelectionState;
}

export enum SelectionState {
  INITIAL = 'initial',
  LOCATION = 'location',
  STREET = 'street',
  BUILDING = 'building',
  DOOR = 'door',
  COMPLETE = 'complete',
}

export interface IAddressProducts {
  address: IAddress;
  products: Product[];
  // can be removed once we have ChangeDetection.OnPush and StateManagement
  areSomeProductsWhiteListed?: boolean;
}

export interface IAddressProductsSelectedEvent {
  addressProduct: IAddressProducts;
  selectionWasSkippedDueToOneAddress: boolean;
}

export interface AddressMultiProductCards {
  address: IAddress;
  multiProductCards: IMultiProductCard[];
}

export class CoverageArea {
  bezirk: number;
  gemeinde: string;

  constructor(bezirk: number, gemeinde: string) {
    this.bezirk = bezirk;
    this.gemeinde = gemeinde;
  }
}

export const availableZipCodes = [
  '1010',
  '1020',
  '1030',
  '1040',
  '1050',
  '1060',
  '1070',
  '1080',
  '1090',
  '1100',
  '1110',
  '1120',
  '1130',
  '1140',
  '1150',
  '1160',
  '1170',
  '1180',
  '1190',
  '1200',
  '1210',
  '1220',
  '1230',
  '1300',
  '2100',
  '2102',
  '2103',
  '2201',
  '2202',
  '2203',
  '2232',
  '2280',
  '2281',
  '2282',
  '2301',
  '2304',
  '2320',
  '2322',
  '2325',
  '2326',
  '2331',
  '2332',
  '2333',
  '2334',
  '2340',
  '2344',
  '2345',
  '2351',
  '2352',
  '2353',
  '2355',
  '2361',
  '2362',
  '2371',
  '2372',
  '2380',
  '2381',
  '2384',
  '2391',
  '2392',
  '2393',
  '2401',
  '2431',
  '2432',
  '2435',
  '2440',
  '2441',
  '2442',
  '2481',
  '2482',
  '2483',
  '2484',
  '2485',
  '2486',
  '2490',
  '2491',
  '2492',
  '2493',
  '2500',
  '2504',
  '2511',
  '2512',
  '2514',
  '2521',
  '2522',
  '2523',
  '2524',
  '2525',
  '2531',
  '2540',
  '2542',
  '2544',
  '2551',
  '2552',
  '2601',
  '2602',
  '2603',
  '2604',
  '2751',
  '3001',
  '3002',
  '3003',
  '3011',
  '3012',
  '3264',
  '3291',
  '3292',
  '3293',
  '3342',
  '3343',
  '3344',
  '3345',
  '3400',
  '3420',
  '3421',
  '3423',
];
