import { computed, inject, Injectable, Signal, signal } from '@angular/core';
import { UserService } from '@ikp/service/user/user.service';
import { Permission } from '@ikp/model/permission.model';
import { ROUTE_PERMISSION_CONFIG } from '@ikp/config/route-permissions.config';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  private userService = inject(UserService);

  private userPermissions = signal<Permission[]>(null);

  constructor() {
    this.reloadUserPermissions();
  }

  async reloadUserPermissions(): Promise<void> {
    return this.userService.getUserData().then(userdata => {
      if (userdata) {
        this.userPermissions.set(userdata.permissions);
      }
    });
  }

  areUserPermissionsLoaded(): boolean {
    return this.userPermissions() !== null;
  }

  public hasUserAccessTo(path: string): boolean {
    return this.getUserAccessAsSignal(path)();
  }

  public getUserAccessAsSignal(path: string): Signal<boolean> {
    return computed(() => {
      if (!path || this.userPermissions() === null) {
        return false;
      }
      const requiredPermissions = ROUTE_PERMISSION_CONFIG[path];
      if (!requiredPermissions) {
        return true;
      }
      if (this.userPermissions().length > 0) {
        return (
          requiredPermissions.every((permission: Permission) => this.userPermissions().includes(permission)) ||
          this.userPermissions().includes(Permission.ADMIN)
        );
      }
      return requiredPermissions.length === 0;
    });
  }
}
