import { ChangeDetectionStrategy, Component, computed, Input, signal } from '@angular/core';
import { IAktionen } from '@mwe/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mwe-additional-information-summary-card',
  templateUrl: './additional-information-summary-card.component.html',
})
export class AdditionalInformationSummaryCardComponent {
  @Input() showMeterReading = false;
  voucherInfoSignal = signal<IAktionen | undefined>(undefined);
  @Input() set voucherInfo(value: IAktionen | undefined) {
    this.voucherInfoSignal.set(value);
  }
  @Input() mgmCode?: string;

  voucherStrom = computed<string>(() => {
    if (this.voucherInfoSignal().infoTextKey?.length > 0) {
      if (this.voucherInfoSignal().infoTextKey[0].toLowerCase().startsWith('strom')) {
        return this.voucherInfoSignal().infoText[0];
      }
      return this.voucherInfoSignal().infoText[1];
    }
    return undefined;
  });
  voucherGas = computed<string>(() => {
    if (this.voucherInfoSignal().infoTextKey?.length > 0) {
      if (this.voucherInfoSignal().infoTextKey[0].toLowerCase().startsWith('strom')) {
        return this.voucherInfoSignal().infoText[1];
      }
      return this.voucherInfoSignal().infoText[0];
    }
    return undefined;
  });
}
