import {
  Address,
  DataChangeType,
  IMultiProductCard,
  IProduct,
  IProduktVerfuegbarkeit,
  IVerknuepfungen,
  IVerknuepfungenProdukte,
  parseAddress,
  Product,
  RecentOrder,
  RecentOrderDTO,
  RecentOrderType,
  Sparte,
} from '@mwe/models';
import dayjs from 'dayjs';
import { parseAddressCode } from '../address/address.utils';
import { formatOrderDate } from '../dates/date.utils';
import { isProductCategoryFernwaerme, sortProductsEnumCategory } from './product-categoriy.utils';
import { isArrayWithMinOneItem } from '../common/mwe-util';

export const parseVerknuepfungen = (associations: IVerknuepfungen[]): Product[] => {
  let products: Product[] = [];
  if (associations) {
    for (const element of associations) {
      const p = parseVerknuepfung(element);
      if (p) {
        products = products.concat(p);
      }
    }
  }
  return products;
};

export const parseVerknuepfung = (association: IVerknuepfungen, ignoreVerknuepfungsStatus = false): Product[] => {
  if (association.produkte && (association.verknuepfungsStatus !== null || ignoreVerknuepfungsStatus)) {
    return parseProdukte(association.produkte, association.geschaeftsPartnerId, association.systemId);
  }
  return [];
};

export const parseProdukte = (produkte: any, customerNumber: string, systemId: string): Product[] => {
  const products: Product[] = [];
  for (const element of produkte) {
    products.push(parseProdukt(element, customerNumber, systemId));
  }
  return products;
};

export const parseProdukt = (ILProduct: IVerknuepfungenProdukte, customerNumber: string, systemId: string): IProduct => {
  const tarifClasses = [];
  const customerNumberPerCategory = isProductCategoryFernwaerme(ILProduct.sparte) ? ILProduct.verrechnungskontoId.slice(2) : customerNumber;
  let tariffKey: string;
  let anlageId: string;
  if (ILProduct.additionalData?.produktDetails) {
    for (const element of ILProduct.additionalData.produktDetails) {
      const pd = element;
      if (pd.tarifName) {
        if (pd.tarifName.toLowerCase() === 'heizung_warmwasser') {
          tarifClasses.push(parseTariff('heizung'));
          tarifClasses.push(parseTariff('warmwasser'));
        } else {
          tarifClasses.push(parseTariff(pd.tarifName));
        }
        tariffKey = pd.ISUTarifKey;
        anlageId = pd.anlageId;
      }
    }
  }

  const p: IProduct = new Product(
    null,
    customerNumberPerCategory,
    ILProduct.verrechnungskontoId,
    ILProduct.geschaeftsPartnerId,
    ILProduct.sparte,
    tarifClasses, // tarif
    parseAdresse(ILProduct.additionalData?.adressDetails),
    ILProduct.vertragsStatus,
    anlageId,
    tariffKey,
    systemId,
  );

  p.associationDetails = ILProduct.additionalData?.produktDetails;
  return p;
};

export const parseAdresse = (addressDetails: any): Address => {
  if (!addressDetails) {
    return null;
  }

  return new Address(
    null,
    addressDetails.strasse,
    addressDetails.hausnummer,
    addressDetails.stiege,
    addressDetails.tuernummer,
    // MWE-4939-ignore hint: addressDetails.adresshinweis || null,
    null,
    addressDetails.plz,
    addressDetails.ort,
    addressDetails.land,
  );
};

export const parseTariff = (tariff: string) => {
  return tariff ? tariff.replace(/^Strom |^Gas |^Erdgas |^NachtStrom |^Stromrueck /, '') : '';
};

export const createMultiProductCardsFromProducts = (products: Product[]): IMultiProductCard[] => {
  let accountNumbers = products.map(product => product.accountNumber);
  accountNumbers = Array.from(new Set(accountNumbers));

  return accountNumbers.map(accountNumber => {
    const filteredProducts = products.filter(product => product.accountNumber === accountNumber);
    const productCategories = filteredProducts.map(product => product.category);
    sortProductsEnumCategory(productCategories);
    const customerNumber = filteredProducts[0].customerNumber;

    return { id: accountNumber, categories: productCategories, accountNumber, customerNumber };
  });
};

export const parseRecentOrdersDTO = (recentOrdersApi: RecentOrderDTO[]): RecentOrder[] => {
  return recentOrdersApi.map(item => {
    const type = item.type as RecentOrderType;
    const dataType = item.dataType as DataChangeType;
    let msgKey = '';

    switch (type) {
      case RecentOrderType.NewClient:
        msgKey = 'products.recentOrders.nc_workInProgress';
        break;
      case RecentOrderType.TariffChange:
        msgKey = getTariffChangeMessage(dataType);
        break;
      case RecentOrderType.InvoiceDataChange:
        msgKey = getInvoiceDataChangeMessage(dataType);
        break;
      case RecentOrderType.VoucherRedeem:
        msgKey = 'products.recentOrders.vr_workInProgress';
        break;
      case RecentOrderType.ChargingCardOrder:
        msgKey = 'products.recentOrders.cco_workInProgress';
        break;
      case RecentOrderType.PaymentAgreement:
        msgKey = getPaymentAgreementMessage(dataType);
        break;
      case RecentOrderType.PartialAmountChange:
        msgKey = 'products.recentOrders.pac_workInProgress';
        break;
      case RecentOrderType.CAInformationRequest:
        msgKey = 'products.recentOrders.cair_workInProgress';
        break;
    }

    const _address = item.address ? parseAddress(item.address) : item.addressCode ? parseAddressCode(item.addressCode) : null;
    const customerData = item.customerData;

    // parse companyName for recent orders, to be compatible with CustomerNumberInfoTooltipCardComponent
    if (item.businessData) {
      customerData.geschaeftsPartner = {
        ...customerData?.geschaeftsPartner,
        organisation: {
          ...customerData?.geschaeftsPartner?.organisation,
          organisationsname: item.businessData.companyName,
        },
      };
    }

    return {
      id: item.id,
      orderedAt: formatOrderDate(item.orderedAt),
      type,
      dataType,
      address: _address,
      customerData,
      msgKey,
      addtlData: item.addtlData,
    };
  });
};

export const getTariffChangeMessage = (dataType: string): string => {
  if (dataType === DataChangeType.SecureOffer) {
    return 'products.recentOrders.tco_workInProgress';
  }

  return 'products.recentOrders.tc_workInProgress';
};

export const getPaymentAgreementMessage = (dataType: string): string => {
  if (dataType === 'PAYMENT_CALLBACK') {
    return 'products.recentOrders.cba_workInProgress';
  }

  return 'products.recentOrders.pa_workInProgress';
};

export const getInvoiceDataChangeMessage = (dataType: DataChangeType): string => {
  switch (dataType) {
    case DataChangeType.BankAccountChangeOnly:
      return 'products.recentOrders.idcb_workInProgress';
    case DataChangeType.PdfInvoiceChangeOnly:
      return 'products.recentOrders.idce_workInProgress';
    case DataChangeType.InvoiceAddressChangeOnly:
      return 'products.recentOrders.idcr_workInProgress';
    default:
      return 'products.recentOrders.idca_workInProgress';
  }
};

export const getInvoiceDataProductHeaderMessage = (dataType: DataChangeType): string => {
  switch (dataType) {
    case DataChangeType.BankAccountChangeOnly:
      return 'invoiceData.change.status.dtb_processTitle';
    case DataChangeType.PdfInvoiceChangeOnly:
      return 'invoiceData.change.status.dte_processTitle';
    case DataChangeType.InvoiceAddressChangeOnly:
      return 'invoiceData.change.status.dtr_processTitle';
    default:
      return 'invoiceData.change.status.dta_processTitle';
  }
};

export const sortRecentOrdersDTO = (recentOrdersApi: RecentOrderDTO[]): RecentOrderDTO[] => {
  return recentOrdersApi.sort((a, b) => (dayjs(a.orderedAt).isAfter(dayjs(b.orderedAt)) ? -1 : 1));
};

// helper method to get simple product for ui-stuff, do not use it for other purposes
export const getWaermeProductFromVerfuegbarkeit = (v: IProduktVerfuegbarkeit): IProduct => {
  if (!v || !isArrayWithMinOneItem(v.details)) {
    return null;
  }
  const tariffClasses = v.details.map(d => d.lieferkomponente);
  return {
    category: Sparte.Fernwaerme,
    tariffClasses,
  } as IProduct;
};
