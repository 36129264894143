import { lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IPopupModel } from '@mwe/models';
import { DEFAULT_POPUP_COMPONENT_TOKEN } from '@mwe/constants';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class PopupService {
  private _events: Subject<any>;
  private _additionalButtonClickEvent: Subject<any>;

  constructor(
    private modalService: NgbModal,
    private injector: Injector,
    private authService: AuthService,
  ) {
    this._events = new Subject();
    this._additionalButtonClickEvent = new Subject();
  }

  async tryOpen<T>(data: IPopupModel): Promise<T> {
    if (this.modalService.hasOpenModals()) {
      await lastValueFrom(this.events());
      return this.tryOpen(data);
    }

    this.open(data);
    return lastValueFrom(this.events());
  }

  open(data: IPopupModel, closeOpenPopups = false) {
    let component = this.injector.get(DEFAULT_POPUP_COMPONENT_TOKEN);
    if (this.modalService.hasOpenModals()) {
      if (closeOpenPopups) {
        this.modalService.dismissAll();
      } else {
        throw "modal already open - can't open more than one";
      }
    }

    const modalOptions: NgbModalOptions = {
      backdrop: data.dismissable ? true : 'static',
      keyboard: data.dismissable,
    };
    modalOptions.centered = true;
    modalOptions.scrollable = true;

    if (data?.additionalWindowClass?.length) {
      modalOptions.windowClass += ` ${data.additionalWindowClass.join(' ')}`;
    }

    if (data?.modalSize) {
      modalOptions.size = data.modalSize;
    }
    if (data?.modalOpts) {
      Object.assign(modalOptions, data.modalOpts);
    }

    if (data.component) {
      component = data.component;
    }
    const popupRef = this.modalService.open(component, modalOptions);
    popupRef.componentInstance.model = data;
    if (data.componentData) {
      popupRef.componentInstance.loadedData = data.componentData;
    }
    const additionalButtonClickSub = popupRef.componentInstance.onAdditionalButtonClick?.subscribe(buttonId => {
      this._additionalButtonClickEvent.next(buttonId);
    });

    popupRef.result
      .then(result => {
        this._events.next(data.formModel || data.useCustomReturnValue ? result : result === 'OK');
      })
      .catch(() => {
        this._events.next(false);
      })
      .finally(() => {
        this.unsubscribeAll(additionalButtonClickSub);
      });
  }

  unsubscribeAll(additionalButtonClickSub: Subscription) {
    this._events.complete();
    this._events = new Subject();
    additionalButtonClickSub?.unsubscribe();
    this._additionalButtonClickEvent.complete();
    this._additionalButtonClickEvent = new Subject();
  }

  isOpen(): boolean {
    return this.modalService.hasOpenModals();
  }

  events(): Observable<any> {
    return this._events.asObservable();
  }

  additionalButtonClickEvent(): Observable<string> {
    return this._additionalButtonClickEvent.asObservable();
  }

  showAddAssociationConfirmationPopup(showCancelButton: boolean, submitButtonKey: string): Observable<any> {
    const model: IPopupModel = {
      id: 'product-choose-confirmation',
      titleKey: 'products.confirm.popup.title',
      messageKey: 'products.confirm.popup.message',
      showSubmitButton: true,
      submitButtonKey,
      showCancelButton,
      cancelButtonKey: 'products.confirm.popup.buttonCancel',
      iconColorClass: 'green',
      iconTypeClass: 'fa-check',
      useCustomReturnValue: true,
      subscriptionId: 'product-choose-confirmation',
    };
    this.open(model, true);
    return this.events();
  }

  openLogoutPopup(): Observable<any> {
    const model: IPopupModel = {
      id: 'profile-logout',
      titleKey: 'profile.logout.popup.title',
      messageKey: 'profile.logout.popup.message',
      showSubmitButton: true,
      submitButtonKey: 'profile.logout.popup.buttonOk',
      showCancelButton: true,
      cancelButtonKey: 'profile.logout.popup.buttonCancel',
    };
    this.open(model);
    this.events().subscribe(result => {
      if (result) {
        this.authService.logout();
      }
    });
    return this.events();
  }
}
