import { ProductCategoryEnum } from '@mwe/constants';
import { Address, IAddress, parseAddress } from '../address/address.model';
import { ICustomerNumberInfo } from '../customer/customer.model';
import { IVerknuepfungenAdditionalDataProduktDetails } from '../integrationlayer/api.model';
import { DataChangeType } from '../invoices/invoice-data-change.models';
import { IBusinessData } from '../order/order.model';

export enum ContractStatusEnum {
  Aktiv = 'AKTIV',
  Beendet = 'BEENDET',
  Geplant = 'GEPLANT',
}

export interface IProduct {
  id: any;
  customerNumber: string; // kundennummer
  accountNumber: string; // vertragsKontoNummer
  category: string; // sparte
  tariffClasses: string[]; // tarif bzw. Kartennummer (Emobility), Subkategorie (bei Wärme)
  address: IAddress;
  getCategoryKey: () => string;
  vertragsStatus?: string;
  tarifKey?: string;
  anlageId?: string;
  systemId?: string;
  isAddressDetailsLinkOnlineNotPossible?: boolean;
  businessPartnerNumber: string; // geschaeftsPartnerId
  associationDetails?: IVerknuepfungenAdditionalDataProduktDetails[];
}

export class Product implements IProduct {
  associationDetails?: IVerknuepfungenAdditionalDataProduktDetails[];
  translatedCategory?: string;
  constructor(
    public id: any,
    public customerNumber: string,
    public accountNumber: string,
    public businessPartnerNumber: string,
    public category: string,
    public tariffClasses: string[],
    public address: IAddress,
    public vertragsStatus?: string,
    public anlageId?: string,
    public tarifKey?: string,
    public systemId?: string,
  ) {
    this.id = id;
    this.customerNumber = customerNumber;
    this.accountNumber = accountNumber;
    this.category = category;
    this.tariffClasses = tariffClasses;
    this.address = address;
    this.anlageId = anlageId;
    this.vertragsStatus = vertragsStatus;
    this.tarifKey = tarifKey;
    this.systemId = systemId;
    this.businessPartnerNumber = businessPartnerNumber;
  }

  // TODO re-factoring candidate
  getCategoryKey(): string {
    return 'products.category.' + this.category.toLowerCase();
  }
}

export class CardProduct {
  constructor(
    public product: Product,
    public status: 'adopted' | 'notAdopted' | 'notAvailable' | 'inProgress',
    public productTraiffKey?: string,
    public cardMessageKey?: string,
  ) {
    this.product = product;
    this.status = status;
    this.productTraiffKey = productTraiffKey;
    this.cardMessageKey = cardMessageKey;
  }

  // TODO re-factoring candidate
  isSelectable(): boolean {
    return this.status.indexOf('dopted') > -1;
  }
}

//TODO move functional processing to utils
export const parseProduct = (json?: any): Product => {
  let objectIn: IProduct = json;
  if (typeof json === 'string') {
    objectIn = JSON.parse(json);
  }
  const product = new Product(
    objectIn.id,
    objectIn.customerNumber,
    objectIn.accountNumber,
    objectIn.businessPartnerNumber,
    objectIn.category,
    objectIn.tariffClasses,
    objectIn.address && parseAddress(objectIn.address),
    objectIn.vertragsStatus,
    objectIn.anlageId,
    objectIn.tarifKey,
    objectIn.systemId,
  );

  product.associationDetails = objectIn.associationDetails;
  return product;
};

//TODO move functional processing to utils
export const parseProducts = (products: Product[]) => {
  const parsedProducts: Product[] = [];
  if (products && products.length) {
    products.forEach(product => {
      parsedProducts.push(
        new Product(
          product.id,
          product.customerNumber,
          product.accountNumber,
          product.businessPartnerNumber,
          product.category,
          product.tariffClasses,
          product.address ? parseAddress(product.address) : null,
          product.vertragsStatus,
          product.anlageId,
          product.tarifKey,
          product.systemId,
        ),
      );
    });
  }
  return parsedProducts;
};

//TODO move functional processing to utils
export const getCategoryCode = (category: string): number => {
  const ordinal = ProductCategoryEnum[category.toUpperCase()];
  return ordinal === undefined ? 1000 : ordinal;
};

export interface IMultiProductCard {
  categories?: string[];
  id?: string;
  isChecked?: boolean;
  accountNumber?: string;
  customerNumber?: string;
}

export enum RecentOrderType {
  NewClient = 'NC',
  TariffChange = 'TC',
  InvoiceDataChange = 'IDC',
  CustomerChange = 'CC',
  VoucherRedeem = 'VR',
  ChargingCardOrder = 'CCO',
  PaymentAgreement = 'PA',
  PartialAmountChange = 'PAC',
  MemberGetsMember = 'MGM',
  CAInformationRequest = 'CAIR',
  Relocation = 'UZ',
  MoveOut = 'AZ',
}

export enum NavigateToType {
  recentOrderStatus = 'ROS',
}

export interface RecentOrderDTO {
  id: number;
  orderedAt: string;
  type: string;
  dataType?: string;
  address?: IAddress;
  addressCode?: string;
  businessData?: IBusinessData;
  customerData?: ICustomerNumberInfo;
  addtlData?: string;
}

export interface RecentOrder {
  id: number;
  orderedAt: string;
  type: RecentOrderType;
  dataType?: DataChangeType;
  address: Address;
  customerData?: ICustomerNumberInfo;
  msgKey: string;
  addtlData?: string;
}

export interface IConsumptionHistory {
  verbrauchshistorie: IConsumption[];
}

export interface IConsumption {
  sparte: string;
  accountNumber?: string;
  verbrauch: IConsumptionDetail[];
}

export interface IConsumptionDetail {
  belegNummer: string;
  lieferKomponente?: string;
  datumVon: string;
  datumBis: string;
  tage: number;
  verbrauch: number;
  verbrauchseinheit: string;
  mittelwert: number;
  betragNetto: number;
}

export interface IChartData {
  name?: string;
  value?: number;
  extra?: { datumVon?: string; datumBis?: string; verbrauchseinheit?: string };
}

export enum ChartCategoryColor {
  STROM = '#F7AD3F',
  NACHTSTROM = '#2D94BC',
  STROMRUECK = '#269782',
  GAS = '#1375A6',
  FERNWAERME = '#E61E5B',
}
