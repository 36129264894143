@if (categories().length) {
  <div [ngClass]="textClass()">
    <div class="row g-2 mb-2" [ngClass]="rowClass()">
      @for (category of categories(); track $index) {
        <div class="col-auto">
          <img
            class="w-auto h-auto"
            aria-hidden="true"
            [style.max-width]="maxWidth()"
            [src]="category | categoryIconSrc: isDisabled()"
            alt=""
            data-test="product-category-img"
          />
        </div>
      }
    </div>
    <mwe-headline [headlineNodeType]="textNodeType()" [text]="joinedLabels()" cssClass="mb-0" [style]="'font-size:' + fontSize()" />
  </div>
}
