import { ChangeDetectionStrategy, Component, input, OnInit, signal } from '@angular/core';
import { InfoText } from '@mwe/models';
import { PopupService, StaticContentService } from '@mwe/services';
import { track } from '@mwe/utils';
import { InfoTextPopupComponent } from './info-text-popup.component';

@Component({
  selector: 'mwe-info-text-icon',
  templateUrl: './info-text-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTextIconComponent implements OnInit {
  infoTextCode = input<string>();
  infoTextTitle = input<string>();
  infoTextDescription = input<string>();
  infoTextLink = input<{ name: string; target: string }>();
  dataTest = input<string>();
  colorClass = input('text-primary');
  translations = input();
  infoText = signal<InfoText>(null);
  customUrlOverride = input<string>();

  constructor(
    private staticContentService: StaticContentService,
    private popupService: PopupService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.infoTextCode()) {
      await this.getInfoText();
    } else {
      this.infoText.set({
        code: null,
        title: this.infoTextTitle(),
        description: this.infoTextDescription(),
        link: this.infoTextLink(),
      });
    }

    if (!!this.translations() && this.infoText()?.description) {
      // create copy to avoid manipulating texts in cache
      const infoText = { ...this.infoText() };
      Object.keys(this.translations()).forEach(key => {
        infoText.description = infoText.description.replace(`{{ ${key} }}`, this.translations()[key]);
      });
      this.infoText.set(infoText);
    }
  }

  async iconClicked(): Promise<void> {
    track('infoCodeClicked', { code: this.infoText().code });

    this.popupService.open({
      id: 'info-text-icon-clicked',
      titleKey: '',
      messageKey: '',
      showSubmitButton: false,
      showCancelButton: false,
      component: InfoTextPopupComponent,
      componentData: this.infoText(),
      dismissable: true,
      modalOpts: {
        ariaLabelledBy: 'mwe_info-text-popup-title',
      },
    });
  }

  private async getInfoText(): Promise<void> {
    const infoTextList = await this.staticContentService.getInfoTextList();

    if (!infoTextList) {
      return;
    }

    // example: rechnungen.interaktiveRechnung.details.nope.label_rechnungsnr
    const parts = this.infoTextCode().split('.');
    // last part is infoTextCode
    const infoTextCode = parts.splice(-1)[0];
    const firstPart = parts.splice(0, 1)[0];

    let obj = infoTextList[firstPart];
    parts.forEach(part => {
      if (!obj) {
        this.infoText.set(null);
        return;
      }

      if (Array.isArray(obj[part])) {
        this.infoText.set(
          (obj[part] as []).filter((it: InfoText) => !!it.title && !!it.description).find((it: InfoText) => it.code === infoTextCode),
        );
        return;
      }

      obj = obj[part];
    });

    if (this.customUrlOverride()) {
      this.infoText.update(old => {
        return {
          ...old,
          link: {
            name: old.link.name,
            target: this.customUrlOverride(),
          },
        };
      });
    }
  }
}
