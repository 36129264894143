import { ChangeDetectionStrategy, Component, input, OnInit, output, signal } from '@angular/core';
import { Address, IAddress, IAddressProducts } from '@mwe/models';
import { AddressLogic } from '@mwe/services';
import { isArrayWithOneItem } from '@mwe/utils';

@Component({
  selector: 'mwe-address-with-selection-dropdown',
  templateUrl: './address-with-selection-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressWithSelectionDropdownComponent implements OnInit {
  address = input.required<Address>();
  loadAllAddresses = input<boolean>(true);
  newAddressSelected = output<IAddressProducts>();
  isCollapsed = signal<boolean>(true);
  addressOptions = signal<IAddressProducts[]>([]);

  constructor(private addressLogic: AddressLogic) {}

  async ngOnInit(): Promise<void> {
    if (!this.loadAllAddresses()) {
      return;
    }

    const options = await this.addressLogic.loadAddressesWithGroupNames();
    this.addressOptions.set(options);
    await this.addressLogic.loadAndMapGroupNames([this.address()]);
  }

  toggleDropDown(): void {
    if (isArrayWithOneItem(this.addressOptions())) {
      return;
    }

    this.isCollapsed.update(old => !old);
  }

  hideDropDown(): void {
    this.isCollapsed.set(true);
  }

  isAddressSelected(address: IAddress): boolean {
    return address.getHashCode() === this.address()?.getHashCode();
  }

  onAddressSelected(addressProducts: IAddressProducts): void {
    this.newAddressSelected.emit(addressProducts);
    this.isCollapsed.set(false);
  }
}
