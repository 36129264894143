import { AuthConfig } from 'angular-oauth2-oidc';
import { Environment, EnvironmentCustomerType } from './environment.model';

export declare interface EnvironmentServiceInterface {
  getApiUrl(): string;

  getEnvironment(): Environment;

  getMaintenanceRefreshIntervall(): number;

  getChatbotWidgetId(): void;

  getLoggingAPIOBaseURL(): string;

  getMNRApiUrl(): string;

  getPortalUrlPrefix(): string;

  getPortalCustomerType(): EnvironmentCustomerType;

  getAuthConfig(): AuthConfig;

  getRegistrationUri(): string;

  getLogoutUri(): string;

  getCurrentPortalUri(): string;

  getBusinessPortalUri(): string;

  getPrivatPortalUri(): string;

  staticContent(): string;

  getLogwienProfileUri(): string;

  getLogwienPartnerName(): string;

  getLogwienLogoutUri(): string;

  getLogwienLoginFlowUri(): string;

  getLogwienProfileReferrer(): string;

  getOssUrl(): string;
}
