import { Component, Input } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { ServiceStateEnumExporter } from '@mwe/ui/shared';

@Component({
  selector: 'mwe-bullet',
  templateUrl: './bullet.component.html',
  styles: [],
})
export class BulletComponent extends ServiceStateEnumExporter {
  @Input() bulletId: string;
  @Input() bulletStateMap: Map<string, ServiceStateEnum> = new Map();
  @Input() bulletLabelMap: Map<string, string | string[]> = new Map();
  @Input() bulletStateIconMap: Map<string, Map<ServiceStateEnum, string>> = new Map();
  @Input() bulletStateClassMap: Map<string, Map<ServiceStateEnum, string>> = new Map();
  @Input() bulletTitleMap: Map<string, string> = new Map();
  @Input() bulletIds: string[];
  @Input() bulletTranslateMap: Map<string, any> = new Map();
  @Input() hasVerticalLine: boolean;

  getBulletTitle(bulletId: string): string {
    return this.bulletTitleMap.get(bulletId);
  }

  getBulletLabel(bulletId: string): string[] {
    const label = this.bulletLabelMap.get(bulletId);
    return Array.isArray(label) ? label : [label];
  }

  getTranslateValues(bulletId: string): any {
    return this.bulletTranslateMap.get(bulletId);
  }

  getIconClass(bulletId: string): string {
    const bulletState = this.bulletStateMap.get(bulletId);
    if (this.bulletStateIconMap?.has(bulletId)) {
      const stateMap = this.bulletStateIconMap.get(bulletId);
      if (stateMap.has(bulletState)) {
        return stateMap.get(bulletState);
      }
    }
    switch (bulletState) {
      case ServiceStateEnum.LOADING:
        return 'fa-cog';
      case ServiceStateEnum.SUCCESS:
        return 'fa-check';
      case ServiceStateEnum.ERROR:
      case ServiceStateEnum.FAILED:
        return 'fa-exclamation-triangle';
      case ServiceStateEnum.INFO:
        return 'fa-exclamation';
      default:
        return 'fa-clock';
    }
  }

  getBulletClass(bulletId: string): string {
    const bulletState = this.bulletStateMap.get(bulletId);
    if (this.bulletStateClassMap?.has(bulletId)) {
      const stateMap = this.bulletStateClassMap.get(bulletId);
      if (stateMap.has(bulletState)) {
        return stateMap.get(bulletState);
      }
    }
    switch (bulletState) {
      case ServiceStateEnum.LOADING:
        return 'has-border-blue has-text-blue';
      case ServiceStateEnum.SUCCESS:
        return 'has-border-green  has-gradient-green has-text-white';
      case ServiceStateEnum.ERROR:
      case ServiceStateEnum.FAILED:
        return 'has-border-red  has-gradient-red has-text-white';
      case ServiceStateEnum.INFO:
        return 'has-border-blue has-gradient-blue has-text-white';
      default:
        return 'has-border-gray has-text-gray-500';
    }
  }

  getLineClass(bulletId: string): string[] {
    let classes: string[] = [];
    const bulletState = this.bulletStateMap.get(bulletId);
    switch (bulletState) {
      case ServiceStateEnum.SUCCESS:
        break;
      default:
        classes.push('has-gray-line');
    }
    if (this.hasVerticalLine) {
      classes.push('has-vertical-line');
    }
    return classes;
  }

  trackByIndex(index: number): number {
    return index;
  }
}
