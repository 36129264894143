<div class="modal-content">
  <div class="modal-header justify-content-between d-flex align-items-center bg--orange">
    <h1 class="modal-title" data-test="info-text-popup-title" id="mwe_info-text-popup-title">{{ loadedData?.title | translate }}</h1>
    <button
      (click)="closePopup()"
      aria-label="Close"
      class="btn btn-clean text-white"
      data-dismiss="modal"
      data-test="info-text-popup-close-btn"
      type="button"
    >
      <i aria-hidden="true" class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body bg-white">
    <p [innerHTML]="loadedData?.description | translate" class="text--big mb-0" data-test="info-text-popup-text"></p>
    <a
      *ngIf="loadedData?.link?.target && loadedData?.link?.name"
      [href]="loadedData?.link.target"
      class="d-block font-size-14 fw-500 mt-4 mb-2 text-uppercase"
      data-test="info-text-popup-link"
      target="_blank"
    >
      {{ loadedData?.link.name | translate }}
    </a>
  </div>
</div>
