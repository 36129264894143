import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  concat,
  filter,
  ignoreElements,
  interval,
  mergeAll,
  of,
  Subject,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { ServiceStateEnum, ServiceStateType } from '@mwe/constants';
import { DownloadObservables, Identifier } from '@mwe/models';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private downloadStateSubject = new BehaviorSubject<Record<Identifier, ServiceStateType>>({});
  private readonly downloadState$ = this.downloadStateSubject.asObservable();

  private downloadErrorSubject = new Subject<Record<Identifier, Error>>();
  private readonly downloadError$ = this.downloadErrorSubject.asObservable();

  download<T>(identifier: Identifier, observables: DownloadObservables<T>, stateChangeDelay = 2000) {
    return concat(
      of(null).pipe(
        tap(() =>
          this.downloadStateSubject.next({
            ...this.downloadStateSubject.value,
            [identifier]: ServiceStateEnum.LOADING,
          }),
        ),
        ignoreElements(),
      ),
      observables?.[identifier]?.pipe(
        tap(() => {
          this.downloadStateSubject.next({
            ...this.downloadStateSubject.value,
            [identifier]: ServiceStateEnum.SUCCESS,
          });
        }),
        catchError(error => {
          this.handleError(identifier, error);
          return throwError(() => error);
        }),
      ),
      interval(stateChangeDelay).pipe(
        take(1),
        tap(() => {
          this.downloadStateSubject.next({
            ...this.downloadStateSubject.value,
            [identifier]: ServiceStateEnum.INIT,
          });
        }),
        tap(() => {
          const value = { ...this.downloadStateSubject.value };
          delete value[identifier];
          this.downloadStateSubject.next(value);
        }),
        ignoreElements(),
      ),
    );
  }

  getLatestMergedDownloadStates(...keys: Identifier[]) {
    const observables = keys.map(key => this.getDownloadState(key));
    return of(...observables).pipe(
      mergeAll(),
      filter(state => state !== undefined),
    );
  }

  getDownloadState(identifier: Identifier) {
    return this.downloadState$.pipe(switchMap(stateRecord => of(stateRecord?.[identifier])));
  }

  getErrorState(identifier: Identifier) {
    return this.downloadError$.pipe(switchMap(stateRecord => of(stateRecord?.[identifier])));
  }

  private handleError(identifier: Identifier, error: Error) {
    this.downloadStateSubject.next({ ...this.downloadStateSubject.value, [identifier]: ServiceStateEnum.ERROR });
    this.downloadErrorSubject.next({ [identifier]: error });
  }
}
