import { Component, inject, input } from '@angular/core';
import { Product } from '@mwe/models';
import { HeadlineService } from '../../../headline/headline.service';

@Component({
  selector: 'mwe-tariff-card-internet',
  templateUrl: './tariff-card-internet.component.html',
  providers: [HeadlineService],
})
export class TariffCardInternetComponent {
  product = input.required<Product>();

  headlineService = inject(HeadlineService);
}
