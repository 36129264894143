<div class="align-items-center text-center">
  <div class="row">
    <div
      class="col-12 col-lg-6 mb-4"
      *ngFor="let card of multiProductCards"
      (click)="cardClicked.emit(card)"
      data-test="mwe-multi-product-card"
    >
      <mwe-multi-product-card
        [ngClass]="{ 'd-block pointer': hasMousePointerStyle }"
        [card]="card"
        [isDisabled]="isDisabled"
        [showCompanyReference]="showCompanyReference"
        [showSummarizedCompanyReference]="showSummarizedCompanyReference"
        [showAccountNumber]="showAccountNumber"
        [showCustomerNumber]="showCustomerNumber"
        [noRadiusBottom]="isSliderVisible && !isDisabled"
      >
      </mwe-multi-product-card>
      <mwe-product-card-slider
        *ngIf="isSliderVisible && !isDisabled"
        [id]="card.id"
        [initialValue]="card.isChecked"
        [showCompanyReference]="showCompanyReference"
        toggleOnMsgKey="invoiceData.change.newAccount.toggleLabel.toggleOn"
        toggleOffMsgKey="invoiceData.change.newAccount.toggleLabel.toggleOff"
        (sliderState)="handleSliderResponse($event)"
      >
      </mwe-product-card-slider>
    </div>
  </div>
</div>
