<div
  [attr.data-test]="dataTest"
  [attr.title]="ariaTitle | translate"
  [attr.ngbAutofocus]="hasAutoFocus ? true : null"
  (click)="cardClicked.emit()"
  (keydown.enter)="cardClicked.emit()"
  class="card-basic card-clickable h-100 p-0"
  tabindex="0"
>
  <div class="card-basic-body">
    <div class="row h-100">
      <div class="col text-center pl-0 pr-0">
        <div class="row gx-3 mb-4 justify-content-center">
          @for (category of categories; track category) {
            <div class="col-auto">
              <mwe-product-icon [category]="category" size="md"></mwe-product-icon>
            </div>
          }
        </div>
        <ng-content></ng-content>
      </div>
      <div class="col-auto align-self-center">
        <i class="fas fa-chevron-right text-black"></i>
      </div>
    </div>
  </div>
</div>
