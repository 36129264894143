import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DetailsLinkTypeEnum, MOBILE_BREAKPOINT, ProductCategoryEnum } from '@mwe/constants';
import {
  Address,
  ContractStatusEnum,
  EingangsZahlweg,
  IAktionen,
  IBankDetails,
  ICustomerAdressDetails,
  ICustomerVertragskonto,
  IERechnungsInfo,
  IGeschaeftsPartner,
  IGeschaeftspartnerBeziehung,
  IOrderDetailsInfo,
  IOrganisation,
  IPartner,
  IPhysischePerson,
  IRechnungsempfaenger,
  IVertragskonto,
  obfuscateIBAN,
  parseILCustomerAddress,
  Rechnung,
  RechnungDetails,
  Verrechnungskonto,
} from '@mwe/models';
import { hasClearingAccountSepaMandant } from './clearing-account.utils';
import {
  isProductCategoryEmobility,
  isProductCategoryEmobilityOrInternet,
  isProductCategoryFernwaerme,
  isProductCategoryInternet,
  isProductCategoryStromOrGas,
} from '../products/product-categoriy.utils';

export const isContractStatus = (value: string, contractEnum: ContractStatusEnum): boolean => {
  return !!value && value.toUpperCase() === contractEnum;
};

export const isAddressGroupToBeDisplayedDisabled = (detailsLink: string, categories: string[]): boolean => {
  if (!detailsLink || detailsLink === DetailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR) {
    return false;
  }
  return !categories.some(category => isProductCategoryEmobility(category) || isProductCategoryInternet(category));
};

export const isTariffChangeCategory = (value: string): boolean => {
  return !isTariffChangeOnlineNotPossible(value) && isProductCategoryStromOrGas(value);
};

export const isTariffChangeOnlineNotPossible = (value: string): boolean => {
  return isProductCategoryEmobilityOrInternet(value);
};

export const isRelocationChangeOnlineNotPossible = (value: string): boolean => {
  return isProductCategoryEmobilityOrInternet(value);
};

export const isMoveOutOnlineNotPossible = (value: string): boolean => {
  return isProductCategoryEmobilityOrInternet(value);
};

export const getRouteData = (activatedRoute: ActivatedRoute, route?: ActivatedRouteSnapshot, data?: any) => {
  if (!route) {
    route = activatedRoute.snapshot;
  }

  const currentRouteData = { ...data, ...route.data };
  const pageTitle = route.data && route.data['pageTitle'] ? route.data['pageTitle'] : '';
  addToPageTitles(currentRouteData, pageTitle);

  return route.firstChild ? getRouteData(activatedRoute, route.firstChild, currentRouteData) : currentRouteData;
};

// html-title should contain all page-titles
function addToPageTitles(currentRouteData, pageTitle: string): void {
  if (pageTitle) {
    if (!currentRouteData.pageTitles) {
      currentRouteData.pageTitles = [pageTitle];
    } else {
      if (!currentRouteData.pageTitles.includes(pageTitle)) {
        currentRouteData.pageTitles.push(pageTitle);
      }
    }
  }
}

export const accumulatedCategoryForNewProducts = (newAddressCategories: ProductCategoryEnum[]) => {
  const accumulatorMap = new Map();
  accumulatorMap.set(ProductCategoryEnum.STROM, 1);
  accumulatorMap.set(ProductCategoryEnum.GAS, 2);
  accumulatorMap.set(ProductCategoryEnum.FERNWAERME, 4);
  const categoryAccumulator = (acc: number, cur: ProductCategoryEnum) => acc + accumulatorMap.get(cur);
  let accumulatedCategory = 0;
  accumulatedCategory = newAddressCategories.reduce(categoryAccumulator, accumulatedCategory);
  return accumulatedCategory;
};

export const everyTrue = (...values): boolean => {
  return values.every(b => b === true);
};

export const anyTrue = (...values): boolean => {
  return !!values.find(b => b === true);
};

export const getEmailValidatorWithRegExp = (): ValidatorFn => {
  return Validators.pattern(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

export const hasVoucherInfoForCategory = (voucherInfo: IAktionen, category: string): boolean => {
  if (voucherInfo) {
    const ix = voucherInfo.infoTextKey.findIndex(elem => {
      return elem.toLowerCase() === (category + 'Msg').toLowerCase();
    });
    if (ix > -1) {
      return voucherInfo.infoText[ix].length > 0;
    }
  }
  return false;
};

export const isSepa = (vertragskonto?: IVertragskonto): boolean => {
  return vertragskonto && vertragskonto.zahlungsmethode === 'Bankeinzug';
};

export const isZahlschein = (vertragskonto?: IVertragskonto): boolean => {
  return vertragskonto && vertragskonto.zahlungsmethode === EingangsZahlweg.Zahlschein;
};

export const isVertragsKontoPaidByZahlschein = (vertragskonto?: ICustomerVertragskonto): boolean => {
  return vertragskonto?.eingangszahlweg === EingangsZahlweg.Zahlschein;
};

export const isSepaMandant = (vertragskonto?: ICustomerVertragskonto): boolean => {
  return vertragskonto && vertragskonto.eingangszahlweg === EingangsZahlweg.Sepa;
};

export const parseInvoiceAccount = (detailData: IOrderDetailsInfo) => {
  if (hasClearingAccountSepaMandant(detailData.verrechnungskonto)) {
    setInvoiceAccount(detailData);
  } else {
    setBezahlartZahlschein(detailData);
  }
};

export const setInvoiceAccount = (detailData: IOrderDetailsInfo) => {
  if (detailData.verrechnungskonto.eingangskonto) {
    detailData.bezahlartIcon = 'sepa';
    detailData.bezahlartText = obfuscateIBAN(detailData.verrechnungskonto.eingangskonto.iban);
  } else {
    // we expect that only FW products sometimes comne without an IBAN definition
    setBezahlartZahlschein(detailData);
  }
};

export const setBezahlartZahlschein = (detailData: IOrderDetailsInfo) => {
  detailData.bezahlartIcon = 'zahlschein';
  detailData.bezahlartText = 'Zahlschein';
};

export const setBezahlartSepa = (detailData: IOrderDetailsInfo) => {
  if (detailData.produktDetails.vertragskonto.iban) {
    detailData.bezahlartIcon = 'sepa';
    detailData.bezahlartText = obfuscateIBAN(detailData.produktDetails.vertragskonto.iban);
  } else {
    // we expect that only FW products sometimes comne without an IBAN definition
    setBezahlartZahlschein(detailData);
  }
};

export const isArrayWithOneItem = <T>(list: T[]): boolean => {
  return Array.isArray(list) && list.length === 1;
};

export const isArrayWithMinOneItem = (list: any[]): boolean => {
  return Array.isArray(list) && list.length > 0;
};

export const isArrayWithMoreThanOneItem = (list: any[]): boolean => {
  return Array.isArray(list) && list.length > 1;
};

export const getExtendedHashCode = (address: Address): string => {
  return address.getHashCode() + '_' + address.city + '_' + address.country;
};

export const getInvoiceAddress = (relations: IGeschaeftspartnerBeziehung[]): ICustomerAdressDetails => {
  const relation = relations?.find(i => i.beziehungsTyp === 'Rechnungsempfaenger' && i.adresse !== undefined);
  return relation?.adresse ? relation.adresse : null;
};

export const getInvoiceEmail = (account: Verrechnungskonto): string => {
  return account?.pdfRechnungsEmpfaenger;
};

export const getBillInfo = (account: Verrechnungskonto): IERechnungsInfo => {
  const invoiceEmail = getInvoiceEmail(account);
  return { isERechnungsEmpfaenger: !!account.istPdfRechnungsEmpfaenger, rechnungEmail: invoiceEmail };
};

export const getAccountCustomerId = (relations: IGeschaeftspartnerBeziehung[]): string => {
  const relation = getAccountOwner(relations);
  return relation ? relation.geschaeftsPartnerId : null;
};

export const getAccountOwner = (relations: IGeschaeftspartnerBeziehung[]): IGeschaeftspartnerBeziehung => {
  return getPartnerRelation(relations, 'Kontoinhaber');
};

// temp fix for MWE-13982 / product-details
// refactor getAccountOwner with this logic ones Rhian has created new story
// so we can have consistent logic everywhere
export const getAccountOwnerBasedOnBusinessPartnerNUmber = (
  relations: IGeschaeftspartnerBeziehung[],
  businessPartnerNumber: string,
): IGeschaeftspartnerBeziehung => {
  return relations?.find(r => r.geschaeftsPartnerId === businessPartnerNumber);
};

export const getAccountOwnerOrDifferentInvoiceRecipient = (details: Verrechnungskonto): IGeschaeftspartnerBeziehung => {
  const accountOwner = getAccountOwner(details?.geschaeftspartnerBeziehungen);

  if (!accountOwner) {
    return null;
  }

  if (details.systemId === 'ISX100') {
    // MWE-12533
    const invoiceRecipient = getInvoiceRecipient(details.geschaeftspartnerBeziehungen);

    if (invoiceRecipient.geschaeftsPartnerId !== accountOwner.geschaeftsPartnerId) {
      return invoiceRecipient;
    }
  }

  return accountOwner;
};

export const getInvoiceRecipient = (relations: IGeschaeftspartnerBeziehung[]): IGeschaeftspartnerBeziehung => {
  return getPartnerRelation(relations, 'Rechnungsempfaenger');
};

export const getPartnerRelation = (relations: IGeschaeftspartnerBeziehung[], typ: string): IGeschaeftspartnerBeziehung => {
  return relations?.find(i => i.beziehungsTyp === typ);
};

export const getInvoiceCustomerId = (relations: IGeschaeftspartnerBeziehung[]): string => {
  const relation = getInvoiceRecipient(relations);
  return relation ? relation.geschaeftsPartnerId : null;
};

export const parseIPartner = (customerVertragskonto: ICustomerVertragskonto, partnerDetails: IGeschaeftsPartner): IPartner => {
  const contactDetails = partnerDetails?.kontaktdetails?.length > 0 ? partnerDetails.kontaktdetails[0] : null;
  return {
    partnerId: getAccountCustomerId(customerVertragskonto.geschaeftspartnerBeziehungen),
    partnerGruppe: null,
    nickname: null,
    registrierungStatus: null,
    rolle: null,
    partnerTyp: null,
    anrede: partnerDetails?.physischePerson.anrede,
    titel: partnerDetails?.physischePerson.titel,
    nachname: partnerDetails?.physischePerson.nachname,
    vorname: partnerDetails?.physischePerson.vorname,
    firma: null,
    email: contactDetails?.email?.eMail,
    telefon: contactDetails?.telefon?.nummer,
    telefonvorwahl: contactDetails?.telefon?.land,
  };
};

export const parseIRechnungsempfaenger = (
  customerVertragskonto: ICustomerVertragskonto,
  partnerDetails: IGeschaeftsPartner,
): IRechnungsempfaenger => {
  const invoiceAddress = getInvoiceAddress(customerVertragskonto.geschaeftspartnerBeziehungen);
  const contactDetails = partnerDetails?.kontaktdetails?.length > 0 ? partnerDetails.kontaktdetails[0] : null;
  return {
    partnerId: getInvoiceCustomerId(customerVertragskonto.geschaeftspartnerBeziehungen),
    partnerGruppe: null,
    nickname: null,
    registrierungStatus: null,
    rolle: null,
    partnerTyp: null,
    anrede: partnerDetails?.physischePerson.anrede,
    titel: partnerDetails?.physischePerson.titel,
    nachname: partnerDetails?.physischePerson.nachname,
    vorname: partnerDetails?.physischePerson.vorname,
    firma: null,
    email: contactDetails?.email?.eMail,
    telefon: contactDetails?.telefon?.nummer,
    telefonvorwahl: contactDetails?.telefon?.land,
    adresse: {
      ort: invoiceAddress.ort,
      plz: invoiceAddress.plz,
      strasse: invoiceAddress.strasse,
      hausnummer: invoiceAddress.hausnummer,
      land: invoiceAddress.land,
      raum: invoiceAddress.raumnummer,
      adresshinweis: invoiceAddress.adresshinweis,
    },
  };
};

export const parseIVertragskonto = (customerVertragskonto: ICustomerVertragskonto): IVertragskonto => {
  return {
    vertragskontoId: customerVertragskonto.vertragskontoId,
    zahlungsmethode: isSepaMandant(customerVertragskonto) ? 'Bankeinzug' : 'Zahlschein',
    kontonummer: null,
    blz: null,
    iban: customerVertragskonto.eingangskonto?.iban,
    bic: customerVertragskonto.eingangskonto?.swift,
  };
};

export const markFormGroupDirty = (form: UntypedFormGroup) => {
  Object.values(form.controls).forEach(control => {
    control.markAsDirty();

    if ((control as any).controls) {
      markFormGroupDirty(control as UntypedFormGroup);
    }
  });
};

export const getFormattedContactInfo = (partner: IPhysischePerson, invoiceAddress: ICustomerAdressDetails): string => {
  const address = parseILCustomerAddress(invoiceAddress);
  return getFormattedContactAddress(partner, address);
};

export const getFormattedContactAddress = (partner: IPhysischePerson, address: Address, organisation?: IOrganisation): string => {
  // B2C has organisation, but falsy name...
  if (organisation?.organisationsname) {
    return `${organisation.organisationsname}: ${address?.getPostCodeAndStreet?.()}`;
  }
  return `${partner?.anrede} ${partner?.vorname} ${partner?.nachname}: ${address?.getPostCodeAndStreet?.()}`;
};

export const removeDuplicateObjects = (objects: unknown[]): unknown[] => {
  const result = [];

  objects.forEach(o => {
    if (!result.find(r => JSON.stringify(o) === JSON.stringify(r))) {
      result.push(o);
    }
  });

  return result;
};

export const getBankDetailsFrom = (invoiceData: IVertragskonto): IBankDetails => {
  return {
    iban: invoiceData.iban,
    bankdaten: {
      bankleitzahl: undefined,
      bankname: undefined,
      bic: invoiceData.bic,
      land: undefined,
    },
  };
};

export const formatCurrencyValue = (unformattedValue: string, formattedCommaSeparator = ','): string => {
  const numberParts = unformattedValue.trim().split(/[.,]/);
  let afterCommaValue = '00';
  if (numberParts.length === 2) {
    afterCommaValue = numberParts[1] + (numberParts[1].length === 1 ? '0' : '');
  }
  return numberParts[0] + formattedCommaSeparator + afterCommaValue;
};

export const isObject = (object: any): boolean => {
  return object !== null && typeof object === 'object';
};

export const getUniqueValues = <T>(values: T[]): T[] => {
  if (isObject(values?.[0])) {
    const stringValues = values.map(v => JSON.stringify(v));
    const uniqueValues = Array.from(new Set(stringValues));
    return uniqueValues.map(uv => JSON.parse(uv));
  }

  return Array.from(new Set(values));
};

export const isNumber = (str: string): boolean => {
  if (!str) {
    return false;
  }

  return /^[0-9]*$/.test(str);
};

export const getNumberFrom = (val: string | number): number => {
  if (typeof val === 'string') {
    return parseFloat(val);
  }

  return val;
};

export const formatAmount = (val: number): string => {
  if (!val || isNaN(val)) {
    return '';
  }

  return (val + '').replace('.', ',');
};

export const isNumberIgnoringSpace = (str: string): boolean => {
  if (!str) {
    return false;
  }

  const strWithoutSpace = str.split(' ').join('');

  return isNumber(strWithoutSpace);
};

export const stringToBoolean = (value: string): boolean => {
  return value === 'true';
};

export const formatAccountNumber = (accountNumber: string, isFernwaerme: boolean): string => {
  return isFernwaerme ? accountNumber?.slice(2) : accountNumber;
};

export const sumReducer = (accumulator, current) => accumulator + current;

export const isMobile = (): boolean => {
  return window.innerWidth < MOBILE_BREAKPOINT;
};

// TODO: WTF? do be refactored!!
export const getTariffClassWithLineBreaks = (tariffClass: string | string[]): string | string[] => {
  type mapType = Record<string, string>;

  const mappingObj: mapType = {
    Speicherheizung: 'Speicher&shy;heizung',
    Wärmepumpe: 'Wärme&shy;pumpe',
    Landwirtschaft: 'Land&shy;wirtschaft',
  };

  if (!tariffClass) {
    return tariffClass;
  }

  let stringVal: string;
  if (Array.isArray(tariffClass) && tariffClass[0]) {
    tariffClass = tariffClass[0];
  } else {
    return tariffClass;
  }

  for (const key of Object.keys(mappingObj)) {
    if (tariffClass.includes(key)) {
      stringVal = tariffClass.replace(key, mappingObj[key]);
    }
  }

  if (!stringVal) {
    stringVal = tariffClass;
  }
  return stringVal;
};

/** Waerme Invoices cannot be combined with SG */
export function isWaermeInvoiceData(invoiceDetails: RechnungDetails | Rechnung): boolean {
  if ('produkt' in invoiceDetails) {
    // duck typing for RechnungDetails
    return invoiceDetails.produkt.some(p => isProductCategoryFernwaerme(p.sparte));
  } else {
    return (invoiceDetails as Rechnung).sparte.some(s => isProductCategoryFernwaerme(s));
  }
}
