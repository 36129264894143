import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sparte } from '@mwe/models';

@Component({
  selector: 'mwe-clickable-products-card',
  templateUrl: 'clickable-products-card.component.html',
})
export class ClickableProductsCardComponent {
  @Output() cardClicked = new EventEmitter();
  @Input() categories: Sparte[];
  @Input() ariaTitle: string;
  @Input() dataTest: string;
  @Input() hasAutoFocus = false;
}
