<button
  (click)="onClick()"
  [attr.aria-hidden]="ariaHidden() || null"
  [attr.aria-label]="(ariaLabel() | translate) || null"
  [attr.data-test]="dataTest()"
  [attr.disabled]="isDisabled() ? true : null"
  [attr.id]="btnId()"
  [attr.tabindex]="tabindex() || null"
  [type]="btnType()"
  class="btn btn-link btn-link--{{ color() }} text-start {{ customCss() }} focus-border-large"
  type="button"
>
  @if (prependIconClass()) {
    <i class="{{ prependIconClass() }} mr-2"></i>
  }
  @if (!showSpinner()) {
    <span class="text-decoration-underline">
      <ng-content></ng-content>
      <span [innerHTML]="label() | translate"></span>
    </span>
  }
  @if (showSpinner()) {
    <span class="loading-spinner"></span>
  }
</button>
