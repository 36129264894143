import { Component, computed, Input, Optional, signal } from '@angular/core';
import { IAktionen, IProduct, PushPdfData, TextNodeType } from '@mwe/models';
import { EnvironmentService } from '@mwe/services';
import { isProductStrom, isProductStromOrGasOnly } from '@mwe/utils';
import { HeadlineService } from '../../headline/headline.service';

/** TODO Outsource into separate Module as this component is too specific */
@Component({
  selector: 'mwe-benefits-card',
  templateUrl: './benefits-card.component.html',
})
export class BenefitsCardComponent {
  @Input() wrapperClass: string;

  @Input() headlineReference: TextNodeType | undefined;

  @Input() set voucherInfo(value: IAktionen | undefined) {
    this.voucherInfoSignal.set(value);
  }
  voucherInfoSignal = signal<IAktionen | undefined>(undefined);
  @Input() set products(value: IProduct[] | undefined) {
    this.productsSignal.set(value);
  }
  productsSignal = signal<IProduct[] | undefined>(undefined);

  isEligableForSepaBenefitsSignal = signal<boolean | undefined>(undefined);
  doShow = computed<boolean>(() => {
    return !!this.voucherInfoSignal();
  });
  pushPdfSignal = signal<PushPdfData | undefined>(undefined);
  stromOrGasProducts = computed<IProduct[]>(() => {
    return this.productsSignal()?.filter(p => isProductStromOrGasOnly(p)) || [];
  });
  hasStromProducts = computed<boolean>(() => this.stromOrGasProducts().filter(p => isProductStrom(p)).length > 0);
  voucherStrom = computed<string>(() => {
    if (this.voucherInfoSignal().infoTextKey?.length > 0) {
      if (this.voucherInfoSignal().infoTextKey[0].toLowerCase().startsWith('strom')) {
        return this.voucherInfoSignal().infoText[0];
      }
      return this.voucherInfoSignal().infoText[1];
    }
    return undefined;
  });
  voucherGas = computed<string>(() => {
    if (this.voucherInfoSignal().infoTextKey?.length > 0) {
      if (this.voucherInfoSignal().infoTextKey[0].toLowerCase().startsWith('strom')) {
        return this.voucherInfoSignal().infoText[1];
      }
      return this.voucherInfoSignal().infoText[0];
    }
    return undefined;
  });
  useWrapper = computed<boolean>(() => !!this.wrapperClass);

  constructor(
    @Optional() public headlineService: HeadlineService,
    protected environmentService: EnvironmentService,
  ) {
    this.headlineService?.setReferenceHeadline(this.headlineReference ?? 'h2');
  }
}
