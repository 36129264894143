import { AlertType } from '../shared/alert.model';

enum IGoodieStatus {
  INIT, // 0
  ACTIVATED,
  EXPIRED,
}

export type GoodieType = 'raffle' | 'discount' | 'gift' | 'bonus' | 'campaign' | 'mgm' | 'none';

export interface IGoodieCategory {
  description: string;
  slug: string;
  name: string;
  position_category?: number;
}

export interface IGoodiesModel {
  isHidden?: boolean;
  isFreetextDiscount?: boolean;
  activatedOn?: number;
  goodieId: string;
  // only returned if activated
  code: null | string;
  isFavorite: boolean;
  status: IGoodieStatus;
  isHighlight: boolean;
  slug: string;
  title: string;
  type: GoodieType;
  userId?: string;
  validFrom: number;
  validTill: number;
  voucher: IGoodieVoucher;
}

export interface IGoodieVoucher {
  position: number;
  category: IGoodieCategory;
  description: string;
  details: string;
  hasRequiredMarketpermission: boolean;
  checkboxRaffleText: string;
  checkboxRaffleCondition: boolean;
  campaignLinks: { label: string; 'campaign-links-url': { title: string; target: string; url: string } }[];
  image: IGoodieImage;
  url: string;
  showContactForm?: boolean;
}

export interface IGoodieImage {
  alt: string;
  caption: string;
  height: number;
  src: string;
  srcSet: string;
  sizes: any;
  title: string;
  width: number;
}

export interface IGoodiesDictModel {
  [key: string]: {
    category: IGoodieCategory;
    goodies: Array<IGoodiesModel>;
  };
}

export interface IGoodieResponse<T> {
  data: T;
  meta: any;
  errors: Array<{ title: string; message: string }>;
}

export interface IGoodieAlert {
  title: string;
  message: string;
  type: AlertType;
  placeholderMap?: Map<string, string>;
  links?: Map<string, string>;
}

export interface IGoodieActivateData {
  goodieId: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  address?: IGoodieAdress;
}

export interface IGoodieAdress {
  country: string;
  city: string;
  postcode: string;
  street: string;
  streetNumber: string;
  block: string;
  doorNumber: string;
}
