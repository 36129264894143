<mwe-basic-card>
  <ul class="list-separated">
    <li class="fw-500 font-size-14" *ngFor="let item of items">
      <a
        *ngIf="item.clickFunc"
        class="link pointer"
        [attr.data-test]="item.dataTestSelector"
        [translate]="item.label"
        (click)="item.clickFunc()"
      ></a>
      <a
        *ngIf="item.href"
        class="link"
        [translate]="item.label"
        target="_blank"
        [attr.data-test]="item.dataTestSelector"
        [href]="item.href"
        rel="noopener noreferrer"
      ></a>
    </li>
  </ul>
</mwe-basic-card>
