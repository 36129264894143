import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { PopupService } from '@mwe/services';

@Component({
  selector: 'mwe-info-text',
  templateUrl: './info-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTextComponent {
  infoTextCode = input<string>();
  infoTextTitle = input<string>();
  infoTextDescription = input<string>();
  infoTextLink = input<{ name: string; target: string }>();
  colorClass = input('text-primary');
  customUrlOverride = input<string>();

  private popupService = inject(PopupService);

  isInPopup = computed(() => this.popupService.isOpen() ?? false);
}
