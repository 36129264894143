<div [ngClass]="alertClasses()" [attr.aria-describedby]="uuid()" [attr.role]="!!role() ? role() : null">
  @if (showCloseButton()) {
    <button aria-label="Close" class="alert-close" type="button" (click)="isVisible.set(false)">
      <span aria-hidden="true"></span>
    </button>
  }
  <div class="row g-2" [ngClass]="{ 'g-md-5': !isMinimalLayout() }">
    <div class="col-auto text-center">
      <div class="alert-icon-wrapper">
        <i [ngClass]="iconClasses()"></i>
      </div>
    </div>
    <div class="col align-self-center">
      @if (showTitle()) {
        @switch (headingNodeType()) {
          @case ('h1') {
            <h1 [id]="uuid()" class="h5" data-test="alert-headline" [translate]="titleKey()" [translateParams]="translateValues()"></h1>
          }
          @case ('h2') {
            <h2 [id]="uuid()" class="h5" data-test="alert-headline" [translate]="titleKey()" [translateParams]="translateValues()"></h2>
          }
          @case ('h3') {
            <h3 [id]="uuid()" class="h5" data-test="alert-headline" [translate]="titleKey()" [translateParams]="translateValues()"></h3>
          }
          @case ('h4') {
            <h4 [id]="uuid()" class="h5" data-test="alert-headline" [translate]="titleKey()" [translateParams]="translateValues()"></h4>
          }
          @case ('h5') {
            <h5 [id]="uuid()" class="h5" data-test="alert-headline" [translate]="titleKey()" [translateParams]="translateValues()"></h5>
          }
          @case ('h6') {
            <h6 [id]="uuid()" class="h5" data-test="alert-headline" [translate]="titleKey()" [translateParams]="translateValues()"></h6>
          }
        }
      }
      @if (infoText()) {
        <p class="text--big">
          <span
            data-test="alert-message"
            [ngStyle]="type() === 'danger' && { color: 'white' }"
            [innerHTML]="messageKey() | translate: translateValues() | safeHtml"
          ></span>
          <mwe-info-text-icon
            [infoTextTitle]="infoText().title | translate"
            [infoTextDescription]="infoText().description | translate"
            colorClass="white"
          />
        </p>
      }
      @if (!infoText() && messageKey()) {
        <p
          [id]="uuid()"
          class="text--big"
          [ngStyle]="type() === 'danger' && { color: 'white' }"
          [innerHTML]="messageKey() | translate: translateValues() | safeHtml"
        ></p>
      }
      @if (messageKeys()) {
        @for (key of messageKeys(); track key) {
          <p class="text--big" [innerHTML]="key | translate: translateValues() | safeHtml"></p>
        }
      }
      @if (link()) {
        <p class="text--big mt-1">
          <a id="alert-link" data-test="alert-link" href="#" mweClickPreventDefault (click)="fireLinkClickEvent()" [translate]="link()"></a>
        </p>
      }
      <ng-content />
    </div>
  </div>
</div>
