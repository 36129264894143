<ng-template #listing>
  <div class="row w-100">
    <div class="col-12">
      <ul class="list-separated spacing-4">
        <li *ngFor="let item of items">
          <p class="text--big mb-0" data-test="sepa-mandat-products-list-header">
            <strong *ngIf="item?.categories?.length > 0">
              <ng-container *ngFor="let c of item.categories; let isLast = last">{{ c | translate }}{{ isLast ? '' : '/' }}</ng-container>
            </strong>
          </p>

          <div class="mb-1 mt-1" data-test="sepa-mandat-products-list-body">
            <div class="row mb-3 mb-sm-0" *ngFor="let part of getPartsToShow(item?.isFernwaerme)">
              @if (item[part]) {
                <div class="col-12 col-sm-4">
                  <p class="text--big mb-0 text-nowrap" translate="bankAccountChange.status.sepaInfo.{{ part }}"></p>
                </div>
                <div class="col-12 col-sm-8">
                  <p class="text--big mb-0">
                    <strong>{{ item[part] | translate }}</strong>
                  </p>
                </div>
              }
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- {{ changeData | json }}
<br />
{{ items | json }} -->
</ng-template>
@if (asCard) {
  <mwe-basic-card [hasBackground]="true" cardTitle="bankAccountChange.status.sepaInfo.title" iconClass="fas fa-credit-card">
    <ng-container *ngTemplateOutlet="listing"></ng-container>
  </mwe-basic-card>
} @else {
  <h6 translate="bankAccountChange.status.sepaInfo.title"></h6>
  <ng-container *ngTemplateOutlet="listing"></ng-container>
}
