import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { convertDateToDisplayFormat, isArrayWithMinOneItem, isMobile, markFormGroupDirty } from '@mwe/utils';
import { FormInputComponentType, IFormInput, IFormInputGroup, IFormModel, IFormSelect } from '@mwe/models';
import { v4 } from 'uuid';

@Component({
  selector: 'mwe-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnDestroy {
  @Input() isLoading = false;
  @Input() isInline = false;
  @Input() suppressValidityCheckOnSubmit = false;
  form: UntypedFormGroup;
  initialValues = {};
  selectedValue = {};
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() resetBtnClicked = new EventEmitter<any>();
  @Output() formError = new EventEmitter<any>();
  @Output() formIsValid = new EventEmitter<any>();
  @Output() formIsInvalid = new EventEmitter<any>();
  @Output() inputBlurred = new EventEmitter<void>();
  private formValueChangeSubscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
  ) {}

  _formModel: IFormModel;

  get formModel(): IFormModel {
    return this._formModel;
  }

  @Input()
  set formModel(model: IFormModel) {
    this.setFormModel(model);
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription.unsubscribe();
  }

  onSelectChange(option: IFormSelect, inputName: string): void {
    this.selectedValue[inputName] = option.text;
    this.form.controls[inputName].patchValue(option.value);
  }

  submit(formData: any): void {
    if (this.isValid() || this.suppressValidityCheckOnSubmit) {
      this.trimFormData(formData);
      this.formSubmitted.emit(formData);
    } else {
      const controlErrors = Object.keys(this.form.controls).map(key => {
        return {
          name: key,
          errors: this.form.controls[key].errors,
        };
      });
      this.formError.emit(controlErrors);
    }
    this.scrollToAlert();
  }

  reset(): void {
    this.form.reset();
    this.form.patchValue(this.initialValues);
    this.resetBtnClicked.emit();
    this.scrollToAlert();
  }

  scrollToAlert(): void {
    if (isMobile()) {
      if (document.querySelector('mwe-alert')) {
        document.querySelector('mwe-alert').scrollIntoView();
      } else {
        window.scroll(0, 0);
      }
    }
  }

  trimFormData(formData: any): void {
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && typeof formData[key] === 'string') {
        formData[key] = this.form.controls[key].value.trim() as string;
      }
    }
  }

  setError(fieldName: string, error): void {
    this.form.get(fieldName).setErrors(error);
    this.fireValidation();
  }

  fireValidation(): void {
    markFormGroupDirty(this.form);
    this.cdRef.markForCheck();
  }

  isValid(): boolean {
    return this.form?.valid;
  }

  setGroupControlVisibility(groupName: string, isVisible: boolean): void {
    const group = this.getFormInputGroup(groupName);

    if (!group) {
      return;
    }

    group.isHidden = !isVisible;
    group.inputs.forEach(i => this.setControlVisibility(i.name, isVisible));
  }

  setControlVisibility(controlName: string, isVisible: boolean): void {
    const control = this.form.get(controlName);

    if (!control) {
      return;
    }

    let inputModel;
    this.formModel.inputs.forEach(input => {
      if (input.name === controlName) {
        inputModel = input;
        return;
      }

      if (isArrayWithMinOneItem((input as IFormInputGroup).inputs)) {
        (input as IFormInputGroup).inputs.forEach(innerInput => {
          if (innerInput.name === controlName) {
            inputModel = innerInput;
          }
        });
      }
    });

    (inputModel as IFormInput).isHidden = !isVisible;
    (inputModel as IFormInput).isDisabled = !isVisible;

    if (isVisible) {
      control.enable({ emitEvent: false });
    } else {
      control.disable({ emitEvent: false });
    }
  }

  triggerFormFieldValueChange(fieldName: string): void {
    this.form.get(fieldName).updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  isInputErrorVisible(componentType: FormInputComponentType): boolean {
    return !this.isInputEmailConfirmation(componentType);
  }

  isInputHelpInfoVisible(inputElem: IFormInput): boolean {
    return !!inputElem.helpLabelKey && !this.isInputEmailConfirmation(inputElem.componentType);
  }

  isInputEmailConfirmation(componentType: FormInputComponentType): boolean {
    return componentType === 'emailToConfirm' || componentType === 'emailConfirmationField';
  }

  private getTranslationKey(text: string, options: IFormSelect[]): string {
    const key = options.find(option => option.value === text);
    return key?.text;
  }

  private getFormInputGroup(name: string): IFormInputGroup {
    return this.formModel.inputs.find(input => {
      const group = input as IFormInputGroup;
      return isArrayWithMinOneItem(group.inputs) && group.name === name;
    }) as IFormInputGroup;
  }

  private setFormModel(model: IFormModel) {
    const group = {};
    if (!model) {
      return;
    }

    this.formValueChangeSubscription.unsubscribe();
    this.formValueChangeSubscription = new Subscription();

    this._formModel = model;
    if (!model.updateOn) {
      model.updateOn = 'change';
    }
    this._formModel.inputs.forEach(elem => {
      if (elem.hasOwnProperty('initialValue')) {
        const elemObj = elem as IFormInput;
        const fc = new UntypedFormControl(elemObj.initialValue || '', {
          validators: elemObj.validators,
          updateOn: elemObj.updateOn ? elemObj.updateOn : model.updateOn,
        });
        elemObj.uuid = v4();
        group[elem.name] = fc;
        this.initialValues[elem.name] =
          elemObj.componentType === 'date'
            ? convertDateToDisplayFormat(elemObj.initialValue.toString(), elemObj.datepickerConfig)
            : elemObj.initialValue;
        if (elemObj.componentType === 'select') {
          this.selectedValue[elem.name] =
            this.initialValues[elem.name] !== ''
              ? this.getTranslationKey(this.initialValues[elem.name], elemObj.options)
              : elemObj.placeholder;
        }
        if (elemObj.isHidden || elemObj.isDisabled) {
          fc.disable({ emitEvent: false });
        }
      } else if (elem.hasOwnProperty('inputs')) {
        const elemObj = elem as IFormInputGroup;
        elemObj.inputs.forEach(ielem => {
          const fc = new UntypedFormControl(ielem.initialValue || '', {
            validators: ielem.validators,
            updateOn: ielem.updateOn ? ielem.updateOn : model.updateOn,
          });
          group[ielem.name] = fc;
          this.initialValues[ielem.name] = ielem.initialValue;
          if (ielem.componentType === 'select') {
            this.selectedValue[ielem.name] =
              this.initialValues[ielem.name] !== ''
                ? this.getTranslationKey(this.initialValues[ielem.name], ielem.options)
                : ielem.placeholder;
          }
          if (ielem.componentType === 'date') {
            this.initialValues[ielem.name] =
              this.initialValues[ielem.name] !== ''
                ? convertDateToDisplayFormat(this.initialValues[ielem.name], ielem.datepickerConfig)
                : ielem.initialValue;
          }
          if (ielem.isHidden || ielem.isDisabled) {
            fc.disable({ emitEvent: false });
          }
        });
      }
    });

    this.form = this.formBuilder.group(group);
    this.form.patchValue(this.initialValues);

    this.formValueChangeSubscription = this.form.valueChanges.subscribe(() => {
      this.onFormValueChange();
    });
    this.onFormValueChange();
  }

  private onFormValueChange() {
    this.trimFormData(this.form.value);

    if (this.isValid()) {
      this.formIsValid.emit(this.form.value);
    } else {
      this.formIsInvalid.emit(this.form.value);
    }
  }
}
