import { Injectable } from '@angular/core';
import {
  Address,
  IdCard,
  IOlavEntry,
  IOrderDetailsInfo,
  IProduktVerfuegbarkeit,
  IRelocationData,
  IUploadResult,
  parseAddress,
  parseProduct,
  Product,
  RelocationIPDFLinks,
} from '@mwe/models';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { ProductCategoryEnum, ServiceStateEnum } from '@mwe/constants';
import { ProductsWhiteListLogic } from '../products/products-white-list.logic';

const addressDetailsLinkTypeKey = 'relocation_addressDetailsLinkType';
const relocationFromAddressKey = 'relocation_relocationFromAddress';
const relocationFromProductsKey = 'relocation_relocationFromProducts';
const relocationToAddressKey = 'relocation_relocationToAddress';
const relocationToOlavKey = 'relocation_relocationToOlav';
const relocationToAvailabilitiesKey = 'relocation_relocationToAvailabilities';
const relocationToAvailabilitiesStateKey = 'relocation_relocationToAvailabilitiesState';
const registrationDateKey = 'relocation_registrationDate';
const deregistrationDateKey = 'relocation_deregistrationDate';
const relocationDetailsKey = 'relocation_details';
const relocationPreviousSelectedProductsKey = 'relocation_previousSelectedProducts';
const relocationNoOnlineCategoriesKey = 'relocation_noOnlineCategories';
const relocationIdCardKey = 'relocation_idCard';
const relocationUploadedFilesKey = 'relocation_uploadedFiles';
const relocationUseSubstituteDetailsKey = 'relocation_useSubstituteDetails';
const relocationPdfLinksKey = 'relocation_pdfLinks';
const approvalDataTransferToStromOrGasKey = 'relocation_approvalDataTransferToStromOrGas';
const relocationLastOrderIdKey = 'relocation_lastOrderId';
const relocationAddressToSetKey = 'relocation_addressToSet';

@Injectable({ providedIn: 'root' })
export class RelocationStateService {
  @SessionStorage(relocationAddressToSetKey)
  private _addressSetTo = false;

  constructor(
    private storage: SessionStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
  ) {}

  @SessionStorage(relocationFromAddressKey)
  private _relocationFromAddress: Address;

  get relocationFromAddress(): Address {
    return (this._relocationFromAddress && parseAddress(this._relocationFromAddress)) || undefined;
  }

  set relocationFromAddress(_relocationFromAddress: Address) {
    if (_relocationFromAddress) {
      this._relocationFromAddress = _relocationFromAddress;
    } else {
      this.storage.clear(relocationFromAddressKey);
    }
  }

  @SessionStorage(relocationFromProductsKey)
  private _relocationFromProducts: Product[];

  get relocationFromProducts(): Product[] {
    return (this._relocationFromProducts && this._relocationFromProducts.map(elem => parseProduct(elem))) || undefined;
  }

  set relocationFromProducts(_relocationFromProducts: Product[]) {
    if (_relocationFromProducts) {
      this._relocationFromProducts = this.productsWhiteListLogic.removeNotWhiteListedProducts(_relocationFromProducts);
    } else {
      this.storage.clear(relocationFromProductsKey);
    }
  }

  @SessionStorage(relocationToAddressKey)
  private _relocationToAddress: Address;

  get relocationToAddress(): Address {
    return (this._relocationToAddress && parseAddress(this._relocationToAddress)) || undefined;
  }

  set relocationToAddress(_relocationToAddress: Address) {
    if (_relocationToAddress) {
      this._relocationToAddress = _relocationToAddress;
    } else {
      this.storage.clear(relocationToAddressKey);
    }
  }

  @SessionStorage(addressDetailsLinkTypeKey)
  private _addressDetailsLinkType: string;

  get addressDetailsLinkType(): string {
    return this._addressDetailsLinkType;
  }

  set addressDetailsLinkType(addressDetailsLinkType: string) {
    if (addressDetailsLinkType) {
      this._addressDetailsLinkType = addressDetailsLinkType;
    } else {
      this.storage.clear(addressDetailsLinkTypeKey);
    }
  }

  @SessionStorage(relocationToOlavKey)
  private _relocationToOlav: IOlavEntry;

  get relocationToOlav(): IOlavEntry {
    return this._relocationToOlav || undefined;
  }

  set relocationToOlav(relocationToOlav: IOlavEntry) {
    if (relocationToOlav) {
      this._relocationToOlav = relocationToOlav;
    } else {
      this.storage.clear(relocationToOlavKey);
    }
  }

  @SessionStorage(relocationToAvailabilitiesKey)
  private _relocationToAvailabilities: IProduktVerfuegbarkeit[];

  get relocationToAvailabilities(): IProduktVerfuegbarkeit[] {
    return this._relocationToAvailabilities || undefined;
  }

  set relocationToAvailabilities(relocationToAvailabilities: IProduktVerfuegbarkeit[]) {
    if (relocationToAvailabilities) {
      this._relocationToAvailabilities = relocationToAvailabilities;
    } else {
      this.storage.clear(relocationToAvailabilitiesKey);
    }
  }

  @SessionStorage(relocationToAvailabilitiesStateKey)
  private _relocationToAvailabilitiesState: ServiceStateEnum;

  get relocationToAvailabilitiesState(): ServiceStateEnum {
    return this._relocationToAvailabilitiesState || undefined;
  }

  set relocationToAvailabilitiesState(relocationToAvailabilitiesState: ServiceStateEnum) {
    if (relocationToAvailabilitiesState) {
      this._relocationToAvailabilitiesState = relocationToAvailabilitiesState;
    } else {
      this.storage.clear(relocationToAvailabilitiesStateKey);
    }
  }

  @SessionStorage(registrationDateKey)
  private _registrationDate: string;

  get registrationDate(): string {
    return this._registrationDate || undefined;
  }

  set registrationDate(_registrationDate: string) {
    if (_registrationDate) {
      this._registrationDate = _registrationDate;
    } else {
      this.storage.clear(registrationDateKey);
    }
  }

  @SessionStorage(deregistrationDateKey)
  private _deregistrationDate: string;

  get deregistrationDate(): string {
    return this._deregistrationDate || undefined;
  }

  set deregistrationDate(_deregistrationDate: string) {
    if (_deregistrationDate) {
      this._deregistrationDate = _deregistrationDate;
    } else {
      this.storage.clear(deregistrationDateKey);
    }
  }

  @SessionStorage(relocationDetailsKey)
  private _relocationDetails: IOrderDetailsInfo[];

  get relocationDetails(): IOrderDetailsInfo[] {
    return this._relocationDetails || undefined;
  }

  set relocationDetails(relocationDetails: IOrderDetailsInfo[]) {
    if (relocationDetails) {
      this._relocationDetails = relocationDetails;
    } else {
      this.storage.clear(relocationDetailsKey);
    }
  }

  @SessionStorage(relocationPreviousSelectedProductsKey)
  private _previousSelectedProducts: ProductCategoryEnum[];

  get previousSelectedProducts(): ProductCategoryEnum[] {
    return this._previousSelectedProducts || undefined;
  }

  set previousSelectedProducts(previousSelectedProducts: ProductCategoryEnum[]) {
    if (previousSelectedProducts) {
      this._previousSelectedProducts = previousSelectedProducts;
    } else {
      this.storage.clear(relocationPreviousSelectedProductsKey);
    }
  }

  @SessionStorage(relocationNoOnlineCategoriesKey)
  private _noOnlineCategories: string[];

  get noOnlineCategories(): string[] {
    return this._noOnlineCategories || undefined;
  }

  set noOnlineCategories(noOnlineCategories: string[]) {
    if (noOnlineCategories) {
      this._noOnlineCategories = noOnlineCategories;
    } else {
      this.storage.clear(relocationNoOnlineCategoriesKey);
    }
  }

  @SessionStorage(relocationIdCardKey)
  private _idCard: IdCard;

  get idCard(): IdCard {
    return this._idCard || undefined;
  }

  set idCard(idCard: IdCard) {
    if (idCard) {
      this._idCard = idCard;
    } else {
      this.storage.clear(relocationIdCardKey);
    }
  }

  @SessionStorage(relocationUploadedFilesKey)
  private _uploadedFiles: IUploadResult;

  get uploadedFiles(): IUploadResult {
    return this._uploadedFiles || undefined;
  }

  set uploadedFiles(uploadedFiles: IUploadResult) {
    if (uploadedFiles) {
      this._uploadedFiles = uploadedFiles;
    } else {
      this.storage.clear(relocationUploadedFilesKey);
    }
  }

  @SessionStorage(relocationUseSubstituteDetailsKey)
  private _useSubstituteDetails: boolean;

  get useSubstituteDetails(): boolean {
    return this._useSubstituteDetails || undefined;
  }

  set useSubstituteDetails(useSubstituteDetails: boolean) {
    if (useSubstituteDetails) {
      this._useSubstituteDetails = useSubstituteDetails;
    } else {
      this.storage.clear(relocationUseSubstituteDetailsKey);
    }
  }

  @SessionStorage(relocationPdfLinksKey)
  private _pdfLinks: RelocationIPDFLinks;

  get pdfLinks(): RelocationIPDFLinks {
    return this._pdfLinks || undefined;
  }

  set pdfLinks(pdfLinks: RelocationIPDFLinks) {
    if (pdfLinks) {
      this._pdfLinks = pdfLinks;
    } else {
      this.storage.clear(relocationPdfLinksKey);
    }
  }

  @SessionStorage(approvalDataTransferToStromOrGasKey)
  private _approvalDataTransferToStromOrGas: boolean;

  get approvalDataTransferToStromOrGas(): boolean {
    return this._approvalDataTransferToStromOrGas || undefined;
  }

  set approvalDataTransferToStromOrGas(_approvalDataTransferToStromOrGas: boolean) {
    if (_approvalDataTransferToStromOrGas) {
      this._approvalDataTransferToStromOrGas = _approvalDataTransferToStromOrGas;
    } else {
      this.storage.clear(approvalDataTransferToStromOrGasKey);
    }
  }

  @SessionStorage(relocationLastOrderIdKey)
  private _lastOrderId: string;

  get lastOrderId(): string {
    return this._lastOrderId || undefined;
  }

  set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this._lastOrderId = lastOrderId;
    } else {
      this.storage.clear(relocationLastOrderIdKey);
    }
  }

  get relocationAddressSetTo(): boolean {
    return this._addressSetTo;
  }

  set relocationAddressSetTo(isAddressSetTo: boolean) {
    if (isAddressSetTo) {
      this._addressSetTo = isAddressSetTo;
    } else {
      this.storage.clear(relocationAddressToSetKey);
    }
  }

  clearData(): void {
    this.storage.clear(relocationFromAddressKey);
    this.storage.clear(relocationFromProductsKey);
    this.storage.clear(relocationToAddressKey);
    this.storage.clear(addressDetailsLinkTypeKey);
    this.storage.clear(relocationToOlavKey);
    this.storage.clear(relocationToAvailabilitiesKey);
    this.storage.clear(relocationToAvailabilitiesStateKey);
    this.storage.clear(registrationDateKey);
    this.storage.clear(deregistrationDateKey);
    this.storage.clear(relocationDetailsKey);
    this.storage.clear(relocationPreviousSelectedProductsKey);
    this.storage.clear(relocationNoOnlineCategoriesKey);
    this.storage.clear(relocationIdCardKey);
    this.storage.clear(relocationUploadedFilesKey);
    this.storage.clear(relocationUseSubstituteDetailsKey);
    this.storage.clear(relocationPdfLinksKey);
    this.storage.clear(approvalDataTransferToStromOrGasKey);
    this.storage.clear(relocationLastOrderIdKey);
    this.storage.clear(relocationAddressToSetKey);
  }

  getRelocationData(): IRelocationData {
    return {
      relocationFromAddress: this.relocationFromAddress,
      relocationToOlav: this.relocationToOlav,
      relocationToAddress: this.relocationToAddress,
      registrationDate: this.registrationDate,
      deregistrationDate: this.deregistrationDate,
      relocationDetails: this.relocationDetails,
      idCard: this.idCard,
      uploadedFiles: this.uploadedFiles,
      useSubstituteDetails: this.useSubstituteDetails,
      pdfLinks: this.pdfLinks,
      noOnlineCategories: this.noOnlineCategories,
    };
  }
}
