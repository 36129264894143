import {
  IExtendedUploadConfiguration,
  IFileModel,
  InfoText,
  IUploadConfiguration,
  IUploadDeletePopupConfiguration,
  IUploadResult,
} from '@mwe/models';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { UploadComponent } from './upload.component';

import { PopupService } from '@mwe/services';
import { MediaPreviewComponent } from '../media-preview/media-preview.component';

@Component({
  selector: 'mwe-extended-upload',
  templateUrl: './extended-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedUploadComponent implements OnInit, OnDestroy {
  @ViewChild(UploadComponent, { static: false }) uploadComponent: UploadComponent;
  @Input() config: IExtendedUploadConfiguration;
  @Input() uploadResult: IUploadResult;
  @Output() readonly emitUploadResult = new EventEmitter<IUploadResult>();

  showUploadError = signal<boolean>(false);
  showValidationError = signal<boolean>(false);
  uploadSuccessful = signal<boolean>(false);
  deletePopupConfiguration = signal<IUploadDeletePopupConfiguration>(undefined);
  uploadConfiguration = signal<IUploadConfiguration>(undefined);
  internalUploadResult = signal<IUploadResult>(undefined);
  uploadedFiles = computed<IFileModel[]>(() => this.internalUploadResult()?.uploadedFiles);
  isValid = computed<boolean>(() => this.internalUploadResult().valid);
  infoPopup = computed<InfoText[]>(() => [
    {
      code: 'documentInfoPopupOpener',
      title: 'upload.overlay.title',
      description: 'upload.overlay.description',
    },
  ]);
  hasError = computed<boolean>(() => this.showUploadError() || this.showValidationError());

  private popupService = inject(PopupService);

  ngOnInit(): void {
    this.internalUploadResult.set(this.uploadResult);
    this.setUploadConfiguration();
    this.removeNotFullyLoadedFiles();
    this.checkForUploadErrors();
    this.fireFormValid();
  }

  ngOnDestroy(): void {
    this.removeNotFullyLoadedFiles();
  }

  setUploadConfiguration(): void {
    this.uploadConfiguration.set(this.config.uploadConfiguration);
    this.deletePopupConfiguration.set(this.config.deletePopupConfiguration);
  }

  setUploadedFiles(data: IUploadResult): void {
    this.showValidationError.set(false);
    this.internalUploadResult.set(data);
    this.checkForUploadErrors();
    this.fireFormValid();
  }

  checkForUploadErrors(): void {
    this.showUploadError.set(false);
    if (!this.internalUploadResult()) return;

    this.internalUploadResult.update(res => {
      return {
        ...res,
        valid: this.uploadedFiles()?.length >= this.uploadConfiguration()?.minAmountOfFiles,
      };
    });

    for (let i = 0; i < this.uploadedFiles()?.length; i++) {
      if (this.uploadedFiles()?.[i]?.showError) {
        this.showUploadError.set(true);
        this.internalUploadResult.update(res => {
          const uploadedFiles = res.uploadedFiles.toSpliced(i, 1);
          return {
            ...res,
            uploadedFiles,
            valid: false,
          };
        });
        break;
      }
      if (this.uploadedFiles()[i].isLoading) {
        this.showUploadError.set(false);
        this.internalUploadResult.update(res => ({
          ...res,
          valid: false,
        }));
        break;
      }
    }
  }

  removeFile(fileToRemove: IFileModel): void {
    this.uploadComponent.uploadedFiles.set(this.internalUploadResult().uploadedFiles);
    this.uploadComponent.removeFile(fileToRemove);
  }

  previewFile(fileToShow: IFileModel): void {
    this.popupService.open({
      id: 'media-preview',
      titleKey: '',
      messageKey: '',
      showSubmitButton: false,
      showCancelButton: false,
      component: MediaPreviewComponent,
      componentData: fileToShow,
      dismissable: true,
      modalSize: 'lg',
      modalOpts: {
        ariaLabelledBy: 'mwe_info-text-popup-title',
      },
    });
  }

  fireFormValid(): void {
    this.emitUploadResult.emit(this.internalUploadResult());
  }

  validate(): boolean {
    if (!this.isValid()) {
      this.checkForUploadErrors();
      if (this.isValid()) {
        this.fireFormValid();
      } else {
        this.showValidationError.set(true);
      }
    }
    return this.isValid();
  }

  setUploadSuccessful() {
    this.uploadSuccessful.set(true);
  }

  private removeNotFullyLoadedFiles(): void {
    const valid = this.uploadedFiles()?.filter(file => file.showError).length > this.uploadConfiguration()?.minAmountOfFiles;
    const uploadedFiles = this.uploadedFiles()?.filter(file => !file.isLoading);
    this.setUploadedFiles({ uploadedFiles, valid });
  }
}
