import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { ProfileService, StaticContentService } from '@mwe/services';
import { Router } from '@angular/router';
import { FeatureToggleEnum } from '@mwe/constants';
import { InteractiveInvoiceStaticData } from '@mwe/models';

@Component({
  selector: 'mwe-interactive-invoice-alert',
  templateUrl: './interactive-invoice-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractiveInvoiceAlertComponent implements OnInit {
  isAlertVisible = signal<boolean>(false);
  data = signal<InteractiveInvoiceStaticData>(undefined);
  featureToggleName = signal<FeatureToggleEnum>(FeatureToggleEnum.INVOICE_ALERT_ENABLED);

  constructor(
    private staticContentService: StaticContentService,
    private router: Router,
    private profileService: ProfileService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.profileService.isFeatureToggleDisabled(this.featureToggleName())) {
      return;
    }
    const data = await this.staticContentService.getInteractiveInvoiceStaticData();
    this.data.set(data);
    this.isAlertVisible.set(this.data()?.alert?.urls?.some(url => url === this.router.url));
  }
}
