import { Component, Input } from '@angular/core';
import { IProduct, IProduktSelektion, TariffSelectionCardData } from '@mwe/models';

@Component({
  selector: 'mwe-selected-products-summary-card',
  templateUrl: './selected-products-summary-card.component.html',
})
export class SelectedProductsSummaryCardComponent {
  @Input() products: IProduct[];
  @Input() selectedTariffs: TariffSelectionCardData[] = [];
  @Input() cardCategoryTitleKeyPrefix?: string = undefined;
  @Input() showTariffDetailsLink = true;
  @Input() showTariffDetails = true;
  @Input() grundpreisLabelOverwrite?: string;
  @Input() productSelection?: IProduktSelektion;
}
