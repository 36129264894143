import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { InteractiveInvoicesStateService } from '../invoices/interactive-invoices/interactive-invoices-state.service';
import { ProfileService } from '../profile/profile.service';
import { FeatureToggleEnum } from '@mwe/constants';
import { isWaermeInvoiceData } from '@mwe/utils';
import { handleToggleFallbackRoute } from './route-toggle-access-service';

export const interactiveInvoiceAccessService: CanActivateFn = async route => {
  const router = inject(Router);
  const interactiveInvoicesStateService = inject(InteractiveInvoicesStateService);
  const profileService = inject(ProfileService);
  const selectedInvoice = interactiveInvoicesStateService.selectedInvoice;
  const disabledToggleFallbackRoute = route.data['disabledToggleFallbackRoute'] ?? '';
  const detailsToggle = route.data['detailsToggle'];
  let isFeatureToggleEnabled = false;
  if (detailsToggle) {
    isFeatureToggleEnabled = profileService.isFeatureToggleEnabled(detailsToggle);
  } else {
    isFeatureToggleEnabled = isWaermeInvoiceData(selectedInvoice)
      ? profileService.isFeatureToggleEnabled(FeatureToggleEnum.INTERACTIVE_INVOICE_DETAILS_WAERME_ENABLED)
      : profileService.isFeatureToggleEnabled(FeatureToggleEnum.INTERACTIVE_INVOICE_DETAILS_SG_ENABLED);
  }
  if (!isFeatureToggleEnabled && disabledToggleFallbackRoute) {
    handleToggleFallbackRoute(route);
  }
  return isFeatureToggleEnabled ? true : router.parseUrl(disabledToggleFallbackRoute);
};
