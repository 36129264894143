import { FormControl } from '@angular/forms';

export interface MNRUserMessagesResponse {
  messageID: number;
  creationDate: string;
  readDate: string | null;
  subject: string;
  preview: string;
  contentID: number;
  templateDesignator: string;
}

export interface MNRContentResponse {
  messageID: number | string;
  contentData: string;
  dataPlaceholder: string;
  templateDesignator: string;
}

export type InitialState = {
  messages: MNRUserMessagesResponse[];
  selectedMessage: MNRContentResponse | undefined;
  hasError: boolean;
  showDetails: boolean;
  errorMessage: string;
};

export interface MessageBase {
  createdAt: string;
  lastModifiedAt?: string;
  lastModifiedBy: string;
  status: string;
}

export interface MessageHeader extends MessageBase {
  contentId: string;
  messageId?: number | string;
  messageType: string;
  messageName: string;
  origin: string;
  portalScope: string;
  subject: string;
  preview: string;
  validFrom: string;
  validTo: string;
}

type StrippedHeaderFormBase = Omit<MessageHeader, 'createdAt' | 'lastModifiedAt' | 'lastModifiedBy' | 'messageId'>;

export type HeaderFormGroup = {
  [K in keyof StrippedHeaderFormBase]: FormControl<StrippedHeaderFormBase[K]>;
};

export interface MessageContent extends MessageBase {
  contentId?: number | string;
  templateId: string;
  contentName: string;
  contentData: string;
}

export interface MessageContentItem {
  elementType: string;
  text?: string;
  innerHtml?: string;
  class: string;
  src?: string;
  btnColor?: 'orange' | 'blue' | 'white' | undefined;
  items?: { text: string }[];
  route?: string;
}

export interface Placeholder {
  name: string;
  placeholder: string;
}

export interface DropdownMenuItem {
  label: string;
  canActivate: boolean;
}
