import { Component, Input } from '@angular/core';
import { Sparte } from '@mwe/models';

@Component({
  selector: 'mwe-small-product-list',
  templateUrl: './small-product-list.component.html',
  styles: [':host { display: block; width: 100% }'],
})
export class SmallProductListComponent {
  @Input() categories: Sparte[];
  @Input() isPositionedInCenter = true;
}
