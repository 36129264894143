import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICustomerNumberInfo, ILSystem } from '@mwe/models';
import { formatAccountNumber, getBusinessDataFromPartnerDetail } from '@mwe/utils';

@Component({
  selector: 'mwe-customer-number-info-tooltip-card',
  templateUrl: './customer-number-info-tooltip-card.component.html',
})
export class CustomerNumberInfoTooltipCardComponent {
  @Input() hasContent: boolean;
  @Input() isClickable = false;
  @Input() tooltipCardClass: { [value: string]: boolean };
  @Input() useRedesignedCard: boolean = false;
  tooltipHeader;
  tooltipContent;
  @Output() onClick = new EventEmitter<void>();
  customerNumberTextLabel = 'Kundennummer: ';
  private data: ICustomerNumberInfo;

  @Input() set customerNumberInfo(data: ICustomerNumberInfo) {
    this.data = data;
    this.createLabels();
  }

  createLabels(): void {
    if (!this.data) {
      return;
    }

    this.tooltipHeader = this.createTooltipHeader();
    this.tooltipContent = this.createTooltipContent();
  }

  createTooltipHeader() {
    let prefix = `${this.data.firstName} ${this.data.lastName}`;
    if (this.data?.geschaeftsPartner?.organisation?.organisationsname) {
      prefix = getBusinessDataFromPartnerDetail(this.data.geschaeftsPartner)?.companyName;
    }

    return `${prefix} | ${this.data.productCategories.join(', ')}`;
  }

  createTooltipContent() {
    // do not use categories, array contains translated strings
    if (this.data.systemId === ILSystem.ISX100) {
      const accountNumber = formatAccountNumber(this.data.accountNumber, true);
      return this.customerNumberTextLabel + accountNumber;
    }

    return this.customerNumberTextLabel + this.data.customerNumber;
  }

  emitOnClickEvent() {
    this.onClick.emit();
  }
}
