import { ChangeDetectionStrategy, Component, computed, input, signal, viewChildren } from '@angular/core';
import { ApprovalFormItem } from '@mwe/models';
import { ApprovalFormItemComponent } from './approval-form-item.component';

@Component({
  selector: 'mwe-approval-form',
  templateUrl: './approval-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprovalFormComponent {
  items = input.required<ApprovalFormItem[]>();
  isDirty = signal<boolean>(false);
  itemRefs = viewChildren(ApprovalFormItemComponent);
  isValid = computed<boolean>(() => {
    return this.itemRefs().every(ref => ref.isValid());
  });

  markDirty(): void {
    this.isDirty.set(true);
  }

  isFormItemChecked(id: string): boolean {
    return this.getFormItemRef(id)?.isChecked();
  }

  private getFormItemRef(id: string): ApprovalFormItemComponent {
    return this.itemRefs().find(ref => ref.item().id === id);
  }
}
