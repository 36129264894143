import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DetailsLinkTypeEnum, ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';
import { AddressGroup, checkAddressesForGroupUpdates, IAddress, parseISODate } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { ProductStateService } from '../products/product-state.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AddressGroupService {
  public resourceUrl: string;

  constructor(
    private http: HttpClient,
    private productStateService: ProductStateService,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/address-group';
  }

  loadAddressGroups(): Promise<AddressGroup[]> {
    return lastValueFrom(this.http.get<AddressGroup[]>(this.resourceUrl + '/allGroups'));
  }

  setAddressGroupName(addressGroup: AddressGroup): Promise<AddressGroup> {
    return lastValueFrom(this.http.post<string>(this.resourceUrl, addressGroup, { observe: 'response' })).then(
      res => {
        return this.parseAddressGroup(res.body);
      },
      err => {
        throw err;
      },
    );
  }

  deleteAddressGroupName(addressGroup: AddressGroup): Promise<boolean> {
    return lastValueFrom(this.http.delete(this.resourceUrl + '/' + addressGroup.id, { observe: 'response' })).then(
      () => {
        // Success
        return true;
      },
      err => {
        throw err;
      },
    );
  }

  toDate(dateTime: any): Date {
    return parseISODate(dateTime);
  }

  parseAddressGroup(json?: any): AddressGroup {
    const a = json as AddressGroup;
    a.createdAt = this.toDate(a.createdAt);
    a.modifiedAt = this.toDate(a.modifiedAt);
    return a;
  }

  async loadAndMapGroupNames(addresses: IAddress[]): Promise<void> {
    const addressGroups = await this.getAndUpdateGroupNames();

    checkAddressesForGroupUpdates(addresses, addressGroups);
  }

  async getAndUpdateGroupNames(): Promise<AddressGroup[]> {
    let groupNames = this.productStateService.groupNames;

    if (!groupNames) {
      try {
        groupNames = await this.loadAddressGroups();
        this.productStateService.groupNames = groupNames;
      } catch (e) {
        groupNames = [];
      }
    }

    return groupNames;
  }
}
