import { ChangeDetectionStrategy, Component, computed, ElementRef, Input, signal, ViewChild } from '@angular/core';

@Component({
  selector: 'mwe-flipping-content',
  templateUrl: './flipping-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlippingContentComponent {
  @ViewChild('flipContent') flipContentRef: ElementRef<HTMLElement>;
  @Input() frontCardLabel: string;
  @Input() backCardLabel: string;

  isFlipped = signal(false);
  flipBtnLabel = computed(() => (this.isFlipped() ? this.backCardLabel : this.frontCardLabel));

  flip(): void {
    this.isFlipped.update(value => !value);
  }
}
