import { Component, Input, OnInit } from '@angular/core';
import { BadgeTypeOld } from '../badge.model';

@Component({
  selector: 'mwe-link-badge',
  templateUrl: './link-badge.component.html',
})
export class LinkBadgeComponent implements OnInit {
  @Input() text: string;
  @Input() type: BadgeTypeOld;
  @Input() link: string;

  constructor() {}

  ngOnInit(): void {}
}
