import { Component, computed, input } from '@angular/core';
import { Product, TextNodeType } from '@mwe/models';
import { ServiceStateEnum } from '@mwe/constants';
import { isProductFernwaerme } from '@mwe/utils';

@Component({
  selector: 'mwe-product-tariff-card-template',
  templateUrl: './product-tariff-card-template.component.html',
})
export class ProductTariffCardTemplateComponent {
  product = input<Product>();
  hasDiscount = input<boolean>();
  state = input<ServiceStateEnum>();
  headlineReference = input<TextNodeType>('p');
  hasActions = input<boolean>();
  hasContent = input<boolean>(true);
  isSpot = input<boolean>(false);
  spotUrl = input<string>('');
  isWaerme = computed<boolean>(() => isProductFernwaerme(this.product()));
}
