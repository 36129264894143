<div class="form-check mb-0">
  <input
    [attr.data-test]="'form-input-' + inputElem.name"
    [attr.id]="inputElem.name"
    [formControl]="parentForm.controls[inputElem.name]"
    [ngClass]="{
      'is-invalid': parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine,
    }"
    class="form-check-input"
    type="checkbox"
    [attr.aria-describedby]="ariaDescribedBy"
    [attr.aria-invalid]="!isValid"
  />
  <div>
    <label
      [attr.data-test]="'form-input-' + inputElem.name + '-label'"
      [for]="inputElem.name"
      [innerHTML]="inputElem.placeholder | translate"
      class="form-check-label mb-0"
    ></label>
  </div>
</div>
