import { Component, inject, input, OnInit } from '@angular/core';
import { getPdfDocumentsSafeUrl, TariffOptionSap, TariffSelectionCardData, TextNodeType } from '@mwe/models';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { HeadlineService } from '../../headline/headline.service';
import { TariffSelectionStore } from '../services/tariff-selection.store';

@Component({
  selector: 'mwe-tariff-selection-container',
  templateUrl: './tariff-selection-container.component.html',
  providers: [HeadlineService],
})
export class TariffSelectionContainerComponent implements OnInit {
  isReadOnly = input<boolean>(false);
  hideAdditionalOptions = input<boolean>(false);
  headlineReference = input<TextNodeType>('h3');
  // selectedTariffs comes from signal() do not manipulate it!!!! use TariffSelectionStore!!!!!
  selectedTariffs = input<TariffSelectionCardData[]>();
  cardCategoryTitleKeyPrefix = input<string>('tariff.selectionCard.stromGas');

  private store = inject(TariffSelectionStore);
  headlineService = inject(HeadlineService);

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference());
  }

  getTariffPdfUrl(tariffKey: string): string {
    return getPdfDocumentsSafeUrl() + tariffKey;
  }

  checkIfAdditionalCardIsVisible(item?: TariffSelectionCardData): boolean {
    if (this.hideAdditionalOptions()) {
      return false;
    }

    if (this.isReadOnly()) {
      // user can only store one option of group. not default -> it is selected
      const options = this.getAdditionalOptions(item.userSelection);
      return options?.length && !options?.[0]?.default;
    }

    return this.hasAdditionalOptions(item.tariffOptionsSap);
  }

  private hasAdditionalOptions(options: TariffOptionSap[]) {
    return isArrayWithMinOneItem(this.getAdditionalOptions(options));
  }

  private getAdditionalOptions(options: TariffOptionSap[]): TariffOptionSap[] {
    return options?.filter(t => t.art === this.store.additionalOptionGroupName());
  }
}
