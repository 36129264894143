@if (infoText()) {
  <button
    (click)="iconClicked()"
    [attr.aria-label]="'tariff.details.furtherInformations' | translate"
    [attr.title]="'tariff.details.furtherInformations' | translate"
    class="btn btn-clean ml-1 {{ colorClass() }}"
    type="button"
  >
    <i [attr.data-test]="dataTest()" class="fas fa-info-circle fa-w-16 pointer my-auto"></i>
  </button>
}
