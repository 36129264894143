<div class="flip-outer mx-n3 flex-grow-1 inline-size overflow-hidden" #flipContent>
  <div class="flip-inner" [ngClass]="{ flipped: isFlipped() }">
    <div class="flip-front px-3">
      <ng-content select="[slot='front']"></ng-content>
    </div>
    <div class="flip-back px-3 h-100">
      <div class="pb-3 h-100">
        <ng-content select="[slot='back']"></ng-content>
      </div>
    </div>
  </div>
</div>
<div class="text-center mt-3">
  <mwe-button-link [label]="flipBtnLabel()" (click)="flip()" color="blue" customCss="d-inline-block">
    <div class="row justify-content-center gx-2 mb-2">
      <div class="col-auto">
        <div
          [ngClass]="{ 'bg-gray': isFlipped(), 'bg-secondary-light': !isFlipped() }"
          class="rounded-pill transition-all"
          style="width: 0.75rem; height: 0.75rem"
        ></div>
      </div>
      <div class="col-auto">
        <div
          [ngClass]="{ 'bg-gray': !isFlipped(), 'bg-secondary-light': isFlipped() }"
          class="rounded-pill transition-all"
          style="width: 0.75rem; height: 0.75rem"
        ></div>
      </div>
    </div>
  </mwe-button-link>
</div>
