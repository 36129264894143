import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { IAddress } from '@mwe/models';

const customerIdKey = 'caInformationRequest_customerId';
const accountIdKey = 'caInformationRequest_accountId';
const categoriesKey = 'caInformationRequest_categories';
const emailKey = 'caInformationRequest_email';
const updateEmailKey = 'caInformationRequest_updateEmail';
const addressKey = 'caInformationRequest_address';
const kindOfInformationKey = 'caInformationRequest_kindOfInformation';
const timeRangeTypeKey = 'caInformationRequest_timeRangeType';
const timeRangeFromKey = 'caInformationRequest_timeRangeFrom';
const timeRangeToKey = 'caInformationRequest_timeRangeTo';
const lastOrderIdKey = 'caInformationRequest_lastOrderId';
const retriedOnErrorKey = 'caInformationRequest_retriedOnError';
const submitIdKey = 'caInformationRequest_submitId';
const directAddressSelectionKey = 'caInformationRequest_directAddressSelection';

@Injectable({
  providedIn: 'root',
})
export class CAInformationRequestStateService {
  @SessionStorage(directAddressSelectionKey)
  private _directAddressSelection: boolean;

  @SessionStorage(customerIdKey)
  private _customerId: string;

  @SessionStorage(accountIdKey)
  private _accountId: string;

  @SessionStorage(emailKey)
  private _email: string;

  @SessionStorage(updateEmailKey)
  private _updateEmail: boolean;

  @SessionStorage(kindOfInformationKey)
  private _kindOfInformation: string;

  @SessionStorage(timeRangeTypeKey)
  private _timeRangeType: string;

  @SessionStorage(timeRangeFromKey)
  private _timeRangeFrom: string;

  @SessionStorage(timeRangeToKey)
  private _timeRangeTo: string;

  @SessionStorage(addressKey)
  private _address: IAddress;

  @SessionStorage(categoriesKey)
  private _categories: string[];

  @SessionStorage(lastOrderIdKey)
  private _lastOrderId: string;

  @SessionStorage(retriedOnErrorKey)
  private _retriedOnError: boolean;

  @SessionStorage(submitIdKey)
  private _submitId: number;

  constructor(private storage: SessionStorageService) {}

  clear(): void {
    this.storage.clear(directAddressSelectionKey);
    this.storage.clear(customerIdKey);
    this.storage.clear(accountIdKey);
    this.storage.clear(emailKey);
    this.storage.clear(updateEmailKey);
    this.storage.clear(kindOfInformationKey);
    this.storage.clear(timeRangeTypeKey);
    this.storage.clear(timeRangeFromKey);
    this.storage.clear(timeRangeToKey);
    this.storage.clear(categoriesKey);
    this.storage.clear(lastOrderIdKey);
    this.storage.clear(retriedOnErrorKey);
    this.storage.clear(submitIdKey);
  }

  get directAddressSelection(): boolean {
    return !!this._directAddressSelection;
  }

  set directAddressSelection(value: boolean) {
    if (!value) {
      this.storage.clear(directAddressSelectionKey);
      return;
    }

    this._directAddressSelection = value;
  }

  get customerId(): string {
    return this._customerId || undefined;
  }

  set customerId(customerId: string) {
    if (customerId) {
      this._customerId = customerId;
    } else {
      this.storage.clear(customerIdKey);
    }
  }

  get accountId(): string {
    return this._accountId || undefined;
  }

  set accountId(accountId: string) {
    if (accountId) {
      this._accountId = accountId;
    } else {
      this.storage.clear(accountIdKey);
    }
  }

  get email(): string {
    return this._email || undefined;
  }

  set email(email: string) {
    if (email) {
      this._email = email;
    } else {
      this.storage.clear(emailKey);
    }
  }

  get updateEmail(): boolean {
    return this._updateEmail || undefined;
  }

  set updateEmail(updateEmail: boolean) {
    if (updateEmail) {
      this._updateEmail = updateEmail;
    } else {
      this.storage.clear(updateEmailKey);
    }
  }

  get kindOfInformation(): string {
    return this._kindOfInformation || undefined;
  }

  set kindOfInformation(kindOfInformation: string) {
    if (kindOfInformation) {
      this._kindOfInformation = kindOfInformation;
    } else {
      this.storage.clear(kindOfInformationKey);
    }
  }

  get timeRangeType(): string {
    return this._timeRangeType || undefined;
  }

  set timeRangeType(timeRangeType: string) {
    if (timeRangeType) {
      this._timeRangeType = timeRangeType;
    } else {
      this.storage.clear(timeRangeTypeKey);
    }
  }

  get timeRangeFrom(): string {
    return this._timeRangeFrom || undefined;
  }

  set timeRangeFrom(timeRangeFrom: string) {
    if (timeRangeFrom) {
      this._timeRangeFrom = timeRangeFrom;
    } else {
      this.storage.clear(timeRangeFromKey);
    }
  }

  get timeRangeTo(): string {
    return this._timeRangeTo || undefined;
  }

  set timeRangeTo(timeRangeTo: string) {
    if (timeRangeTo) {
      this._timeRangeTo = timeRangeTo;
    } else {
      this.storage.clear(timeRangeToKey);
    }
  }

  get address(): IAddress {
    return this._address || undefined;
  }

  set address(address: IAddress) {
    if (address) {
      this._address = address;
    } else {
      this.storage.clear(addressKey);
    }
  }

  get categories(): string[] {
    return this._categories || undefined;
  }

  set categories(categories: string[]) {
    if (categories) {
      this._categories = categories;
    } else {
      this.storage.clear(categoriesKey);
    }
  }

  get submitId(): number {
    return this._submitId || undefined;
  }

  set submitId(submitId: number) {
    if (submitId) {
      this._submitId = submitId;
    } else {
      this.storage.clear(submitIdKey);
    }
  }

  get lastOrderId(): string {
    return this._lastOrderId || undefined;
  }

  set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this._lastOrderId = lastOrderId;
    } else {
      this.storage.clear(lastOrderIdKey);
    }
  }

  get retriedOnError(): boolean {
    return this._retriedOnError || undefined;
  }

  set retriedOnError(retriedOnError: boolean) {
    if (retriedOnError) {
      this._retriedOnError = retriedOnError;
    } else {
      this.storage.clear(retriedOnErrorKey);
    }
  }
}
