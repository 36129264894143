import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { ProductCategoryEnum } from '@mwe/constants';
import {
  getSelectedTariffOptionsFromProductSelection,
  getTarifBinding,
  getTariffOptionLabel,
  getTariffOptionsIdList,
  getTariffOptionTranslationParams,
  getTarifIndication,
  isProductCategoryEmobility,
  mergeSapT3TariffOptions,
} from '@mwe/utils';
import {
  Boni,
  getPdfDocumentsSafeUrl,
  IFormModel,
  IFormSelect,
  IPopupModel,
  IProduktAuswahlDetails,
  IProduktDetailsVerfuegbarkeit,
  IProduktSelektion,
  PreisIndikation,
  ProduktAttribute,
  Sparte,
  TarifOption,
} from '@mwe/models';
import {
  EnvironmentService,
  LoggingService,
  NewClientStateService,
  PopupService,
  TariffSelectionLogic,
  TariffSelectionStateService,
} from '@mwe/services';
import { cloneDeepJSON, isArrayWithMinOneItem, isProductCategory, isProductCategoryInternet, parseTariff } from '@mwe/utils';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TariffOptionsFormComponent } from './tariff-options-form/tariff-options-form.component';

@Component({
  selector: 'mwe-tariff-selection-card-old',
  templateUrl: './tariff-selection-card-old.component.html',
})
export class TariffSelectionCardOldComponent implements OnInit, OnDestroy {
  @Input() products: IProduktAuswahlDetails[];
  @Input() initialProduct: IProduktSelektion;
  @Input() anlageId: string;
  @Input() placeholder: string;
  @Input() postCode: string;
  @Input() tariffLinkOverwrite: string;
  @Input() tariffLinkNamePrefix: string;
  @Input() grundpreisLabel = 'tariff.details.grundpreis';
  @Input() onProductsSelectionChange: EventEmitter<{ changedCategory: string; products: IProduktAuswahlDetails[] }>;
  @Input() tariffOptionAllesSicherKey: string;
  @Input() tariffOptionAllesSicherTariffLink: string;
  @Input() tariffOptionAllesSicherTariffLinkLabel: string;
  discountInfoPopupText: string;

  @Output() onChange = new EventEmitter();
  @Output() onPriceIndicationReloadError = new EventEmitter();

  options: IFormSelect[] = [];
  category: string;
  formModel: IFormModel;
  productSelection: IProduktAuswahlDetails;
  tariffClasses: IProduktDetailsVerfuegbarkeit[];
  tarifIndication: PreisIndikation;
  tarifBinding: ProduktAttribute;
  selectedTariffOptions: TarifOption[];
  displayedTarifName: string;
  areTariffIndicationsUpdating = false;
  allesSicherOption: TarifOption;
  boni: Boni;
  pricePeriod: string;
  basePriceKey = 'tariff.details.basePrice';
  installationPriceKey = 'tariff.details.installationPrice';
  isCategoryInternet = false;
  isCategoryIPTV = false;
  isCategoryVOIP = false;
  labelMoreInformation: string;
  getPdfDocumentsSafeUrl = getPdfDocumentsSafeUrl;
  tariffOptionsOpenPopupSelector: string;

  get isTariffSelectionStromGasVisible(): boolean {
    return (
      this.productSelection?.tarif?.ISUTarifKey &&
      this.productSelection.tarif.ISUTarifKey !== 'MWE_EMPTY' &&
      this.category !== 'Fernwaerme' &&
      !this.areTariffIndicationsUpdating
    );
  }

  get isTariffSelectionWaermeVisible(): boolean {
    return this.category === 'Fernwaerme';
  }

  private availableTariffOptions: TarifOption[]; // contains options with all infos from SAP and T3
  private previouslySelectedTariffOptions = new Map<string, TarifOption[]>();
  private subscriptions = new Subscription();
  private tariffSelectionInputName: string;

  private get currentTariff(): string {
    return this.tarifIndication?.tarif?.ISUTarifKey;
  }

  constructor(
    private newClientStateService: NewClientStateService,
    private translateService: TranslateService,
    private tariffSelectionLogic: TariffSelectionLogic,
    private tariffSelectionStateService: TariffSelectionStateService,
    private popupService: PopupService,
    private loggingService: LoggingService,
    private environmentService: EnvironmentService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.discountInfoPopupText = `tariff.isDiscounted.infoIcon.text.${this.environmentService.getPortalCustomerType()}`;
    this.category = this.products[0].sparte;
    this.tariffOptionsOpenPopupSelector = `mwe-tariff-option-open-popup-btn-${this.category?.toLowerCase()}`;

    this.isCategoryIPTV = isProductCategory(this.category, ProductCategoryEnum.IPTV);
    this.isCategoryVOIP = isProductCategory(this.category, ProductCategoryEnum.VOIP);

    if (isProductCategoryInternet(this.category)) {
      this.isCategoryInternet = isProductCategory(this.category, ProductCategoryEnum.INTERNET);
      this.basePriceKey = 'tariff.details.basePriceInternet';
      this.installationPriceKey = 'tariff.details.installationPrice' + this.category;
    }

    this.labelMoreInformation =
      this.isCategoryInternet || this.isCategoryIPTV || this.isCategoryVOIP
        ? 'tariff.details.chargingProvisions'
        : 'tariff.details.furtherInformations';

    if (this.category !== 'Fernwaerme') {
      this.getOptions();
      this.initializeFormModel();
    } else {
      this.tariffClasses = this.newClientStateService.productsForSelection.fernwaerme.details;
    }

    // der tarifberater liefert wegen dem T2/T3 portal EUR/Jahr wir wollen aber €/Jahr
    this.pricePeriod = this.tarifIndication?.grundpreisUnit;

    if (this.onProductsSelectionChange) {
      const subscription = this.onProductsSelectionChange.subscribe(changedProducts => {
        if (this.category === changedProducts.changedCategory) {
          this.products = changedProducts.products;
          this.options = [];
          this.getOptions();
          this.productSelection = this.products[0];
          this.initialProduct[this.category.toLowerCase()] = this.products[0];
          this.initializeFormModel(true);
          this.setSelectedTariffOptions();
        }
      });
      this.subscriptions.add(subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getOptions(): void {
    for (let i = 0; i < this.products.length; i++) {
      this.options.push({
        value: this.products[i].tarif.ISUTarifKey,
        text: parseTariff(this.products[i].tarif.tarifName),
      });
    }

    if (isProductCategoryEmobility(this.category)) {
      // no sorting for emobility tariffs. use order from backend response
      return;
    }

    this.options.sort(this.isCategoryInternet ? this.compareInternet : this.compare);
  }

  private compare(a, b): number {
    const valueA = a.text.toUpperCase();
    const valueB = b.text.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  private compareInternet(a, b): number {
    const valueA = a.text.toUpperCase();
    const valueB = b.text.toUpperCase();
    let comparison = 0;

    const speedA = new Number(valueA.replace('SUPERSCHNELL ', ''));
    const speedB = new Number(valueB.replace('SUPERSCHNELL ', ''));
    if (speedA > speedB) {
      comparison = 1;
    } else if (speedA < speedB) {
      comparison = -1;
    }
    return comparison;
  }

  initializeFormModel(keepSelectedTariffOptions: boolean = false): void {
    this.tariffSelectionInputName = `mwe-order-tarif-selection-data-${this.category.toLowerCase()}`;
    const defaultTariffKey = this.getDefaultTariffKey();
    const initialTariffOptions = this.getInitialProduktAuswahlDetails()?.tarif?.tarifOptionen;

    if (defaultTariffKey && !keepSelectedTariffOptions) {
      this.previouslySelectedTariffOptions.set(defaultTariffKey, initialTariffOptions ?? []);
    }

    this.setDataFrom(defaultTariffKey);

    this.formModel = {
      inputs: [
        {
          name: this.tariffSelectionInputName,
          initialValue: defaultTariffKey,
          validators: [Validators.required],
          labelKey: 'tariff.details.tariff',
          validate: true,
          componentType: 'select',
          placeholder: this.placeholder ? this.placeholder : undefined,
          options: this.options,
        },
      ],
    };
  }

  handleFormValidation(value: boolean, formData?: any): void {
    const tariffKey = formData?.[this.tariffSelectionInputName];

    if (!value || !tariffKey) {
      return;
    }

    this.setDataFrom(tariffKey);
    this.openTariffOptionsFormOnFirstVisit();
  }

  private setDataFrom(tariffKey: string): void {
    if (!tariffKey) {
      return;
    }
    this.productSelection = cloneDeepJSON(this.products.find(product => product.tarif.ISUTarifKey === tariffKey)) as IProduktAuswahlDetails;
    this.displayedTarifName = parseTariff(this.productSelection.tarif.tarifName);
    this.updateTariffIndications();
    this.tarifBinding = getTarifBinding(this.tarifIndication);
    this.setAvailableTariffOptions();
    this.setSelectedTariffOptions();
    this.onChange.emit(this.productSelection);
  }

  // available for selection, merged infos from SAP and T3
  private setAvailableTariffOptions() {
    const sapOptions = this.getSapTariffOptions();
    const t3Options = this.tarifIndication?.tarif?.tarifOptionen;
    t3Options?.forEach(to => (to.pricePeriod = this.pricePeriod));

    this.availableTariffOptions = mergeSapT3TariffOptions(sapOptions, t3Options);
  }

  // user selected options
  private setSelectedTariffOptions(): void {
    if (!isArrayWithMinOneItem(this.tarifIndication?.tarif?.tarifOptionen)) {
      this.productSelection.tarif.tarifOptionen = [];
    } else {
      const previouslySelected = this.previouslySelectedTariffOptions.get(this.currentTariff);
      this.productSelection.tarif.tarifOptionen = previouslySelected;
    }

    this.setSelectedTariffOptionsFromProductSelection();
    // on first visit productSelection has no tariff-options, method above selects all default options
    // store default options in product selection in case we have nothing in selection
    if (!isArrayWithMinOneItem(this.productSelection.tarif.tarifOptionen)) {
      this.setUserSelectedTariffOptionsInProductSelection(this.selectedTariffOptions);
    }
  }

  getLabelFrom(option: TarifOption): string {
    // get label info from merged tariffOptions to get all data
    const mergedOption = this.availableTariffOptions.find(o => o.option === option.option);
    const labelKey = getTariffOptionLabel(mergedOption, true);
    const params = getTariffOptionTranslationParams(mergedOption);
    params['pricePeriod'] = this.pricePeriod;
    return this.translateService.instant(labelKey, params);
  }

  async openTariffOptionsForm(): Promise<void> {
    const componentData = {
      category: this.category as Sparte,
      tariffName: this.productSelection.tarif.tarifName,
      availableTariffOptions: this.availableTariffOptions,
      selectedTariffOptions: this.selectedTariffOptions,
    };
    const model: IPopupModel = {
      id: 'tariff-options-form-popup',
      titleKey: '',
      messageKey: '',
      showSubmitButton: false,
      showCancelButton: false,
      component: TariffOptionsFormComponent,
      componentData,
      dismissable: false,
      useCustomReturnValue: true,
      modalOpts: {
        ariaLabelledBy: 'mwe_tariff-options-form-headline',
      },
    };
    const result = await this.popupService.tryOpen(model);

    if (!Array.isArray(result)) {
      return;
    }

    this.setUserSelectedTariffOptionsInProductSelection(result);
    this.setSelectedTariffOptionsFromProductSelection();
    await this.reloadTariffIndications(result);
    this.onChange.emit(this.productSelection);
  }

  private setSelectedTariffOptionsFromProductSelection(): void {
    this.selectedTariffOptions = getSelectedTariffOptionsFromProductSelection(
      this.productSelection.tarif.tarifOptionen,
      this.availableTariffOptions,
    );

    this.allesSicherOption = this.selectedTariffOptions.find(to => to.option === this.tariffOptionAllesSicherKey);
  }

  private getInitialProduktAuswahlDetails(): IProduktAuswahlDetails {
    if (this.anlageId) {
      return this.initialProduct.anlagen.find(iv => iv.anlageId === this.anlageId);
    }

    return this.initialProduct[this.category.toLowerCase()];
  }

  private getDefaultTariffKey(): string {
    const currentDetails = this.getInitialProduktAuswahlDetails();

    if (!currentDetails) {
      return this.options[0].value;
    }

    return currentDetails.tarif ? currentDetails.tarif.ISUTarifKey : currentDetails.tarifKey;
  }

  private getSapTariffOption(option: string): TarifOption {
    const options = this.getSapTariffOptions();

    return options?.find(to => to.option === option);
  }

  private getSapTariffOptions(): TarifOption[] {
    const product = this.products.find(p => p.tarif.ISUTarifKey === this.tarifIndication?.tarif?.ISUTarifKey);

    return product?.tarif.tarifOptionen;
  }

  private async reloadTariffIndications(selectedSapTariffOptions: TarifOption[]): Promise<void> {
    try {
      this.areTariffIndicationsUpdating = true;
      const options = getTariffOptionsIdList(selectedSapTariffOptions);
      await this.tariffSelectionLogic.updatePriceIndications(
        this.postCode,
        this.category,
        this.productSelection.tarif.ISUTarifKey,
        options,
      );
      this.updateTariffIndications();
    } catch (e) {
      this.loggingService.logError(e, 'error reloading price indications from T3');
      this.onPriceIndicationReloadError.emit();
    } finally {
      this.areTariffIndicationsUpdating = false;
    }
  }

  private updateTariffIndications(): void {
    this.tarifIndication = getTarifIndication(this.tariffSelectionStateService.indications, this.productSelection?.tarif.ISUTarifKey);
    this.boni = this.tarifIndication?.boni?.find(i => i.id.includes('-1plus1'));
  }

  // only store SAP-options without infos from T3
  private setUserSelectedTariffOptionsInProductSelection(userSelectedOptions: TarifOption[]): void {
    this.productSelection.tarif.tarifOptionen = userSelectedOptions.map(o => this.getSapTariffOption(o.option)).filter(o => !!o);
    this.previouslySelectedTariffOptions.set(this.currentTariff, [...this.productSelection.tarif.tarifOptionen]);
  }

  private openTariffOptionsFormOnFirstVisit(): void {
    if (!isArrayWithMinOneItem(this.availableTariffOptions)) {
      return;
    }

    // only open on first visit
    if (this.tariffSelectionLogic.wasTariffOptionsFormPopupOpenedFor(this.anlageId ? this.anlageId : this.category)) {
      return;
    }

    // and when there are no selected/preselected tariff-options
    if (isArrayWithMinOneItem(this.productSelection?.tarif?.tarifOptionen.filter(o => !o.default))) {
      return;
    }

    this.tariffSelectionLogic.markCategoryAsOpened(this.anlageId ? this.anlageId : this.category);
    this.openTariffOptionsForm();
  }

  getTariffLinkLabel(): string {
    if (!this.tariffLinkNamePrefix) {
      return this.displayedTarifName;
    }

    return `${this.tariffLinkNamePrefix} ${this.displayedTarifName}`;
  }

  getDataTestValue(value: string): string {
    return `tariff-selection-card-${this.category.toLowerCase()}-${value}`;
  }
}
