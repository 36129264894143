import { Aktionscode, GueltigkeitsDetails, VoucherBodyModel } from '@mwe/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AccountLogic } from '../account/account.logic';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';
import { ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  private voucherResourceUrl: string;

  constructor(
    protected http: HttpClient,
    protected accountLogic: AccountLogic,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) protected environmentService: EnvironmentService,
  ) {
    this.voucherResourceUrl = this.environmentService.getApiUrl() + 'api/voucher/';
  }

  async voucherDetails(voucherCode: string): Promise<Aktionscode> {
    let _params = new HttpParams();
    _params = this.appendNotLoggedIn(_params);
    return lastValueFrom(this.http.get<Aktionscode>(this.getVoucherResourceUrl() + 'details/' + voucherCode, { params: _params }));
  }

  async voucherValidity(voucherCode: string, body: string): Promise<GueltigkeitsDetails> {
    let _params = new HttpParams();
    _params = this.appendNotLoggedIn(_params);
    return lastValueFrom(
      this.http.post<GueltigkeitsDetails>(this.getVoucherResourceUrl() + 'validity/' + voucherCode, body, { params: _params }),
    );
  }

  createVoucherValidityBody(now: string, voucherBodyModel: VoucherBodyModel): any {
    return {
      datum: now,
      tarifTyp: voucherBodyModel.tariffKey,
      prozessCode: voucherBodyModel.processCode,
      zusatzInformationen: voucherBodyModel.additionalFields,
      gewaehlteTarifOptionen: voucherBodyModel.gewaehlteTarifOptionen,
    };
  }

  private getVoucherResourceUrl(): string {
    if (this.accountLogic.isLoggedOut()) {
      return this.voucherResourceUrl.replace('api', 'api/public');
    }
    return this.voucherResourceUrl;
  }

  private appendNotLoggedIn(params: HttpParams): HttpParams {
    if (this.accountLogic.isLoggedOut()) {
      return params.set('loggedIn', 'false');
    }
    return params;
  }
}
