<div class="mt-2">
  <p
    #collapsible="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
    class="{{ textCss || 'text--large' }} mb-0"
    [innerHTML]="textKey | translate"
    [id]="uuid"
    [attr.aria-expanded]="!isCollapsed"
  ></p>
  <p class="mb-0 mt-3">
    <button
      class="btn btn-link btn-link--blue"
      mweClickPreventDefault
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      [attr.aria-controls]="uuid"
    >
      <span class="text-decoration-underline">{{ (isCollapsed ? showMoreKey : showLessKey) | translate }}</span>
    </button>
  </p>
</div>
