<div class="mb-3">
  <div class="row gx-2">
    <div class="col-auto">
      <button
        [ngClass]="swiperId"
        data-swiper-btn="prev"
        [attr.title]="'global.productsFooter.sliderCards.sliderControlPrev' | translate"
        class="btn btn-tertiary btn-bubble btn-bubble-s"
      >
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </button>
    </div>
    <div class="col-auto">
      <button
        [ngClass]="swiperId"
        data-swiper-btn="next"
        [attr.title]="'global.productsFooter.sliderCards.sliderControlNext' | translate"
        class="btn btn-tertiary btn-bubble btn-bubble-s"
      >
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
<div class="swiper-focus-outline-fix">
  <div #swiper [ngClass]="swiperId" class="swiper swiper-container s-wrapper">
    <div class="swiper-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>
