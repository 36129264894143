<div class="form-floating">
  <input
    id="typeahead-prevent-manual-entry"
    type="text"
    class="form-control"
    [formControl]="control"
    [ngbTypeahead]="search"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    #instance="ngbTypeahead"
    placement="bottom"
    (selectItem)="onSelected($event)"
    (blur)="onBlur()"
    [ngClass]="control.errors && !control.pristine ? 'is-invalid' : ''"
    [attr.id]="formInputOptions.name"
    [attr.data-test]="'form-input-' + formInputOptions.name"
    [attr.name]="formInputOptions.name"
    [attr.maxlength]="formInputOptions.maxLength || 64"
    [attr.placeholder]="formInputOptions.placeholder | translate"
    [attr.disabled]="isLoading ? '' : null"
    [attr.aria-describedby]="'form-input-info--' + formInputOptions.name"
    [attr.aria-invalid]="!isValid"
    [attr.aria-label]="(formInputOptions.ariaLabel | translate) || null"
  />
  <label
    [attr.for]="formInputOptions.name"
    class="form-label"
    [ngClass]="formInputOptions.labelCss"
    [translate]="formInputOptions.labelKey"
    *ngIf="formInputOptions.labelKey"
  ></label>
</div>
