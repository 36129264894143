import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserJournal } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { lastValueFrom } from 'rxjs';
import { ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';

@Injectable({ providedIn: 'root' })
export class UserJournalService {
  public static readonly EVENT_NOT_FOUND = 'userjournal.event.not.found';
  public static readonly SERVICE_API_URL = 'api/user-journal';
  NO_ADDITIONAL_DATA = 'no_additional_data';
  private resourceUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + UserJournalService.SERVICE_API_URL;
  }

  async updateEvent(event: string, additionalData?: string): Promise<UserJournal> {
    const resp = await lastValueFrom(
      this.http.post<UserJournal[]>(this.resourceUrl + '/' + event, additionalData || this.NO_ADDITIONAL_DATA),
    );
    return this.getEventFromArray(resp, event);
  }

  async getEvent(event: string): Promise<UserJournal | string> {
    const events: string = localStorage.getItem('user.journal');
    let journalEvent: UserJournal;
    if (events) {
      journalEvent = this.getEventFromArray(JSON.parse(events), event);
    }

    if (!journalEvent) {
      const resp = await this.getAllEvents();
      journalEvent = this.getEventFromArray(resp, event);
      return journalEvent ? journalEvent : UserJournalService.EVENT_NOT_FOUND;
    } else {
      return journalEvent;
    }
  }

  getEventFromArray(eventList: UserJournal[], event: string): UserJournal {
    for (let i = eventList.length - 1; i >= 0; i--) {
      if (eventList[i].event === event) {
        return new UserJournal(
          eventList[i].id,
          eventList[i].userId,
          eventList[i].event,
          eventList[i].createdAt,
          eventList[i].modifiedAt,
          eventList[i].additionalData,
        );
      }
    }
    return null;
  }

  checkIfEventExists(eventName: string): Promise<boolean> {
    return this.checkIfSomeEventsExists([eventName]);
  }

  async checkIfSomeEventsExists(eventNames: string[]): Promise<boolean> {
    const events = await this.getAllEvents();
    return eventNames.some(eventName => events.some(event => event.event === eventName));
  }

  containsEventName(events: UserJournal[], eventName: string): boolean {
    if (!isArrayWithMinOneItem(events)) {
      return false;
    }

    return events.some(event => event.event === eventName);
  }

  async getEvents(eventNamesToSearchFor: string[]): Promise<UserJournal[]> {
    const allEvents = await this.getAllEvents();
    return (allEvents || []).filter(event => eventNamesToSearchFor.includes(event.event));
  }
  async checkIfEventsDoesNotExists(eventNames: string[]): Promise<boolean> {
    return !(await this.checkIfSomeEventsExists(eventNames));
  }

  getAllEvents(): Promise<UserJournal[]> {
    return lastValueFrom(this.http.get<UserJournal[]>(this.resourceUrl + '/allEvents'));
  }
}
