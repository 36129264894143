import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { AlertType, InfoText, TextNodeType } from '@mwe/models';
import { v4 } from 'uuid';

@Component({
  selector: 'mwe-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  type = input<AlertType>('info');
  titleKey = input<string>();
  messageKey = input<string>();
  messageKeys = input<string[]>();
  showCloseButton = input<boolean>(false);
  placeholderMap = input<Map<string, string>>();
  link = input<string>();
  iconClassOverwrite = input<string>('');
  infoText = input<InfoText>();
  isMinimalLayout = input<boolean>(false);
  headingNodeType = input<TextNodeType>('h5');
  role = input<string | null>('alert');
  onLinkClick = output();

  alertClasses = computed<string[]>(() => {
    const classes = ['w-100', 'alert', `alert-${this.type()}`];

    if (this.showCloseButton()) {
      classes.push('alert-dismissible');
    }
    if (this.isMinimalLayout()) {
      classes.push('alert-minimal');
    }

    return classes;
  });

  iconClasses = computed<string>(() => {
    if (this.iconClassOverwrite()) {
      return this.iconClassOverwrite();
    }

    switch (this.type()) {
      case 'info':
        return 'fas fa-bell';
      case 'neutral':
        return 'fas fa-info';
      case 'danger':
        return 'fas fa-exclamation';
      case 'warning':
        return 'fas fa-siren-on';
      case 'success':
        return 'fas fa-check';
    }
  });

  showTitle = computed<boolean>(() => this.titleKey() && this.titleKey().length > 0);

  isVisible = signal(true);
  translateValues = computed<{ [key: string]: string | number }>(() => {
    if (!this.placeholderMap()) return {};
    return [...this.placeholderMap().entries()]?.reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {},
    );
  });
  uuid = signal<string>(v4());

  fireLinkClickEvent(): void {
    this.onLinkClick.emit();
  }
}
