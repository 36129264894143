import { IFileModel, IPopupModel, IUploadConfiguration, IUploadResult } from '@mwe/models';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { FileService, LoggingService, PopupService } from '@mwe/services';
import { catchError, finalize, tap } from 'rxjs';
import { validate } from 'uuid';

@Component({
  selector: 'mwe-upload',
  templateUrl: './upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadComponent implements OnInit {
  @ViewChild('file', { static: false }) fileRef: any;

  @Input() config: IUploadConfiguration;
  @Input() filesFromStorage: IFileModel[];
  @Input() processType: string;
  @Input() errorType: string;
  @Input() deletePopupTitleKey: string;
  @Input() deletePopupMsgKey: string;
  @Input() deletePopupSubmitKey: string;
  @Input() deletePopupCancelKey: string;
  @Output() readonly emitUploadResult = new EventEmitter<IUploadResult>();
  @Output() readonly fileUploaded = new EventEmitter<boolean>();
  @Input() hasMarginBottom = true;

  uploadedFiles = signal<IFileModel[]>([]);
  uploadField = signal<HTMLInputElement>(undefined);
  validForm = signal<boolean>(false);
  totalSizeOfBytes = signal<number>(0);
  showUploadError = signal<boolean>(false);
  showLoadingSpinner = signal<boolean>(false);

  constructor(
    private fileService: FileService,
    private popupService: PopupService,
    private loggingService: LoggingService,
  ) {}

  onUploadClicked(): void {
    this.uploadedFiles.update(files => {
      return files.filter(file => file.size <= this.config.maxSizeOfFileInByte);
    });
    this.fileRef.nativeElement.click();
  }

  ngOnInit(): void {
    if (this.filesFromStorage && this.filesFromStorage.length > 0) {
      this.uploadedFiles.set(this.filesFromStorage);
      this.uploadedFiles().forEach(element => {
        this.totalSizeOfBytes.update(oldSize => oldSize + element.size);
      });
    }
  }

  async fileUpload(fileInput: Event): Promise<void> {
    const target = fileInput.currentTarget as HTMLInputElement;
    if (!target || !target.files) return;
    this.showLoadingSpinner.set(true);
    this.uploadField.set(target);
    const file = this.uploadField().files[0];
    if (this.checkIfFileSizeAndTypeIsValid(file)) {
      await this.readFile(file);
      this.fileUploaded.emit(true);
    } else {
      this.showLoadingSpinner.set(false);
    }
  }

  checkIfFormIsValid(): boolean {
    return (
      this.uploadedFiles() &&
      this.uploadedFiles().length >= this.config.minAmountOfFiles &&
      this.uploadedFiles().length <= this.config.maxAmountOfFiles
    );
  }

  async readFile(file: File): Promise<void> {
    const myReader: FileReader = new FileReader();

    try {
      myReader.onloadend = () => {
        const uploadedFile: IFileModel = this.parseToUploadFile(file, myReader);
        const result: IUploadResult = this.updateFileResults(uploadedFile);
        if (this.validForm()) {
          uploadedFile.isLoading = true;
          this.emitUploadResult.emit(result);

          this.fileService
            .upload(this.replaceNameInFile(file, uploadedFile.name), this.processType)
            .pipe(
              tap(({ body }) => {
                this.showUploadError.set(false);
                this.validForm.set(this.checkIfFormIsValid());
                uploadedFile.isLoading = false;
                uploadedFile.id = this.getUploadId(body);
                if (this.hasValidUploadId(uploadedFile)) {
                  result.valid = this.validForm();
                  return;
                }
                result.valid = false;
                this.loggingService.logError(new Error('UploadService'), 'Returned upload ID is not valid!');
                this.handleError(uploadedFile);
              }),
              catchError(error => {
                this.handleError(uploadedFile);
                uploadedFile.isLoading = false;

                return error;
              }),
              finalize(() => {
                this.showLoadingSpinner.set(false);
                this.emitUploadResult.emit(result);
              }),
            )
            .subscribe();
        }
        this.emitUploadResult.emit(result);
        this.showUploadError.set(false);
        if (this.uploadField()) {
          this.uploadField.update(input => ({
            ...input,
            value: '',
          }));
        }
      };

      myReader.readAsDataURL(file);
    } catch (ex) {
      this.handleError(null);
      this.showLoadingSpinner.set(false);
      this.validForm.set(false);
      this.emitUploadResult.emit({ uploadedFiles: this.uploadedFiles(), valid: this.validForm() });
    }
  }

  hasValidUploadId(uploadedFile: IFileModel): boolean {
    return validate(uploadedFile.id);
  }

  getUploadId(body: any): string {
    return body.id;
  }

  renameFileIfNeeded(fileName: string): string {
    const fileFound = this.uploadedFiles().find(elem => elem.name === fileName) !== undefined;
    if (fileFound) {
      fileName = this.replaceFileName(fileName);
    }
    return fileName;
  }

  checkIfFileSizeAndTypeIsValid(file: File): boolean {
    let result = false;

    const fileSmallEnough = file?.size >= this.config.minSizeOfFileInByte;
    const fileBigEnough = file?.size <= this.config.maxSizeOfFileInByte;
    const matchedType = this.checkAcceptedTypes(file?.type);

    if (fileSmallEnough && fileBigEnough && matchedType) {
      result = true;
      this.showUploadError.set(false);
    } else if (file) {
      const uploadedFile: IFileModel = this.parseToUploadFile(file, null);
      this.handleError(uploadedFile);
      this.emitUploadResult.emit(this.updateFileResults(uploadedFile));
      this.uploadField.update(input => ({
        ...input,
        value: '',
      }));
      this.removeFileFromUploadList(uploadedFile, false);
    }

    return result;
  }

  checkAcceptedTypes(fileType: string): string {
    return this.config.accept.split(',').find(type => {
      const acceptedType = type.replace('*', '');
      return fileType?.trim().startsWith(acceptedType.trim());
    });
  }

  replaceFileName(fileName: string): string {
    try {
      const stringInParantheses = fileName.match(/.*\((\d)\)\.\w*/);

      if (stringInParantheses) {
        const oldNumber = parseInt(stringInParantheses[1], 10);
        fileName = fileName.replace('(' + oldNumber + ')', '(' + (oldNumber + 1) + ')');
      } else {
        fileName = fileName.replace(fileName.match(/.*(\.\w*)/)[1], ' (1)' + fileName.match(/.*(\.\w*)/)[1]);
      }
      fileName = this.renameFileIfNeeded(fileName);
    } catch (error) {}
    return fileName;
  }

  removeFile(fileToRemove): void {
    const model: IPopupModel = {
      id: 'upload-remove-file',
      titleKey: this.deletePopupTitleKey,
      messageKey: this.deletePopupMsgKey,
      showSubmitButton: true,
      submitButtonKey: this.deletePopupSubmitKey,
      showCancelButton: true,
      cancelButtonKey: this.deletePopupCancelKey,
      iconTypeClass: 'fa-sharp-duo tone fa-solid fa-siren-on',
      iconColorClass: 'yellow',
      modalSize: 'lg',
    };
    if (fileToRemove.showError) {
      this.removeFileFromUploadList(fileToRemove);
    } else {
      this.popupService.open(model);
      this.popupService.events().subscribe(result => {
        if (result) {
          this.removeFileFromUploadList(fileToRemove);
        }
      });
    }
  }

  formatBytes(bytes, decimals = 1, totalSumBytes = false): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    if (!totalSumBytes) {
      this.totalSizeOfBytes.update(oldSize => oldSize + bytes);
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private replaceNameInFile(file: File, newName: string): File {
    if (file.name === newName) {
      return file;
    }

    const formData = new FormData();
    formData.append('file', file, newName);
    return formData.get('file') as any; // need to fix default FormData typing, type File is correct
  }

  private parseToUploadFile(file: File, myReader: FileReader): IFileModel {
    const currentDate = Date.now();
    const timestamp = new Date(currentDate).toLocaleTimeString() + ':' + new Date(currentDate).getMilliseconds().toString();
    const fileName = this.renameFileIfNeeded(file.name);

    return {
      id: timestamp,
      name: fileName,
      formattedSize: this.formatBytes(file.size),
      size: file.size,
      base64: myReader && (file.type.includes('image') || file.type.includes('pdf')) ? myReader.result : null,
      showPreview: file.type.includes('image'),
      extension: this.getFileExtension(fileName),
    };
  }

  private updateFileResults(uploadedFile: IFileModel): IUploadResult {
    this.uploadedFiles.update(files => {
      return [...files, uploadedFile];
    });

    this.validForm.set(this.checkIfFormIsValid());
    return { uploadedFiles: this.uploadedFiles(), valid: this.validForm() };
  }

  private handleError(file: IFileModel): void {
    if (file && this.errorType === 'list') {
      this.showUploadError.set(false);
      file.showError = true;
      file.showPreview = false;
    } else {
      this.showUploadError.set(true);
    }
    this.validForm.set(false);
  }

  private removeFileFromUploadList(fileToRemove: any, emit = true): void {
    const lastUploadedFile = this.uploadedFiles()[this.uploadedFiles().length - 1];
    if (lastUploadedFile && lastUploadedFile.id === fileToRemove.id && this.uploadField) {
      this.uploadField.update(input => ({
        ...input,
        value: '',
      }));
    }
    this.totalSizeOfBytes.update(oldSize => oldSize - fileToRemove.size);
    this.uploadedFiles.update(files => files.filter(file => file.id !== fileToRemove.id));

    this.validForm.set(this.checkIfFormIsValid());

    if (emit) {
      this.emitUploadResult.emit({ uploadedFiles: this.uploadedFiles(), valid: this.validForm() });
    }
  }

  getFileExtension(name: string): string {
    return name
      .match(/(?<=\.)([^.]+)$/g)
      .toString()
      .toUpperCase();
  }
}
