<div [ngClass]="CssClasses()" class="p-3 border border-2 rounded" [attr.aria-describedby]="uuid()">
  <div class="row flex-nowrap g-2">
    <div class="col-auto text-center">
      <i [ngClass]="iconClasses()" class="{{ colorScheme().iconColorClass }}" style="font-size: 1.5rem"></i>
    </div>
    <div class="col align-self-center">
      <div class="d-flex">
        @if (showTitle()) {
          <mwe-headline
            [headlineNodeType]="headingNodeType()"
            cssClass="h5"
            [text]="titleKey()"
            [textParams]="translateValues()"
            [id]="uuid()"
            data-test="inline-notification-headline"
          />
          @if (!!infoText()) {
            <mwe-info-text-icon
              [infoTextTitle]="infoText().title | translate"
              [infoTextDescription]="infoText().description | translate"
              colorClass="text-primary ml-2"
            />
          }
        }
      </div>
      <ng-content />
    </div>
  </div>
  @if (ctaLabelKey()) {
    <div class="text-end">
      <mwe-button
        color="blue"
        category="tertiary"
        size="md"
        [prependIconClass]="ctaPrependIconClass()"
        [labelKey]="ctaLabelKey()"
        (click)="onCTAClick.emit()"
      />
    </div>
  }
</div>
