import { Inject, Injectable } from '@angular/core';
import { NewClientStateService } from './new-client-state.service';
import { OrderService } from '../order/order.service';
import {
  Address,
  HTTPError,
  HTTPResponse,
  IDefaultProdukt,
  INewClientConfirmationStatusResponse,
  IOlavEntry,
  IPersonalData,
  IProduktAuswahl,
  IProduktAuswahlParams,
  IProduktVerfuegbarkeit,
  ISolrOlavKeyAddress,
  NewClientDetailsDTO,
} from '@mwe/models';
import { ENVIRONMENT_SERVICE_TOKEN, ProductCategoryEnum } from '@mwe/constants';
import config from '../config/products.config.json';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewClientService {
  private config: any;

  constructor(
    public http: HttpClient,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) public environmentService: EnvironmentService,
    private orderService: OrderService,
    private newClientStateService: NewClientStateService,
  ) {
    this.config = config;
  }

  getConfigPossibleCategories(): any {
    return this.config.possibleCategories;
  }

  getMultiProductCardCategories(): any {
    return this.config.multiProductCardCategories;
  }

  getMultiProductTariffRestrictions(): any {
    return this.config.multiProductTariffRestrictions;
  }

  getAvailability(selectedOlav: IOlavEntry): Promise<IProduktVerfuegbarkeit[]> {
    return this.orderService.getAvailability(selectedOlav.identAdresse ? selectedOlav.identAdresse : selectedOlav);
  }

  getInternetAvailability(gaa: string, tkConnId: string): Promise<IProduktVerfuegbarkeit[]> {
    return this.orderService.getInternetAvailability(gaa, tkConnId);
  }

  async getProductsForSelection(params: IProduktAuswahlParams): Promise<HTTPResponse<IProduktAuswahl>> {
    return this.orderService.getProductsForSelection(params);
  }

  getAlreadySelectedProductCategories(): ProductCategoryEnum[] {
    return this.newClientStateService.orderDetails
      .filter(elem => elem.status === 'Anmeldung')
      .map(elem => ProductCategoryEnum[elem.category.toUpperCase()]);
  }

  getIpAddress(): Promise<{ remoteAddress: string }> {
    return lastValueFrom(this.http.get<{ remoteAddress: string }>('/api/public/newclient/ip-address'));
  }

  getConfigDefaultTariffs(): IDefaultProdukt {
    const customerType = this.environmentService.getPortalCustomerType();
    return {
      strom: {
        tarifBeschreibung: this.config.tariffs.strom[customerType].name,
        tarifKey: this.config.tariffs.strom[customerType].shortcut,
      },
      gas: {
        tarifBeschreibung: this.config.tariffs.gas[customerType].name,
        tarifKey: this.config.tariffs.gas[customerType].shortcut,
      },
      internet: {
        tarifBeschreibung: this.config.tariffs.internet[customerType].name,
        tarifKey: this.config.tariffs.internet[customerType].shortcut,
      },
      iptvLight: this.config.possibleCategories.includes('Iptv')
        ? {
            tarifBeschreibung: this.config.tariffs.iptvLight[customerType].name,
            tarifKey: this.config.tariffs.iptvLight[customerType].shortcut,
          }
        : null,
      iptvPremium: this.config.possibleCategories.includes('Iptv')
        ? {
            tarifBeschreibung: this.config.tariffs.iptvPremium[customerType].name,
            tarifKey: this.config.tariffs.iptvPremium[customerType].shortcut,
          }
        : null,
      voip: this.config.possibleCategories.includes('Voip')
        ? {
            tarifBeschreibung: this.config.tariffs.voip[customerType].name,
            tarifKey: this.config.tariffs.voip[customerType].shortcut,
          }
        : null,
    };
  }

  async confirm(addressCode: string, newClientType: number, dataDTO: NewClientDetailsDTO): Promise<INewClientConfirmationStatusResponse> {
    try {
      return await lastValueFrom(
        this.http.post<INewClientConfirmationStatusResponse>(
          this.orderService.getResourceUrl('confirm') + '?addressCode=' + addressCode + '&newClientType=' + newClientType,
          dataDTO,
        ),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, newClientType: number, dataDTO: NewClientDetailsDTO): Promise<INewClientConfirmationStatusResponse> {
    try {
      return await lastValueFrom(
        this.http.put<INewClientConfirmationStatusResponse>(
          this.orderService.getResourceUrl('retry') + '?submitId=' + submitId + '&newClientType=' + newClientType,
          dataDTO,
        ),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number, newClientType: number): Promise<INewClientConfirmationStatusResponse> {
    const url = this.orderService.getResourceUrl('confirmStatus') + '?submitId=' + submitId + '&newClientType=' + newClientType;
    return lastValueFrom(this.http.get<INewClientConfirmationStatusResponse>(url));
  }

  async getAddressFromOlavKey(olavKey: string, processType?: string): Promise<ISolrOlavKeyAddress> {
    return this.orderService.getAddressFromOlavKey(olavKey, processType);
  }

  async sendProductInterestRequest(personalData: IPersonalData, address: Address): Promise<string> {
    return lastValueFrom(this.http.post<string>(this.orderService.getResourceUrl('productInterest'), { personalData, address }));
  }
}
