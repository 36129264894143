<div class="row align-items-center">
  <div [ngClass]="{ 'col-5': showTariff, 'col-12': !showTariff }">
    <div class="text-center">
      <mwe-product-icon [category]="product.category" size="lg" [textNodeType]="headlineService.getHeadline(0)"></mwe-product-icon>
    </div>
  </div>

  <div class="col-7 pl-0 pr-4 text-start my-auto" *ngIf="showTariff">
    <mwe-product-card-tariff-list
      [product]="product"
      [showWaermeTariffClassesAsideOfIcon]="showWaermeTariffClassesAsideOfIcon"
      [tariffClasses]="tariffClasses$ | async"
    >
    </mwe-product-card-tariff-list>
    <p
      *ngIf="accountNrLabel"
      class="product-account mb-1"
      [translate]="accountNrLabel"
      [translateParams]="translateParams"
      data-test="card-account-nr"
    ></p>
    <mwe-button-link
      *ngIf="showTariffDetailsLink"
      [url]="getTariffDetailsLink()"
      label="tariff.tariffDetails"
      customCss="expander-item-btn-fix"
    ></mwe-button-link>
  </div>
</div>
<div *ngIf="showTariffDetails && tariffIndication">
  <hr class="my-4" />
  <div *ngIf="showPrices" class="text-uppercase text-secondary fw-bold mb-2" translate="tariff.details.prices"></div>
  <div class="row mb-2" *ngIf="showPrices && tariffIndication?.grundpreis">
    <div class="col col-sm hyphenate">
      <p class="text--big mb-0" [translate]="basePriceKey"></p>
    </div>
    <div class="col col-sm hyphenate">
      <p
        class="text--big mb-0"
        data-test="wizard-product-card-grundpreis"
        [translate]="grundpreisLabel"
        [translateParams]="{ value: tariffIndication?.grundpreis, pricePeriod: tariffIndication?.grundpreisUnit }"
      ></p>
    </div>
  </div>

  <div class="row mb-2" *ngIf="showPrices && tariffIndication?.arbeitspreis">
    <div class="col col-sm hyphenate">
      <p class="text--big mb-0" translate="tariff.details.consumptionPrice"></p>
    </div>
    <div class="col col-sm">
      <p
        class="text--big mb-0"
        data-test="wizard-product-card-arbeitspreis"
        translate="tariff.details.arbeitspreis"
        [translateParams]="{ value: tariffIndication?.arbeitspreis, pricePeriod: tariffIndication?.arbeitspreisUnit }"
      ></p>
    </div>
  </div>

  <div class="row mb-2" *ngIf="showPrices && tariffIndication?.anschlusspreis">
    <div class="col col-sm hyphenate">
      <p class="text--big mb-0" [translate]="installationPriceKey"></p>
    </div>
    <div class="col col-sm hyphenate">
      <p
        class="text--big mb-0"
        data-test="wizard-product-card-anschlusspreis"
        translate="tariff.details.anschlusspreis"
        [translateParams]="{ value: tariffIndication?.anschlusspreis, pricePeriod: tariffIndication?.anschlusspreisUnit }"
      ></p>
    </div>
  </div>

  <div class="row mb-2" *ngFor="let info of tariffIndication?.preisInfos">
    <div class="col col-sm hyphenate">
      <p class="text--big mb-0">{{ info.text }}</p>
    </div>
    <div class="col col-sm hyphenate">
      <p class="text--big mb-0">{{ info.preis }}</p>
    </div>
  </div>

  <div class="row mb-2 mt-3 align-items-center" *ngIf="isCategoryInternet && tariffIndication.vorteile?.length > 0">
    <div class="row align-items-center" *ngFor="let vorteil of tariffIndication.vorteile">
      <div class="col-2 col-sm-2">
        <i class="fas fa-check-circle font-size-18 green"></i>
      </div>
      <div class="col col-sm pl-0">
        <p class="text--big mb-0">{{ vorteil }}</p>
      </div>
    </div>
  </div>

  <div
    *ngIf="tariffBinding?.kurzWert || selectedTariffOptions?.length > 0"
    class="text-uppercase text-secondary fw-bold mt-4 mb-2"
    translate="tariff.details.options"
  ></div>
  <div *ngIf="(!selectedTariffOptions || selectedTariffOptions?.length === 0) && tariffBinding?.kurzWert" class="row mb-2">
    <div class="col col-sm hyphenate">
      <p class="text--big mb-0" translate="tariff.details.binding"></p>
    </div>
    <div class="col col-sm">
      <p class="text--big mb-0">{{ tariffBinding?.kurzWert }}</p>
    </div>
  </div>
  <div *ngIf="!!selectedTariffOptions && selectedTariffOptions?.length > 0">
    <div class="row mb-2" *ngFor="let option of selectedTariffOptions">
      <div class="col col-sm hyphenate">
        <p class="text--big mb-0">{{ option.art }}:</p>
      </div>
      <div class="col col-sm" [attr.data-test]="'wizard-product-card-' + option.option">
        <p class="text--big mb-0" [innerHTML]="getLabelFrom(option)"></p>
      </div>
    </div>
  </div>

  <mwe-alert
    class="d-block mt-4"
    type="success"
    messageKey="tariff.isDiscounted.text"
    [attr.data-test]="'new-client-summary-discount-' + product.category"
    *ngIf="tariffIndication?.istRabattiert"
    [isMinimalLayout]="true"
    [infoText]="{ description: discountInfoPopupText, title: 'tariff.isDiscounted.infoIcon.title' }"
  ></mwe-alert>

  <div *ngIf="voucherInfo?.length > 0" class="text-uppercase text-secondary fw-bold mt-4 mb-2" translate="tariff.details.voucherInfo"></div>
  <div *ngIf="voucherInfo?.length > 0">
    <div class="row mb-2" *ngFor="let voucher of voucherInfo">
      <div class="col col-sm">
        <p class="text--big mb-0">{{ voucher }}</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="this.isCategoryFernwaerme && tariffClasses.length && !showWaermeTariffClassesAsideOfIcon">
  <hr class="my-4" />
  <mwe-headline
    [headlineLevelIncrement]="1"
    cssClass="fw-bold mb-2 text--big text-secondary"
    text="products.details.tariffList"
  ></mwe-headline>
  <mwe-product-card-tariff-list-fernwaerme [tariffClasses]="tariffClasses$ | async"></mwe-product-card-tariff-list-fernwaerme>
</div>
