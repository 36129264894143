<a
  href="{{ href }}"
  target="{{ target }}"
  [ngClass]="class"
  [attr.rel]="target === '_blank' ? 'noopener noreferrer' : null"
  class="fw-normal {{ linkClass }} text-decoration-none text-none"
>
  <span *ngIf="iconClass"> <i [ngClass]="iconClass"> </i></span>
  <span *ngIf="labelKey">{{ labelKey | translate }} </span>
</a>
