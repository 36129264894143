import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import {
  Address,
  IAktionen,
  IBusinessData,
  IConfirmationStatus,
  IContractDetails,
  IdCard,
  IERechnungsInfo,
  INewClientTrackingData,
  IOlavEntry,
  IOrderData,
  IOrderDetailsInfo,
  IPersonalData,
  IProduktAuswahl,
  IProduktDetails,
  IProduktSelektion,
  IProduktVerfuegbarkeit,
  IUploadResult,
  IVertragskonto,
  NewClientContractDetailsViewModel,
  OrderIPDFLinks,
  parseAddress,
  PreisIndikation,
} from '@mwe/models';
import { ProductCategoryEnum, ServiceStateEnum } from '@mwe/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { TariffSelectionStateService } from '../tariff/selection/tariff-selection-state.service';
import { sortProductsByCategory } from '@mwe/utils';
import { VoucherStateService } from '../voucher/voucher-state.service';

const profileCheckedKey = 'newClient_profileChecked';
const addressKey = 'newClient_address';
const addressOlavKey = 'newClient_addressOlav';
const predefinedOlavKey = 'newClient_predefinedOlavKey';
const newProductAvailabilitiesKey = 'newClient_newProductAvailabilities';
const availabilitiesStateKey = 'newClient_newProductAvailabilitiesState';
const predefinedProductsForSelectionKey = 'newClient_predefinedProductsForSelection';
const productsForSelectionKey = 'newClient_productsForSelection';
const productSelectionKey = 'newClient_productSelection';
const selectedPriceIndicationsKey = 'newClient_selectedPriceIndications';
const registrationDateKey = 'newClient_registrationDate';
const detailsKey = 'newClient_details';
const personalDataKey = 'newClient_personalData';
const invoiceDataKey = 'newClient_invoiceData';
const idCardKey = 'newClient_idCard';
const uploadedFilesKey = 'newClient_uploadedFiles';
const pdfLinksKey = 'newClient_pdfLinks';
const contractDetailsKey = 'newClient_contractDetails';
const contractDetailsViewModelKey = 'newClient_contractDetailsViewModel';
const newClientERechnungsInfoKey = 'newClient_eRechnungsInfo';
const notLoggedInKey = 'newClient_notLoggedIn';
const confirmationStatusKey = 'newClient_confirmationStatus';
const lastOrderIdKey = 'newClient_lastOrderId';
const lastOrderDateKey = 'newClient_lastOrderDate';
const retriedOnErrorKey = 'newClient_retriedOnError';
const submitIdKey = 'newClient_submitId';
const orderSuccessfullKey = 'newClient_orderSuccessfull';
const submitSuccessStateKey = 'newClient_submitSuccessState';
const voucherInfoKey = 'newClient_voucherInfo';
const predefinedVoucherCodeKey = 'newClient_predefinedVoucherCode';
const autoRegistrationSuccessfulKey = 'newClient_autoRegistrationSuccessful';
const preselectProductFernwaermeKey = 'newClient_preselectProductFernwaerme';
const newClientTrackingDataKey = 'newClient_trackingData';
const newClientTypeKey = 'newClient_newClientType';
const sendProductInterestStateKey = 'newClient_sendProductInterestState';
const newClientMgmCodeKey = 'newClient_mgmCode';
const grantedMarketingPermissionKey = 'newClient_grantedMarketingPermission';
const hasPriceIndicationsReloadErrorKey = 'tariffChange_hasPriceIndicationsReloadErrorKey';
const personalBusinessDataKey = 'newClient_business_personalData';
const tkConnIdKey = 'newClient_tkconnid';
const skipPreselectedAddressKey = 'newClient_skipPreselectedAddress';

@Injectable({ providedIn: 'root' })
export class NewClientStateService extends VoucherStateService {
  // VoucherService methods start
  tempProductSelectionForVoucher: IProduktSelektion;
  @SessionStorage(profileCheckedKey)
  private _newClientProfileChecked: boolean;
  private newProductAvailabilitiesSuccessStateSubject = new BehaviorSubject(true);
  private submitSuccessStateSubject = new BehaviorSubject(ServiceStateEnum.INIT);
  @SessionStorage(newClientMgmCodeKey)
  private _newClientMgmCode: string;

  constructor(
    protected override storage: SessionStorageService,
    private tariffSelectionStateService: TariffSelectionStateService,
  ) {
    super(storage, lastOrderIdKey, retriedOnErrorKey, submitIdKey);
  }

  @SessionStorage(addressKey)
  private _newClientAddress: Address;

  get newClientAddress(): Address {
    return (this._newClientAddress && parseAddress(this._newClientAddress)) || undefined;
  }

  set newClientAddress(value: Address) {
    if (value) {
      this._newClientAddress = value;
    } else {
      // TODO Obsolete Logik?!?
      this.storage.clear('newClient_newClientAddress');
    }
  }

  @SessionStorage(addressOlavKey)
  private _newClientAddressOlav: IOlavEntry;

  get newClientAddressOlav(): IOlavEntry {
    return this._newClientAddressOlav || undefined;
  }

  set newClientAddressOlav(value: IOlavEntry) {
    if (value) {
      this._newClientAddressOlav = value;
    } else {
      // TODO Obsolete Logik?!?
      this.storage.clear('newClient_newClientAddressOlav');
    }
  }

  @SessionStorage(predefinedOlavKey)
  private _predefinedOlavKey: string;

  get predefinedOlavKey(): string {
    return this._predefinedOlavKey || undefined;
  }

  set predefinedOlavKey(value: string) {
    if (value) {
      this._predefinedOlavKey = value;
    } else {
      this.storage.clear(predefinedOlavKey);
    }
  }

  @SessionStorage(newProductAvailabilitiesKey)
  private _newProductAvailabilities: IProduktVerfuegbarkeit[];

  get newProductAvailabilities(): IProduktVerfuegbarkeit[] {
    return this._newProductAvailabilities || undefined;
  }

  set newProductAvailabilities(value: IProduktVerfuegbarkeit[]) {
    if (value) {
      this._newProductAvailabilities = value;
    } else {
      this.storage.clear(newProductAvailabilitiesKey);
    }
  }

  @SessionStorage(availabilitiesStateKey)
  private _newProductAvailabilitiesState: ServiceStateEnum;

  get newProductAvailabilitiesState(): ServiceStateEnum {
    return this._newProductAvailabilitiesState || undefined;
  }

  set newProductAvailabilitiesState(value: ServiceStateEnum) {
    if (value) {
      this._newProductAvailabilitiesState = value;
      this.newProductAvailabilitiesSuccessStateSubject.next(value === ServiceStateEnum.SUCCESS);
    } else {
      this.storage.clear(availabilitiesStateKey);
    }
  }

  @SessionStorage(predefinedProductsForSelectionKey)
  private _predefinedProductsForSelection: IProduktAuswahl;

  get predefinedProductsForSelection(): IProduktAuswahl {
    return this._predefinedProductsForSelection || undefined;
  }

  set predefinedProductsForSelection(value: IProduktAuswahl) {
    if (value) {
      this._predefinedProductsForSelection = value;
    } else {
      this.storage.clear(predefinedProductsForSelectionKey);
    }
  }

  @SessionStorage(productsForSelectionKey)
  private _productsForSelection: IProduktAuswahl;

  get productsForSelection(): IProduktAuswahl {
    return this._productsForSelection || undefined;
  }

  set productsForSelection(value: IProduktAuswahl) {
    if (value) {
      this._productsForSelection = value;
    } else {
      this.storage.clear(productsForSelectionKey);
    }
  }

  @SessionStorage(productSelectionKey)
  private _productSelection: IProduktSelektion;

  get productSelection(): IProduktSelektion {
    return this._productSelection || undefined;
  }

  set productSelection(value: IProduktSelektion) {
    if (value) {
      this._productSelection = value;
    } else {
      this.storage.clear(productSelectionKey);
    }
  }

  @SessionStorage(selectedPriceIndicationsKey)
  private _selectedPriceIndications: PreisIndikation[];

  get selectedPriceIndications(): PreisIndikation[] {
    return this._selectedPriceIndications || [];
  }

  set selectedPriceIndications(value: PreisIndikation[]) {
    if (value) {
      this._selectedPriceIndications = value;
    } else {
      this.storage.clear(selectedPriceIndicationsKey);
    }
  }

  @SessionStorage(registrationDateKey)
  private _registrationDate: string;

  get registrationDate(): string {
    return this._registrationDate || undefined;
  }

  set registrationDate(value: string) {
    if (value) {
      this._registrationDate = value;
    } else {
      this.storage.clear(registrationDateKey);
    }
  }

  @SessionStorage(detailsKey)
  private _orderDetails: IOrderDetailsInfo[];

  get orderDetails(): IOrderDetailsInfo[] {
    return this._orderDetails || undefined;
  }

  set orderDetails(value: IOrderDetailsInfo[]) {
    if (value) {
      sortProductsByCategory(value);
      this._orderDetails = value;
    } else {
      this.storage.clear(detailsKey);
    }
  }

  @SessionStorage(personalDataKey)
  private _personalData: IPersonalData;

  get personalData(): IPersonalData {
    return this._personalData || undefined;
  }

  set personalData(value: IPersonalData) {
    if (value) {
      this._personalData = value;
    } else {
      this.storage.clear(personalDataKey);
    }
  }

  @SessionStorage(personalBusinessDataKey)
  private _personalBusinessData: IBusinessData;

  get personalBusinessData(): IBusinessData {
    return this._personalBusinessData || undefined;
  }

  set personalBusinessData(value: IBusinessData) {
    if (value) {
      this._personalBusinessData = value;
    } else {
      this.storage.clear(personalBusinessDataKey);
    }
  }

  @SessionStorage(invoiceDataKey)
  private _invoiceData: IVertragskonto;

  get invoiceData(): IVertragskonto {
    return this._invoiceData || undefined;
  }

  set invoiceData(value: IVertragskonto) {
    if (value) {
      this._invoiceData = value;
    } else {
      this.storage.clear(invoiceDataKey);
    }
  }

  @SessionStorage(idCardKey)
  private _idCard: IdCard;

  get idCard(): IdCard {
    return this._idCard || undefined;
  }

  set idCard(value: IdCard) {
    if (value) {
      this._idCard = value;
    } else {
      this.storage.clear(idCardKey);
    }
  }

  @SessionStorage(uploadedFilesKey)
  private _uploadedFiles: IUploadResult;

  get uploadedFiles(): IUploadResult {
    return this._uploadedFiles || undefined;
  }

  set uploadedFiles(value: IUploadResult) {
    if (value) {
      this._uploadedFiles = value;
    } else {
      this.storage.clear(uploadedFilesKey);
    }
  }

  @SessionStorage(pdfLinksKey)
  private _pdfLinks: OrderIPDFLinks;

  get pdfLinks(): OrderIPDFLinks {
    return this._pdfLinks || undefined;
  }

  set pdfLinks(value: OrderIPDFLinks) {
    if (value) {
      this._pdfLinks = value;
    } else {
      this.storage.clear(pdfLinksKey);
    }
  }

  @SessionStorage(contractDetailsKey)
  private _contractDetails: IContractDetails;

  get contractDetails(): IContractDetails {
    return this._contractDetails || undefined;
  }

  set contractDetails(contractDetails: IContractDetails) {
    if (contractDetails) {
      this._contractDetails = contractDetails;
    } else {
      this.storage.clear(contractDetailsKey);
    }
  }

  @SessionStorage(contractDetailsViewModelKey)
  private _contractDetailsViewModel: NewClientContractDetailsViewModel;

  // just used to restore view, data for IL is stored in contractDetails
  get contractDetailsViewModel(): NewClientContractDetailsViewModel {
    return this._contractDetailsViewModel || undefined;
  }

  set contractDetailsViewModel(data: NewClientContractDetailsViewModel) {
    if (data) {
      this._contractDetailsViewModel = data;
    } else {
      this.storage.clear(contractDetailsViewModelKey);
    }
  }

  @SessionStorage(newClientERechnungsInfoKey)
  private _eRechnungsInfo: IERechnungsInfo;

  get eRechnungsInfo(): IERechnungsInfo {
    return this._eRechnungsInfo || undefined;
  }

  set eRechnungsInfo(value: IERechnungsInfo) {
    if (value) {
      this._eRechnungsInfo = value;
    } else {
      this.storage.clear(newClientERechnungsInfoKey);
    }
  }

  @SessionStorage(notLoggedInKey)
  private _notLoggedIn: string;

  get notLoggedIn(): string {
    return this._notLoggedIn || undefined;
  }

  set notLoggedIn(value: string) {
    if (value) {
      this._notLoggedIn = value;
    } else {
      this.storage.clear(notLoggedInKey);
    }
  }

  @SessionStorage(confirmationStatusKey)
  private _confirmationStatus: IConfirmationStatus;

  get confirmationStatus(): IConfirmationStatus {
    return this._confirmationStatus || undefined;
  }

  set confirmationStatus(confirmationStatus: IConfirmationStatus) {
    this._confirmationStatus = confirmationStatus;
  }

  @SessionStorage(lastOrderDateKey)
  private _lastOrderDate: string;

  get lastOrderDate(): string {
    return this._lastOrderDate || undefined;
  }

  set lastOrderDate(value: string) {
    if (value) {
      this._lastOrderDate = value;
    } else {
      this.storage.clear(lastOrderDateKey);
    }
  }

  @SessionStorage(orderSuccessfullKey)
  private _orderSuccessfull: boolean;

  get orderSuccessfull(): boolean {
    return this._orderSuccessfull;
  }

  set orderSuccessfull(value: boolean) {
    this._orderSuccessfull = value;
    this.newProductAvailabilitiesSuccessStateSubject.next(value === true);
  }

  @SessionStorage(submitSuccessStateKey)
  private _submitSuccessState: ServiceStateEnum;

  get submitSuccessState(): ServiceStateEnum {
    return this._submitSuccessState || undefined;
  }

  set submitSuccessState(submitSuccessState: ServiceStateEnum) {
    if (submitSuccessState) {
      this._submitSuccessState = submitSuccessState;
      this.submitSuccessStateSubject.next(submitSuccessState);
    } else {
      this.storage.clear(submitSuccessState);
    }
  }

  @SessionStorage(voucherInfoKey)
  private _voucherInfo: IAktionen;

  get voucherInfo(): IAktionen {
    return this._voucherInfo || undefined;
  }

  set voucherInfo(value: IAktionen) {
    if (value) {
      this._voucherInfo = value;
    } else {
      this.storage.clear(voucherInfoKey);
    }
  }

  @SessionStorage(predefinedVoucherCodeKey)
  private _predefinedVoucherCode: string;

  get predefinedVoucherCode(): string {
    return this._predefinedVoucherCode || undefined;
  }

  set predefinedVoucherCode(value: string) {
    if (value) {
      this._predefinedVoucherCode = value;
    } else {
      this.storage.clear(predefinedVoucherCodeKey);
    }
  }

  @SessionStorage(autoRegistrationSuccessfulKey)
  private _autoRegistrationSuccessful: boolean;

  get autoRegistrationSuccessful(): boolean {
    return this._autoRegistrationSuccessful;
  }

  set autoRegistrationSuccessful(value: boolean) {
    this._autoRegistrationSuccessful = value;
  }

  @SessionStorage(preselectProductFernwaermeKey)
  private _preselectProductFernwaerme: boolean;

  get preselectProductFernwaerme(): boolean {
    return this._preselectProductFernwaerme;
  }

  set preselectProductFernwaerme(value: boolean) {
    this._preselectProductFernwaerme = value;
  }

  @SessionStorage(newClientTrackingDataKey)
  private _trackingData: INewClientTrackingData;

  get trackingData(): INewClientTrackingData {
    return this._trackingData || undefined;
  }

  set trackingData(value: INewClientTrackingData) {
    if (value) {
      this._trackingData = value;
    } else {
      this.storage.clear(newClientTrackingDataKey);
    }
  }

  @SessionStorage(newClientTypeKey)
  private _newClientType: 'kunde-werden' | 'superschnell-bestellen';

  get newClientType(): 'kunde-werden' | 'superschnell-bestellen' {
    return this._newClientType || undefined;
  }

  set newClientType(value: 'kunde-werden' | 'superschnell-bestellen') {
    if (value) {
      this._newClientType = value;
    } else {
      this.storage.clear(newClientTypeKey);
    }
  }

  @SessionStorage(sendProductInterestStateKey)
  private _sendProductInterestState: ServiceStateEnum;

  get sendProductInterestState(): ServiceStateEnum {
    return this._sendProductInterestState || undefined;
  }

  set sendProductInterestState(value: ServiceStateEnum) {
    if (value) {
      this._sendProductInterestState = value;
    } else {
      this.storage.clear(sendProductInterestStateKey);
    }
  }

  @SessionStorage(hasPriceIndicationsReloadErrorKey)
  private _hasPriceIndicationsReloadError: boolean;

  get hasPriceIndicationsReloadError(): boolean {
    return this._hasPriceIndicationsReloadError;
  }

  set hasPriceIndicationsReloadError(value: boolean) {
    this._hasPriceIndicationsReloadError = value;
  }

  @SessionStorage(grantedMarketingPermissionKey)
  private _grantedMarketingPermission: boolean;

  get grantedMarketingPermission(): boolean {
    return this._grantedMarketingPermission;
  }

  set grantedMarketingPermission(value: boolean) {
    this._grantedMarketingPermission = value;
  }

  @SessionStorage(tkConnIdKey)
  private _tkConnId: string;

  get tkConnId(): string {
    return this._tkConnId || undefined;
  }

  set tkConnId(value: string) {
    if (value) {
      this._tkConnId = value;
    } else {
      this.storage.clear(tkConnIdKey);
    }
  }

  @SessionStorage(skipPreselectedAddressKey)
  private _skipPreselectedAddress: boolean;

  get skipPreselectedAddress(): boolean {
    return this._skipPreselectedAddress;
  }

  set skipPreselectedAddress(value: boolean) {
    this._skipPreselectedAddress = value;
  }

  get profileChecked(): boolean {
    return this._newClientProfileChecked;
  }

  set profileChecked(value: boolean) {
    this._newClientProfileChecked = value;
  }

  get mgmCode(): string {
    return this._newClientMgmCode || undefined;
  }

  set mgmCode(value: string) {
    if (value) {
      this._newClientMgmCode = value;
    } else {
      this.storage.clear(newClientMgmCodeKey);
    }
  }

  clearAll() {
    this.clearData();
    super.clear();
  }

  clearData(): void {
    this.storage.clear(profileCheckedKey);
    this.storage.clear(addressKey);
    this.storage.clear(addressOlavKey);
    this.storage.clear(predefinedOlavKey);
    this.storage.clear(newProductAvailabilitiesKey);
    this.storage.clear(availabilitiesStateKey);
    this.storage.clear(predefinedProductsForSelectionKey);
    this.storage.clear(productsForSelectionKey);
    this.storage.clear(productSelectionKey);
    this.storage.clear(selectedPriceIndicationsKey);
    this.storage.clear(registrationDateKey);
    this.storage.clear(detailsKey);
    this.storage.clear(personalDataKey);
    this.storage.clear(personalBusinessDataKey);
    this.storage.clear(invoiceDataKey);
    this.storage.clear(idCardKey);
    this.storage.clear(uploadedFilesKey);
    this.storage.clear(pdfLinksKey);
    this.storage.clear(contractDetailsKey);
    this.storage.clear(contractDetailsViewModelKey);
    this.storage.clear(newClientERechnungsInfoKey);
    this.storage.clear(notLoggedInKey);
    this.storage.clear(confirmationStatusKey);
    this.storage.clear(lastOrderIdKey);
    this.storage.clear(lastOrderDateKey);
    this.storage.clear(retriedOnErrorKey);
    this.storage.clear(submitIdKey);
    this.storage.clear(orderSuccessfullKey);
    this.storage.clear(submitSuccessStateKey);
    this.storage.clear(voucherInfoKey);
    this.storage.clear(predefinedVoucherCodeKey);
    this.storage.clear(autoRegistrationSuccessfulKey);
    this.storage.clear(preselectProductFernwaermeKey);
    this.storage.clear(newClientTrackingDataKey);
    this.storage.clear(sendProductInterestStateKey);
    this.storage.clear(newClientTypeKey);
    this.storage.clear(newClientMgmCodeKey);
    this.storage.clear(hasPriceIndicationsReloadErrorKey);
    this.storage.clear(tkConnIdKey);
  }

  getOrderData(): IOrderData {
    this.orderDetails.forEach(pdi => {
      if (!pdi.eRechnungInfo) {
        pdi.eRechnungInfo = this.eRechnungsInfo;
      }
      if (!pdi.produktDetails) {
        pdi.produktDetails = {} as IProduktDetails;
      }
      this.updateProductPartner(pdi);
      this.updateRechnungsempfaenger(pdi);
      this.updatePartner(pdi);
      this.invoiceInformation(pdi);
    });

    return {
      selectedOlav: this.newClientAddressOlav,
      newAddress: this.newClientAddress,
      registrationDate: this.registrationDate,
      orderDetails: this.orderDetails,
      idCard: this.idCard,
      uploadedFiles: this.uploadedFiles,
      pdfLinks: this.pdfLinks,
      contractDetails: this.contractDetails,
      priceIndications: this.tariffSelectionStateService.indications,
    };
  }

  invoiceInformation(pdi: IOrderDetailsInfo): void {
    if (this.invoiceData) {
      pdi.produktDetails.vertragskonto = this.invoiceData;
    }
  }

  observeProductAvailabilitiesSuccessState(): Observable<boolean> {
    return this.newProductAvailabilitiesSuccessStateSubject.asObservable();
  }

  getNewAddressProductCategories(): ProductCategoryEnum[] {
    return this.orderDetails.map(elem => ProductCategoryEnum[elem.category.toUpperCase()]);
  }

  observeSubmitSuccessState(): Observable<ServiceStateEnum> {
    return this.submitSuccessStateSubject.asObservable();
  }

  getProductSelection(): IProduktSelektion {
    return this.tempProductSelectionForVoucher;
  }

  getProductSelectionIds(): string[] {
    const selectedIds = [];
    if (this.tempProductSelectionForVoucher?.strom) {
      selectedIds.push('strom');
    }

    if (this.tempProductSelectionForVoucher?.gas) {
      selectedIds.push('gas');
    }

    return selectedIds;
  }

  private updatePartner(pdi: IOrderDetailsInfo): void {
    if (this.personalData) {
      pdi.partnerDetails = {
        adressen: null,
        bankkonten: null,
        geschaeftsPartnerId: null,
        kontaktdetails: [
          {
            email: { eMail: this.personalData.email },
            fax: null,
            telefon: { land: this.personalData.telefonCountryCode, nummer: this.personalData.telefonNumber },
          },
        ],
        kundensegment: null,
        nickname: null,
        organisation: null,
        systemId: null,
        physischePerson: {
          anrede: this.personalData.salutation,
          titel: this.personalData.title?.text,
          titelNachgestellt: null,
          titelVorgestellt: null,
          familienstand: null,
          geschlecht: null,
          staatsbuergerschaft: null,
          vorname: this.personalData.firstName,
          nachname: this.personalData.lastName,
          geburtsdatum: this.personalData.birthday + 'T00:00:00Z',
        },
      };
    }
  }

  private updateProductPartner(pdi: IOrderDetailsInfo): void {
    if (this.personalData) {
      pdi.produktDetails.partner = {
        partnerId: null,
        partnerGruppe: null,
        nickname: null,
        registrierungStatus: null,
        rolle: null,
        partnerTyp: null,
        anrede: this.personalData.salutation,
        titel: this.personalData.title?.text,
        titelKurz: this.personalData.title?.value,
        nachname: this.personalData.lastName,
        vorname: this.personalData.firstName,
        firma: null,
        email: this.personalData.email,
        telefon: this.personalData.telefonNumber,
        telefonvorwahl: this.personalData.telefonCountryCode,
      };
    }
  }

  private updateRechnungsempfaenger(pdi: IOrderDetailsInfo): void {
    if (this.personalData) {
      pdi.produktDetails.rechnungsempfaenger = {
        partnerId: null,
        partnerGruppe: null,
        nickname: null,
        registrierungStatus: null,
        rolle: null,
        partnerTyp: null,
        titel: this.personalData.title?.value,
        anrede: this.personalData.salutation,
        nachname: this.personalData.lastName,
        vorname: this.personalData.firstName,
        firma: null,
        email: this.personalData.email,
        telefon: this.personalData.telefonNumber,
        telefonvorwahl: this.personalData.telefonCountryCode,
        adresse: {
          ort: this.newClientAddress.city,
          plz: this.newClientAddress.postcode,
          strasse: this.newClientAddress.street,
          hausnummer: this.newClientAddress.streetNumber,
          raum: null,
          land: this.newClientAddress.country,
          adresshinweis: null,
        },
      };
    }
  }

  // VoucherService methods end
}
