<ng-container *mweIsFeatureEnabled="featureToggleName()">
  @if (isAlertVisible()) {
    <mwe-alert
      [iconClassOverwrite]="data().alert.icon"
      [messageKey]="data().alert.text"
      [titleKey]="data().alert.title"
      [type]="data().alert.type"
    />
  }
</ng-container>
