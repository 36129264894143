import { Component, computed, inject, input } from '@angular/core';
import { TariffOptionSap, TariffSelectionCardData } from '@mwe/models';
import { TariffSelectionCardLogic } from '../services/tariff-selection-card.logic';
import { TariffSelectionStore } from '../services/tariff-selection.store';

@Component({
  selector: 'mwe-tariff-selection-card-store',
  templateUrl: './tariff-selection-card-store.component.html',
})
export class TariffSelectionCardStoreComponent {
  hasActions = input<boolean>();
  isReadonly = input<boolean>();
  lockedGroupIds = input<string[]>([]);
  // data comes from signal() do not manipulate it!!!! notify parent and parent should change it in TariffSelectionStore
  tariff = input<TariffSelectionCardData>();

  additionalOptionGroupName = computed<string>(() => this.store.additionalOptionGroupName());

  private cardLogic = inject(TariffSelectionCardLogic);
  private store = inject(TariffSelectionStore);

  onTariffUpdated(data: TariffOptionSap) {
    this.cardLogic.handleTariffOptionChange(this.tariff(), data);
  }

  onReloadAfterError() {
    // retry with old state
    this.cardLogic.handleTariffOptionChange(this.tariff(), this.tariff().userSelection[0]);
  }
}
