<mwe-tariff-card-template
  [attr.data-test]="'tariff-card-' + selectedTariff()?.category.toLowerCase()"
  [category]="selectedTariff()?.category"
  [hasActions]="hasActions"
  [hasDiscount]="selectedTariff()?.priceIndication?.istRabattiert"
  [isDiscountKnoedlVisible]="!!selectedTariff()?.priceIndication?.aktionsHinweis"
  [isSpot]="isSpotTariff()"
  [noBoxShadow]="true"
  [state]="state()"
  [subTitle]="selectedTariff()?.priceIndication?.tarif?.tarifBeschreibung"
  [title]="selectedTariff()?.priceIndication?.tarif?.tarifName"
>
  <mwe-pre-load-data
    [errorTemplate]="error"
    [state]="state()"
    [successTemplate]="success"
    loadingSpinnerType="normal-without-box"
  ></mwe-pre-load-data>
  <ng-container slot="actions">
    <ng-content></ng-content>
  </ng-container>
</mwe-tariff-card-template>

<ng-template #error>
  <div class="text-center">
    <img [src]="'error.svg' | addStaticImagePath" />
    <p class="mt-3" translate="tariff.error.reloadPriceIndications.card.info"></p>
    <mwe-button-link
      (clicked)="reloadAfterError.emit()"
      color="blue"
      label="tariff.error.reloadPriceIndications.card.reloadButton"
    ></mwe-button-link>
  </div>
</ng-template>

<ng-template #success>
  <mwe-flipping-content backCardLabel="tariff.selectionCard.flip.back" frontCardLabel="tariff.selectionCard.flip.front">
    <ng-container slot="front">
      <mwe-tariff-selection-card-front-ui
        (tariffUpdated)="tariffUpdated.emit($event)"
        [activeCampaignTexts]="activeCampaignTexts"
        [advantages]="advantages"
        [groups]="groups"
        [selectedTariff]="selectedTariff()"
      ></mwe-tariff-selection-card-front-ui>
    </ng-container>
    <ng-container slot="back">
      <mwe-tariff-selection-card-back-ui [selectedTariff]="selectedTariff()"></mwe-tariff-selection-card-back-ui>
    </ng-container>
  </mwe-flipping-content>
</ng-template>
