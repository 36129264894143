<mwe-pre-load-data
  [state]="state()"
  alertTitleKey="error.service.default.title"
  alertMessageKey="error.service.default.message"
  [successTemplate]="success"
></mwe-pre-load-data>

<ng-template #success>
  @if (hasNoAddress()) {
    <mwe-no-associations-found-warning
      [navigatingBackTo]="navigatingBackTo()"
      [noAddressGroupsTitle]="noAddressGroupsTitle()"
      [noAddressGroupsText]="noAddressGroupsText()"
    ></mwe-no-associations-found-warning>
  } @else {
    @for (item of addressMultiProductCards(); track item.address?.getHashCode()) {
      <div class="mb-5">
        <mwe-address [address]="item.address"></mwe-address>
        <mwe-multi-product-card-container
          [multiProductCards]="item.multiProductCards"
          [showSummarizedCompanyReference]="true"
          [isSliderVisible]="false"
          [showAccountNumber]="true"
          [showCustomerNumber]="true"
          [hasMousePointerStyle]="true"
          (cardClicked)="onMultiCardClicked(item, $event)"
        >
        </mwe-multi-product-card-container>
      </div>
    }
  }
</ng-template>
