<mwe-impersonate-warning *mweShowOnlyForImpersonate />
<mwe-page-ribbon />

<div
  class="page-content overflow-x-hidden"
  [ngClass]="{
    'maintenance-mode': isMaintenanceEnabled,
    'p-0': noBottomPadding,
  }"
>
  @if (routeLoaded) {
    <mwe-header [withBottomMargin]="!isMaintenanceEnabled">
      <mwe-navbar-header />
    </mwe-header>
  }
  <router-outlet />
</div>

@if (showChatbot) {
  <mwe-chatbot-popup />
}

@if (!hideFooter) {
  <ng-content select="[slot='footer']" />
}

@if (isCookieConsentEnabled) {
  <mwe-cookie-consent />
}

@if (showNavBar) {
  <mwe-navbar-footer />
}

<mwe-aria-live />
