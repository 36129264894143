import { Address, IOlavEntry } from '../../address/address.model';
import { PreisIndikation } from '../../availabilities/availabilities.model';
import {
  CommonMappingData,
  IBankDetails,
  IBulletConfirmationStatusChangeResponse,
  IERechnungsInfo,
  IProduktSelektion,
} from '../../integrationlayer/api.model';
import { KundenTypEnum } from '../../integrationlayer/tarifwechsel.model';
import { IBusinessData, IPersonalData } from '../../order/order.model';

// tslint:disable-next-line: no-empty-interface
export type IChargingCardOrderMapping = CommonMappingData;
// tslint:disable-next-line:no-empty-interface
export type IChargingCardOrderStatusResponse = IBulletConfirmationStatusChangeResponse;
export interface IChargingCardOrderDTO {
  selectedOlav: IOlavEntry;
  newAddress: Address;
  personalData: IPersonalData;
  businessData?: IBusinessData;
  carModelType?: string;
  numberOfCards?: string;
  sepaMandat?: IBankDetails;
  eRechnungsInfo?: IERechnungsInfo;
  registrationDate: string;
  orderedAt?: string;
  id?: number;
  processStatus?: string;
  mappingData?: IChargingCardOrderMapping[];
  productSelection?: IProduktSelektion;
  grantedMarketingPermission?: boolean;
  priceIndications?: PreisIndikation[];
  activationNumbers?: string[];
}

export const parseChargingCardOrderDataDTOFromOrderStatusDetail = (chargingOrderDetails: any): IChargingCardOrderDTO => {
  const chargingCardOrderDTO: IChargingCardOrderDTO = JSON.parse(chargingOrderDetails.changeData);
  chargingCardOrderDTO.registrationDate = chargingOrderDetails?.createdAt;
  return chargingCardOrderDTO;
};

export interface ChargingCardBusinessCardActivationDTO {
  customerNumber: string;
  cardNumber: string;
  activationCode?: string;
  type: KundenTypEnum;
}

export interface ChargingCard {
  customerNumber: string;
  cardNumber: string;
  activated: boolean;
}
