<div class="row g-5 g-md-6" data-test="tariff-overview">
  @for (item of selectedTariffs(); track $index) {
    <div class="col-md-6" [attr.data-test]="'tariff-container-' + item.category.toLowerCase() + '-' + item.tariffKey">
      <mwe-headline
        [headlineLevelIncrement]="0"
        cssClass="h5 mb-4 mb-lg-6 mt-lg-2"
        text="{{ cardCategoryTitleKeyPrefix() }}.{{ item.category.toLowerCase() }}"
      />
      <mwe-tariff-selection-card-store [hasActions]="true" [isReadonly]="isReadOnly()" [tariff]="item">
        <div class="text-center">
          <a
            class="btn btn-link text-uppercase"
            [href]="getTariffPdfUrl(item.tariffKey)"
            target="_blank"
            translate="tariff.selectionCard.tariffPdfLink"
          ></a>
        </div>
      </mwe-tariff-selection-card-store>

      @if (!isReadOnly()) {
        <mwe-tariffs-selection-button [category]="item.category" [anlageId]="item.anlageId" class="d-block mt-3 mt-md-4" />
      }

      @if (item.priceIndication.rechtsText && !isReadOnly()) {
        <div class="row g-3 mt-3">
          <div class="col col-auto">
            <i class="fa fa-info fs-6 text-secondary"></i>
          </div>
          <div class="col">
            <mwe-expander class="d-block mt-n4" [textKey]="item.priceIndication.rechtsText" />
          </div>
        </div>
      }
    </div>
    @if (checkIfAdditionalCardIsVisible(item)) {
      <div class="col-md-6">
        <div
          class="h5 mb-3 mb-lg-4"
          translate="{{ cardCategoryTitleKeyPrefix() }}.{{ item.category.toLowerCase() }}"
          style="opacity: 0; pointer-events: none"
          aria-hidden="true"
        ></div>
        <mwe-tariff-card-additional-options
          [headlineReference]="headlineService.getHeadline(1)"
          [tariff]="item"
          [isReadOnly]="isReadOnly()"
        />
      </div>
    }
  }
</div>
