import { Injectable } from '@angular/core';
import { PreisIndikation } from '@mwe/models';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';

const indicationsKey = 'order_indications';
const tariffOptionsFormPopupAlreadyOpenedForKey = 'tariff-selection_tariffOptionsFormPopupAlreadyOpenedFor';

@Injectable({ providedIn: 'root' })
export class TariffSelectionStateService {
  @SessionStorage(indicationsKey)
  private _indications: PreisIndikation[];

  @SessionStorage(tariffOptionsFormPopupAlreadyOpenedForKey)
  private _tariffOptionsFormPopupAlreadyOpenedFor: string[];

  constructor(private storage: SessionStorageService) {}

  clearData(): void {
    this.storage.clear(indicationsKey);
    this.storage.clear(tariffOptionsFormPopupAlreadyOpenedForKey);
  }

  get indications(): PreisIndikation[] {
    return this._indications ?? [];
  }

  set indications(indications: PreisIndikation[]) {
    this._indications = indications?.filter(i => i);
  }

  get tariffOptionsFormPopupAlreadyOpenedFor(): string[] {
    return this._tariffOptionsFormPopupAlreadyOpenedFor ?? [];
  }

  set tariffOptionsFormPopupAlreadyOpenedFor(value: string[]) {
    this._tariffOptionsFormPopupAlreadyOpenedFor = value;
  }
}
