import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click/click-outside.directive';
import { ClickPreventDefaultDirective } from './click/click-prevent-default.directive';
import { ScrollToTargetOnClickDirective } from './click/scroll-to-element-on-click.directive';
import { LinkInLabelClickDirective } from './click/link-in-label-click.directive';
import { DefaultImageDirective } from './default-image/default-image.directive';
import { DefaultTariffImageDirective } from './default-image/default-tariff-image.directive';
import { IsAnyFeatureEnabledDirective } from './feature-toggles/is-any-feature-enabled.directive';
import { IsFeatureDisabledDirective } from './feature-toggles/is-feature-disabled.directive';
import { IsFeatureEnabledDirective } from './feature-toggles/is-feature-enabled.directive';
import { LookAtMeIAmTheFocusNowDirective } from './focus/look-at-me-i-am-the-focus-now.directive';
import { EnvTranslateDirective } from './env-translate/env-translate.directive';
import { DotlottiePlayerSrcDirective } from './dotlottie-player-src/dotlottie-player-src.directive';

const directives = [
  ClickOutsideDirective,
  ClickPreventDefaultDirective,
  DotlottiePlayerSrcDirective,
  LinkInLabelClickDirective,
  ScrollToTargetOnClickDirective,
  DefaultImageDirective,
  DefaultTariffImageDirective,
  EnvTranslateDirective,
  IsAnyFeatureEnabledDirective,
  IsFeatureDisabledDirective,
  IsFeatureEnabledDirective,
  LookAtMeIAmTheFocusNowDirective,
];

@NgModule({
  declarations: [...directives],
  exports: [...directives],
})
export class DirectivesModule {}
