import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DetailsLinkTypeEnum,
  FeatureToggleEnum,
  PRODUCTS_BILLINFO_ROUTE,
  PRODUCTS_CONSUMPTION,
  PRODUCTS_ROUTE,
  TARIFF_CHANGE_PRODUCTS_ROUTE,
  TARIFF_CHANGE_ROUTE,
} from '@mwe/constants';
import { Address, ClearingAccount, IPopupModel, parseAddress, Product, UnpaidInvoices } from '@mwe/models';
import {
  containsStromGasOrWaermeProducts,
  getProductCategories,
  isArrayWithMinOneItem,
  isProductCategoryEmobility,
  isProductCategoryEmobilityOrInternet,
  isProductCategoryInternet,
  sortProductsByCategory,
} from '@mwe/utils';
import { AddressGroupService, ProductStateService, ProfileService } from '@mwe/services';
import {
  ClearingAccountLogic,
  ConsumptionStateService,
  InteractiveInvoicesLogic,
  InvoiceDataChangeLogic,
  MoveOutLogic,
  ProductBillInfoStateService,
  RelocationLogic,
  TariffChangeStateService,
  PopupService,
} from '@mwe/services';
import { AddressDropdownItem } from '../address/address.component';
import { HeadlineService } from '../headline/headline.service';

@Component({
  selector: 'mwe-products-group',
  templateUrl: './products-group.component.html',
  styles: [],
})
export class ProductsGroupComponent implements OnInit {
  @Input() hasAllProcesses = true; // temp input, todo remove once b2b has all processes implemented
  @Input() addressIdx: string;
  @Input() address: Address;
  @Input() products: Product[];
  @Input() state: 'details' | 'list';
  @Input() showCompanyCorrelation = false;
  @Input() checkIfFernwaermeIsActive = false;
  @Output() relocationDataReloaded = new EventEmitter<any>();

  dropdownItems: AddressDropdownItem[] = [];
  featuredBtnIds: string[] = [];
  productCategories: string[] = [];
  dropdownType = '';
  unpaidInvoices: ClearingAccount[] = [];

  constructor(
    private popupService: PopupService,
    private addressGroupService: AddressGroupService,
    private profileService: ProfileService,
    private relocationLogic: RelocationLogic,
    private tariffChangeStateService: TariffChangeStateService,
    private productBillInfoStateService: ProductBillInfoStateService,
    private consumptionStateService: ConsumptionStateService,
    private router: Router,
    private productStateService: ProductStateService,
    private invoiceDataChangeLogic: InvoiceDataChangeLogic,
    private moveOutLogic: MoveOutLogic,
    private interactiveInvoicesLogic: InteractiveInvoicesLogic,
    private clearingAccountLogic: ClearingAccountLogic,
    public headlineService: HeadlineService,
  ) {}

  ngOnInit(): void {
    if (this.products != null) {
      sortProductsByCategory(this.products);
      this.productCategories = getProductCategories(this.products);
    }
    if (this.address.addressGroup.detailsLinkType) {
      this.products.forEach(p => (p.address = this.address));
    }

    this.createDropDownItems();
    this.getAllUnpaidInvoices();
  }

  get isDetailsState(): boolean {
    return this.state === 'details';
  }

  async saveGroupName(name: string): Promise<void> {
    const oldValue = this.address.getGroupName();
    this.address = this.address.setGroupName(name);
    try {
      const addressGroup = await this.addressGroupService.setAddressGroupName(this.address.addressGroup);
      this.address = parseAddress({
        ...this.address,
        addressGroup,
      });
    } catch (err) {
      this.address = this.address.setGroupName(oldValue);
      this.showErrorPopup();
    }
  }

  actionHandler(item: string): void {
    switch (item) {
      case 'products.addresGroupItems.groupName':
        this.showGroupNamePopup();
        break;
      case 'products.addresGroupItems.relocation':
        this.relocationLogic.initRelocationProcess(this.products);
        break;
      case 'tariffChange.entry':
        this.initTariffChangeProcess();
        break;
      case 'interactive-invoices.entry':
        this.interactiveInvoicesLogic.showInteractiveInvoices({
          address: this.address,
          products: this.products,
        });
        break;
      case 'products.addresGroupItems.costOverview':
        this.showBillinfoGroup();
        break;
      case 'consumption.menuItem':
        this.showConsumptionOverview();
        break;
      case 'relocation.status':
        this.relocationLogic.initRelocationStatusProcess(this.address);
        break;
      case 'moveout.status':
        this.moveOutLogic.initMoveOutStatusProcess(this.address);
        break;
      case 'bankAccountChange.entry':
        this.invoiceDataChangeLogic.startBankAccountChangeWithoutAddressSelection(this.products, this.address);
        break;
      case 'pdfInvoiceChange.entryDropDown':
        this.invoiceDataChangeLogic.startPdfInvoiceChangeWithoutAddressSelection(this.products, this.address);
        break;
      case 'move-out.entry':
        this.moveOutLogic.startProcessWithoutAddressSelection(this.products, this.address);
        break;
      default:
      // ...
    }
  }

  initTariffChangeProcess(): void {
    this.tariffChangeStateService.directAddressSelection = true;
    this.tariffChangeStateService.tariffChangeAddress = parseAddress(this.products[0].address);
    this.tariffChangeStateService.tariffChangeProducts = this.products;
    this.router.navigateByUrl(`/${TARIFF_CHANGE_ROUTE}/${TARIFF_CHANGE_PRODUCTS_ROUTE}`);
  }

  showBillinfoGroup(): void {
    this.productBillInfoStateService.products = this.products;
    this.productBillInfoStateService.address = this.address;
    this.router.navigateByUrl(`/${PRODUCTS_ROUTE}/${PRODUCTS_BILLINFO_ROUTE}`);
  }

  showConsumptionOverview(): void {
    this.consumptionStateService.products = this.products;
    this.consumptionStateService.address = this.address;
    this.router.navigateByUrl(`/${PRODUCTS_ROUTE}/${PRODUCTS_CONSUMPTION}`);
  }

  showGroupNamePopup(): void {
    const model: IPopupModel = {
      id: 'products-group-group-name',
      titleKey: 'products.groupName.popup.title',
      messageKey: 'products.groupName.popup.message',
      showSubmitButton: true,
      submitButtonKey: 'products.groupName.popup.buttonOk',
      showCancelButton: true,
      cancelButtonKey: 'products.groupName.popup.buttonCancel',
      iconColorClass: 'blue',
      iconTypeClass: 'fa-pen',
      formModel: {
        inputs: [
          {
            name: 'groupName',
            initialValue: this.address.getGroupName(),
            validators: [Validators.required, Validators.minLength(1)],
            labelKey: 'products.groupName.popup.labels.groupName',
            placeholder: 'products.groupName.popup.placeholders.groupName',
            validate: true,
            dataType: 'text',
            maxLength: 32,
          },
        ],
      },
    };
    this.popupService.open(model);
    this.popupService.events().subscribe(result => {
      if (result && result !== 'CANCEL') {
        this.productStateService.groupNames = null;

        if ((result.groupName && result.groupName.length) || !!this.address.addressGroup.detailsLinkType) {
          this.saveGroupName(result.groupName.substring(0, 32));
        } else {
          if (this.address.addressGroup?.id) {
            this.addressGroupService.deleteAddressGroupName(this.address.addressGroup);
            this.address = this.address.setGroupName(undefined);
          }
        }
      }
    });
  }

  showErrorPopup(): void {
    const model: IPopupModel = {
      id: 'products-group-error',
      titleKey: 'global.http.error.popup.title',
      messageKey: 'global.http.error.popup.message',
      showSubmitButton: true,
      submitButtonKey: 'global.http.error.popup.buttonOk',
      showCancelButton: false,
      iconColorClass: 'red',
    };
    this.popupService.open(model, true);
  }

  getUnpaidInvoice(product: Product): UnpaidInvoices {
    return this.unpaidInvoices.find(ui => {
      return (
        ui.accountId === product.accountNumber && ui.businessPartnerId === product.businessPartnerNumber && ui.systemId === product.systemId
      );
    })?.unpaidInvoices;
  }

  hasUnpaidInvoices(): boolean {
    return this.unpaidInvoices?.some(ui => isArrayWithMinOneItem(ui?.unpaidInvoices?.offeneZahlungen));
  }

  private async getAllUnpaidInvoices(): Promise<void> {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.DUE_PAYMENT_NOTIFICATIONS_ENABLED) || this.state !== 'details') {
      return;
    }
    const emobilityAccounts = this.products.filter(p => isProductCategoryEmobilityOrInternet(p.category)).map(p => p.accountNumber);
    // unpaid invoices is loaded from memory service and not backend. so no loading spinner is needed
    this.unpaidInvoices = await this.clearingAccountLogic.getClearingAccountsForProducts(this.products);
    if (this.unpaidInvoices) {
      this.unpaidInvoices.forEach(ui => {
        if (emobilityAccounts.includes(ui.accountId)) {
          // emobility + tk are not supported at the moment
          // can be removed once categories are supported
          ui.unpaidInvoices = null;
        }
      });
    }
  }

  private createDropDownItems(): void {
    if (!this.isDetailsState) {
      return;
    }

    if (this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.RENAME_ADDRESS_GROUP_ENABLED)) {
      this.dropdownItems.push({ id: 'products.addresGroupItems.groupName', label: 'products.addresGroupItems.groupName' });
    }

    this.dropdownType = 'dots';
    this.dropdownItems.push({ id: 'tariffChange.entry', label: 'tariffChange.entry' });
    this.dropdownItems.push({ id: 'interactive-invoices.entry', label: 'interactive-invoices.entry' });

    if (this.hasAllProcesses) {
      this.dropdownItems.push({ id: 'products.addresGroupItems.relocation', label: 'products.addresGroupItems.relocation' });
      this.dropdownItems.push({ id: 'products.addresGroupItems.costOverview', label: 'products.addresGroupItems.costOverview' });
    }

    if (containsStromGasOrWaermeProducts(this.products)) {
      this.dropdownItems.push({ id: 'consumption.menuItem', label: 'consumption.menuItem' });
    }

    this.dropdownItems.push({ id: 'pdfInvoiceChange.entryDropDown', label: 'pdfInvoiceChange.entryDropDown' });
    this.dropdownItems.push({ id: 'bankAccountChange.entry', label: 'bankAccountChange.entry' });
    this.dropdownItems.push({ id: 'move-out.entry', label: 'move-out.entry' });

    this.featuredBtnIds = ['products.addresGroupItems.relocation', 'move-out.entry'];
  }
}
