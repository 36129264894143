import { Injectable } from '@angular/core';
import { AddressGroup, IVerknuepfungen, IVerknuepfungenResponse, RecentOrder } from '@mwe/models';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';

const associationSuggestionsKey = 'product_associationSuggestions';
const wasPushPdfPopupAlreadyShownKey = 'product_wasPushPdfPopupAlreadyShown';
const wasPushSepaPopupAlreadyShownKey = 'product_wasPushSepaPopupAlreadyShown';
const loggedMissingAssociationsKey = 'product_loggedMissingAssociations';
const loggedNewAssociationsKey = 'product_loggedNewAssociations';
const loggedAssociationSuggestionsKey = 'product_loggedAssociationSuggestions';
const associationPopupClosedKey = 'product_associationPopupClosed';

@Injectable({
  providedIn: 'root',
})
export class ProductStateService {
  // no session storage and no caching
  // after browser reload we want new data from backend
  associations: IVerknuepfungenResponse;
  associationsOrig: IVerknuepfungenResponse;
  groupNames: AddressGroup[];
  recentOrders: RecentOrder[];

  @SessionStorage(wasPushPdfPopupAlreadyShownKey)
  _wasPushPdfPopupAlreadyShown: boolean;

  @SessionStorage(wasPushSepaPopupAlreadyShownKey)
  _wasPushSepaPopupAlreadyShown: boolean;

  @SessionStorage(associationSuggestionsKey)
  _associationSuggestions: IVerknuepfungen[];

  @SessionStorage(loggedAssociationSuggestionsKey)
  _loggedAssociationSuggestions: string[];

  @SessionStorage(loggedMissingAssociationsKey)
  _loggedMissingAssociations: string[];

  @SessionStorage(loggedNewAssociationsKey)
  _loggedNewAssociations: string[];

  @SessionStorage(associationPopupClosedKey)
  _associationPopupClosed: boolean;
  constructor(private storage: SessionStorageService) {}

  clear(): void {
    this.clearProducts();
    this.clearRecentOrdersAndGroups();
    this.storage.clear(wasPushPdfPopupAlreadyShownKey);
    this.storage.clear(associationPopupClosedKey);
  }

  clearProducts(): void {
    this.associations = null;
    this.associationsOrig = null;
    this.storage.clear(associationSuggestionsKey);
  }

  clearRecentOrders(): void {
    this.recentOrders = null;
  }

  clearRecentOrdersAndGroups(): void {
    this.clearRecentOrders();
    this.groupNames = null;
  }

  get associationSuggestions(): IVerknuepfungen[] {
    return this._associationSuggestions || undefined;
  }

  set associationSuggestions(associationSuggestions: IVerknuepfungen[]) {
    if (associationSuggestions) {
      this._associationSuggestions = associationSuggestions;
    } else {
      this.storage.clear(associationSuggestionsKey);
    }
  }

  get wasPushPdfPopupAlreadyShown(): boolean {
    return this._wasPushPdfPopupAlreadyShown;
  }

  set wasPushPdfPopupAlreadyShown(wasPushPdfPopupAlreadyShown: boolean) {
    this._wasPushPdfPopupAlreadyShown = wasPushPdfPopupAlreadyShown;
  }

  get wasPushSepaPopupAlreadyShown(): boolean {
    return this._wasPushSepaPopupAlreadyShown;
  }

  set wasPushSepaPopupAlreadyShown(wasPushSepaPopupAlreadyShown: boolean) {
    this._wasPushSepaPopupAlreadyShown = wasPushSepaPopupAlreadyShown;
  }

  observeAssociationSuggestions(): Observable<IVerknuepfungen[]> {
    return this.storage.observe(associationSuggestionsKey);
  }

  hasLoggedAssociationSuggestion(association: IVerknuepfungen): boolean {
    return this._loggedAssociationSuggestions?.includes(association.geschaeftsPartnerId);
  }

  addLoggedAssociationSuggestion(association: IVerknuepfungen): void {
    if (!this._loggedAssociationSuggestions) {
      this._loggedAssociationSuggestions = [];
    }
    this._loggedAssociationSuggestions = [...this._loggedAssociationSuggestions, association.geschaeftsPartnerId];
  }

  hasLoggedMissingAssociation(association: IVerknuepfungen): boolean {
    return this._loggedMissingAssociations?.includes(association.geschaeftsPartnerId);
  }

  addLoggedMissingAssociation(association: IVerknuepfungen): void {
    if (!this._loggedMissingAssociations) {
      this._loggedMissingAssociations = [];
    }
    this._loggedMissingAssociations = [...this._loggedMissingAssociations, association.geschaeftsPartnerId];
  }

  hasLoggedNewAssociation(association: IVerknuepfungen): boolean {
    return this._loggedNewAssociations?.includes(association.geschaeftsPartnerId);
  }

  addLoggedNewAssociation(association: IVerknuepfungen): void {
    if (!this._loggedNewAssociations) {
      this._loggedNewAssociations = [];
    }
    this._loggedNewAssociations = [...this._loggedNewAssociations, association.geschaeftsPartnerId];
  }

  get associationPopupClosed(): boolean {
    return this._associationPopupClosed;
  }

  set associationPopupClosed(associationPopupClosed: boolean) {
    this._associationPopupClosed = associationPopupClosed;
  }
}
