import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import {
  AccountSelectedEvent,
  AddressMultiProductCards,
  IAddressProducts,
  ILSystem,
  IMultiProductCard,
  NavigatingBackTo,
  Sparte,
} from '@mwe/models';
import { AddressLogic, LoggingService } from '@mwe/services';
import { createMultiProductCardsFromProducts, isArrayWithMinOneItem, isArrayWithOneItem, sortProductsByCategory } from '@mwe/utils';

@Component({
  selector: 'mwe-account-selection',
  templateUrl: './account-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSelectionComponent implements OnInit {
  categoryWhitelist = input<Sparte[]>([]);
  noAddressGroupsTitle = input<string>('');
  noAddressGroupsText = input<string>('');
  navigatingBackTo = input<NavigatingBackTo>();
  accountSelected = output<AccountSelectedEvent>();
  hasNoAddressProducts = output<void>();
  selectionSkipped = output<void>();
  state = signal<ServiceStateEnum>(ServiceStateEnum.INIT);
  addressOptions = signal<IAddressProducts[]>([]);
  addressMultiProductCards = signal<AddressMultiProductCards[]>([]);

  hasNoAddress = computed<boolean>(() => {
    return !isArrayWithMinOneItem(this.addressOptions());
  });

  private addressLogic = inject(AddressLogic);
  private loggingService = inject(LoggingService);

  async ngOnInit(): Promise<void> {
    await this.loadAddressOptions();

    this.removeUnwantedProducts();
    this.createMultiProductCards();
    this.checkIfCanSkipSelection();
  }

  onMultiCardClicked(item: AddressMultiProductCards, card: IMultiProductCard): void {
    const addressProducts = this.addressOptions().find(option => option.address.getHashCode() === item.address.getHashCode());
    addressProducts.products = addressProducts.products.filter(p => card.accountNumber === p.accountNumber);
    const systemId = addressProducts.products[0].systemId as ILSystem;
    const accountId = addressProducts.products[0].accountNumber;

    this.accountSelected.emit({ systemId, accountId, addressProducts });
  }

  private async loadAddressOptions(): Promise<void> {
    this.state.set(ServiceStateEnum.LOADING);

    try {
      this.addressOptions.set(await this.addressLogic.loadAddressesWithGroupNames());
      this.state.set(ServiceStateEnum.SUCCESS);
    } catch (ex) {
      this.state.set(ServiceStateEnum.ERROR);
      this.loggingService.logError(ex, 'error on loading possible addresses');
    }
  }

  private removeUnwantedProducts(): void {
    this.addressOptions().forEach(option => {
      option.products = option.products.filter(p => this.hasValidCategory(p.category));
    });

    const filteredOptions = this.addressOptions().filter(ao => isArrayWithMinOneItem(ao.products));
    this.addressOptions.set(filteredOptions);
  }

  private hasValidCategory(category: string): boolean {
    if (!isArrayWithMinOneItem(this.categoryWhitelist())) {
      return true;
    }

    return this.categoryWhitelist().some(c => c.toLowerCase() === category.toLowerCase());
  }

  private createMultiProductCards(): void {
    const cards = this.addressOptions().map(ao => {
      sortProductsByCategory(ao.products);
      return {
        address: ao.address,
        multiProductCards: createMultiProductCardsFromProducts(ao.products),
      };
    });
    this.addressMultiProductCards.set(cards);
  }

  private checkIfCanSkipSelection() {
    // check for one Vertragskonto, not one address
    const onlyOneAddress = isArrayWithOneItem(this.addressMultiProductCards());
    const onlyOneCard = isArrayWithOneItem(this.addressMultiProductCards()?.[0]?.multiProductCards);

    if (onlyOneAddress && onlyOneCard) {
      this.selectionSkipped.emit();
      this.onMultiCardClicked(this.addressMultiProductCards()[0], this.addressMultiProductCards()[0].multiProductCards[0]);
    }
  }
}
