@if (useWrapper()) {
  <div [ngClass]="wrapperClass" *ngIf="doShow()">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="cardContent"></ng-container>
}
<ng-template #cardContent>
  <mwe-basic-card [hasBackground]="true">
    <mwe-basic-card-title
      [cardTitle]="'summary.yourBenefits'"
      [iconClass]="'fa-gift'"
      [headingNodeType]="headlineService?.getHeadline(1) ?? 'h3'"
    ></mwe-basic-card-title>
    <div *ngIf="voucherInfoSignal()">
      <p class="text--big mb-0" data-test="products-summary-additionalDetails-voucher">
        <strong class="text-blue d-inline-block pb-2">
          <span [translateParams]="{ voucherCode: voucherInfoSignal().aktionscode }" translate="summary.voucherInfo.title"></span>
        </strong>
        <br />
        <span
          *ngIf="voucherStrom()?.length > 0"
          [innerHTML]="'&lt;strong>' + ('products.category.strom' | translate) + ':&lt;/strong> ' + voucherStrom() + '&lt;br/>'"
          class="d-inline-block pb-2"
        ></span>
        <span
          *ngIf="voucherGas()?.length > 0"
          [innerHTML]="'&lt;strong>' + ('products.category.gas' | translate) + ':&lt;/strong> ' + voucherGas() + '&lt;br/>'"
          class="d-inline-block pb-2"
        ></span>
      </p>
    </div>
  </mwe-basic-card>
</ng-template>
