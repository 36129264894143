import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { getRouteData } from '@mwe/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mwe-header',
  templateUrl: './header.component.html',
  styles: [],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isLongHeader = false;
  @Input() isOrangeHeader = false;
  @Input() hideHeader = false;
  @Input() title: string | undefined;
  @Input() withBottomMargin = true;
  @Input() wideHeader = false;
  routeData: any; // TODO

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.routeData = getRouteData(this.activatedRoute) || {};
    this.updateClasses();
    this.setPageTitle();
    const routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routeData = getRouteData(this.activatedRoute) || {};
        this.updateClasses();
        this.setPageTitle();
      }
    });
    this.subscriptions.add(routerSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateClasses(): void {
    this.isLongHeader = this.routeData.isLongHeader;
    this.isOrangeHeader = this.routeData.isOrangeHeader;
    this.hideHeader = this.routeData.hideHeader;
  }

  setPageTitle(): void {
    if (this.routeData) {
      this.title = this.routeData.pageTitle;
    }
  }
}
