import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  getTariffChangeAvailability,
  HTTPError,
  HTTPResponse,
  IDefaultProdukt,
  IOrderDetailsInfo,
  IProduct,
  newTariffChangeDetailsInfo,
  parseILCustomerAddress,
  Sparte,
  TariffChangeAvailabilities,
  TariffChangeDTO,
  TariffChangeResponse,
  TarifwechselProdukte,
} from '@mwe/models';
import { getInvoiceAddress, getInvoiceEmail, isArrayWithMinOneItem, parseInvoiceAccount } from '@mwe/utils';
import { TariffChangeStateService } from './tariff-change-state.service';
import { AccountLogic } from '../account/account.logic';
import { EnvironmentService } from '../environment/environment.service';
import { VoucherService } from '../voucher/voucher.service';
import { ClearingAccountLogic } from '../invoices/clearing-account/clearing-account.logic';
import { lastValueFrom } from 'rxjs';
import config from '../config/products.config.json';
import { ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';

@Injectable({
  providedIn: 'root',
})
export class TariffChangeService extends VoucherService {
  private config: any;
  private resourceUrl: string;

  constructor(
    protected override http: HttpClient,
    protected override accountLogic: AccountLogic,
    private tariffChangeStateService: TariffChangeStateService,
    private clearingAccountLogic: ClearingAccountLogic,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) protected override environmentService: EnvironmentService,
  ) {
    super(http, accountLogic, environmentService);
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/tariffChange/';
    this.config = config;
  }

  async getTariffChangeProducts(accounts: string[]): Promise<TariffChangeAvailabilities> {
    const tariffChangeAvailability: TariffChangeAvailabilities = { availabilities: [] };
    for (const element of accounts) {
      const _params = new HttpParams().set('vetragskontoId', element);
      const res = await lastValueFrom(
        this.http.get<{ produkte: TarifwechselProdukte[] }>(this.getRequestUrl('availability'), { params: _params }),
      );
      if (res?.produkte) {
        res.produkte.forEach(p => {
          let tcAvalibility = getTariffChangeAvailability(tariffChangeAvailability, element, p);
          if (!tcAvalibility) {
            tcAvalibility = {
              anlage: p.anlage,
              vertagskontoId: element,
              sparte: p.sparte?.toLowerCase() as Sparte,
              kundenTyp: p.kundenTyp,
              tariffs: [],
            };
            tariffChangeAvailability.availabilities.push(tcAvalibility);
          }
          tcAvalibility.tariffs.push({ tariff: p.tarif, condition: p.bedingungen });
        });
      }
    }
    return tariffChangeAvailability;
  }

  async loadDetailsData(products: IProduct[], stateOrderDetails: IOrderDetailsInfo[]): Promise<IOrderDetailsInfo[]> {
    if (!isArrayWithMinOneItem(products)) {
      return null;
    }

    const newDetails: IOrderDetailsInfo[] = [];
    for (const element of products) {
      const product = element;

      const orderDetail = stateOrderDetails?.length > 0 ? stateOrderDetails : null;
      if (!orderDetail) {
        const orderDetailData = newTariffChangeDetailsInfo(product.category, product.customerNumber, product.accountNumber);

        if (!product.accountNumber || product.accountNumber === '') {
          newDetails.push(orderDetailData);
          continue;
        }

        const alreadyLoadedDetails = this.getAlreadyLoadedDetails(newDetails, product);
        if (alreadyLoadedDetails) {
          // already in newDetails
          continue;
        }

        try {
          const pd = await this.clearingAccountLogic.getAccountInfo(product.accountNumber, product.businessPartnerNumber, product.systemId);
          orderDetailData.verrechnungskonto = pd.verrechnungskonto;
          orderDetailData.invoiceAddress = parseILCustomerAddress(
            getInvoiceAddress(orderDetailData.verrechnungskonto.geschaeftspartnerBeziehungen),
          );
          const invoiceEmail = getInvoiceEmail(orderDetailData.verrechnungskonto);
          orderDetailData.eRechnungInfo = {
            isERechnungsEmpfaenger: !!invoiceEmail,
            rechnungEmail: invoiceEmail,
          };
          parseInvoiceAccount(orderDetailData);
          newDetails.push(orderDetailData);
        } catch {
          throw new Error('general_error');
        }
      } else {
        const copy = JSON.parse(JSON.stringify(orderDetail));
        newDetails.push(copy);
      }
    }

    return newDetails;
  }

  private getAlreadyLoadedDetails(newDetails: IOrderDetailsInfo[], product: IProduct) {
    return newDetails.find(
      item =>
        item.customerNumber === product.customerNumber &&
        item.accountNumber === product.accountNumber &&
        item.category === product.category,
    );
  }

  async confirm(dataDTO: TariffChangeDTO, isSecureOffer: boolean): Promise<HTTPResponse<TariffChangeResponse>> {
    try {
      let params = new HttpParams();
      // do not send false as value! for tariff-changes send no param
      if (isSecureOffer) {
        params = params.set('offer', true);
      }

      const res = await lastValueFrom(this.http.post(this.getRequestUrl('confirm'), dataDTO, { params, observe: 'response' }));
      return new HTTPResponse<TariffChangeResponse>(res.status + '', 'IL', res.body as TariffChangeResponse);
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, dataDTO: TariffChangeDTO): Promise<HTTPResponse<TariffChangeResponse>> {
    try {
      const res = await lastValueFrom(
        this.http.put(this.getRequestUrl('retry') + '?submitId=' + submitId, dataDTO, {
          observe: 'response',
        }),
      );
      return new HTTPResponse<TariffChangeResponse>(res.status + '', 'IL', res.body as TariffChangeResponse);
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<TariffChangeResponse> {
    return lastValueFrom(this.http.get<TariffChangeResponse>(this.getRequestUrl('confirmStatus') + '?submitId=' + submitId));
  }

  landingPageConfirm(encryptedData: string, checkboxSB: string): Promise<{ message: string }> {
    return lastValueFrom(
      this.http.post<{ message: string }>(
        this.environmentService.getApiUrl() + 'api/public/tariffChange/confirm?sb=' + checkboxSB,
        encryptedData,
      ),
    );
  }

  setLastOrderId(lastOrderId: string): void {
    this.tariffChangeStateService.lastOrderId = lastOrderId;
  }

  getOrderStatusDetails(orderId: string): Promise<any> {
    return lastValueFrom(this.http.get(this.resourceUrl + 'orderStatusDetails/' + orderId));
  }

  private getRequestUrl(path: string): string {
    return this.resourceUrl + path;
  }

  getConfigDefaultTariffs(): IDefaultProdukt {
    const customerType = this.environmentService.getPortalCustomerType();
    return {
      strom: {
        tarifBeschreibung: this.config.tariffs.strom[customerType].name,
        tarifKey: this.config.tariffs.strom[customerType].shortcut,
      },
      gas: {
        tarifBeschreibung: this.config.tariffs.gas[customerType].name,
        tarifKey: this.config.tariffs.gas[customerType].shortcut,
      },
    };
  }
}
