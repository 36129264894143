export const PRIVATE_ROUTE = 'privat';
export const BUSINESS_ROUTE = 'business';
export const LANDING_PAGE_ROUTE = '';
export const LANDING_PAGE_EMAIL_INVITATION_ROUTE = 'einladung';
// - Abloese Email-Verifizierung
export const LANDING_PAGE_EMAIL_VERIFICATION_ROUTE = 'e-mail-adresse-bestaetigen';
// - Abloese PDFBILL
export const LANDING_PAGE_PDF_BILL_ACTIVATION_ROUTE = 'pdf-rechnung-bestaetigen';
// - Abloese SEPACONFIRM
export const LANDING_PAGE_SEPA_CONFIRM_ROUTE = 'sepa-lastschrift-mandat-bestaetigen';
// - Abloese B2C
export const LANDING_PAGE_B2C_REPLACEMENT_ROUTE = 'wir-sind-umgezogen';
// - Tarifwechsel Campaign (Optima, Mega) 2022
export const LANDING_PAGE_TARIFF_CHANGE_CONFIRM_ROUTE = 'tarifwechsel-bestaetigen';
// - VKI Maßnahmen
export const LANDING_PAGE_VKI_OFFER_ROUTE = 'ausgleichszahlung-vki';
export const LANDING_PAGE_VKI_OFFER_CUSTOMER_DATA_ROUTE = 'kundendaten';
export const LANDING_PAGE_VKI_OFFER_OFFER_ROUTE = 'angebot';
export const LANDING_PAGE_VKI_OFFER_SUMMARY_ROUTE = 'zusammenfassung';
export const LANDING_PAGE_VKI_OFFER_CONFIRMATION_ROUTE = 'bestaetigung';
export const LANDING_PAGE_VKI_END_ROUTE = 'aktion-abgelaufen';
// - Buendelprodukte bestellen
export const LANDING_PAGE_PARTNER_PRODUCT_ORDER_ROUTE = 'buendelprodukt-bestellen';

// kollaborieren (internal forward)
export const COLLABORATION_ROUTE = 'kollaborieren';

// common navigate to url
export const NAVIGATE_TO_ROUTE = 'navigateto';

// gateway auth
export const GATEWAY_ROUTE = 'gateway';
export const GATEWAY_AUTH_ROUTE = 'auth';
export const REQUEST_APPROVAL_CONDITIONS_OF_USE = 'abfrage-nutzungsbedingungen';

// meine-produkte
export const PRODUCTS_ROUTE = 'meine-produkte';
export const PRODUCTS_DETAILS_ROUTE = 'details';
export const PRODUCTS_BILLINFO_ROUTE = 'kosten';

// meine-produkte - strom/gas
export const PRODUCTS_CONSUMPTION = 'verbrauch';

// profil
export const PROFILE_ROUTE = 'profil';
export const PROFILE_DSGVO_SETTINGS_ROUTE = 'datenschutz-einstellungen';
export const PROFILE_NEWSLETTER_ROUTE = 'newsletter';
export const PROFILE_DSGVO_CHECK_ROUTE = 'einwilligung';

// produkte-hinzufuegen
export const ADD_PRODUCTS_ROUTE = 'produkte-hinzufuegen';
export const ADD_PRODUCTS_SEARCH_ROUTE = 'suche';
export const ADD_PRODUCTS_CHOOSE_ROUTE = 'auswahl';
export const ADD_PRODUCTS_NO_BIRTHDATE_ROUTE = 'bestaetigung';

// umzug-bekanntgeben
export const RELOCATION_ROUTE = 'umzug';
export const RELOCATION_CURRENT_ADDRESS_ROUTE = 'aktuelle-adresse';
export const RELOCATION_NEW_ADDRESS_ROUTE = 'neue-adresse';
export const RELOCATION_PRODUCTS_ROUTE = 'produktverfuegbarkeiten';
export const RELOCATION_TIMES_ROUTE = 'umzugsdaten';
export const RELOCATION_DOCUMENTS_ROUTE = 'unterlagen';
export const RELOCATION_SUMMARY_ROUTE = 'zusammenfassung';
export const RELOCATION_CONFIRMATION_ROUTE = 'bestaetigung';
export const RELOCATION_ERROR_ROUTE = 'fehler';
export const RELOCATION_STATUS_ROUTE = 'status';

// kunde-werden
export const NEW_CLIENT_ROUTE = 'kunde-werden';
export const NEW_CLIENT_NEW_ADDRESS_ROUTE = 'neue-adresse';
export const NEW_CLIENT_PRODUCTS_ROUTE = 'produktverfuegbarkeiten';
export const NEW_CLIENT_PRODUCT_INTEREST_ROUTE = 'interesse';
export const NEW_CLIENT_PRODUCTS_SELECTION_ROUTE = 'produktauswahl';
export const NEW_CLIENT_CONTRACT_DETAILS_ROUTE = 'vertragsdaten';
export const NEW_CLIENT_PERSONAL_DETAILS_ROUTE = 'persoenliche-informationen';
export const NEW_CLIENT_INVOICE_INFORMATION_ROUTE = 'rechnungsinformationen';
export const NEW_CLIENT_SUMMARY_ROUTE = 'zusammenfassung';
export const NEW_CLIENT_CONFIRMATION_ROUTE = 'bestaetigung';
export const NEW_CLIENT_CONFIRMATION_INFO_ROUTE = 'naechste-schritte';
export const NEW_CLIENT_STATUS_ROUTE = 'status';

// kunde-werden - Internet, VOIP, IPTV
export const SUPERSCHNELL_ROUTE = 'superschnell-bestellen';

// vorteilswelt
export const GOODIE_ROUTE = 'meine-vorteile';

// nutzungsbedingungen
export const TERMS_OF_USE_ROUTE = 'nutzungsbedingungen';

// tarif-wechsel
export const TARIFF_CHANGE_ROUTE = 'tarif-wechseln';
export const TARIFF_CHANGE_ADDRESS_SELECTION_ROUTE = 'aktuelle-adresse';
export const TARIFF_CHANGE_PRODUCTS_ROUTE = 'produktverfuegbarkeiten';
export const TARIFF_CHANGE_PRODUCTS_SELECTION_ROUTE = 'produktauswahl';
export const TARIFF_CHANGE_INVOICE_INFORMATION_ROUTE = 'rechnungsinformationen';
export const TARIFF_CHANGE_SUMMARY_ROUTE = 'zusammenfassung';
export const TARIFF_CHANGE_CONFIRMATION_ROUTE = 'bestaetigung';
export const TARIFF_CHANGE_STATUS_ROUTE = 'status';
// angebot sichern - kind of tariff-change but with preselected values
export const SECURE_OFFER_ROUTE = 'angebot-sichern';
export const SECURE_OFFER_ADDRESS_SELECTION_ROUTE = 'aktuelle-adresse';
export const SECURE_OFFER_DETAILS_ROUTE = 'details';
export const SECURE_OFFER_SUMMARY_ROUTE = 'zusammenfassung';
export const SECURE_OFFER_CONFIRMATION_ROUTE = 'bestaetigung';
export const SECURE_OFFER_STATUS_ROUTE = 'status';

// invoice change
export const INVOICE_CHANGE_ADDRESS_SELECTION_ROUTE = 'aktuelle-adresse';
export const INVOICE_CHANGE_CONFIRMATION_ROUTE = 'bestaetigung';
export const INVOICE_CHANGE_STATUS_ROUTE = 'status';

// - bankdaten wechseln
export const BANK_ACCOUNT_CHANGE_ROUTE = 'bankverbindung-aendern';
export const BANK_ACCOUNT_CHANGE_FORM_ROUTE = 'neue-bankverbindung';

// - pdf-rechnung aendern
export const PDF_INVOICE_CHANGE_ROUTE = 'pdf-rechnung-aendern';
export const PDF_INVOICE_CHANGE_FORM_ROUTE = 'neue-email';

// - rechnungsadresse aendern
export const INVOICE_ADDRESS_CHANGE_ROUTE = 'rechnungsadresse-aendern';
export const INVOICE_ADDRESS_CHANGE_FORM_ROUTE = 'neue-rechnungsadresse';

// Kundendaten ändern
export const CUSTOMER_DATA_CHANGE_ROUTE = 'kundendaten-aendern';
export const CUSTOMER_DATA_CHANGE_SELECTION_ROUTE = 'kundennummer-auswaehlen';
export const CUSTOMER_DATA_CHANGE_FORM_ROUTE = 'neue-kundendaten';
export const CUSTOMER_DATA_CHANGE_CONFIRMATION_ROUTE = 'bestaetigung';
export const CUSTOMER_DATA_CHANGE_STATUS_ROUTE = 'status';

// Auszug
export const MOVE_OUT_ROUTE = 'abmeldung';
export const MOVE_OUT_ADDRESS_SELECTION_ROUTE = 'aktuelle-adresse';
export const MOVE_OUT_ASK_IF_RELOCATION_ROUTE = 'umzug-oder-abmeldung';
export const MOVE_OUT_CURRENT_PRODUCTS_ROUTE = 'aktuelle-produkte';
export const MOVE_OUT_FINAL_INVOICE_ROUTE = 'rechnungsinformationen';
export const MOVE_OUT_SUMMARY_ROUTE = 'zusammenfassung';
export const MOVE_OUT_CONFIRMATION_ROUTE = 'bestaetigung';
export const MOVE_OUT_CONFIRMATION_INFO_ROUTE = 'naechste-schritte';
export const MOVE_OUT_STATUS_ROUTE = 'status';

// Zählerstand bekanntgeben
export const METER_READING_ROUTE = 'zaehlerstand-bekanntgeben';

// voucher - redeem
export const VOUCHER_REDEEM_ROUTE = 'energiegutschein-einloesen';
export const VOUCHER_REDEEM_FORM_ROUTE = 'gutschein-eingeben';
export const VOUCHER_REDEEM_CONFIRMATION_ROUTE = 'bestaetigung';
export const VOUCHER_REDEEM_STATUS_ROUTE = 'status';

// interaktive rechnung
export const INTERACTIVE_INVOICE_DEMO_ROUTE = 'interaktive-rechnung';
export const INTERACTIVE_INVOICE_ROUTE = 'rechnungen';
export const INTERACTIVE_INVOICE_DETAILS_ROUTE = 'interaktive-rechnung';
export const INTERACTIVE_INVOICE_DETAILS_PRODUCT_ROUTE = 'details';

// partial payments
export const INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE = 'teilbetraege';
export const INVOICE_PARTIALPAYMENT_SELECTION_ROUTE = 'vertragskonto-auswaehlen';
export const INVOICE_PARTIALPAYMENT_CHANGE_RATE_ROUTE = 'teilbetrag-aendern';
export const INVOICE_PARTIALPAYMENT_NEW_RATE_ROUTE = 'neuer-teilbetrag';
export const INVOICE_PARTIALPAYMENT_CONFIRM_RATE_ROUTE = 'bestaetigung';
export const INVOICE_PARTIALPAYMENT_CHANGE_STATUS_ROUTE = 'status';

// active payment plan
export const ACTIVE_PAYMENT_PLAN_ROUTE = 'ratenvereinbarung';

// Zahlungsprobleme loesen
export const FIND_PAYMENT_SOLUTION_ROUTE = 'loesung-finden';
export const FIND_PAYMENT_SOLUTION_PAYMENT_EXTENSION_ROUTE = 'stundung';
export const FIND_PAYMENT_SOLUTION_PAYMENT_EXTENSION_CONFIRMATION_ROUTE = 'stundung-bestaetigung';
export const FIND_PAYMENT_SOLUTION_PAYMENT_PLAN_ROUTE = 'ratenplan';
export const FIND_PAYMENT_SOLUTION_PAYMENT_PLAN_CONFIRMATION_ROUTE = 'ratenplan-bestaetigung';
export const FIND_PAYMENT_SOLUTION_CUSTOMER_SUPPORT_ROUTE = 'kontakt';
export const FIND_PAYMENT_SOLUTION_CUSTOMER_SUPPORT_CONFIRMATION_ROUTE = 'rueckruf-bestaetigen';
export const FIND_PAYMENT_SOLUTION_STATUS_ROUTE = 'status';
// faellige Zahlungen
export const UNPAID_INVOICES_ROUTE = 'faellige-zahlung';
export const UNPAID_INVOICES_SELECTION_ROUTE = 'vertragskonto-auswaehlen';
export const UNPAID_INVOICES_PAYMENT_OPTIONS_ROUTE = 'zahlungsoptionen';

// MWE-Wartungsseite
export const MAINTENANCE_ROUTE = 'wir-erweitern-unser-angebot';
export const FEATURE_TOGGLED_OUT_ROUTE = 'feature-aktuell-deaktiviert';

// Ladekarten
export const CHARGING_CARD_ORDER_ROUTE = 'tankekarte-bestellen';
export const CHARGING_CARD_ORDER_TARIFF_SELECTION_ROUTE = 'produktauswahl';
export const CHARGING_CARD_ORDER_PERSONAL_DATA_ROUTE = 'persoenliche-informationen';
export const CHARGING_CARD_ORDER_INVOICE_ROUTE = 'rechnungsinformation';
export const CHARGING_CARD_ORDER_SUMMARY_ROUTE = 'zusammenfassung';
export const CHARGING_CARD_ORDER_CONFIRMATION_ROUTE = 'bestaetigung';
export const CHARGING_CARD_ORDER_STATUS_ROUTE = 'status';

// Kontoinformationen
export const CLEARING_ACCOUNT_INFORMATION_REQUEST_ROUTE = 'kontoinformation-anfordern';
export const CLEARING_ACCOUNT_INFORMATION_REQUEST_ACCOUNT_SELECTION_ROUTE = 'vertragskonto-auswaehlen';
export const CLEARING_ACCOUNT_INFORMATION_REQUEST_INFORMATION_ROUTE = 'kontoinformationen';
export const CLEARING_ACCOUNT_INFORMATION_REQUEST_OVERVIEW_ROUTE = 'empfaenger';
export const CLEARING_ACCOUNT_INFORMATION_REQUEST_CONFIRMATION_ROUTE = 'bestaetigung';
export const CLEARING_ACCOUNT_INFORMATION_REQUEST_STATUS_ROUTE = 'status';

// MAC Angebot bestätigen
export const OFFER_CONFIRMATION_ROUTE = 'angebot-annehmen';
export const OFFER_CONFIRMATION_CHECK_ROUTE = 'angebot-pruefen';
export const OFFER_CONFIRMATION_CONFIRMATION_ROUTE = 'bestaetigung';
