<mwe-custom-popup (dismissClicked)="closePopup()">
  <h4 mweEnvTranslate="products.orderProducts.popup.title"></h4>
  <p translate="products.orderProducts.popup.description"></p>

  <div class="row mb-3 mt-4 g-4">
    <div class="col-lg-6">
      <mwe-clickable-products-card
        (cardClicked)="startNewClientProcess()"
        [categories]="[Sparte.Strom, Sparte.Gas, Sparte.Fernwaerme]"
        [hasAutoFocus]="true"
        dataTest="order-products-new-client"
        ariaTitle="newClient.title"
      >
        <p class="text--legal mb-1">
          <span class="fw-semibold mb-0" translate="products.orderProducts.popup.newClient.stromGas"></span> {{ stromCompany() }}
        </p>
        <p class="text--legal mb-0">
          <span class="fw-semibold" translate="products.orderProducts.popup.newClient.waerme"></span> {{ waermeCompany() }}
        </p>
      </mwe-clickable-products-card>
    </div>

    <div class="col-lg-6">
      <mwe-clickable-products-card
        (cardClicked)="orderChargingCard()"
        [categories]="[Sparte.Emobility]"
        dataTest="order-products-emobility"
        ariaTitle="charging-card.order.entry"
      >
        <p class="text--legal mb-0">{{ emobilityCompany() }}</p>
      </mwe-clickable-products-card>
    </div>

    @if (hasAllProducts()) {
      <div class="col-lg-6">
        <mwe-clickable-products-card
          (cardClicked)="startSuperSchnellProcess()"
          [categories]="[Sparte.Internet, Sparte.Iptv]"
          dataTest="order-products-super-schnell"
          ariaTitle="newClient.internet.entry"
        >
          <p class="text--legal mb-0">{{ internetCompany() }}</p>
        </mwe-clickable-products-card>
      </div>
    }
  </div>
</mwe-custom-popup>
