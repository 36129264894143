<mwe-alert
  *ngIf="state === serviceStateEnum.ERROR || state === serviceStateEnum.INFO"
  [type]="state === serviceStateEnum.INFO ? 'info' : 'danger'"
  [titleKey]="errorTitleKey"
  [messageKey]="errorMsgKey"
  [placeholderMap]="alertPlaceholders"
></mwe-alert>
<div class="sr-only" aria-live="assertive">
  <ng-container *ngIf="state === serviceStateEnum.LOADING" [translate]="workInProgressMsgKey"></ng-container>
  <ng-container *ngIf="state === serviceStateEnum.SUCCESS" [translate]="successMsgKey"></ng-container>
</div>
<mwe-basic-card>
  <div *ngIf="state === serviceStateEnum.LOADING">
    <h1 data-test="mwe-confirmation-view-header-loading" class="h6 text-center" [translate]="workInProgressMsgKey"></h1>
    <br />
    <dotlottie-player
      class="mx-auto mb-5 d-block"
      aria-hidden="true"
      autoplay
      loop
      playMode="normal"
      id="dot-lottie-confirmation-loading"
      [mweDotLottiePlayerSrc]="getImagePath() + 'lottie/windmills.lottie'"
      playerId="dot-lottie-confirmation-loading"
      style="max-width: 214px"
    >
    </dotlottie-player>
  </div>
  <div *ngIf="state === serviceStateEnum.SUCCESS">
    <h1
      *ngIf="!!successMsgKey"
      data-test="mwe-confirmation-view-header-success"
      class="h6 text-center"
      [translate]="successMsgKey"
      [translateParams]="generalTranslateValues"
    ></h1>
    <div class="row mb-4" *ngIf="isSuccessImageVisible">
      <div class="col my-auto text-center">
        <img [src]="successImageSrc" alt="success-animation" class="animation img-fluid" alt="" *ngIf="!useStaticSuccessImage" />
        <img src="/assets/img/success.png" style="height: 200px" class="mt-3" *ngIf="useStaticSuccessImage" />
      </div>
    </div>
    <h1
      *ngIf="!!bottomMsgKey"
      data-test="mwe-confirmation-view-header-success"
      class="h6 text-center"
      [translate]="bottomMsgKey"
      [translateParams]="generalTranslateValues"
    ></h1>
  </div>
  <div class="steps is-vertical" role="list">
    <mwe-bullet
      *ngFor="let bulletId of bulletIds; let last = last"
      [bulletId]="bulletId"
      [bulletLabelMap]="bulletLabelMap"
      [bulletStateMap]="bulletStateMap"
      [bulletTitleMap]="bulletTitleMap"
      [bulletTranslateMap]="bulletTranslateMap"
      [bulletStateClassMap]="bulletStateClassMap"
      [bulletStateIconMap]="bulletStateIconMap"
      [hasVerticalLine]="!last"
    >
    </mwe-bullet>
    <ng-content> </ng-content>
  </div>
</mwe-basic-card>
