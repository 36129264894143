<span class="badge {{ sizingTheme().badgeClass }} {{ colorScheme().backgroundClass }}">
  <span [ngClass]="size() === 'micro' ? 'sr-only' : ''" class="d-flex">
    @if (iconClass()) {
      <i class="{{ iconClass() }} {{ sizingTheme().iconMarginClass }} {{ colorScheme().iconColorClass }}"></i>
    }
    @if (text()) {
      <span [ngClass]="colorScheme().textColorClass">
        {{ text() | translate }}
      </span>
    } @else {
      <span [ngClass]="colorScheme().textColorClass">
        <ng-content></ng-content>
      </span>
    }
  </span>
</span>
