import { Component, input, output } from '@angular/core';

@Component({
  selector: 'mwe-card-clickable',
  templateUrl: './card-clickable.component.html',
})
export class CardClickableComponent {
  cardTitle = input.required<string>();
  cardIcon = input.required<string>();
  isLoading = input<boolean>(false);
  testId = input<string>('');
  cardClicked = output();

  onCardClick() {
    if (this.isLoading()) {
      return;
    }

    this.cardClicked.emit();
  }
}
