import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FrontpageAlertData } from '@mwe/models';
import { IkpEnvironmentService } from '../environment/ikp-environment.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private envService = inject(IkpEnvironmentService);
  protected httpClient = inject(HttpClient);
  protected apiUrl = this.envService.getApiUrl();
  protected staticContentUrl = this.envService.getStaticContentUrl();

  getTemporaryAlertData(): Observable<FrontpageAlertData> {
    return this.httpClient.get<FrontpageAlertData>(`${this.staticContentUrl}/frontpage-alert.json`);
  }
}
