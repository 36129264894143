import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { IProduct, parseProduct, parseProducts, Product, Address, parseAddress } from '@mwe/models';

const productKey = 'productDetails_product';
const groupProductsKey = 'productDetails_groupProducts';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsStateService {
  keepStorageOnce = false;

  @SessionStorage(productKey)
  private _product: IProduct;

  @SessionStorage(groupProductsKey)
  private _groupProducts: Product[];

  constructor(private storage: SessionStorageService) {}

  clearData(): void {
    if (this.keepStorageOnce) {
      this.keepStorageOnce = false;
      return;
    }

    this.storage.clear(productKey);
    this.storage.clear(groupProductsKey);
  }

  get product(): IProduct {
    if (!this._product) {
      return null;
    }

    return parseProduct(this._product);
  }

  set product(product: IProduct) {
    this._product = product;
  }

  get groupProducts(): Product[] {
    return parseProducts(this._groupProducts);
  }

  set groupProducts(groupProducts: Product[]) {
    this._groupProducts = groupProducts;
  }

  get address(): Address {
    return parseAddress(this.groupProducts[0].address);
  }
}
