<div class="d-flex expander-item align-items-start">
  @if (hasCheckbox()) {
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        [ngClass]="{ 'is-invalid': isErrorVisible() }"
        type="checkbox"
        [required]="isCheckboxMandatory()"
        [checked]="item().checkbox.initialValue"
        [attr.id]="item().id"
        [attr.data-test]="'approval-form-item-cb-' + item().id"
        [attr.aria-describedby]="labelId()"
        [attr.aria-invalid]="isErrorVisible()"
        (change)="onCheckBoxChanged($event.target.checked)"
      />
    </div>
  }
  <div class="flex-fill">
    @if (hasCheckbox()) {
      <label
        [for]="item().id"
        [attr.id]="labelId()"
        [attr.data-test]="'approval-form-item-label-' + item().id"
        [translate]="item().checkbox.label"
      ></label>
    }

    @if (hasBulletPoints()) {
      <ul class="mb-0">
        @for (point of item().bulletPoints; track $index) {
          <li>
            <p>
              <a [href]="point.link" [translate]="point.label" [translateParams]="point.labelParams" target="_blank"></a>
            </p>
          </li>
        }
      </ul>
    }

    @if (hasText()) {
      <p [id]="'approval-form-item-text-' + item().id" [innerHTML]="item().text | translate: item().textParams | safeHtml"></p>
    }

    @if (isErrorVisible()) {
      <span [translate]="item().checkbox.error" id="expander-form-checkbox-error" class="invalid-feedback d-block"></span>
    }
  </div>
</div>
