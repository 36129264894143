import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiPipesModule } from '@mwe/ui/pipes';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponentsModule } from './loading-module/loading.module';
import { AddressInputComponent } from './address-input/address-input.component';
import { InternationalAddressInputComponent } from './address-input/international-address-input.component';
import { ManualAddressInputComponent } from './address-input/manual-address-input.component';
import { AddressSelectionComponent } from './address/address-selection/address-selection.component';
import { AddressWithSelectionDropdownComponent } from './address/address-with-selection-dropdown/address-with-selection-dropdown.component';
import { AddressComponent } from './address/address.component';
import { AlertComponent } from './alert/alert.component';
import { SpecialAlertComponent } from './alert/special-alert.component';
import { AssociationSuggestionNotificationComponent } from './association-suggestion-notification/association-suggestion-notification.component';
import { NavigateToComponent } from './auth/navigateto.component';
import { CollaborationEntryComponent } from './auth/collaboration-entry.component';
import { BasicBadgeComponent } from './badge/basic-badge/basic-badge.component';
import { LinkBadgeComponent } from './badge/link-badge/link-badge.component';
import { PillBadgeComponent } from './badge/pill-badge/pill-badge.component';
import { BadgeComponent } from './badge/badge/badge.component';
import { BankDetailsComponent } from './bank/bank-details/bank-details.component';
import { NewBankAccountInfoTextsComponent } from './bank/new-bank-account-info-texts/new-bank-account-info-texts.component';
import { SepaMandatListingComponent } from './bank/sepa-mandat-listing/sepa-mandat-listing.component';
import { BannerPopupComponent } from './banner-popup/banner-popup.component';
import { ButtonBackComponent } from './buttons/back/button-back.component';
import { ButtonLinkComponent } from './buttons/button-link/button-link.component';
import { ButtonComponent } from './buttons/button/button.component';
import { TabButtonsComponent } from './buttons/tab-buttons/tab-buttons.component';
import { CampaignPopupComponent } from './campaign/campaign-popup.component';
import { BasicCardTitleComponent } from './cards/basic-card/basic-card-title/basic-card-title.component';
import { BasicCardComponent } from './cards/basic-card/basic-card.component';
import { BasicCardBodylessComponent } from './cards/basic-card/basic-card-bodyless.component';
import { CardWithIconAndButtonComponent } from './cards/card-with-icon-and-button/card-with-icon-and-button.component';
import { CategoryDropdownFilterComponent } from './category-dropdown-filter/category-dropdown-filter.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { CheckboxExpanderFormComponent } from './checkbox/checkbox-expander-form.component';
import { ChoicesComponent } from './choices/choices.component';
import { CollapseComponent } from './collapse/collapse.component';
import { BulletComponent } from './confirmation/bullet.component';
import { BulletConfirmationViewComponent } from './confirmation/bullet-confirmation-view.component';
import { CookieComponent } from './cookie/cookie.component';
import { CustomerNumberInfoTooltipCardComponent } from './customer-number-info-tooltip-card/customer-number-info-tooltip-card.component';
import { MweDatepickerComponent } from './datepicker/datepicker.component';
import { DirectivesModule } from './directives/directives.module';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { ExpanderComponent } from './expander/expander.component';
import { ExpanderBasicComponent } from './expander-basic/expander-basic.component';
import { FormComponent } from './form/form.component';
import { InputKeyDirective } from './form/form.directive';
import { CheckboxInputComponent } from './form/inputs/checkbox-input/checkbox-input.component';
import { SelectableCardCheckboxInputComponent } from './form/inputs/selectable-card-checkbox-input/selectable-card-checkbox-input.component';
import { FormSelectComponent } from './form/inputs/form-select/form-select.component';
import { BirthdateInputComponent } from './form/inputs/birthdate-input/birthdate-input.component';
import { EmailConfirmationComponent } from './form/inputs/email-confirmation/email-confirmation.component';
import { FormInputErrorComponent } from './form/inputs/form-input/form-input-error.component';
import { FormInputHelpInfoComponent } from './form/inputs/form-input/form-input-help-info.component';
import { FormInputLoadingSpinnerComponent } from './form/inputs/form-input/form-input-loading-spinner/form-input-loading-spinner.component';
import { FormInputComponent } from './form/inputs/form-input/form-input.component';
import { SelectFirstInvalidInputDirective } from './form/inputs/select-first-invalid-input/select-first-invalid-input.directive';
import { TypeaheadInputComponent } from './form/inputs/typeahead-input/typeahead-input.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { IBANFormComponent } from './iban-form/iban-form.component';
import { IdCardComponent } from './id-card/id-card.component';
import { InfoTextCardComponent } from './info-text/info-text-card/info-text-card.component';
import { InfoTextIconComponent } from './info-text/info-text-icon.component';
import { InfoTextPopupComponent } from './info-text/info-text-popup.component';
import { InfoTextComponent } from './info-text/info-text.component';
import { InvoiceDataChangeConfirmationComponent } from './invoice/invoice-data-change-confirmation/invoice-data-change-confirmation.component';
import { PushPdfInfoOnConfirmationComponent } from './marketing/push-pdf-info-on-confirmation/push-pdf-info-on-confirmation.component';
import { InteractiveInvoiceAlertComponent } from './invoice/interactive-invoice-alert/interactive-invoice-alert.component';
import { InvoiceInformationComponent } from './invoice/invoice-information/invoice-information.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ImpersonateWarningComponent } from './layout/impersonate-warning/impersonate-warning.component';
import { NavbarFooterComponent } from './layout/navbar/navbar-footer.component';
import { NavbarHeaderComponent } from './layout/navbar/navbar-header.component';
import { PageRibbonComponent } from './layout/profiles/page-ribbon.component';
import { LinkComponent } from './link/link.component';
import { DefaultListGroupComponent } from './list/default-list-group/default-list-group.component';
import { GreenCheckListComponent } from './list/green-check-list/green-check-list.component';
import { LinkListGroupComponent } from './list/link-list-group/link-list-group.component';
import { ListGroupDropdownComponent } from './list/list-group-dropdown/list-group-dropdown.component';
import { MaintenanceTemplateComponent } from './maintenance-template/maintenance-template.component';
import { ChevronNavComponent } from './navigation/chevron-nav/chevron-nav.component';
import { NoAssociationsFoundWarningComponent } from './no-associations-found-warning/no-associations-found-warning.component';
import { PersonalDetailsFormComponent } from './personal-details-form/personal-details-form.component';
import { PopupComponent } from './popup/popup.component';
import { CardWithProductListComponent } from './product-card/card-with-product-list/card-with-product-list.component';
import { VertragsKontoProductListComponent } from './product-card/card-with-product-list/vertrags-konto-product-list.component';
import { MultiProductCardContainerComponent } from './product-card/multi-product-card/multi-product-card-container.component';
import { MultiProductCardComponent } from './product-card/multi-product-card/multi-product-card.component';
import { ProductCardSliderComponent } from './product-card/product-card-slider/product-card-slider.component';
import { ProductCardTariffListComponent } from './product-card/product-card-tariff-list/product-card-tariff-list.component';
import { ProductsCardComponent } from './product-card/products-card/products-card.component';
import { SmallProductListComponent } from './product-card/small-product-list/small-product-list.component';
import { WizardProductCardContainerComponent } from './product-card/wizard-product-card/wizard-product-card-container.component';
import { WizardProductCardListComponent } from './product-card/wizard-product-card/wizard-product-card-list.component';
import { WizardProductCardComponent } from './product-card/wizard-product-card/wizard-product-card.component';
import { ProductsGroupUnpaidInvoiceNotificationComponent } from './product-group/products-group-unpaid-invoice-notification/products-group-unpaid-invoice-notification.component';
import { ProductsGroupComponent } from './product-group/products-group.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PushPdfComponent } from './marketing/push-pdf/push-pdf.component';
import { PushSepaComponent } from './marketing/push-sepa/push-sepa.component';
import { SlidingCardComponent } from './sliding-card/sliding-card.component';
import { ProcessSummaryComponent } from './summary/process-summary/process-summary.component';
import { ProductsSummaryInvoiceOldComponent } from './summary/products-summary/products-summary-invoice-old.component';
import { ProductsSummaryComponent } from './summary/products-summary/products-summary.component';
import { TableStripedComponent } from './table-striped/table-striped.component';
import { TariffOptionsFormComponent } from './tariff/tariff-selection-card-old/tariff-options-form/tariff-options-form.component';
import { TariffSelectionCardOldComponent } from './tariff/tariff-selection-card-old/tariff-selection-card-old.component';
import { TooltipCardComponent } from './tooltip-card/tooltip-card.component';
import { ExtendedUploadComponent } from './upload/extended-upload.component';
import { UploadComponent } from './upload/upload.component';
import { VoucherFormComponent } from './voucher/voucher-form/voucher-form.component';
import { VoucherInputDirective } from './voucher/voucher.directive';
import { WizardHeaderComponent } from './wizard/wizard-header.component';
import { WizardComponent } from './wizard/wizard.component';
import { RadioButtonListComponent } from './list/radio-button-list/radio-button-list.component';
import { ToggleComponent } from './toggle/toggle.component';
import { CustomPopupComponent } from './popup/custom-popup/custom-popup.component';
import { DateInputComponent } from './form/inputs/date-input/date-input.component';
import { DatepickerInputComponent } from './form/inputs/date-input/datepicker-input.component';
import { BlockPasteEventDirective } from './form/directives/block-paste-event.directive';
import { ActivePaymentPlanAlertComponent } from './invoice/active-payment-plan-alert/active-payment-plan-alert.component';
import { AvailabilityBadgeComponent } from './availability-badge/availability-badge.component';
import { InvoiceDataChangeConfirmationCheckPushPdfSepaComponent } from './invoice/invoice-data-change-confirmation/check-push-pdf-sepa/check-push-pdf-sepa.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ShowOnlyForImpersonateDirective } from './layout/directives/show-only-for-impersonate.directive';
import { ProductRadioButtonSelectionComponent } from './product-card/product-radio-button-selection/product-radio-button-selection.component';
import { AssociationSuggestionsComponent } from './association-suggestions/association-suggestions.component';
import { HeaderLogoImageComponent } from './header-logo/header-logo-image.component';
import { ContentCardComponent } from './terms/content-card/content-card.component';
import { ProductIconComponent } from './product-icon/product-icon.component';
import { ProductCardTariffListFernwaermeComponent } from './product-card/product-card-tariff-list/product-card-tariff-list-fernwaerme.component';
import { WlVoucherFormComponent } from './voucher/wl-voucher-form/wl-voucher-form.component';
import { HorizontalSwiperComponent, HorizontalSwiperItemComponent } from './swiper';
import { RadioInputComponent } from './form/inputs/radio-input/radio-input.component';
import { InfoTextPopupFromLinkDirective } from './info-text/info-text-popup-from-link/info-text-popup-from-link.directive';
import { PreLoadDataComponent } from './pre-load-data/pre-load-data.component';
import { AccountSelectionComponent } from './account/account-selection/account-selection.component';
import { ProductsSummaryRedesignComponent } from './summary/products-summary/products-summary-redesign.component';
import { DsgvoDisclaimerComponent } from './dsgvo/dsgvo-disclaimer/dsgvo-disclaimer.component';
import { TariffCardTemplateComponent } from './tariff/tariff-card-template/tariff-card-template.component';
import { TransparentCardComponent } from './cards/transparent-card/transparent-card.component';
import { ProductCardSimpleComponent } from './product-card/product-card-simple/product-card-simple.component';
import { SummaryInvoiceComponent } from './summary/invoice/summary-invoice.component';
import { ClipboardCopyButtonComponent } from './clipboard-copy-button/clipboard-copy-button.component';
import { AlternateRouteComponent } from './redirect/alternate-route/alternate-route.component';
import { TariffCardWaermeComponent } from './tariff/products/waerme/tariff-card-waerme.component';
import { TariffOptionDropdownComponent } from './tariff/tariff-selection-card/tariff-option-dropdown/tariff-option-dropdown.component';
import { TariffSelectionCardUiComponent } from './tariff/tariff-selection-card/tariff-selection-card-ui.component';
import { TariffSelectionContainerComponent } from './tariff/tariff-selection-container/tariff-selection-container.component';
import { ProductTariffCardTemplateComponent } from './tariff/tariff-card-template/product-tariff-card-template.component';
import { TariffsSelectionButtonComponent } from './tariff/tariffs-selection-button/tariffs-selection-button.component';
import { IconInfoBoxComponent } from './box/icon-info-box/icon-info-box.component';
import { TariffCardAdditionalOptionsComponent } from './tariff/tariff-card-additional-options/tariff-card-additional-options.component';
import { TariffSelectionCardStoreComponent } from './tariff/tariff-selection-card/tariff-selection-card-store.component';
import { TariffSelectionCardUiRowComponent } from './tariff/tariff-selection-card/tariff-selection-card-ui-row.component';
import { HeadlineComponent } from './headline/headline.component';
import { CheckboxCardComponent } from './cards/checkbox-card/checkbox-card.component';
import { SelectionCardComponent } from './cards/selection-card/selection-card.component';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { FlippingContentComponent } from './cards/flipping-content/flipping-content.component';
import { FlippingContentAnimatedComponent } from './cards/flipping-content/flipping-content-animated.component';
import { TariffSelectionCardFrontUiComponent } from './tariff/tariff-selection-card/pages/tariff-selection-card-front-ui.component';
import { TariffSelectionCardBackUiComponent } from './tariff/tariff-selection-card/pages/tariff-selection-card-back-ui.component';
import { TariffSelectionCardCollapseItemComponent } from './tariff/tariff-selection-card/pages/tariff-selection-card-collapse-item.component';
import { ClickableProductsCardComponent } from './cards/clickable-products-card/clickable-products-card.component';
import { CardClickableComponent } from './cards/card-clickable/card-clickable.component';
import { SmallCardClickableComponent } from './cards/card-clickable/small-card-clickable.component';
import { AriaLiveComponent } from './aria/aria-live/aria-live.component';
import { ApprovalFormComponent } from './summary/approval/approval-form.component';
import { ApprovalFormItemComponent } from './summary/approval/approval-form-item.component';
import { BenefitsCardComponent } from './summary/benefits-card/benefits-card.component';
import { PersonalDataSummaryCardComponent } from './summary/personal-data-summary-card/personal-data-summary-card.component';
import { SelectedProductsSummaryCardComponent } from './summary/selected-products-summary-card/selected-products-summary-card.component';
import { AdditionalInformationSummaryCardComponent } from './summary/additional-information-summary-card/additional-information-summary-card.component';
import { TariffCardEmobilityComponent } from './tariff/products/emobility/tariff-card-emobility.component';
import { TariffCardInternetComponent } from './tariff/products/internet/tariff-card-internet.component';
import { TariffCardNachtstromComponent } from './tariff/products/nachtstrom/tariff-card-nachtstrom.component';
import { TariffCardStromrueckComponent } from './tariff/products/stromrueck/tariff-card-stromrueck.component';
import { FormTextareaComponent } from './form/inputs/form-textarea/form-textarea.component';
import { OrderProductsPopupComponent } from './order-products-popup/order-products-popup.component';
import { SelectableCardRadioInputComponent } from './form/inputs/selectable-card-radio-input/selectable-card-radio-input.component';
import { InlineNotificationComponent } from './inline-notification/inline-notification.component';
import { InfoboxComponent } from './infobox/infobox.component';
import { ProductIconsComponent } from './product-icons/product-icons.component';

const components = [
  AccountSelectionComponent,
  ButtonComponent,
  ButtonLinkComponent,
  TabButtonsComponent,
  BasicCardComponent,
  BasicCardBodylessComponent,
  BasicCardTitleComponent,
  CardClickableComponent,
  SmallCardClickableComponent,
  TransparentCardComponent,
  ProgressBarComponent,
  ButtonBackComponent,
  BulletComponent,
  BulletConfirmationViewComponent,
  AddressInputComponent,
  ManualAddressInputComponent,
  NoAssociationsFoundWarningComponent,
  OrderProductsPopupComponent,
  InternationalAddressInputComponent,
  CardWithIconAndButtonComponent,
  CheckboxCardComponent,
  FlippingContentComponent,
  FlippingContentAnimatedComponent,
  ClickableProductsCardComponent,
  PushPdfInfoOnConfirmationComponent,
  ActivePaymentPlanAlertComponent,
  InteractiveInvoiceAlertComponent,
  ProductTariffCardTemplateComponent,
  TariffCardTemplateComponent,
  TariffCardEmobilityComponent,
  TariffCardInternetComponent,
  TariffCardNachtstromComponent,
  TariffCardStromrueckComponent,
  TariffCardWaermeComponent,
  TariffOptionDropdownComponent,
  TariffSelectionCardUiComponent,
  TariffSelectionCardFrontUiComponent,
  TariffSelectionCardBackUiComponent,
  TariffSelectionCardCollapseItemComponent,
  TariffSelectionCardUiRowComponent,
  TariffSelectionCardStoreComponent,
  TariffSelectionCardOldComponent,
  TariffOptionsFormComponent,
  TariffSelectionContainerComponent,
  TariffsSelectionButtonComponent,
  ContentCardComponent,
  ToggleComponent,
  PushPdfComponent,
  PushSepaComponent,
  InvoiceDataChangeConfirmationComponent,
  InvoiceDataChangeConfirmationCheckPushPdfSepaComponent,
  InvoiceInformationComponent,
  PreLoadDataComponent,
  CollaborationEntryComponent,
  NavigateToComponent,
  IBANFormComponent,
  ChoicesComponent,
  ClipboardCopyButtonComponent,
  CollapseComponent,
  PopupComponent,
  CustomPopupComponent,
  ExpanderComponent,
  ExpanderBasicComponent,
  LinkComponent,
  DefaultListGroupComponent,
  GreenCheckListComponent,
  LinkListGroupComponent,
  RadioButtonListComponent,
  ListGroupDropdownComponent,
  SlidingCardComponent,
  TooltipCardComponent,
  BankDetailsComponent,
  NewBankAccountInfoTextsComponent,
  SepaMandatListingComponent,
  TypeaheadInputComponent,
  FormSelectComponent,
  CheckboxInputComponent,
  SelectableCardCheckboxInputComponent,
  SelectableCardRadioInputComponent,
  FormInputComponent,
  FormTextareaComponent,
  FormInputLoadingSpinnerComponent,
  FormInputErrorComponent,
  FormInputHelpInfoComponent,
  BirthdateInputComponent,
  DateInputComponent,
  DatepickerInputComponent,
  BlockPasteEventDirective,
  InfoTextCardComponent,
  InfoTextComponent,
  InfoTextIconComponent,
  InfoTextPopupComponent,
  InfoTextPopupFromLinkDirective,
  SelectFirstInvalidInputDirective,
  ProductCardTariffListComponent,
  ProductCardTariffListFernwaermeComponent,
  CardWithProductListComponent,
  VertragsKontoProductListComponent,
  MultiProductCardComponent,
  MultiProductCardContainerComponent,
  ProductCardSimpleComponent,
  ProductCardSliderComponent,
  SmallProductListComponent,
  AlertComponent,
  SpecialAlertComponent,
  AriaLiveComponent,
  InputKeyDirective,
  FormComponent,
  EmailConfirmationComponent,
  MweDatepickerComponent,
  HeaderLogoComponent,
  HeaderLogoImageComponent,
  IconInfoBoxComponent,
  BannerPopupComponent,
  CampaignPopupComponent,
  DsgvoDisclaimerComponent,
  ProductsGroupComponent,
  ProductsGroupUnpaidInvoiceNotificationComponent,
  WizardProductCardContainerComponent,
  WizardProductCardListComponent,
  WizardProductCardComponent,
  ProductRadioButtonSelectionComponent,
  ProductsCardComponent,
  AddressSelectionComponent,
  AddressWithSelectionDropdownComponent,
  AddressComponent,
  ApprovalFormComponent,
  ApprovalFormItemComponent,
  SummaryInvoiceComponent,
  ProductsSummaryInvoiceOldComponent,
  ProductsSummaryComponent,
  ProcessSummaryComponent,
  WizardHeaderComponent,
  WizardComponent,
  PersonalDetailsFormComponent,
  CategoryDropdownFilterComponent,
  CustomerNumberInfoTooltipCardComponent,
  AssociationSuggestionNotificationComponent,
  AssociationSuggestionsComponent,
  NavbarFooterComponent,
  NavbarHeaderComponent,
  FooterComponent,
  HeaderComponent,
  ShowOnlyForImpersonateDirective,
  ImpersonateWarningComponent,
  PageRibbonComponent,
  MaintenanceTemplateComponent,
  ChevronNavComponent,
  ChatbotComponent,
  CookieComponent,
  CheckboxExpanderFormComponent,
  VoucherInputDirective,
  VoucherFormComponent,
  WlVoucherFormComponent,
  IdCardComponent,
  DownloadButtonComponent,
  UploadComponent,
  TableStripedComponent,
  ExtendedUploadComponent,
  BasicBadgeComponent,
  PillBadgeComponent,
  LinkBadgeComponent,
  BadgeComponent,
  AvailabilityBadgeComponent,
  RedirectComponent,
  AlternateRouteComponent,
  ProductIconComponent,
  ProductIconsComponent,
  HorizontalSwiperItemComponent,
  HorizontalSwiperComponent,
  RadioInputComponent,
  ProductsSummaryRedesignComponent,
  TariffCardAdditionalOptionsComponent,
  HeadlineComponent,
  SelectionCardComponent,
  MediaPreviewComponent,
  // TODO Move following to separate module (too specific)
  BenefitsCardComponent,
  PersonalDataSummaryCardComponent,
  SelectedProductsSummaryCardComponent,
  AdditionalInformationSummaryCardComponent,
  InlineNotificationComponent,
  InfoboxComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    UiPipesModule,
    RouterModule,
    DirectivesModule,
    LoadingComponentsModule,
  ],
  declarations: components,
  providers: [NgbActiveModal],
  exports: [...components, DirectivesModule, TranslateModule, UiPipesModule, LoadingComponentsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiComponentsModule {}
