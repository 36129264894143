import { getPdfDocumentsSafeUrl, getPdfDocumentsUrl } from '../model_utils';

export interface CheckboxExpanderForm {
  items: CheckboxExpanderFormItem[];
  showLessKey: string;
  showMoreKey: string;
}

export interface CheckboxExpanderFormItem {
  name: string;
  titleKey: string;
  descriptionKey: string;
  hintKey?: string;
  descriptionValues?: CheckboxExpanderFormItemDescriptionValues;
  errorKey?: string;
  showMore: boolean;
  open?: boolean;
  initialValue?: boolean;
  required: boolean;
}

export interface CheckboxExpanderFormItemDescriptionValues {
  [index: string]: string;
}

export function getCheckboxExpanderApprovalMandateItem(process: string): CheckboxExpanderFormItem {
  return {
    name: `mwe-${process}-summary-approvalMandate`,
    titleKey: 'approval.mandate.label',
    descriptionKey: 'approval.mandate.description',
    errorKey: 'approval.mandate.error',
    showMore: true,
    required: true,
  };
}

export function getCheckboxExpanderApprovalKGGItem(
  process: string,
  titleKey?: string,
  descriptionKey?: string,
  errorKey?: string,
): CheckboxExpanderFormItem {
  return {
    name: `mwe-${process}-summary-approvalKGG`,
    titleKey: titleKey || 'approval.kgg.label',
    descriptionKey: descriptionKey || 'approval.kgg.description',
    descriptionValues: {
      basePdfLinkUrlHttp: getPdfDocumentsUrl(),
      basePdfLinkUrlHttps: getPdfDocumentsSafeUrl(),
    },
    errorKey: errorKey || 'approval.kgg.error',
    showMore: true,
    required: true,
  };
}

export function getCheckboxExpanderApprovalGeneralItem(
  process: string,
  descriptionKey: string,
  descriptionValues: CheckboxExpanderFormItemDescriptionValues,
  titleKey?: string,
  errorKey?: string,
): CheckboxExpanderFormItem {
  return {
    name: `mwe-${process}-summary-approvalGeneral`,
    titleKey: titleKey || 'approval.general.label',
    descriptionKey,
    descriptionValues,
    errorKey: errorKey || 'approval.general.error',
    showMore: true,
    required: true,
  };
}

export function getCheckboxExpanderGeneralCustomerInfoItem(process: string, descriptionKey: string): CheckboxExpanderFormItem {
  return {
    name: `mwe-${process}-summary-generalCustomerInfo`,
    titleKey: 'approval.generalCustomerInfo.label',
    descriptionKey,
    descriptionValues: {
      basePdfLinkUrlHttp: getPdfDocumentsUrl(),
      basePdfLinkUrlHttps: getPdfDocumentsSafeUrl(),
    },
    errorKey: 'approval.generalCustomerInfo.error',
    showMore: true,
    required: true,
  };
}

export function insertCheckboxExpanderItemAfter(items: CheckboxExpanderFormItem[], newItem: CheckboxExpanderFormItem, name: string): void {
  const index = items.findIndex(i => i.name === name);
  items.splice(index + 1, 0, newItem);
}
