import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { TrackingService } from '@ikp/service/tracking/tracking-service';

const trackVirtualPageView: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const trackingService = inject(TrackingService);
  trackingService.trackVirtualPageView(state.url);
  return true;
};
export const trackingGuard = trackVirtualPageView;
