import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { InitialState, MNRContentResponse, MNRUserMessagesResponse } from '@mwe/models';
import { computed, inject } from '@angular/core';
import { MnrMessageService } from '../mnr-message.service';

export const MessagesStore = signalStore(
  { providedIn: 'root' },
  withState<InitialState>({
    messages: [],
    selectedMessage: undefined,
    hasError: false,
    showDetails: false,
    errorMessage: '',
  }),
  withComputed(({ messages }) => ({
    unreadMessageCount: computed<string>(() => {
      const unreadMessages = messages().filter(message => message.readDate == null);
      if (unreadMessages.length === 0) return '';
      if (unreadMessages.length <= 9) return `${unreadMessages.length}`;

      return '9+';
    }),
  })),
  withMethods((store, messageService = inject(MnrMessageService)) => ({
    updateMessages(messages: MNRUserMessagesResponse[]): void {
      patchState(store, state => ({ ...state, messages }));
    },
    updateSelectedMessage(selectedMessage: MNRContentResponse | undefined): void {
      patchState(store, state => ({ ...state, selectedMessage }));
    },
    toggleShowDetails(): void {
      patchState(store, state => ({ ...state, showDetails: !state.showDetails }));
    },
    setErrorMessage(errorMessage: string, hasError = true): void {
      patchState(store, state => ({ ...state, hasError, errorMessage }));
    },
    setShowDetails(showDetails: boolean): void {
      patchState(store, state => ({ ...state, showDetails }));
    },
    async loadMessages(): Promise<void> {
      try {
        const messages = await messageService.getUserMessages();
        messages.sort((a, b) => {
          return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
        });
        patchState(store, state => ({ ...state, messages }));
      } catch (e: any) {
        patchState(store, state => ({ ...state, hasError: true, errorMessage: 'mnr.overlay.error.title-plural' }));
      }
    },
    async loadMessage(id: number): Promise<void> {
      try {
        const selectedMessage = await messageService.getMessageContent(id);
        patchState(store, state => ({ ...state, selectedMessage, showDetails: true }));
      } catch (e: any) {
        patchState(store, state => ({
          ...state,
          hasError: true,
          showDetails: true,
          errorMessage: 'mnr.overlay.error.title-singular',
        }));
      }
    },
    async markAsRead(id: number) {
      try {
        await messageService.markMessageAsRead(id);
        /**
         * Update current Message Pool to avoid refetching all messages.
         * Messages should be updated on reload
         */
        patchState(store, state => {
          const newMessages = state.messages.map(message => {
            if (message.messageID === id) {
              message.readDate = new Date().toISOString();
            }
            return message;
          });

          return {
            ...state,
            messages: newMessages,
          };
        });
      } catch (e) {}
    },
  })),
);
