import { ChangeDetectionStrategy, Component, Input, input, output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mwe-button-link',
  templateUrl: './button-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLinkComponent {
  customCss = input<string>('');
  dataTest = input<string>('');
  label = input<string>('');
  ariaLabel = input<string>('');
  color = input<'orange' | 'blue'>('orange');
  url = input<string>();
  btnId = input<string>();
  isDisabled = input<boolean>(null);
  btnType = input<string>('button');
  route = input<string>();
  prependIconClass = input<string>();
  tabindex = input<string>();
  ariaHidden = input<boolean>();
  showSpinner = input<boolean>();
  clicked = output();

  constructor(private router: Router) {}

  onClick(): void {
    if (this.route()) {
      this.router.navigateByUrl(this.route());
    }

    if (this.url()) {
      window.open(this.url(), '_blank');
      return;
    }

    this.clicked.emit();
  }
}
