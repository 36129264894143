<div [attr.data-test]="testId()" (click)="cardClicked.emit()" class="card-basic card-clickable" tabindex="0">
  <div class="card-basic-body p-4">
    <div class="row gx-2">
      <div class="col">
        <div class="fw-semibold text-secondary" [translate]="cardTitle()"></div>
      </div>
      <div class="col-auto align-self-center">
        <i class="fas fa-chevron-right text-secondary"></i>
      </div>
    </div>
  </div>
</div>
