import { Address, IAddressProducts } from '../address/address.model';
import { CommonMappingData, IBulletConfirmationStatusChangeResponse, ILSystem } from '../integrationlayer/api.model';
import { IPersonalData } from '../order/order.model';
import { ClearingAccount, UnpaidInvoice, UnpaidInvoicesSum } from './clearing-account.model';
import { TeilbetragsUebersicht } from './invoice-overview.model';

type ZahlungStatusEnum = 'offen' | 'beglichen';
const ZahlungStatusEnum = {
  Offen: 'offen' as ZahlungStatusEnum,
  Beglichen: 'beglichen' as ZahlungStatusEnum,
};

type RatenplanTypEnum = 'Ratenplan für normale Zahler' | 'Ratenplan für schlechte Zahler' | 'Zahlungsschema Ratenplan';
const RatenplanTypEnum = {
  RatenplanFrNormaleZahler: 'Ratenplan für normale Zahler' as RatenplanTypEnum,
  RatenplanFrSchlechteZahler: 'Ratenplan für schlechte Zahler' as RatenplanTypEnum,
  ZahlungsschemaRatenplan: 'Zahlungsschema Ratenplan' as RatenplanTypEnum,
};

type RatenplanStatusEnum = 'ausgeglichen' | 'deaktiviert' | 'offen' | 'aktiv';

export interface UnpaidInvoicesSelectionViewModel {
  addressProducts: IAddressProducts;
  clearingAccounts: ClearingAccount[];
}

export type PaymentSolutionType = 'payment-extension' | 'payment-plan' | 'payment-callback';

// tslint:disable-next-line: no-empty-interface
export type IClearingAccountMapping = CommonMappingData;

// tslint:disable-next-line:no-empty-interface
export type IClearingAccountStatusResponse = IBulletConfirmationStatusChangeResponse;

export interface RatenplanPosition {
  faellig: string;
  betrag: number;
  offenerBetrag?: number;
  status: 'ausstehend' | 'bezahlt' | 'ueberfaellig';
}

export interface Stundung {
  moeglichesStundungsdatum: string[];
  offeneZahlungen: UnpaidInvoice[];
}

export interface GetRatenPlanResponse {
  ratenplaene: Ratenplan[];
  offeneZahlungen?: UnpaidInvoice[];
}

export interface CreateRatenplanResponse {
  verrechnungskontoId: string;
  ratenplan: Ratenplan;
  offeneZahlungen?: UnpaidInvoice[];
}

export interface Ratenplan {
  ratenplannummer: string;
  beginn: string;
  ende: string;
  anzahlRaten: number;
  positionen: RatenplanPosition[];
  systemId: ILSystem;
  typ: RatenplanTypEnum;
  status: RatenplanStatusEnum;
}

export interface IClearingAccountChangeDataDTO {
  mappingData: IClearingAccountMapping[];
  ratenplan?: Ratenplan;
  unpaidInvoices?: UnpaidInvoice[];
  unpaidInvoicesSum?: UnpaidInvoicesSum[];
  customerSupportCallback?: CustomerSupportCallbackSuggestionRequest;
  invoiceParts?: TeilbetragsUebersicht;
  newExtensionDate?: string;
  extensionAmount?: string;
  address: Address;
  personalData: IPersonalData;
  qrCodeData: any;
  paymentInfo: PaymentInfo;
  createdAt?: string;
}

export interface PaymentInfo {
  iban: string;
  bic?: string;
  reasonForPayment?: string;
}

export const parseClearingAccountChangeDataDTOFromOrderStatusDetail = (chargingOrderDetails: any): IClearingAccountChangeDataDTO => {
  const chargingCardOrderDTO: IClearingAccountChangeDataDTO = JSON.parse(chargingOrderDetails.changeData);
  return chargingCardOrderDTO;
};

export interface CustomerSupportCallback {
  terminVorschlaege: CustomerSupportCallbackSuggestion[];
}

interface CustomerSupportCallbackSuggestionRequest {
  termin: CustomerSupportCallbackSuggestion;
}

export interface CustomerSupportCallbackSuggestion {
  systemId: string;
  id: string;
  typ: string;
  gruppenId: CallBackGroupId;
  queueId: string;
  queueName: string;
  kanalCode: string;
  quelle: string;
  telefon: {
    land: string;
    nummer: string;
  };
  sprache: string;
  zeitpunkt: string;
  vergeben: boolean;
  zusatzInformationen: string;
  token: string;
}

export enum CallBackGroupId {
  StromGas = '1503',
  Waerme = '1488',
}

export interface BankStatement {
  kontoauszug: {
    positionen: BankStatementPosition[];
  };
}

export interface BankStatementPosition {
  faelligkeitsdatum: string;
  buchungstext: string;
  forderung: number;
  guthaben: number;
  waehrung: string;
  erdk: string;
  offenerBetrag: number;
  mahnstufe: string;
  ausgleichsbelegnummer: string;
  stundungsdatum?: string;
  ratenplannummer: string;
  abschlagsplannummer: string;
}
