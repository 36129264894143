<div (click)="onClick.emit()" [ngClass]="{ pointer: isClickable() }" class="mb-3" data-test="tooltip-card-container">
  <div class="card-basic">
    <div class="card-basic-body position-relative py-lg-4">
      <div
        [ngClass]="{
          'bg-ellipse-inverse bg-gray-light': disabled(),
          'bg-secondary-light': !disabled(),
          'rounded-bottom-0': hasTooltipBody(),
        }"
        class="bg-ellipse bg-secondary-light rounded-3"
      ></div>
      <div class="row gx-3 gy-2 position-relative">
        @if (iconClass()) {
          <div class="col-auto">
            <div
              class="text-secondary rounded-pill bg-white text-center d-flex align-items-center justify-content-center"
              style="width: 1.875rem; height: 1.875rem"
            >
              <i class="{{ iconClass() }} font-size-11"></i>
            </div>
          </div>
        }
        <div class="col align-self-center">
          @if (content() || header()) {
            <mwe-headline cssClass="text--big text-white mb-0" [headlineNodeType]="textNodeType()">
              @if (header()) {
                <div class="fw-bold" data-test="mwe-tooltip-header">{{ header() }}</div>
              }
              @if (content()) {
                <div class="text--big text-white" id="content" data-test="mwe-tooltip-content">{{ content() }}</div>
              }
            </mwe-headline>
          }
        </div>
        <ng-content select="[slot='buttons']" />
        <ng-content select="[slot='dropdown']" />
      </div>
      @if (orangeInfoTooltipText()) {
        <ng-container>
          <div
            class="position-absolute tooltip-info-icon bg-gradient--orange d-flex align-items-center justify-content-center rounded-circle"
            [ngbTooltip]="orangeInfoTooltipText()"
            triggers="hover focus click"
            placement="top"
            tooltipClass="text--medium mt-1 mb-1"
          >
            <i class="fas fa-info text-white"></i>
          </div>
        </ng-container>
      }
    </div>
    <ng-content select="[slot='tooltip-body']" />
  </div>
</div>
