<mwe-address
  *ngIf="address && products?.length > 0"
  [address]="address"
  [addressIdx]="addressIdx"
  [singleMarker]="products?.length === 1"
  dropdownEditKey="products.addresGroupItems.label"
  [allDropdownItems]="dropdownItems"
  [dropdownType]="dropdownType"
  [featuredBtnIds]="featuredBtnIds"
  [isAddressDetailsBodyVisible]="isDetailsState"
  (onItemClick)="actionHandler($event)"
  [productCategories]="productCategories"
  [textNodeType]="headlineService.getHeadline(1)"
></mwe-address>

<div class="pt-2">
  <mwe-products-group-unpaid-invoice-notification
    *ngIf="hasUnpaidInvoices()"
    [unpaidInvoices]="unpaidInvoices"
    [addressProducts]="{ address: address, products: products }"
  ></mwe-products-group-unpaid-invoice-notification>

  <div class="row">
    <ng-container *ngFor="let p of products">
      <mwe-product-card
        class="col-12 col-md-6 pb-4 mh-100"
        [product]="p"
        [groupProducts]="products"
        [state]="state"
        [showCompanyCorrelation]="showCompanyCorrelation"
        [checkIfFernwaermeIsActive]="checkIfFernwaermeIsActive"
        [unpaidInvoices]="getUnpaidInvoice(p)"
      ></mwe-product-card>
    </ng-container>
  </div>
</div>
