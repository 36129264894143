export interface ApprovalFormItem {
  id: string;
  checkbox?: ApprovalFormItemCheckbox;
  bulletPoints?: ApprovalFormItemBulletPoint[];
  text?: string;
  textParams?: any;
}

export interface ApprovalFormItemCheckbox {
  label: string;
  isMandatory?: boolean;
  initialValue?: boolean;
  error?: string;
}

export interface ApprovalFormItemBulletPoint {
  label: string;
  labelParams?;
  link: string;
}

export interface ApprovalFormItemBulletPointTariffInfo {
  tariffName: string;
  tariffKey: string;
  category: string;
}

export interface ApprovalFormItemAllesSicher {
  labelPrefix: string;
  url: string;
}

export interface CreateApprovalFormItemsForStromGasParams {
  tariffInfos: ApprovalFormItemBulletPointTariffInfo[];
  allesSicherInfo?: ApprovalFormItemAllesSicher;
  withContractInfos?: boolean;
  withImmediateDelivery?: boolean;
  withCancellationInfo?: boolean;
}
