import { PreisIndikation, ProduktAttribute } from '@mwe/models';

export const getTarifIndication = (indications: PreisIndikation[], tarifKey: string): PreisIndikation => {
  return indications?.find(indication => indication.tarif.ISUTarifKey === tarifKey);
};

export const getTarifBinding = (indication: PreisIndikation): ProduktAttribute => {
  return getPricePart(indication, 'Vertragsbindung:');
};

export const getPricePart = (indication: PreisIndikation, partName: string): ProduktAttribute => {
  return indication?.preisbestandteile?.produktAttribute?.find(attribute => attribute.name.trim() === partName);
};
