<div class="{{ outerClass }}" [class.card-basic]="showBoxStyle">
  <div
    class="rounded-3 pointer {{ headerBackgroundColor }} {{ showBoxStyle ? headerMarginPadding : '' }}"
    [class.card-basic-body]="showBoxStyle"
    [class.card-basic]="!showBoxStyle"
    [ngClass]="{ 'rounded-bottom-3': isCollapsed }"
    [class.collapsed]="isCollapsed"
    [attr.aria-controls]="collapseId"
    [attr.aria-expanded]="!isCollapsed"
    [attr.data-test]="collapseId"
    (click)="collapse.toggle()"
    *ngIf="isCollapseHeaderVisible"
  >
    <div [class.card-basic-body]="!showBoxStyle">
      <div class="row">
        <div class="col my-auto">
          <div class="d-flex align-items-center justify-content-start">
            <i *ngIf="iconClass" class="{{ iconClass }}"></i>
            <div>
              <h6 class="mb-0 {{ headerTextClass }}" [translate]="titleMessageKey" [translateParams]="headerTranslateParams"></h6>
              <p
                class="mt-1 mb-0 text--big {{ headerTextClass }}"
                *ngIf="subtitleMessageKey"
                [translate]="subtitleMessageKey"
                [translateParams]="headerTranslateParams"
              ></p>
            </div>
          </div>
        </div>
        <div class="col-auto d-flex align-items-center justify-content-center">
          <button
            type="button"
            class="btn btn-bubble btn-bubble-s float-end collapse-button {{ chevronButton }}"
            [class.collapsed]="isCollapsed"
            [attr.aria-controls]="collapseId"
            [attr.aria-expanded]="!isCollapsed"
            data-bs-toggle="collapse"
            role="button"
          >
            <i class="fas f-weight-400" [ngClass]="{ 'fa-angle-down': isCollapsed, 'fa-angle-up': !isCollapsed }"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="collapse" [class.show]="initialState === 'expanded'">
    <div class="py-0" [class.card-basic-body]="showBoxStyle">
      <div class="{{ contentClass }}" [ngClass]="{ 'border-top': headerTheme === '' }">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
