<div class="card-basic {{ cardClass }}" [ngClass]="{ 'has-background': hasBackground, 'h-100': height100, 'no-box-shadow': noBoxShadow }">
  <div class="card-background" *ngIf="hasBackground"></div>
  <div class="card-basic-body" data-test="basic-card-body">
    <mwe-basic-card-title
      *ngIf="cardTitle"
      [cardTitle]="cardTitle"
      [cardTitleParams]="cardTitleParams"
      [iconClass]="iconClass"
      [headingNodeType]="headingNodeType"
      data-test="basic-card-title"
    ></mwe-basic-card-title>
    <ng-content></ng-content>
  </div>
  <div *ngIf="optionalLastChildClass" class="{{ optionalLastChildClass }}"></div>
</div>
