@if (categoryOptions().length > 1) {
  <div id="flip" class="pill-btn" data-test="invoice-list-category-filter-container" ngbDropdown placement="bottom-right top-right">
    <button class="btn btn-sm btn-primary--blue" style="padding: 6px 16px" ngbDropdownToggle>
      <span>{{ 'interactive-invoices.product' | translate }}: </span>
      <span class="ml-1">{{
        selectedCategory()
          ? (selectedCategory() | translateCategory: isFernwaermeTariffClass(selectedCategory()) | async)
          : ('interactive-invoices.allCategoriesFilter' | translate)
      }}</span>
      <i class="ml-1 fas fa-chevron-down" aria-hidden="true"></i>
    </button>

    <div ngbDropdownMenu class="dropdown-menu top-border-0" id="panel">
      <div class="list-group-flush px-0 address-menu text-muted">
        @for (category of categoryOptions(); track $index) {
          <button
            [ngClass]="{ selected: category === selectedCategory() }"
            [attr.data-test]="'invoice-list-category-filter-dropdown-item-' + (category?.toLowerCase() || 'all')"
            class="list-group-item"
            (click)="selectCategory(category)"
            ngbDropdownItem
          >
            @if (isFernwaermeTariffClass(category)) {
              <span>
                {{ category | translateCategory: true | async }}
              </span>
            } @else {
              <span>
                {{ category ? (category | translateCategory | async) : ('interactive-invoices.allCategoriesFilter' | translate) }}
              </span>
            }
          </button>
        }
      </div>
    </div>
  </div>
}
