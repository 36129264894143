import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, EnvironmentService } from '@mwe/services';
import { PRODUCTS_ROUTE } from '@mwe/constants';

@Component({
  selector: 'mwe-alternate-route',
  template: '',
  styles: '',
})
export class AlternateRouteComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private environmentService: EnvironmentService,
  ) {}

  ngOnInit(): void {
    this.navigateTo();
  }

  navigateTo(): void {
    const portal = this.environmentService.getPortalCustomerType();
    let url = portal === 'business' ? '' : '/privat';
    if (this.authService.isAuthenticated() || sessionStorage.getItem('mwe_privat_account_isbskw') !== null) {
      url += portal === null ? '' : '/' + PRODUCTS_ROUTE;
    }
    this.router.navigateByUrl(`${url}`);
  }
}
