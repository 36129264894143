import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_SERVICE_TOKEN, FeatureToggleEnum } from '@mwe/constants';
import { ProfileInfo } from '@mwe/models';
import dayjs, { Dayjs } from 'dayjs';
import { AccountStateService } from '../account/state/account-state.service';
import { AppStorageService } from '../cache/app-storage.service';
import { EnvironmentService } from '../environment/environment.service';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private infoUrl;
  private profileInfo: ProfileInfo;
  private lastProfileInfoRequest: Dayjs;
  private currentTimestamp: Dayjs;
  private readonly maintenanceRefreshIntervall: number;
  private landingPages = [];

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
    private appStorageService: AppStorageService,
    private accountStateService: AccountStateService,
    private router: Router,
  ) {
    // infoUrl is excluded from BlockHttpRequestInterceptor checks to prevent infinite loops
    // after url-change make sure that BlockHttpRequestInterceptor does not throw MaxCallSizeError (infinite loop)
    this.infoUrl = this.environmentService.getApiUrl() + 'api/mgmt/info';
    this.maintenanceRefreshIntervall = this.environmentService.getMaintenanceRefreshIntervall();

    if (router['config']) {
      router['config']
        .filter(route => route.path === '')
        .forEach(route => {
          route.children?.forEach(childRoute => {
            if (childRoute.data && childRoute.data['isLandingPage'] && childRoute.path.length > 0) {
              this.landingPages.push(childRoute.path);
            }
          });
        });
    }
  }

  async loadProfileInfo(): Promise<void> {
    const lpPath = this.landingPages.find(lp => window.location.pathname.indexOf(lp) > -1);
    let headers = new HttpHeaders();

    if (lpPath) {
      headers = headers.set('x-sub-module', 'LP');
    }

    this.currentTimestamp = dayjs();
    const res = await lastValueFrom(
      this.http.get<ProfileInfo>(this.infoUrl, {
        observe: 'response',
        headers: headers,
      }),
    );
    const data = res.body;
    const pi = new ProfileInfo();
    pi.version = data['build'] ? data['build'].version : undefined;
    pi.featureToggles = data['feature-toggles'];
    pi.serverTimestamp = data['serverTimestamp'];
    pi.backendVersion = data['backendVersion'];
    pi.toggleSetDesignator = data['toggleSetDesignator'];

    this.profileInfo = pi;
    this.lastProfileInfoRequest = dayjs();
  }

  shouldRefreshProfileInfo(): boolean {
    this.currentTimestamp = dayjs();
    const timeDifference = this.currentTimestamp.diff(this.lastProfileInfoRequest, 'minutes');
    return !this.lastProfileInfoRequest || timeDifference > this.maintenanceRefreshIntervall;
  }

  getProfileInfo(): ProfileInfo {
    return this.profileInfo;
  }

  isFeatureToggleEnabled(toggle: FeatureToggleEnum): boolean {
    return this.profileInfo.featureToggles[toggle] ? this.profileInfo.featureToggles[toggle] : false;
  }

  isFeatureToggleDisabled(toggle: FeatureToggleEnum): boolean {
    return !this.isFeatureToggleEnabled(toggle);
  }

  isAnyFeatureToggleEnabled(toggles: FeatureToggleEnum[]): boolean {
    return toggles.some(toggle => this.isFeatureToggleEnabled(toggle));
  }

  areAllFeatureTogglesEnabled(toggles: FeatureToggleEnum[]): boolean {
    return toggles.every(toggle => this.isFeatureToggleEnabled(toggle));
  }

  checkFeatureToggleOverwrites(): void {
    if (!this.accountStateService.isImpersonate()) {
      return;
    }

    const overwrites = this.appStorageService.getFeatureTogglesOverwrite();

    Object.keys(overwrites).forEach(key => {
      this.profileInfo.featureToggles[key] = overwrites[key];
    });
  }
}
