import { IDefaultProdukt, IProduktAuswahlDetails, PreisIndikation, TariffSelectionCardData } from '@mwe/models';

export interface TariffSelectionStoreState {
  allProducts: IProduktAuswahlDetails[];
  allPriceIndications: PreisIndikation[];
  selectedTariffs: TariffSelectionCardData[];
  additionalOptionGroupName: string;
  zipCode: string;
}

export interface TariffSelectionStoreInitParams {
  allProducts: IProduktAuswahlDetails[];
  allPriceIndications: PreisIndikation[];
  additionalOptionGroupName: string;
  zipCode: string;
  // data to get selectedTariffs
  userSelectedItems: IProduktAuswahlDetails[];
  userSelectedPriceIndications: PreisIndikation[];
  defaultProducts?: IDefaultProdukt;
}
