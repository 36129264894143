import { IVerfuegbarkeiten, PreisIndikation } from '@mwe/models';

export const getVerfuegbarkeitenMock = (): IVerfuegbarkeiten => {
  return {
    verfuegbarkeiten: [
      { sparte: 'Strom', verfuegbar: 'JA' },
      { sparte: 'Gas', verfuegbar: 'JA' },
      { sparte: 'Fernwaerme', verfuegbar: 'JA', details: [{ anlagentyp: 'Fernwaerme', lieferkomponente: 'HEIZUNG' }] },
      { sparte: 'Internet', verfuegbar: 'NEIN' },
      { sparte: 'Iptv', verfuegbar: 'NEIN' },
      { sparte: 'Voip', verfuegbar: 'NEIN' },
    ],
  };
};

export const getPriceIndicationSuperschnellMock = (): PreisIndikation[] => {
  return [
    {
      sparte: 'superschnell',
      tarif: {
        ISUTarifKey: 'WEBTKHIGHSPEED250',
        tarifName: 'SuperSchnell250',
        tarifBeschreibung: '',
        tarifOptionen: [
          {
            option: 'WEBIPTVPREMIUM',
            optionLabel: 'premiumtv',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '59,90',
              },
              {
                deltaUnit: 'EUR',
                delta: '13,90',
                deltaBasis: 'grundpreis',
              },
            ],
            tarifOptionen: [
              {
                option: 'HDPREMIUM',
                optionLabel: 'hdpremium',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '5,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVZUSMEDBOX',
                optionLabel: 'tvbox',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '7,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '69,90',
                  },
                ],
              },
              {
                option: 'WEBIPTVPAKET50',
                optionLabel: '50hspeicherplatz',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '2,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVPAKET100',
                optionLabel: '100hspeicherplatz',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '4,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVENTERTAINHD',
                optionLabel: 'hdentertainment',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '15,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
            ],
          },
          {
            option: 'WEBTELÖ500',
            optionLabel: 'telefonieoe500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '5,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
          {
            option: 'WEBTELEU500',
            optionLabel: 'telefonieeu500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '9,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
        ],
      },
      grundpreis: '29,90',
      grundpreisLabel: 'Abopreis',
      anschlusspreis: '69,90',
      preisbestandteile: {
        produktAttribute: [
          {
            name: 'Bindung',
            kurzWert: '12 Monate',
            langWert: '',
          },
          {
            name: 'Installation',
            kurzWert: '69,90 EUR (Pauschale)',
            langWert: '',
          },
          {
            name: 'Verfügbare Optionen',
            kurzWert: 'TV, Telefonie',
            langWert: '',
          },
        ],
      },
      vorteile: [
        'Aktion: Jetzt 29,90 EUR* statt 34,90 EUR',
        '250 Mbit/s Up- und Download',
        'Perfekt für TV-Streaming, Social Media und Home Office',
      ],
      preisDisclaimer: '<p>Tariferklärung Was macht diesen Tarif aus&#8230;</p>\n',
    },
    {
      sparte: 'superschnell',
      tarif: {
        ISUTarifKey: 'WEBTKHIGHSPEED100',
        tarifName: 'SuperSchnell100',
        tarifBeschreibung: '',
        tarifOptionen: [
          {
            option: 'WEBIPTVLIGHT',
            optionLabel: 'lighttv',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '59,90',
              },
              {
                deltaUnit: 'EUR',
                delta: '5,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
          {
            option: 'WEBTELÖ500',
            optionLabel: 'telefonieoe500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '5,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
          {
            option: 'WEBTELEU500',
            optionLabel: 'telefonieeu500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '9,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
        ],
      },
      grundpreis: '24,90',
      grundpreisLabel: 'Abopreis',
      anschlusspreis: '69,90',
      preisbestandteile: {
        produktAttribute: [
          {
            name: 'Bindung',
            kurzWert: '12 Monate',
            langWert: '',
          },
          {
            name: 'Installation',
            kurzWert: '69,90 EUR (Pauschale)',
            langWert: '',
          },
          {
            name: 'Verfügbare Optionen',
            kurzWert: 'TV, Telefonie',
            langWert: '',
          },
        ],
      },
      vorteile: ['Aktion: Jetzt 24,90 EUR* statt 29,90 EUR', '100 Mbit/s Up- und Download', 'Perfekt zum Surfen und Mailen'],
      preisDisclaimer: '<p>Tariferklärung Was macht diesen Tarif aus&#8230;</p>\n',
    },
    {
      sparte: 'superschnell',
      tarif: {
        ISUTarifKey: 'WEBTKHIGHSPEED500',
        tarifName: 'SuperSchnell500',
        tarifBeschreibung: '',
        tarifOptionen: [
          {
            option: 'WEBIPTVPREMIUM',
            optionLabel: 'premiumtv',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '59,90',
              },
              {
                deltaUnit: 'EUR',
                delta: '13,90',
                deltaBasis: 'grundpreis',
              },
            ],
            tarifOptionen: [
              {
                option: 'HDPREMIUM',
                optionLabel: 'hdpremium',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '5,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVZUSMEDBOX',
                optionLabel: 'tvbox',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '7,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '69,90',
                  },
                ],
              },
              {
                option: 'WEBIPTVPAKET50',
                optionLabel: '50hspeicherplatz',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '2,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVPAKET100',
                optionLabel: '100hspeicherplatz',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '4,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVENTERTAINHD',
                optionLabel: 'hdentertainment',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '15,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
            ],
          },
          {
            option: 'WEBTELÖ500',
            optionLabel: 'telefonieoe500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '5,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
          {
            option: 'WEBTELEU500',
            optionLabel: 'telefonieeu500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '9,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
        ],
      },
      grundpreis: '34,90',
      grundpreisLabel: 'Abopreis',
      anschlusspreis: '69,90',
      preisbestandteile: {
        produktAttribute: [
          {
            name: 'Bindung',
            kurzWert: '12 Monate',
            langWert: '',
          },
          {
            name: 'Installation',
            kurzWert: '69,90 EUR (Pauschale)',
            langWert: '',
          },
          {
            name: 'Verfügbare Optionen',
            kurzWert: 'TV, Telefonie',
            langWert: '',
          },
        ],
      },
      vorteile: [
        'Aktion: Jetzt 34,90 EUR* statt 54,90 EUR',
        '500 Mbit/s Up- und Download',
        'Perfekt für Online-Gaming und 4k/3D-Streaming',
      ],
      preisDisclaimer: '<p>Tariferklärung Was macht diesen Tarif aus&#8230;</p>\n',
    },
    {
      sparte: 'superschnell',
      tarif: {
        ISUTarifKey: 'WEBTKHIGHSPEED1000',
        tarifName: 'SuperSchnell1000',
        tarifBeschreibung: '',
        tarifOptionen: [
          {
            option: 'WEBIPTVPREMIUM',
            optionLabel: 'premiumtv',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '59,90',
              },
              {
                deltaUnit: 'EUR',
                delta: '13,90',
                deltaBasis: 'grundpreis',
              },
            ],
            tarifOptionen: [
              {
                option: 'HDPREMIUM',
                optionLabel: 'hdpremium',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '5,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVZUSMEDBOX',
                optionLabel: 'tvbox',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '7,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '69,90',
                  },
                ],
              },
              {
                option: 'WEBIPTVPAKET50',
                optionLabel: '50hspeicherplatz',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '2,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVPAKET100',
                optionLabel: '100hspeicherplatz',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '4,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
              {
                option: 'WEBIPTVENTERTAINHD',
                optionLabel: 'hdentertainment',
                preise: [
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'grundpreis',
                    delta: '15,90',
                  },
                  {
                    deltaUnit: 'EUR',
                    deltaBasis: 'anschlusspreis',
                    delta: '0,00',
                  },
                ],
              },
            ],
          },
          {
            option: 'WEBTELÖ500',
            optionLabel: 'telefonieoe500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '2,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
          {
            option: 'WEBTELEU500',
            optionLabel: 'telefonieeu500',
            preise: [
              {
                deltaUnit: 'EUR',
                deltaBasis: 'anschlusspreis',
                delta: '0,00',
              },
              {
                deltaUnit: 'EUR',
                delta: '4,90',
                deltaBasis: 'grundpreis',
              },
            ],
          },
        ],
      },
      grundpreis: '79,90',
      grundpreisLabel: 'Abopreis',
      anschlusspreis: '69,90',
      preisbestandteile: {
        produktAttribute: [
          {
            name: 'Bindung',
            kurzWert: '12 Monate',
            langWert: '',
          },
          {
            name: 'Installation',
            kurzWert: '69,90 EUR (Pauschale)',
            langWert: '',
          },
          {
            name: 'Verfügbare Optionen',
            kurzWert: 'TV, Telefonie',
            langWert: '',
          },
        ],
      },
      vorteile: [
        'Aktion: Jetzt 79,90 EUR* statt 99,90 EUR',
        '1000 Mbit/s Up- und Download',
        'Perfekt für Profi-Vlogger, Heimserver und Back-ups',
      ],
      preisDisclaimer: '<p>Tariferklärung Was macht diesen Tarif aus&#8230;</p>\n',
    },
  ];
};
