<div [ngClass]="{ 'mb-3': hasMarginBottom }">
  <input
    #file
    (change)="fileUpload($event)"
    [accept]="config.accept"
    [disabled]="this.uploadedFiles() && this.uploadedFiles().length >= config.maxAmountOfFiles"
    data-test="hidden-file-upload"
    hidden
    name="image"
    type="file"
  />
  <mwe-button-link (clicked)="onUploadClicked()" label="upload.uploadButton.text">
    <i aria-hidden="true" class="fa-solid fa-cloud-arrow-up mr-2 w-max-content fs-6 text-decoration-none"></i>
  </mwe-button-link>
  @if (showLoadingSpinner()) {
    <mwe-loading-spinner />
  }
  @if (showUploadError()) {
    <mwe-alert [messageKey]="config.uploadErrorMsgKey" type="danger" />
  }
</div>
