<div class="row gx-4 gy-3">
  <div class="col-md-6 col-xl-4" *ngFor="let category of categories">
    <label class="card-selectable mb-0" [ngClass]="{ 'border-danger': isInvalid }" [attr.data-test]="'option' + category">
      <div class="text-center mb-2">
        <ng-container *ngIf="category === 'StromGas'">
          <img [src]="'Strom' | categoryIconSrc" [alt]="'Strom' | translateCategory | async" class="mb-2 mr-2" style="max-width: 50px" />
          <img [src]="'Gas' | categoryIconSrc" [alt]="'Gas' | translateCategory | async" class="mb-2" style="max-width: 50px" />
          <br />
          <h4 class="mb-0 h6" data-test="product-category-text" [style.font-size]="'18px'">{{ category | translateCategory | async }}</h4>
        </ng-container>
        <ng-container *ngIf="category !== 'StromGas'">
          <mwe-product-icon [category]="category" size="md" textNodeType="h4"></mwe-product-icon>
        </ng-container>
      </div>
      <div class="text-center">
        <input
          type="radio"
          id="option{{ category }}"
          name="product-categories"
          class="form-check-input"
          [value]="category"
          (change)="onSelected(category)"
          [ngClass]="{ 'is-invalid': isInvalid }"
        />
      </div>
    </label>
  </div>
  <p class="font-size-11 text-danger mt-2 mb-0" *ngIf="isInvalid && errorMessage">
    <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
    <span [translate]="errorMessage"></span>
  </p>
</div>
