import {
  ApprovalFormItemBulletPointTariffInfo,
  ApprovalFormItem,
  ApprovalFormItemBulletPoint,
  getPdfDocumentsSafeUrl,
  ApprovalFormItemAllesSicher,
  Sparte,
  CreateApprovalFormItemsForStromGasParams,
} from '@mwe/models';
import { isProductCategoryGas, isProductCategoryStrom } from '../products/product-categoriy.utils';

export function createApprovalFormItemsForStromGas(params: CreateApprovalFormItemsForStromGasParams): ApprovalFormItem[] {
  let items = [];

  if (params.tariffInfos.some(info => isProductCategoryStrom(info.category))) {
    items.push(getCommonApprovalsForStrom(params.tariffInfos, params.withContractInfos));
  }

  if (params.tariffInfos.some(info => isProductCategoryGas(info.category))) {
    items.push(getCommonApprovalsForGas(params.tariffInfos, params.withContractInfos));
  }

  if (params.allesSicherInfo) {
    items.push(createAllesSicherApproval(params.allesSicherInfo));
  }

  items = [...items, ...createSpecialTariffInfos(params.tariffInfos)];

  if (params.withImmediateDelivery) {
    items.push(createImmediateDeliveryApproval());
  }

  if (params.withCancellationInfo) {
    items.push(createCancellationInfo());
  }

  items.push(createDsgvoInfo());

  return items.filter(i => !!i);
}

function getCommonApprovalsForStrom(tariffInfos: ApprovalFormItemBulletPointTariffInfo[], withContractInfos = true): ApprovalFormItem {
  const bulletPoints: ApprovalFormItemBulletPoint[] = [
    { label: 'approval.strom.bulletPoints.generalConditions', link: 'https://www.wienenergie.at/agb/alb_strom' },
    { label: 'approval.strom.bulletPoints.authority', link: 'https://www.wienenergie.at/vki-vollmacht' },
    ...createTariffInfoBulletPoints(tariffInfos, Sparte.Strom),
  ];

  if (withContractInfos) {
    bulletPoints.push({
      label: 'approval.strom.bulletPoints.contractInfos',
      link: 'https://www.wienenergie.at/fagg-strom',
    });
  }

  return {
    id: 'approval-form-strom-common',
    checkbox: {
      label: 'approval.strom.checkboxLabel',
      isMandatory: true,
      error: 'approval.strom.error',
    },
    bulletPoints,
  };
}

function getCommonApprovalsForGas(tariffInfos: ApprovalFormItemBulletPointTariffInfo[], withContractInfos = true): ApprovalFormItem {
  let bulletPoints: ApprovalFormItemBulletPoint[] = [
    { label: 'approval.gas.bulletPoints.generalConditions', link: 'https://www.wienenergie.at/agb/alb_gas' },
    { label: 'approval.gas.bulletPoints.authority', link: 'https://www.wienenergie.at/vki-vollmacht' },
    ...createTariffInfoBulletPoints(tariffInfos, Sparte.Gas),
  ];

  if (withContractInfos) {
    bulletPoints.push({
      label: 'approval.gas.bulletPoints.contractInfos',
      link: 'https://www.wienenergie.at/fagg-gas',
    });
  }

  return {
    id: 'approval-form-gas-common',
    checkbox: {
      label: 'approval.gas.checkboxLabel',
      isMandatory: true,
      error: 'approval.gas.error',
    },
    bulletPoints,
  };
}

function createTariffInfoBulletPoints(
  tariffInfos: ApprovalFormItemBulletPointTariffInfo[],
  category: Sparte,
): ApprovalFormItemBulletPoint[] {
  return tariffInfos
    .filter(info => info.category.toLowerCase() === category.toLowerCase())
    .map(info => {
      return {
        label: 'approval.common.infoPdf',
        labelParams: { tariffName: info.tariffName },
        link: getPdfDocumentsSafeUrl() + info.tariffKey,
      } as ApprovalFormItemBulletPoint;
    });
}

function createSpecialTariffInfos(tariffInfos: ApprovalFormItemBulletPointTariffInfo[]): ApprovalFormItem[] {
  const specialTariffWhitelist = ['SOPTA_0001', 'SMEGA_0001', 'SOPTS_0001', 'SMEGS_0001', 'GEOPTA__01', 'GEMEGA__01'];

  return tariffInfos
    ?.map(info => {
      if (!specialTariffWhitelist.find(key => key === info.tariffKey)) {
        return null;
      }

      const labelPrefix = `approval.tariffs.${info.category?.toLowerCase()}.${info.tariffKey?.toUpperCase()}`;
      return {
        id: `approval-form-tariff-info-${info.tariffKey?.toUpperCase()}`,
        checkbox: {
          label: `${labelPrefix}.checkboxLabel`,
          isMandatory: true,
          error: `approval.tariffs.${info.category?.toLowerCase()}.error`,
        },
        text: `${labelPrefix}.text`,
      } as ApprovalFormItem;
    })
    .filter(i => !!i);
}

function createAllesSicherApproval(info: ApprovalFormItemAllesSicher): ApprovalFormItem {
  return {
    id: 'approval-form-alles-sicher',
    checkbox: {
      label: `${info.labelPrefix}.checkboxLabel`,
      isMandatory: true,
      error: `${info.labelPrefix}.error`,
    },
    text: `${info.labelPrefix}.text`,
    textParams: { url: info.url },
  };
}

function createImmediateDeliveryApproval(): ApprovalFormItem {
  return {
    id: 'approval-form-immediate-delivery',
    checkbox: {
      label: 'approval.common.immediateDelivery.checkboxLabel',
      initialValue: true,
    },
    text: 'approval.common.immediateDelivery.text',
  };
}

function createCancellationInfo(): ApprovalFormItem {
  return {
    id: 'approval-form-cancellation-info',
    text: 'approval.common.cancellationInfo.text',
  };
}

function createDsgvoInfo(): ApprovalFormItem {
  return {
    id: 'approval-form-dsgvo-info',
    text: 'approval.common.dsgvo.text',
  };
}
