import { Permission } from '@ikp/model/permission.model';

/**
 * This is the configuration for the permissions of the routes.
 * The key is the route path and the value is an array of permissions of which any is required to access the page.
 * Note: The ADMIN permission contains all other permissions, so the following configuration would allow everyone with
 * either the RECHNUNGEN permission or the ADMIN permission to access the path '/foo':
 * ```
 * export const ROUTE_PERMISSION_CONFIG = {
 *   '/foo': [Permission.RECHNUNGEN],
 * };
 * ```
 */
export const ROUTE_PERMISSION_CONFIG = {
  '/home/berechtigungen': [Permission.ADMIN],
};
