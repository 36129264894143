import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { Address, TextNodeType } from '@mwe/models';
import { DetailsLinkTypeEnum } from '@mwe/constants';
import { DetailsLinkTypeEnumExporter, isAddressGroupToBeDisplayedDisabled, isArrayWithMinOneItem } from '@mwe/utils';

export interface AddressDropdownItem {
  id: string;
  label: string;
}

@Component({
  selector: 'mwe-address',
  templateUrl: './address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent extends DetailsLinkTypeEnumExporter {
  addressIdx = input<number>();
  dropdownType = input<'pen' | 'dropdown' | 'custom' | 'dots'>();
  address = input.required<Address>();
  dropdownEditKey = input<string>();
  allDropdownItems = input<AddressDropdownItem[]>([]); // is split in featuredButtons and dropdownItems
  featuredBtnIds = input<string[]>([]);
  orderDateFormatted = input<string>();
  recentOrderMsgKey = input<string>();
  isArrowDownHidden = input<boolean>(false);
  isAddressDetailsBodyVisible = input<boolean>(false);
  productCategories = input<string[]>([]);
  useRedesignedCard = input(false);
  singleMarker = input(false);
  textNodeType = input<TextNodeType>();
  onItemClick = output<string>();
  disabled = computed<boolean>(() =>
    isAddressGroupToBeDisplayedDisabled(this.address().addressGroup.detailsLinkType, this.productCategories()),
  );
  dropdownEditId = computed<string>(() => `mwe-address-${this.addressIdx()}-dropdown`);
  formattedAddress = computed<string>(() => this.address().getGroupFormatted());
  tooltipClass = computed<{ [value: string]: boolean }>(() => ({
    single: this.singleMarker(),
    'no-after': !!this.orderDateFormatted() || this.isArrowDownHidden(),
    'has-body':
      (!!this.address().addressGroup.detailsLinkType && this.isAddressDetailsBodyVisible()) || this.orderDateFormatted()?.length > 0,
  }));
  detailsLinkItemKey = computed<string>(() => {
    if (this.address().addressGroup.detailsLinkType === DetailsLinkTypeEnum.RELOCATION) {
      return 'relocation.status';
    }

    if (
      this.address().addressGroup.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT ||
      this.address().addressGroup.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR
    ) {
      return 'moveout.status';
    }

    return '';
  });

  featuredButtons = computed<AddressDropdownItem[]>(() => {
    return this.allDropdownItems()?.filter(i => this.hasId(i.id));
  });
  dropdownItems = computed<AddressDropdownItem[]>(() => {
    if (!isArrayWithMinOneItem(this.featuredBtnIds())) {
      return this.allDropdownItems() || [];
    }

    return this.allDropdownItems().filter(i => !this.hasId(i.id));
  });
  hasTooltipBody = computed(() => {
    return (this.address().addressGroup.detailsLinkType && this.isAddressDetailsBodyVisible()) || this.orderDateFormatted()?.length > 0;
  });

  constructor() {
    super();
  }

  executeItemAction(id: string): void {
    this.onItemClick.emit(id);
  }

  private hasId(id: string) {
    return !!this.featuredBtnIds().find(fId => fId === id);
  }
}
