import { CommonMappingData, IBulletConfirmationStatusChangeResponse } from '../integrationlayer/api.model';
import { IAddress } from '../address/address.model';

export type ICAInformationRequestMappingData = CommonMappingData;
// tslint:disable-next-line:no-empty-interface
export type ICAInformationRequestStatusResponse = IBulletConfirmationStatusChangeResponse;
export interface ICAInformationRequestDTO {
  mappingData: ICAInformationRequestMappingData[];
  address: IAddress;
  customerDataChangeData?: { email: string; customerNumbers: string[] };
  kindOfInformation: string;
  timeRangeType: string;
  timeRangeFrom?: string;
  timeRangeTo?: string;
  infoRecipientEmail: string;
  processStatus?: string;
  createdAt?: string;
}

export const parseCAInformationRequestDTOFromOrderStatusDetail = (caInformationRequestDetails: any): ICAInformationRequestDTO => {
  return JSON.parse(caInformationRequestDetails.changeData);
};
