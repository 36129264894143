import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Action, HTTPError } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';

export interface LoggingServiceInterface {
  logError: (error: Error, message?: string) => Promise<void>;
  logAction: (action: Action) => Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class LoggingService {
  resourceUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getLoggingAPIOBaseURL() + 'api/log/';
  }

  async logError(error: Error, message?: string): Promise<void> {
    try {
      await lastValueFrom(
        this.http.post(this.resourceUrl + 'error', {
          message: `${message ? message + ': ' : ''}${this.errorMessage(error)}`,
          stack: error && error.stack,
        }),
      );
    } catch (e) {
      console.error(`error on writing error log: ${e}`);
    }
  }

  private errorMessage(error: HTTPError | Error): string {
    if (error instanceof HTTPError) {
      return `${JSON.stringify(error.error)}`;
    }
    if (error instanceof Error) {
      return error.message;
    }
    return 'Unrecognized Error object';
  }

  async logAction(action: Action): Promise<void> {
    try {
      // tslint:disable-next-line:no-console
      console.log(action);
      await firstValueFrom(this.http.post(this.resourceUrl + 'action', action));
    } catch (e) {
      console.error(`error on writing action log: ${e}`);
    }
  }
}
