import { Inject, Injectable } from '@angular/core';
import { OAuthErrorEvent, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Identity } from '@mwe/models';
import { AppStorageService } from '../cache/app-storage.service';
import { EnvironmentService } from '../environment/environment.service';
import { AccountStateService } from '../account/state/account-state.service';
import { ApplicationPaths } from '@mwe/environment';
import { LoggingService } from '../logging/logging.service';
import { stringToBoolean } from '@mwe/utils';
import { ENVIRONMENT_SERVICE_TOKEN } from '@mwe/constants';

const LOGOUT_IN_PROGRESS_KEY = 'logout_in_progress';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private oauthService: OAuthService,
    private oauthStorage: OAuthStorage,
    private appStorage: AppStorageService,
    @Inject(ENVIRONMENT_SERVICE_TOKEN) private environmentService: EnvironmentService,
    private accountStateService: AccountStateService,
    private loggingService: LoggingService,
  ) {
    this.registerEventListener();
    this.cleanLogoutInProgress();
  }

  async configureOAuth(): Promise<boolean> {
    try {
      this.oauthService.configure(this.environmentService.getAuthConfig());
      this.oauthService.setupAutomaticSilentRefresh();
      return await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  login(reconfiguredPaths?: ApplicationPaths): void {
    if (reconfiguredPaths) {
      this.oauthService.configure({
        ...this.environmentService.getAuthConfig(),
        redirectUri: reconfiguredPaths.loginRedirectUri || this.environmentService.getAuthConfig().redirectUri,
        postLogoutRedirectUri: reconfiguredPaths.logoutRedirectUri || this.environmentService.getAuthConfig().postLogoutRedirectUri,
      });
    }
    this.oauthService.initLoginFlow();
  }

  logout(logoutRedirectUri?: string): void {
    if (this.isLogoutInProgess()) {
      return;
    }
    this.setLogoutInProgress();
    this.appStorage.clear();
    this.accountStateService.clearBeforeCollaboration();
    const redirectUri = `${window.location.origin}/${this.environmentService.getPortalCustomerType()}${logoutRedirectUri ?? ''}`;

    if (logoutRedirectUri) {
      this.oauthService.postLogoutRedirectUri = `${redirectUri}`;
    }

    if (this.oauthService.getIdToken()) {
      this.oauthService.revokeTokenAndLogout();
    } else {
      try {
        this.oauthService
          .refreshToken()
          .then(response => this.oauthService.logOut())
          .catch(error => {
            location.href = `${redirectUri}`;
          });
      } catch (err) {
        console.error(err);
        sessionStorage.clear();
        location.href = `${redirectUri}`;
      }
    }
  }

  private setLogoutInProgress(inProgress?: boolean) {
    this.oauthStorage.setItem(LOGOUT_IN_PROGRESS_KEY, `${inProgress ?? true}`);
  }

  private isLogoutInProgess(): boolean {
    return stringToBoolean(this.oauthStorage.getItem(LOGOUT_IN_PROGRESS_KEY));
  }

  private cleanLogoutInProgress() {
    this.oauthStorage.removeItem(LOGOUT_IN_PROGRESS_KEY);
  }

  isAuthenticated(): boolean {
    // bypass auth during cypress execution if authorized access is required
    return !!(window as any).cypressAuthBypass || (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken());
  }

  reloadUserProfile(): Promise<object> {
    return this.oauthService.loadUserProfile();
  }

  register(): void {
    window.location.href = this.environmentService.getRegistrationUri();
  }

  getIdentity(): Identity {
    return this.oauthService.getIdentityClaims() as Identity;
  }

  private registerEventListener() {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        const error = event as OAuthErrorEvent;

        switch (error.type) {
          case 'discovery_document_load_error':
            if (error.reason) {
              this.loggingService.logError(error.reason as Error);
            }
            this.appStorage.setAuthServerFailure({
              type: 'danger',
              title: 'home.authServerFailure.title',
              message: 'home.authServerFailure.message',
            });
            break;
          case 'code_error':
            location.href = '';
            break;
          case 'token_refresh_error':
            if (error.reason) {
              this.loggingService.logError(error.reason as Error);
            }
            this.logout('');
            break;
          case 'jwks_load_error':
            break;
        }
      }
    });
  }
}
