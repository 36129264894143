<div>
  <mwe-alert
    *ngIf="error"
    type="danger"
    [titleKey]="alertTitleKey"
    [messageKey]="alertMsgKey"
    showIcon="false"
    extended="false"
  ></mwe-alert>
  <form #formComponent="ngForm" class="form-horizontal col-12" [formGroup]="form">
    <div class="mb-3">
      <div class="form-floating">
        <input
          data-test="iban-form-iban-input"
          [attr.type]="'text'"
          class="form-control"
          [ngClass]="{
            'is-invalid': !isValidating && (ibanError || (ibanControl.errors && !this.ibanControl.pristine)),
            'is-valid': !isValidating && inputAriaValid === true,
          }"
          required
          [attr.id]="ibanControlName"
          [attr.name]="ibanControlName"
          [formControlName]="ibanControlName"
          [attr.minlength]="6"
          [attr.maxlength]="32"
          [attr.aria-invalid]="inputAriaValid === false || null"
          [attr.aria-describedby]="ibanControlName + '-desc'"
          placeholder="{{ config.ibanPlaceholderKey | translate }}"
          (input)="onInputIBAN($event.target.value)"
          (blur)="validate()"
        />
        <label [attr.for]="ibanControlName" class="form-label" [translate]="config.ibanLabelKey"></label>
        <div class="form-floating-icon" *ngIf="isValidating">
          <div class="loading-spinner" style="--bs-loading-spinner-color: var(--bs-gray-light)"></div>
        </div>
      </div>
      <div
        class="font-size-11 text-danger mt-1"
        [attr.id]="ibanControlName + '-desc'"
        *ngIf="ibanError || (ibanControl.errors && !this.ibanControl.pristine)"
      >
        <i aria-hidden="true" class="fa fa-exclamation-circle mr-1"></i>
        <span [translate]="config.ibanErrorKey"></span>
      </div>
    </div>
  </form>
</div>
