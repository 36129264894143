import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { anyTrue, isArrayWithMinOneItem, removeLeadingZero } from '@mwe/utils';

/*
VALIDATOR_NAME_PATTERN
^[^0-9_!¡?÷?¿/\\+=@#$§€%ˆ&*(){}|~<>;:[\]]{1, }$
supports following characters
abcdefghijklmnopqrstwxyz
ABCDEFGHIJKLMNOPQRSTUVWXYZ
áéíóúäëïöüÄ'
陳大文
łŁőŐűŰZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųū
ÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁ
ŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ.-
ñÑâê都道府県Федерации
আবাসযোগ্য জমির걸쳐 있는
*/

/*
VALIDATOR_NAME_PATTERN
^[^0-9_!¡?÷?¿/\\+=@#$§€%ˆ&*(){}|~<>;:[\]]{1, }$
supports following characters
abcdefghijklmnopqrstwxyz
ABCDEFGHIJKLMNOPQRSTUVWXYZ
áéíóúäëïöüÄ'
陳大文
łŁőŐűŰZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųū
ÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁ
ŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ.-
ñÑâê都道府県Федерации
আবাসযোগ্য জমির걸쳐 있는
*/
export const VALIDATOR_NAME_PATTERN = /^[^0-9_!¡?÷?¿/\\+=@#$§%°ˆ#"`´&*()|~<>,;:]{1,}$/;

@Directive({
  selector: 'input[mweInputKeyDirective]',
})
export class InputKeyDirective {
  @Input() mweInputKeyDirective: string;
  intRegExp = new RegExp('^[0-9]+$');
  amountRegExp = new RegExp('^\\d+(,\\d{0,2})?$');
  telefonNumberRegExp = new RegExp('^[+0-9][0-9]*$');
  intRegExpEmpty = new RegExp('^[0-9]*$');
  inputMethods = ['autoFocusElement', 'integer', 'amount', 'telefonNumber', 'name'];
  blurMethods = ['leadingZero', 'removeLeadingZero'];

  constructor(
    private _el: ElementRef,
    private control: NgControl,
  ) {}

  @HostListener('input', ['$event'])
  onClick(event: any): void {
    this.handleMethods(event, this.inputMethods);
  }

  @HostListener('blur', ['$event.target'])
  onBlur(input: any): void {
    this.handleMethods(input, this.blurMethods);
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent): void {
    const methods = this.getMethods();
    const integerCheckFailed = methods.includes('integer') && !this.intRegExp.test(event.clipboardData.getData('Text'));
    const nameCheckFailed = methods.includes('name') && !VALIDATOR_NAME_PATTERN.test(event.clipboardData.getData('Text'));
    const telefonNumberCheckFailed =
      methods.includes('telefonNumber') && !this.telefonNumberRegExp.test(event.clipboardData.getData('Text'));

    if (anyTrue(integerCheckFailed, nameCheckFailed, telefonNumberCheckFailed)) {
      event.preventDefault();
    }
  }

  private integer = (event: any) => {
    const pattern = this.intRegExp;
    this.checkInput(event, pattern);
  };

  private amount = (event: any) => {
    const pattern = this.amountRegExp;
    this.checkInput(event, pattern);
  };

  private name = (event: any) => {
    const pattern = VALIDATOR_NAME_PATTERN;
    this.checkInput(event, pattern);
  };

  private telefonNumber = (event: any) => {
    const pattern = this.telefonNumberRegExp;
    this.checkInput(event, pattern);
  };

  private integerEmpty = (input: any) => {
    if (input.value && !this.intRegExpEmpty.test(input.value)) {
      input.value = input.value.slice(0, input.value.length - 1);
    }
  };

  private leadingZero = (input: any) => {
    if (input.value < 10 && input.value.length === 1) {
      const newValue = '0' + input.value;
      this.control.control.patchValue(newValue);
    }
  };

  private removeLeadingZero = (input: any) => {
    if (input.value) {
      const newValue = removeLeadingZero(input?.value);
      this.control.control.setValue(newValue);
    }
  };

  private maxLength = (input: any) => {
    if (input.maxLength && input.value.length > input.maxLength) {
      input.value = input.value.slice(0, input.maxLength);
    }
  };

  private autoFocusElement = (event: any, params: string) => {
    const input = event.target;
    if (input.maxLength && input.value.length === input.maxLength) {
      this.control.control.patchValue(input.value);
      const focusInput = document.getElementById(params) as HTMLInputElement;
      focusInput.focus();
      if (focusInput.type === 'text') {
        focusInput.select();
      }
    }
  };

  private getMethods(): string[] {
    if (!this.mweInputKeyDirective) {
      return [];
    }

    return this.mweInputKeyDirective.split(';');
  }

  private checkInput(event: any, pattern: RegExp): void {
    const input = event.target;
    if (input.value?.length > 0 && !pattern.test(input.value)) {
      const currentSelectionStart = input.selectionStart;
      const fixedValue = [input.value.slice(0, currentSelectionStart - 1), input.value.slice(currentSelectionStart)].join('');
      input.value = fixedValue;
      input.selectionStart = input.selectionEnd = currentSelectionStart - 1;
      this.control.control.setValue(fixedValue);
    }
  }

  private handleMethods(input: any, allowedMethods: string[]): void {
    const methods = this.getMethods();

    if (!isArrayWithMinOneItem(methods) || !isArrayWithMinOneItem(allowedMethods)) {
      return;
    }

    for (let methodName of methods) {
      methodName = methodName.trim();
      let params: string = null;
      if (methodName.includes('(')) {
        params = methodName.substring(methodName.indexOf('(') + 1, methodName.indexOf(')'));
        methodName = methodName.substring(0, methodName.indexOf('(')).trim();
      }
      if (!allowedMethods.includes(methodName)) {
        continue;
      }
      if (this[methodName]) {
        if (params === null) {
          this[methodName](input);
        } else {
          this[methodName](input, params);
        }
      }
    }
  }
}
