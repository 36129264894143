import packageInfo from 'package.json';
import {
  AppAuthConfig,
  AppName,
  AuthServerPaths,
  Environment,
  EnvironmentAdmintool,
  EnvironmentSetup,
  EnvironmentUris,
} from './environment.model';

const appVersion = packageInfo.version;

const realmPath = '/auth/realms/{{realm}}';
const openIdPath = `${realmPath}/protocol/openid-connect`;
const registrationPath = `${openIdPath}/registrations?client_id={{clientId}}&response_mode=query&response_type={{responseType}}&scope={{scope}}&redirect_uri=${window.location.origin}/{{appName}}/registration&company={{isCompany}}`;
const loginPath = `${openIdPath}/auth?client_id=self-service&scope=openid&response_mode=fragment&response_type=code&redirect_uri=`;
const loginFlowPath = `${openIdPath}/auth?response_type={{responseType}}&client_id={{clientId}}&redirect_uri=${window.location.origin}/{{appName}}/registration?redirect={{appName}}/profil&scope={{scope}}&kc_idp_hint=logwien`;
const logoutPath = `${openIdPath}/logout`;
const collaborationPath = '/rest/logwien/admin/users/{userId}/partners/{partnerName}/services/{serviceName}/impersonation';
const profileReferer = 'referrer=self-service&referrer_uri={{host}}/profile';

// TODO Es ist eine Kopie der Funktion in libs/utils/src/lib/common/string.utils.ts:47 und gehört nach dem Auflösen von circular deps in model_utils.ts entfernt.
export function applyVariablesToTemplateString(
  template: string,
  variables: Record<string, string>,
  placeholderPattern = /(\{{2}([^\}]+)\}{2})/g,
) {
  return template.replace(placeholderPattern, (...args): string => {
    const [, token, placeholderName] = args;
    return placeholderName in variables ? variables[placeholderName] : `${token}`;
  });
}

// TODO Auch nach refactoring entfernen
export type GenericStringRecordType<T extends Record<string, string | boolean>> = {
  [K in keyof T as K]: `${T[K]}`;
};

/**
 * Converts type <T> into Record<string, string>
 * @param object
 */
export function toStringRecord<T extends Record<string, string | boolean>>(object: T) {
  return Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `${value}`,
    }),
    {} as GenericStringRecordType<T>,
  );
}

export const authServerPaths: AuthServerPaths = {
  realm: `${realmPath}`,
  openID: `${openIdPath}`,
  collaboration: `${collaborationPath}`,
  login: `${loginPath}`,
  loginFlow: `${loginFlowPath}`,
  logout: `${logoutPath}`,
  registration: `${registrationPath}`,
};

const envSetup: EnvironmentSetup = {
  production: false,
  name: 'dev',
  chatbotWidgetId: 'N/A',
};

const envUriConfig: EnvironmentUris = {
  authServerInternal: 'https://test.service.wienerstadtwerke.at',
  authServerExternal: 'https://test.log.wien',
  bskw: 'https://test-community.wienenergie.at',
  privat: 'http://localhost/privat',
  business: 'http://localhost/business',
  portalBase: 'http://localhost',
  portalApi: 'http://localhost:8080',
  mnrApi: 'http://localhost:8180/',
  admintool: 'http://localhost/admintool',
  admintoolApi: 'http://localhost:8280',
  mac: 'http://localhost:4204',
  documents: 'test-dokumente.wienenergie.at/link/',
  superSchnellBestellen: 'https://www-development.wienenergie.at/privat/produkte/internet-tv-telefonie/',
  staticContent: 'https://test-static.wienenergie.at/',
  addGoodiesForm: 'https://www-development.wienenergie.at/business/vorteile-anbieten/',
  oss: 'https://test-meineservices.wstw.at/',
};

const portalAuthConfig: AppAuthConfig = {
  storagePrefix: '{{appName}}',
  loginRedirectPath: '/{{appName}}',
  logoutRedirectPath: '/{{appName}}/#logout=success',
  realm: 'logwien',
  clientId: 'we-meine-we',
  responseType: 'code',
  scope: 'openid',
  showDebugInformation: false,
  preserveRequestedRoute: true,
};

export const admintoolAppAuthClientConfig: AppAuthConfig = {
  storagePrefix: 'admtool',
  loginRedirectPath: '/admintool',
  logoutRedirectPath: '/admintool/#logout=success',
  realm: 'admin',
  clientId: 'client-meinwienenergie-admin-logwien-public',
  responseType: 'code',
  scope: 'openid microprofile-jwt',
};

export const macAppAuthClientConfig: AppAuthConfig = {
  storagePrefix: 'mac',
  loginRedirectPath: '/mac/#',
  logoutRedirectPath: '/mac/#logout=success',
  realm: 'admin',
  clientId: 'client-meinwienenergie-mac-public',
  responseType: 'code',
  scope: 'openid microprofile-jwt',
};

const portalAuthConfigRecord = toStringRecord(portalAuthConfig);

function substituteTokens(letterObject: AuthServerPaths, tokens: GenericStringRecordType<AppAuthConfig>): AuthServerPaths {
  const json = JSON.stringify(letterObject);
  const substitutedJson = applyVariablesToTemplateString(json, tokens);
  return JSON.parse(substitutedJson);
}

export const formattedPortalAuthServerPaths = substituteTokens(authServerPaths, portalAuthConfigRecord);

export function portalEnvironmentBuilder(setup: EnvironmentSetup, uris: EnvironmentUris): Environment {
  return buildWithPreformattedPaths(setup, uris, formattedPortalAuthServerPaths);
}

function buildWithPreformattedPaths(setup: EnvironmentSetup, uris: EnvironmentUris, paths: AuthServerPaths): Environment {
  const substitutedProfileReferer = applyVariablesToTemplateString(profileReferer, { host: `${uris.authServerExternal}` });
  const loginUri = `${uris.authServerExternal}${paths.login}`;
  const userAuthProfileUri = `${loginUri}${encodeURIComponent(uris.authServerExternal + '/profile')}`;
  return {
    production: setup.production,
    env: `${setup.name}`,
    apiUrl: '/', // API resources in environment file?
    mnrApiUrl: uris.mnrApi,
    documentUrl: `${uris.documents}`,
    authConfig: {
      issuer: `${uris.authServerExternal}${paths.realm}`,
      redirectUri: `${window.location.origin}${portalAuthConfig.loginRedirectPath}`,
      postLogoutRedirectUri: `${window.location.origin}${portalAuthConfig.logoutRedirectPath}`,
      clientId: `${setup.portal?.authentication.clientId ?? portalAuthConfig.clientId}`,
      responseType: `${portalAuthConfig.responseType}`,
      scope: `${portalAuthConfig.scope}`,
      showDebugInformation: portalAuthConfig.showDebugInformation,
      preserveRequestedRoute: portalAuthConfig.preserveRequestedRoute,
    },
    registrationUri: `${uris.authServerExternal}${paths.registration}`,
    logoutUri: `${uris.authServerExternal}${paths.logout}`,
    businessServices: {
      uri: `${uris.business}`,
      entryPath: 'business',
      processPath: null,
    },
    mweServices: {
      uri: `${uris.privat}`,
      entryPath: 'privat',
      processPath: null,
    },
    staticContent: `${uris.staticContent}`,
    bskwUri: `${uris.bskw}`,
    authServer: {
      uri: `${uris.authServerExternal}`,
      profileReferer: `${substitutedProfileReferer}`,
      profileUri: `${userAuthProfileUri}`,
      profileChangeEmailUri: 'personal-data-email',
      profileChangePasswordUri: '/auth/realms/logwien/account/password',
      profileChangePersonalDataUri: 'personal-data',
      partnerName: 'wien-energie',
      registrationUri: `${uris.authServerExternal}${paths.registration}`, // Wiederholt sich mit registrationUri
      logoutUri: `${uris.authServerExternal}${paths.logout}`,
      loginUri: `${loginUri}`,
      loginFlowUri: `${uris.authServerExternal}${paths.loginFlow}`,
    },
    maintenanceRefreshIntervall: 15,
    chatbotWidgetId: `${setup.chatbotWidgetId}`,
    appVersion: appVersion,
    addGoodiesForm: `${uris.addGoodiesForm}`,
    ossUri: `${uris.oss}`,
  };
}

export const baseEnvironment: Environment = portalEnvironmentBuilder(envSetup, envUriConfig);

export const baseEnvironmentAT: EnvironmentAdmintool = internalToolsEnvironmentBuilder(envUriConfig, admintoolAppAuthClientConfig);
export const baseEnvironmentMAC: EnvironmentAdmintool = internalToolsEnvironmentBuilder(envUriConfig, macAppAuthClientConfig);

export function internalToolsEnvironmentBuilder(envUris: EnvironmentUris, authClientConfig: AppAuthConfig): EnvironmentAdmintool {
  return {
    production: false,
    env: '',
    keycloakConfig: {
      issuer: `${envUris.authServerInternal}/auth/realms/${authClientConfig.realm}`,
      redirectUri: `${window.location.origin}${authClientConfig.loginRedirectPath}`,
      postLogoutRedirectUri: `${window.location.origin}${authClientConfig.logoutRedirectPath}`,
      responseType: `${authClientConfig.responseType}`,
      scope: `${authClientConfig.scope}`,
      showDebugInformation: false,
      preserveRequestedRoute: true,
      clientId: `${authClientConfig.clientId}`,
    },
    apiUrl: `${envUris.admintoolApi}`,
    collaborationUrl: `${envUris.authServerExternal}${collaborationPath}`,
    collaborationTargetUrl: `${envUris.portalBase}/kollaborieren`,
    collaborationTargetUrlBskw: `${envUris.bskw}/dashboard`,
    appVersion: 'N/A',
    admintoolUri: `${envUris.admintool}`,
    storagePrefix: `${authClientConfig.storagePrefix}`,
    macUri: `${envUris.mac}`,
    ROLES: {
      // Rollen können als Array substituiert werden
      WE_ADMIN: 'N/A',
      WE_VERWALTEN: 'WE.KSQ.MWE.Admin-Verwaltung',
      WE_BSKW_ADMIN: 'WE.KSQ.MWE.Admin-Bskw',
      WE_REGISTRIEREN: 'WE.MWE.AdminTool-Registrieren',
      WE_KSD_MAC_AnyMAC: 'WE.KSD.MAC.AnyMAC',
      WE_KSD_MAC_ALL: 'N/A',
      WE_KSD_MAC_KSAdmin: 'N/A',
      WE_KSD_MAC_VMAdmin: 'N/A',
      WE_KSD_MAC_SG: 'N/A',
      WE_KSD_MAC_WAERME: 'N/A',
      WE_KSD_MAC_TANKE: 'N/A',
      WE_KSD_MAC_SUPERSCHNELL: 'N/A',
    },
    toggleRefreshMins: 15,
    staticContent: 'N/A',
    appName: AppName.ADMINTOOL,
  };
}
