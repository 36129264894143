<div
  class="card card-sliding"
  [attr.data-test]="id"
  (click)="onClick($event)"
  [ngClass]="{ 'card-has-focus': cardIsFocused }"
  style="cursor: pointer"
  [attr.title]="text | translate"
  tabindex="0"
>
  <button
    class="btn btn-bubble card-focus-effect-trigger"
    (click)="$event.stopPropagation(); onClick($event)"
    (focus)="cardIsFocused = true"
    (blur)="cardIsFocused = false"
    [ngClass]="type === 'featured' ? 'btn-tertiary' : 'btn-primary'"
    [attr.title]="text | translate"
  >
    <i class="fas fa-chevron-right" aria-hidden="true"></i>
  </button>
  <div class="card-sliding-inner" aria-hidden="true" [ngClass]="{ featured: type === 'featured' }">
    <p class="h6 text-white" *ngIf="type === 'default'" [translate]="text"></p>
    <p class="h6 text-white" *ngIf="type === 'featured'">{{ text }}</p>
  </div>
</div>
