<div class="mb-5 h-100">
  <mwe-basic-card [height100]="true" [ngClass]="{ pointer: route }" (click)="cardClicked()" [attr.id]="cardId" [attr.data-test]="cardId">
    <div class="row gx-4">
      @if (iconClass || iconImage) {
        <div class="col-auto" data-test="card-image">
          @if (iconClass) {
            <i class="fas {{ iconClass }} text-secondary" style="font-size: 2rem"></i>
          } @else if (iconImage) {
            <img *ngIf="iconImage" src="assets/icons/{{ iconImage }}" />
          }
        </div>
      }
      <div class="col">
        @if (titleLabel) {
          <h5 [translate]="titleLabel" data-test="card-headline"></h5>
        }
        <div class="card-desc text--big mb-3">
          <ng-content></ng-content>
        </div>

        @if (isCtaButtonLink) {
          <div class="mt-3">
            <mwe-pre-load-data
              class="d-flex"
              [successTemplate]="buttonLink"
              loadingSpinnerType="normal-without-box"
              alertTitleKey=""
              [alertMessageKey]="ctaButtonStateErrorMessage"
              [state]="ctaButtonState"
            >
            </mwe-pre-load-data>
            <ng-template #buttonLink>
              <mwe-button-link
                [customCss]="'mt-0 ' + ctaButtonCss"
                [dataTest]="ctaButtonDataTest"
                [label]="ctaButtonLabel"
                (clicked)="ctaButtonClicked.emit()"
              ></mwe-button-link>
            </ng-template>
          </div>
        } @else {
          <mwe-button [buttonId]="ctaButtonDataTest" [labelKey]="ctaButtonLabel" (clicked)="ctaButtonClicked.emit()"></mwe-button>
        }
      </div>
      @if (route) {
        <div class="col-auto align-self-center">
          <i class="fas fa-chevron-right text-secondary"></i>
        </div>
      }
    </div>
  </mwe-basic-card>
</div>
