@if (state() === ServiceStateEnum.LOADING) {
  @if (loadingTemplate()) {
    <ng-container *ngTemplateOutlet="loadingTemplate()" />
  } @else if (loadingSpinnerType() === 'normal') {
    <mwe-basic-card>
      <div class="d-flex align-items-center justify-content-center" style="min-height: 10rem">
        <mwe-loading-spinner />
      </div>
    </mwe-basic-card>
  } @else if (loadingSpinnerType() === 'normal-without-box') {
    <mwe-loading-spinner />
  } @else if (loadingSpinnerType() === 'extended') {
    <div>
      <mwe-loading-spinner-extended />
    </div>
  } @else if (loadingSpinnerType() === 'extended-white-background') {
    <div>
      <mwe-loading-spinner-extended-white-background [loadingSpinnerText]="loadingSpinnerText()" />
    </div>
  }
}

@if (state() === ServiceStateEnum.ERROR) {
  @if (errorTemplate()) {
    <ng-container *ngTemplateOutlet="errorTemplate()" />
  } @else {
    @if (!specialAlertError()) {
      <mwe-alert
        [type]="alertType()"
        [titleKey]="alertTitleKey()"
        [messageKey]="alertMessageKey()"
        [placeholderMap]="errorPlaceholderMap()"
      />
    } @else {
      <mwe-special-alert
        [titleKey]="alertTitleKey()"
        [messageKey]="alertMessageKey()"
        [reloadButtonKey]="reloadButtonKey()"
        (reloadEventEmitter)="reloadButtonClicked.emit()"
      />
    }
  }
}

@if (state() === ServiceStateEnum.SUCCESS) {
  <ng-container *ngTemplateOutlet="successTemplate()" />
}
