import {
  GOODIE_ROUTE,
  INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE,
  NEW_CLIENT_ROUTE,
  PROFILE_NEWSLETTER_ROUTE,
  PROFILE_ROUTE,
  TARIFF_CHANGE_ROUTE,
  TERMS_OF_USE_ROUTE,
} from '@mwe/constants';
import { EnvironmentService, NavigationLogic, PopupService, ProfileService } from '@mwe/services';
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { EnvironmentCustomerType } from '@mwe/environment';
import { IPopupModel } from '@mwe/models';
import { OrderProductsPopupComponent } from '../../order-products-popup/order-products-popup.component';

@Component({
  selector: 'mwe-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit, AfterViewInit {
  currentYear: number;

  private profileService = inject(ProfileService);
  private environmentService = inject(EnvironmentService);
  private navigationLogic = inject(NavigationLogic);
  private popupService = inject(PopupService);

  links = {
    b2c: {
      newClient: NEW_CLIENT_ROUTE,
      partialPaymentOverview: INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE,
      goodies: GOODIE_ROUTE,
      newsletter: `${PROFILE_ROUTE}/${PROFILE_NEWSLETTER_ROUTE}`,
      tariffChange: TARIFF_CHANGE_ROUTE,
    },
    b2b: {
      newClient: NEW_CLIENT_ROUTE,
      tariffChange: TARIFF_CHANGE_ROUTE,
      newsletter: `${PROFILE_ROUTE}/${PROFILE_NEWSLETTER_ROUTE}`,
      goodies: GOODIE_ROUTE,
    },
    termsOfUseRoute: `/${this.environmentService.getPortalUrlPrefix()}/${TERMS_OF_USE_ROUTE}`,
  };

  ngOnInit(): void {
    this.setCurrentYear();
  }

  ngAfterViewInit() {
    this.checkOrderProductPopup();
  }

  setCurrentYear(): void {
    const profileInfo = this.profileService.getProfileInfo();
    const serverTimestamp = profileInfo.serverTimestamp.split('-');
    this.currentYear = new Date(
      parseInt(serverTimestamp[0], 10),
      parseInt(serverTimestamp[1], 10) - 1,
      parseInt(serverTimestamp[2], 10),
    ).getFullYear();
  }

  navigateToB2C(route: string): void {
    this.navigationLogic.navigateToB2C(route);
  }

  navigateToB2B(route: string): void {
    this.navigationLogic.navigateToB2B(route);
  }

  /*
  Order Products Popup - START
  - footer-link "Produkte bestellen" should open OrderProductsPopupComponent as Popup
  - problem: if you switch portal (e.g. from /privat to /business) you have to load a new app
  -- so we have to add custom query param to switch-portal-url
  -- and we have to check on app-load in new portal if popup has to be displayed
  */
  orderProducts(type: EnvironmentCustomerType) {
    if (type !== this.environmentService.getPortalCustomerType()) {
      // target is in other portal, open portal and then popup
      this.navigationLogic.switchPortal(`?${this.orderProductsQueryParamName}=true`);
      return;
    }

    // same context, can open popup
    this.openProductOrderPopup();
  }

  private orderProductsQueryParamName = 'produkteBestellenPopup';
  private openProductOrderPopup(): void {
    const model: IPopupModel = {
      id: 'products-footer-order-products',
      component: OrderProductsPopupComponent,
      componentData: this.environmentService.getPortalCustomerType() === 'privat',
      modalSize: 'xl',
    };
    this.popupService.open(model);
  }

  private checkOrderProductPopup() {
    const canOpen = location.search.indexOf(this.orderProductsQueryParamName) !== -1;

    if (canOpen) {
      this.openProductOrderPopup();
      const url = new URL(location.href);
      url.searchParams.delete(this.orderProductsQueryParamName);
      history.replaceState(null, null, url);
    }
  }
  // Order Products Popup - END
}
