import { Component, computed, input } from '@angular/core';
import { BadgeSize, BadgeType } from '../badge.model';

const SizingThemes = {
  micro: {
    iconMarginClass: '',
    badgeClass: 'badge-mc',
  },
  xs: {
    iconMarginClass: 'mr-2',
    badgeClass: 'badge-xs',
  },
  sm: {
    iconMarginClass: 'mr-2',
    badgeClass: 'badge-sm',
  },
  md: {
    iconMarginClass: 'mr-12px',
    badgeClass: 'badge-md',
  },
  lg: {
    iconMarginClass: 'mr-12px',
    badgeClass: 'badge-lg',
  },
};

const ColorSchemes = {
  success: {
    subtle: {
      backgroundClass: 'bg-success-100',
      iconColorClass: 'text-success',
      textColorClass: 'text-body',
    },
    bold: {
      backgroundClass: 'bg-success',
      iconColorClass: 'text-white',
      textColorClass: 'text-white',
    },
  },
  danger: {
    subtle: {
      backgroundClass: 'bg-danger-100',
      iconColorClass: 'text-danger',
      textColorClass: 'text-body',
    },
    bold: {
      backgroundClass: 'bg-danger',
      iconColorClass: 'text-white',
      textColorClass: 'text-white',
    },
  },
  info: {
    subtle: {
      backgroundClass: 'bg-blue-050',
      iconColorClass: 'text-secondary-light',
      textColorClass: 'text-body',
    },
    bold: {
      backgroundClass: 'bg-secondary-light',
      iconColorClass: 'text-white',
      textColorClass: 'text-white',
    },
  },
  warning: {
    subtle: {
      backgroundClass: 'bg-warning-100',
      iconColorClass: 'text-warning',
      textColorClass: 'text-body',
    },
    bold: {
      backgroundClass: 'bg-warning',
      iconColorClass: 'text-white',
      textColorClass: 'text-white',
    },
  },
  brand: {
    subtle: {
      backgroundClass: 'bg-brand-100',
      iconColorClass: 'text-primary',
      textColorClass: 'text-body',
    },
    bold: {
      backgroundClass: 'bg-primary',
      iconColorClass: 'text-white',
      textColorClass: 'text-white',
    },
  },
  neutral: {
    subtle: {
      backgroundClass: 'bg-white',
      iconColorClass: 'text-body',
      textColorClass: 'text-body',
    },
    bold: {
      backgroundClass: 'bg-gray-light',
      iconColorClass: 'text-white',
      textColorClass: 'text-white',
    },
  },
};

@Component({
  selector: 'mwe-badge',
  templateUrl: './badge.component.html',
})
export class BadgeComponent {
  text = input<string>();
  type = input.required<BadgeType>();
  emphasis = input.required<'bold' | 'subtle'>();
  size = input.required<BadgeSize>();
  iconClass = input<string>();

  sizingTheme = computed(() => {
    return SizingThemes[this.size()];
  });
  colorScheme = computed(() => {
    return ColorSchemes[this.type()][this.emphasis()];
  });
}
