<ng-container [ngSwitch]="headlineNodeType">
  <h1 class="{{ cssClass }}" *ngSwitchCase="'h1'" [style]="style">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h1>
  <h2 class="{{ cssClass }}" *ngSwitchCase="'h2'" [style]="style">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h2>
  <h3 class="{{ cssClass }}" *ngSwitchCase="'h3'" [style]="style">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h3>
  <h4 class="{{ cssClass }}" *ngSwitchCase="'h4'" [style]="style">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h4>
  <h5 class="{{ cssClass }}" *ngSwitchCase="'h5'" [style]="style">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h5>
  <h6 class="{{ cssClass }}" *ngSwitchCase="'h6'" [style]="style">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </h6>
  <p class="{{ cssClass }}" *ngSwitchCase="'p'" [style]="style">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </p>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
