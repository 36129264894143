<ng-template #asAlert>
  <mwe-alert type="info">
    <p
      class="text--big text-white"
      [ngClass]="{ 'mb-2': isFernwaermeInfoVisible || isStromGasInfoVisible }"
      [innerHTML]="'invoiceData.change.newBankData.info' | translate"
    ></p>
    <p
      class="text--big text-white"
      [ngClass]="{ 'mb-2': isFernwaermeInfoVisible }"
      *ngIf="isStromGasInfoVisible"
      [innerHTML]="'invoiceData.change.newBankData.infoStromGas' | translate"
    ></p>
    <p
      class="text--big text-white"
      *ngIf="isFernwaermeInfoVisible"
      [innerHTML]="'invoiceData.change.newBankData.infoFernwaerme' | translate"
    ></p>
  </mwe-alert>
</ng-template>

<ng-template #asInfoText>
  <mwe-info-text-card [text]="'invoiceData.change.newBankData.info' | translate" [iconClass]="'fa-info-circle'"></mwe-info-text-card>
  <div class="mt-2" *ngIf="isStromGasInfoVisible">
    <mwe-info-text-card
      [text]="'invoiceData.change.newBankData.infoStromGas' | translate"
      [iconClass]="'fa-info-circle'"
    ></mwe-info-text-card>
  </div>

  <div class="mt-2" *ngIf="isFernwaermeInfoVisible">
    <mwe-info-text-card
      [text]="'invoiceData.change.newBankData.infoFernwaerme' | translate"
      [iconClass]="'fa-info-circle'"
    ></mwe-info-text-card>
  </div>
</ng-template>
@if (useTemplate === 'info-text') {
  <ng-container *ngTemplateOutlet="asInfoText"></ng-container>
} @else {
  <ng-container *ngTemplateOutlet="asAlert"></ng-container>
}
