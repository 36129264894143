import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as NavigationConstants from '@mwe/constants';
import { hasSameClearingAccount } from '@mwe/utils';
import {
  Address,
  ClearingAccount,
  IAddressProducts,
  ILSystem,
  NavigatingBackTo,
  parseAddress,
  Product,
  Sparte,
  UnpaidInvoices,
} from '@mwe/models';
import { NavigationStateService } from '../../cache/navigation-state.service';
import { CustomerDataChangeLogic } from '../../customer/change/customer-data-change.logic';
import { FindPaymentSolutionLogic } from '../../invoices/find-payment-solution/find-payment-solution.logic';
import { InteractiveInvoicesLogic } from '../../invoices/interactive-invoices/interactive-invoices.logic';
import { InvoiceDataChangeLogic } from '../../invoices/invoice-data/change/invoice-data-change.logic';
import { MoveOutLogic } from '../../move-out/move-out.logic';
import { RelocationLogic } from '../../relocation/relocation.logic';
import { TariffChangeStateService } from '../../tariff/tariff-change-state.service';
import { VoucherRedeemLogic } from '../../voucher/redeem/voucher-redeem.logic';
import { ConsumptionStateService } from '../consumption/consumption-state.service';
import { ProductDetailsStateService } from './product-details-state.service';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsLogic {
  constructor(
    private router: Router,
    private navigationStateService: NavigationStateService,
    private productDetailsStateService: ProductDetailsStateService,
    private relocationLogic: RelocationLogic,
    private invoiceDataChangeLogic: InvoiceDataChangeLogic,
    private tariffChangeStateService: TariffChangeStateService,
    private customerDataChangeLogic: CustomerDataChangeLogic,
    private consumptionStateService: ConsumptionStateService,
    private moveOutLogic: MoveOutLogic,
    private voucherRedeemLogic: VoucherRedeemLogic,
    private interactiveInvoicesLogic: InteractiveInvoicesLogic,
    private findPaymentSolutionLogic: FindPaymentSolutionLogic,
  ) {}

  get product(): Product {
    return this.productDetailsStateService.product;
  }

  get address(): Address {
    return parseAddress(this.productDetailsStateService.product.address);
  }

  get products(): Product[] {
    return this.productDetailsStateService.groupProducts;
  }

  get addressProducts(): IAddressProducts {
    return {
      address: this.address,
      products: this.products.filter(p => p.accountNumber === this.product.accountNumber),
    };
  }

  get allAddressProducts(): IAddressProducts {
    return {
      address: this.address,
      products: this.products,
    };
  }

  startNewClientProcess(): void {
    this.setWizardBackUrl();
    this.router.navigateByUrl(`/${NavigationConstants.NEW_CLIENT_ROUTE}`);
  }

  startRelocationProcess(): void {
    this.setWizardBackUrl();

    this.relocationLogic.initRelocationProcess(this.products);
  }

  changeBankAccount(): void {
    this.setWizardBackUrl();
    this.invoiceDataChangeLogic.startBankAccountChangeWithoutAddressSelection(this.products, this.address);
  }

  changeTariffs(preselectedProduct: Product = undefined): void {
    this.tariffChangeStateService.directAddressSelection = true;
    this.tariffChangeStateService.tariffChangeAddress = this.address;
    this.tariffChangeStateService.tariffChangeProducts = this.productDetailsStateService.groupProducts;
    this.tariffChangeStateService.preselectedProduct = preselectedProduct;
    this.setWizardBackUrl();
    this.router.navigateByUrl(`/${NavigationConstants.TARIFF_CHANGE_ROUTE}/${NavigationConstants.TARIFF_CHANGE_PRODUCTS_ROUTE}`);
  }

  changePdfInvoice(): void {
    this.setWizardBackUrl();
    this.invoiceDataChangeLogic.startPdfInvoiceChangeWithoutAddressSelection(this.products, this.address);
  }

  changeInvoiceAddress(): void {
    this.setWizardBackUrl();
    this.invoiceDataChangeLogic.startInvoiceAddressChangeWithoutAddressSelection(this.products, this.address);
  }

  changeCustomerData(): void {
    this.setWizardBackUrl();

    const products = this.productDetailsStateService.groupProducts.filter(
      gp => gp.businessPartnerNumber === this.product.businessPartnerNumber,
    );
    this.customerDataChangeLogic.startProcessWithoutAddressSelection(products);
  }

  showConsumption(): void {
    this.setWizardBackUrl();

    this.consumptionStateService.products = [...this.products];
    this.consumptionStateService.preselectedProduct = this.product;
    this.consumptionStateService.address = this.address;
    this.router.navigateByUrl(`/${NavigationConstants.PRODUCTS_ROUTE}/${NavigationConstants.PRODUCTS_CONSUMPTION}`);
  }

  moveOut(): void {
    this.setWizardBackUrl();

    const products = this.productDetailsStateService.groupProducts;
    const address = this.productDetailsStateService.address;
    this.moveOutLogic.startProcessWithoutAddressSelection(products, address);
  }

  showMeterReading(): void {
    this.setWizardBackUrl();
    this.router.navigateByUrl(`/${NavigationConstants.METER_READING_ROUTE}`);
  }

  redeemVoucher(): void {
    this.setWizardBackUrl();
    this.voucherRedeemLogic.redeemVoucher();
  }

  showInteractiveInvoices(): void {
    this.addNavigatingBackTo('ProductDetails');
    this.interactiveInvoicesLogic.showInteractiveInvoices(this.allAddressProducts, this.product.category);
  }

  showInvoicePartialpaymentOverview(): void {
    const addressProducts = this.addressProducts;
    const accountNumber = this.product.accountNumber;
    const systemId = this.product.systemId;

    this.addNavigatingBackTo('ProductDetails');
    this.interactiveInvoicesLogic.showInvoicePartialPaymentOverview(addressProducts, accountNumber, systemId as ILSystem);
  }

  navigateToUnpaidInvoices(unpaidInvoices: UnpaidInvoices): void {
    this.addNavigatingBackTo('ProductDetails');
    this.setWizardBackUrl();
    const categories = this.products.filter(gp => hasSameClearingAccount(gp, this.product)).map(gp => gp.category);
    const clearingAccount: ClearingAccount = {
      accountId: this.product.accountNumber,
      systemId: this.product.systemId,
      businessPartnerId: this.product.businessPartnerNumber,
      unpaidInvoices,
    };
    this.findPaymentSolutionLogic.navigateToUnpaidInvoices(categories as Sparte[], clearingAccount, this.address);
  }

  private addNavigatingBackTo(item: NavigatingBackTo): void {
    this.navigationStateService.addNavigatingBackTo(item);
    this.productDetailsStateService.keepStorageOnce = true;
  }

  private setWizardBackUrl(): void {
    this.navigationStateService.wizardCancelUrl = `/${NavigationConstants.PRODUCTS_ROUTE}/${NavigationConstants.PRODUCTS_DETAILS_ROUTE}`;
    this.productDetailsStateService.keepStorageOnce = true;
  }
}
