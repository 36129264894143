export interface AddressGroup {
  id: string;
  userId: string;
  addressCode: string;
  groupName: string;
  detailsLinkType: string;
  customerType: string;
  createdAt: Date;
  modifiedAt: Date;
  matchedGroup?: boolean;
}
