import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';

const checkDsgvoAtNextPossibleMomentKey = 'dsgvo_checkDsgvoAtNextPossibleMoment';

@Injectable({ providedIn: 'root' })
export class DsgvoStateService {
  wasDsgvoCheckOpened = false;
  hasCheckedIfDsgvoCheckShouldBeOpened = false;
  showOptionToSaveDecisionFor365Days = false;

  @SessionStorage(checkDsgvoAtNextPossibleMomentKey)
  private checkDsgvoAtNextPossibleMoment: boolean;

  // is true when copyConsent fails with specific error code -> consent data not found/valid in dsgvo-db
  // user-journal (our db) is not always in sync with dsgvo-db
  shouldCheckDsgvoAtNextPossibleMoment(): boolean {
    return !!this.checkDsgvoAtNextPossibleMoment;
  }

  activateDsgvoCheckAtNextPossibleMoment(): void {
    this.checkDsgvoAtNextPossibleMoment = true;
  }

  resetDsgvoCheckAtNextPossibleMoment(): void {
    this.checkDsgvoAtNextPossibleMoment = false;
  }
}
