import { Inject, Injectable } from '@angular/core';
import { ApplicationPaths, environment, Environment, EnvironmentCustomerType, EnvironmentServiceInterface } from '@mwe/environment';
import { applyVariablesToTemplateString } from '@mwe/utils';
import { AuthConfig } from 'angular-oauth2-oidc';
import { APPLICATION_NAME, APPLICATION_PATHS } from '@mwe/constants';

@Injectable({ providedIn: 'root' })
export class EnvironmentService implements EnvironmentServiceInterface {
  private environment: Environment;

  constructor(@Inject(APPLICATION_NAME) private appName: EnvironmentCustomerType) {
    const replacedAppNameEnvironment = applyVariablesToTemplateString(JSON.stringify(environment), {
      appName: this.appName,
      isCompany: this.appName === 'business' ? 'true' : 'false',
    });
    this.environment = JSON.parse(replacedAppNameEnvironment);
  }

  getEnvironment(): Environment {
    return this.environment;
  }

  getMaintenanceRefreshIntervall(): number {
    return this.environment.maintenanceRefreshIntervall;
  }

  getChatbotWidgetId() {
    return this.environment.chatbotWidgetId;
  }

  getLoggingAPIOBaseURL(): string {
    return this.environment.loggingAPIBaseURL || this.environment.apiUrl;
  }

  getApiUrl(): string {
    return this.environment.apiUrl;
  }

  getMNRApiUrl(): string {
    return this.environment.mnrApiUrl;
  }

  getPortalUrlPrefix(): string {
    return this.environment.portalUrlPrefix || '';
  }

  getPortalCustomerType(): EnvironmentCustomerType {
    return this.appName;
  }

  getAuthConfig(): AuthConfig {
    return this.environment.authConfig;
  }

  getRegistrationUri(): string {
    return `${this.environment.authServer?.registrationUri}`;
  }

  getLogoutUri(): string {
    return this.environment.logoutUri;
  }

  getCurrentPortalUri(): string {
    switch (this.getPortalCustomerType()) {
      case 'business':
        return this.getBusinessPortalUri();
      case 'privat':
        return this.getPrivatPortalUri();
      default:
        return '';
    }
  }

  getBusinessPortalUri(): string {
    return this.environment.businessServices.uri;
  }

  getPrivatPortalUri(): string {
    return this.environment.mweServices.uri;
  }

  staticContent(): string {
    return this.environment.staticContent;
  }

  getLogwienProfileUri(): string {
    return `${this.environment.authServer?.profileUri}`;
  }

  getLogwienPartnerName(): string {
    return this.environment.authServer?.partnerName;
  }

  getLogwienLogoutUri(): string {
    return this.environment.authServer?.logoutUri;
  }

  getLogwienLoginFlowUri(): string {
    return `${this.environment.authServer?.loginFlowUri}`;
  }

  getLogwienProfileReferrer(): string {
    return this.environment.authServer?.profileReferer;
  }

  getOssUrl(): string {
    return this.environment.ossUri;
  }
}
