import { IWizardStepAlert, PreisIndikation, TarifOption } from '@mwe/models';
import { getUniqueValues, isArrayWithMinOneItem } from '../common/mwe-util';

// todo most of these is DEPRECATED remove once it is no longer needed
// only conversion functions between different TWN data structures are needed

export const getSelectedTariffOptionsFromProductSelection = (
  selectedTariffOptions: TarifOption[],
  availableTariffOptions: TarifOption[],
): TarifOption[] => {
  if (!isArrayWithMinOneItem(availableTariffOptions)) {
    return [];
  }

  if (!isArrayWithMinOneItem(selectedTariffOptions)) {
    selectedTariffOptions = [];
  }

  // check for missing items in user-selection, e.g. first start or tariff-key-change
  const uniqueTariffOptionTypes = getUniqueTariffOptionTypes(availableTariffOptions);

  // no items are missing
  if (selectedTariffOptions.length === uniqueTariffOptionTypes.length) {
    return selectedTariffOptions;
  }

  // some items are missing, so check for default-element of art
  uniqueTariffOptionTypes.forEach(uniqueArt => {
    if (selectedTariffOptions.find(sto => sto.art === uniqueArt)) {
      // do nothing when we have a option for this art
      return;
    }

    // no option found, add the default
    const artOptions = availableTariffOptions.filter(to => to.art === uniqueArt);
    const defaultOption = artOptions.find(to => to.default);

    // no default, just take the first option
    selectedTariffOptions.push(defaultOption || artOptions[0]);
  });

  return selectedTariffOptions;
};

export const getUniqueTariffOptionTypes = (tariffOptions: TarifOption[]): string[] => {
  if (!tariffOptions) return [];
  return getUniqueValues(tariffOptions?.map(o => o.art));
};

// SAP provides some infos, T3 provides some infos. unfortunately we have to merge the data...
// keep all SAP options, ignore T3 option if there is no counterpart in SAP
// when a SAP option has no counterpart in T3, than use just the sapOption without price, unit, etc.
export const mergeSapT3TariffOptions = (sapOptions: TarifOption[], t3Options: TarifOption[]): TarifOption[] => {
  if (!isArrayWithMinOneItem(sapOptions)) {
    return [];
  }

  return sapOptions
    .map(sapOption => {
      const t3Option = t3Options?.find(t3O => t3O.option === sapOption.option);

      if (!t3Option) {
        return sapOption;
      }

      return mergeSapT3TariffOption(sapOption, t3Option);
    })
    .filter(s => !!s);
};

// we just need delta, deltaUnit, pricePeriod from T3, the rest is provided from SAP
export const mergeSapT3TariffOption = (sapOption: TarifOption, t3Option: TarifOption): TarifOption => {
  return {
    ...sapOption,
    delta: t3Option.delta,
    deltaUnit: t3Option.deltaUnit,
    pricePeriod: t3Option.pricePeriod,
    installationBasis: t3Option.installationBasis,
    installationLabel: t3Option.installationLabel,
    installationPrice: t3Option.installationPrice,
    installationUnit: t3Option.installationUnit,
  };
};

export const getTariffOptionLabel = (option: TarifOption, smallArea = false): string => {
  if (option.deltaUnit) {
    return `${option.optionLabel}${smallArea ? '<br/>' : ''} (${
      smallArea && option.installationLabel
        ? option.installationLabel + ' ' + option.installationPrice + ' ' + option.installationUnit + '<br/>'
        : ''
    }${option.delta} ${option.deltaUnit})`;
  }

  return option.optionLabel;
};

export const getTariffOptionTranslationParams = (option: TarifOption) => {
  return {
    label: option.optionLabel,
    value: option.delta,
    unit: option.deltaUnit,
    pricePeriod: option.pricePeriod,
    installationLabel: option.installationLabel,
    installationValue: option.installationPrice,
    installationPricePeriod: option.installationUnit,
  };
};

export const getTariffOptionsIdList = (selectedSapTariffOptions: TarifOption[]): string => {
  if (!isArrayWithMinOneItem(selectedSapTariffOptions)) {
    return '';
  }

  // options are stored as immutables, so every request can have other options-order.
  // sort it alphabetically to prevent flaky-cypress-tests or multiple cypress-intercept-definitions
  const sortedOptions = [...selectedSapTariffOptions].sort((a, b) => a.option.localeCompare(b.option));
  return sortedOptions.map(o => o.option).join(',');
};

// we have to support old twn 1 data in old status pages, easiest way is to map it to twn2 compatible data
// twn 1 -> selected default options are not stored in productSelection
// twn 2 -> every selected option is stored in productSelection
export const mapTwn1ToTwn2 = (sapTariffOptions: TarifOption[], availableTariffOptions: TarifOption[]): void => {
  if (!isArrayWithMinOneItem(availableTariffOptions) || !Array.isArray(sapTariffOptions)) {
    return;
  }

  // no empty default options -> twn 2, no mapping needed
  const defaultOptions = availableTariffOptions.filter(o => !o.option);
  if (!isArrayWithMinOneItem(defaultOptions)) {
    return;
  }

  // check if ASP is available but default item is missing
  const dAsp = availableTariffOptions.find(o => o.artLabel === 'Zusatzoptionen' && !o.option);
  const sAsp = availableTariffOptions.find(o => o.artLabel === 'Zusatzoptionen' && !!o.option);
  if (!dAsp && sAsp) {
    const aspDefault = {
      option: '',
      optionLabel: 'tariff.optionsSelectionForm.dummyNopeItem',
      artLabel: 'Zusatzoptionen',
    };
    availableTariffOptions.push(aspDefault);
  }
  // fix ASP sap option
  const sapAspOption = sapTariffOptions.find(o => o.art === 'Zusatzprodukte');
  if (sapAspOption) {
    sapAspOption.art = 'Zusatzoptionen';
  }

  // map default options to twn2
  availableTariffOptions
    .filter(o => !o.option)
    .forEach((dO, index) => {
      const fakeId = `fake-option-id-${index}`;
      dO.option = fakeId;
      dO.default = true;
    });

  // map t3Options to twn2
  availableTariffOptions.forEach(o => {
    // Alles Sicher Hack... fix art property, its duplicate to bindung
    if (o.artLabel === 'Zusatzoptionen') {
      o.art = 'Zusatzoptionen';
    }

    // fake delta unit
    if (o.artLabel === 'Bindung') {
      o.deltaUnit = '€';
    } else {
      if (o.deltaBasis === 'grundpreis') {
        o.deltaUnit = '€ / ' + (o.pricePeriod || 'Jahr');
      } else if (o.deltaBasis === 'arbeitspreis') {
        o.deltaUnit = 'Cent / kWh';
      }
    }
  });

  // map sapOptions to twn2
  // first check for selected default options
  getSelectedTariffOptionsFromProductSelection(sapTariffOptions, availableTariffOptions);
  // than do the mapping
  sapTariffOptions.forEach(o => {
    const t3Option = availableTariffOptions.find(a => a.option === o.option);
    o.optionLabel = t3Option.optionLabel;
  });
};

export const isTariffOptionSelected = (options: TarifOption[], key: string): boolean => {
  if (!isArrayWithMinOneItem(options)) {
    return false;
  }

  return !!options?.find(o => o.option === key);
};

export const priceIndicationsReloadErrorAlert: IWizardStepAlert = {
  titleKey: 'tariff.error.reloadPriceIndications.title',
  messageKey: 'tariff.error.reloadPriceIndications.message',
};

export const mapTwn2ToTwn3 = (indication: PreisIndikation): PreisIndikation => {
  if (indication?.arbeitspreisZahlbar) {
    return indication;
  }

  return {
    ...indication,
    arbeitspreisLabel: 'Grundpreis',
    arbeitspreisZahlbar: indication?.arbeitspreis,
    arbeitspreisSubtext: '',
    grundpreisLabel: 'Verbrauchspreis',
    grundpreisZahlbar: indication?.grundpreis,
    grundpreisSubtext: '',
  };
};
