export const ROUTE_HOME = 'home';
export const ROUTE_STARTPAGE = '/home';
export const ROUTE_METER_POINTS = 'zaehlpunkte';
export const ROUTE_INVOICES = 'rechnungen';
export const ROUTE_DSGVO = 'dsgvo';
export const ROUTE_FAQ = 'faq';
export const ROUTE_LOGOUT_SUCCESS = '#logout=success';
export const ROUTE_COMPANY_DATA = 'unternehmensdaten';
export const ROUTE_PERMISSIONS = 'berechtigungen';
export const ROUTE_PROFILE_DATA = 'profildaten';
export const ROUTE_REGISTRATION = 'registrierung';
export const ROUTE_CONDITIONS_OF_USE_WIZARD = 'nutzungsbedingungen-einwilligen';
export const ROUTE_TERMS_OF_USE = 'nutzungsbedingungen';
export const ROUTE_THANK_YOU = 'danke';
export const ROUTE_ACCOUNT_USER = 'account-user';
export const ROUTE_NOT_FOUND = 'nicht-gefunden';

// API
export const API_ROUTE_ZAEHLPUNKTE = 'zaehlpunkte';
export const API_ROUTE_INVOICES = 'rechnung';
export const API_ROUTE_COMPANY = 'unternehmen';

export const API_ROUTE_USER = 'user';
export const API_ROUTE_BERATER_OF_USER = 'user/berater';

export const API_ROUTE_PERMISSIONS = 'permissions/users';

// Utility function for relative paths
export function routePrefix(route: string) {
  return '/' + route;
}

// Utility function for full URL paths
export function fullRoutePrefix(route: string) {
  // check if route already starts with /
  if (route.startsWith('/')) {
    return window.location.origin + route;
  }

  return window.location.origin + '/' + route;
}

export function homeRoutePrefix(route: string) {
  return '/' + ROUTE_HOME + routePrefix(route);
}

export function getApiUrl(apiUrl: string, route: string) {
  return `${apiUrl}/v1/ikp/auth/${route}`;
}
