import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BadgeTypeOld } from '../badge.model';

@Component({
  selector: 'mwe-pill-badge',
  templateUrl: './pill-badge.component.html',
})
export class PillBadgeComponent implements OnInit {
  @Input() text: string;
  @Input() state: string;
  @Input() isLast: boolean = false;
  @Input() type: BadgeTypeOld;
  @Input() closable = false;
  @Output() unselect: EventEmitter<any> = new EventEmitter();
  @ViewChild('button') buttonElement: ElementRef<HTMLElement>;
  constructor() {}

  ngOnInit(): void {}

  get labelText(): string {
    return 'address-input-pill.' + this.state + (this.isLast ? '' : 'Long');
  }
  remove(event): void {
    this.unselect.emit(event);
  }
}
