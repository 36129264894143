import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AriaLiveService } from '@mwe/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mwe-aria-live',
  templateUrl: './aria-live.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AriaLiveComponent {
  ariaLiveText = computed<string>(() => {
    if (this.service.currentAriaLiveText()) {
      return this.translateService.instant(this.service.currentAriaLiveText());
    }
    return '';
  });

  private service = inject(AriaLiveService);
  private translateService = inject(TranslateService);
}
