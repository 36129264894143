import { Component, inject, input } from '@angular/core';
import { HeadlineService } from '../../../headline/headline.service';
import { Product } from '@mwe/models';

@Component({
  selector: 'mwe-tariff-card-emobility',
  templateUrl: './tariff-card-emobility.component.html',
  providers: [HeadlineService],
})
export class TariffCardEmobilityComponent {
  product = input.required<Product>();

  headlineService = inject(HeadlineService);
}
