@if (inputElem?.options) {
  <div>
    @if (inputElem.labelKey) {
      <p [ngClass]="inputElem.labelCss" [translate]="inputElem.labelKey" class="form-label mb-2"></p>
    }
    @for (radio of inputElem?.options; track radio.value) {
      <div [ngClass]="{ 'mb-0': $last }" class="form-check">
        <input
          [attr.data-test]="getUniqueId($index)"
          [attr.id]="getUniqueId($index)"
          [formControl]="parentForm.controls[inputElem.name]"
          [name]="inputElem.name"
          [attr.name]="inputElem.name"
          [value]="radio.value"
          [ngClass]="{
            'is-invalid': parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine,
          }"
          class="form-check-input"
          type="radio"
        />
        <div>
          <label
            [attr.data-test]="getUniqueId($index) + '-label'"
            [for]="getUniqueId($index)"
            [innerHTML]="radio.text | translate"
            [ngClass]="{ 'mb-0': $last }"
            class="form-label"
          ></label>
        </div>
      </div>
    }
  </div>
}
