import { TariffSelectionStoreInitParams, TariffSelectionStoreState } from './tariff-selection-store.model';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { IProduktSelektion, PreisIndikation, TariffSelectionCardData } from '@mwe/models';
import { tariffSelectionStoreUtils } from './tariff-selection-store.utils';

const baseState: TariffSelectionStoreState = {
  allProducts: [],
  allPriceIndications: [],
  selectedTariffs: [],
  additionalOptionGroupName: '',
  zipCode: '',
};

export const TariffSelectionStore = signalStore(
  { providedIn: 'root' },
  withState<TariffSelectionStoreState>(baseState),
  withComputed(data => ({
    hasError: computed<boolean>(() => data.selectedTariffs().some(t => t.state === ServiceStateEnum.ERROR)),
    getProductSelection: computed<IProduktSelektion>(() => {
      return tariffSelectionStoreUtils.getProductSelection(data.allProducts(), data.selectedTariffs());
    }),
  })),
  withMethods(store => ({
    initializeStore(data: TariffSelectionStoreInitParams): void {
      patchState(store, state => {
        return {
          ...state,
          allProducts: data.allProducts,
          allPriceIndications: data.allPriceIndications,
          additionalOptionGroupName: data.additionalOptionGroupName,
          zipCode: data.zipCode,
        };
      });
    },
    initializeSelectedTariffs(selectedTariffs: TariffSelectionCardData[]): void {
      patchState(store, state => ({ ...state, selectedTariffs }));
    },
    updateAllCardLoadingStates(loadingState: ServiceStateEnum): void {
      patchState(store, state => ({
        ...state,
        selectedTariffs: state.selectedTariffs.map(t => ({ ...t, state: loadingState })),
      }));
    },
    updateCardLoadingState(tariffData: TariffSelectionCardData, state: ServiceStateEnum): void {
      const selectedTariffs = tariffSelectionStoreUtils.updateTariffs(store.selectedTariffs(), {
        ...tariffData,
        state,
      });
      patchState(store, state => ({ ...state, selectedTariffs }));
    },
    updateCardData(tariffData: TariffSelectionCardData): void {
      const selectedTariffs = tariffSelectionStoreUtils.updateTariffs(store.selectedTariffs(), tariffData);
      patchState(store, state => ({ ...state, selectedTariffs }));
    },
    // every tariffOption/voucher-Change triggers priceIndication-update-request. store these data in allPriceIndiactions
    // otherwise default indication from initialization is used (e.g. without voucher)
    updatePriceIndication(priceIndication: PreisIndikation): void {
      if (!priceIndication) {
        return;
      }

      const allPriceIndications = tariffSelectionStoreUtils.updatePriceIndication(store.allPriceIndications(), priceIndication);
      patchState(store, state => ({ ...state, allPriceIndications }));
    },
    // just for status-page
    updateAdditionalOptionGroupName(additionalOptionGroupName: string): void {
      patchState(store, state => ({ ...state, additionalOptionGroupName }));
    },
    reset(): void {
      patchState(store, _ => baseState);
    },
  })),
);
