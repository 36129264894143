<div class="d-grid grid-congruent">
  <div class="form-floating" aria-hidden="true">
    <div class="form-control h-auto visually-hidden" style="white-space: pre-wrap">
      {{ formControlDirective.control?.value }}
    </div>
  </div>
  <div class="form-floating">
    <div class="d-flex h-100">
      <ng-container *ngIf="inputElem.content?.before">{{ inputElem.content?.before }}</ng-container>
      <textarea
        [attr.type]="inputElem.dataType || 'text'"
        [ngClass]="{
          'pl-2': inputElem.inputPrependLabelKey,
          'is-valid':
            inputElem.validateSuccess &&
            parentForm.controls[inputElem.name].status === 'VALID' &&
            !parentForm.controls[inputElem.name].pristine,
          'is-invalid': parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine,
          'form-control': !isInline,
        }"
        class="{{ inputElem.inputCssClass }} h-100"
        [attr.id]="inputElem.name"
        [attr.name]="inputElem.name"
        [attr.data-test]="'form-input-' + inputElem.name"
        [formControl]="parentForm.controls[inputElem.name]"
        [attr.maxlength]="inputElem.maxLength || null"
        [attr.placeholder]="inputElem.placeholder | translate"
        [attr.style]="inputElem.inputStyle || null"
        [attr.avoidCopyPaste]="inputElem.avoidCopyPaste || false"
        [mweBlockPasteEvent]="inputElem.avoidCopyPaste"
        (blur)="inputBlurred.emit()"
        [attr.disabled]="isLoading ? '' : null"
        (input)="handleInput($event)"
        (keydown.enter)="onEnterPressed($event)"
        [attr.aria-describedby]="ariaDescribedBy || null"
        [attr.aria-invalid]="!isValid"
        [attr.aria-label]="(inputElem.ariaLabel | translate) || null"
        [attr.aria-labelledby]="inputElem.ariaLabelledby || null"
        [attr.readonly]="inputElem.isReadonly || null"
        [attr.autocomplete]="inputElem.noAutocomplete ? 'off' : null"
        [required]="isRequired || null"
      ></textarea>
      <label
        [attr.for]="inputElem.name"
        class="form-label"
        [ngClass]="inputElem.labelCss"
        [translate]="inputElem.labelKey"
        *ngIf="inputElem.labelKey"
      ></label>
      <mwe-form-input-loading-spinner
        *ngIf="inputElem.loadingSpinnerOptions"
        [inputElem]="inputElem"
        [currentValue]="currentValue"
        [isValid]="isValid"
        (isLoading)="isLoading = $event"
        [suppressValidityCheckOnSubmit]="inputElem.loadingSpinnerOptions?.suppressValidityCheckOnSubmit"
      ></mwe-form-input-loading-spinner>
      <div *ngIf="inputElem.toggleIcon" class="form-floating-icon">
        <button class="btn btn-clean" (click)="togglePassword($event)">
          <i class="fas" [ngClass]="inputElem.dataType === 'password' ? 'fa-eye' : 'fa-eye-slash'"></i>
        </button>
      </div>
      <ng-container *ngIf="inputElem.content?.after">{{ inputElem.content?.after }}</ng-container>
    </div>
  </div>
</div>
