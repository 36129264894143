import { AuthConfig } from 'angular-oauth2-oidc';

export type EnvironmentType = 'dev' | 'int' | 'test' | 'prod';

export type AppName = 'mac' | 'at';
export const AppName = {
  MITARBEITER: 'mac' as AppName,
  ADMINTOOL: 'at' as AppName,
};

export interface Environment {
  production: boolean;
  env: EnvironmentType;
  portalUrlPrefix?: string;
  kundenTyp?: EnvironmentCustomerType;
  apiUrl: string;
  mnrApiUrl: string;
  documentUrl: string;
  authConfig: AuthConfig;
  registrationUri: string;
  logoutUri: string;
  businessServices: EnvironmentServiceConfig;
  mweServices: EnvironmentServiceConfig; // Neuportal
  staticContent: string;
  bskwUri: string;
  superSchnellBestellenUrl?: string;
  authServer: AuthServerConfig;
  maintenanceRefreshIntervall: number;
  chatbotWidgetId: string;
  appVersion: string;
  addGoodiesForm: string;
  ossUri: string;
  loggingAPIBaseURL?: string;
}

export type EnvironmentCustomerType = 'privat' | 'business' | 'messages' | 'unknown';

export const EnvironmentCustomerType = {
  PRIVAT: 'privat' as EnvironmentCustomerType,
  BUSINESS: 'business' as EnvironmentCustomerType,
  MESSAGES: 'messages' as EnvironmentCustomerType,
  UNKNOWN: 'unknown' as EnvironmentCustomerType,
};

export interface EnvironmentServiceConfig {
  uri: string;
  entryPath: string;
  processPath: string;
}

interface AuthServerConfig {
  uri: string;
  //mweKeycloakHost: string;
  profileReferer: string;
  profileUri: string;
  profileChangePasswordUri: string;
  profileChangeEmailUri: string;
  profileChangePersonalDataUri: string;
  registrationUri: string;
  loginUri: string;
  loginFlowUri: string;
  logoutUri: string;
  partnerName: string;
}

export interface EnvironmentAdmintool {
  production: boolean;
  env: string;
  keycloakConfig: AuthConfig;
  apiUrl: string;
  collaborationUrl: string;
  collaborationTargetUrl: string;
  collaborationTargetUrlBskw: string;
  appVersion: string;
  macUri: string;
  storagePrefix: string;
  admintoolUri: string;
  ROLES: {
    WE_ADMIN: string;
    WE_VERWALTEN: string;
    WE_REGISTRIEREN: string;
    WE_BSKW_ADMIN: string;
    WE_KSD_MAC_AnyMAC: string;
    WE_KSD_MAC_ALL: string;
    WE_KSD_MAC_KSAdmin: string;
    WE_KSD_MAC_VMAdmin: string;
    WE_KSD_MAC_SG: string;
    WE_KSD_MAC_WAERME: string;
    WE_KSD_MAC_TANKE: string;
    WE_KSD_MAC_SUPERSCHNELL: string;
  };
  blacklist?: {
    roles: string[];
  };
  sapCrmBaseUri?: string;
  sapCrmClient?: string;
  toggleRefreshMins: number;
  staticContent: string;
  appName: AppName;
}

export interface EnvironmentSetup {
  production: boolean;
  name: EnvironmentType;
  chatbotWidgetId: string;
  portal?: AppSetup;
}

export interface AppSetup {
  authentication: AuthConfig;
}

export interface EnvironmentUris {
  authServerInternal: string;
  authServerExternal: string;
  bskw: string;
  privat: string;
  business: string;
  portalBase: string;
  portalApi: string;
  mnrApi: string;
  admintool: string;
  admintoolApi: string;
  mac: string;
  documents: string;
  superSchnellBestellen: string;
  staticContent: string;
  addGoodiesForm: string;
  oss: string;
  sapClientUri?: string;
}

export interface AppAuthConfig {
  storagePrefix: string;
  loginRedirectPath: string;
  logoutRedirectPath: string;
  realm: string;
  clientId: string;
  responseType: string;
  scope: string;
  showDebugInformation?: boolean;
  preserveRequestedRoute?: boolean;
  [key: string]: string | boolean;
}

export interface CommonPathsConfig {
  authServer: {
    realmsPath: string;
    collaborationPath: string;
    userinfoPath: string;
  };
}

export interface AuthServerPaths {
  realm: string;
  openID: string;
  collaboration: string;
  registration: string;
  login: string;
  loginFlow: string;
  logout: string;
}
