import { Component, input, output } from '@angular/core';

@Component({
  selector: 'mwe-small-card-clickable',
  templateUrl: './small-card-clickable.component.html',
})
export class SmallCardClickableComponent {
  cardTitle = input.required<string>();
  testId = input<string>('');
  cardClicked = output();
}
