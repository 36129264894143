import { Component, input, signal, output, viewChild } from '@angular/core';
import { ProduktAttribute } from '@mwe/models';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mwe-tariff-selection-card-collapse-item',
  templateUrl: './tariff-selection-card-collapse-item.component.html',
})
export class TariffSelectionCardCollapseItemComponent {
  item = input<ProduktAttribute>();
  collapseToggleClicked = output();
  isCollapsed = signal(true);

  private collapseRef = viewChild<NgbCollapse>('collapse');

  collapse(): void {
    this.isCollapsed.set(true);
    this.collapseRef().collapsed = true;
  }

  // accordion - close other collapse-items
  toggleCollapse() {
    const openMe = this.isCollapsed();
    this.collapseToggleClicked.emit();
    if (openMe) {
      this.isCollapsed.set(false);
      this.collapseRef().collapsed = false;
    }
  }
}
