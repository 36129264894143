<mwe-basic-card
  *ngIf="localConfig"
  [cardTitle]="localConfig.titleKey"
  [hasBackground]="true"
  [headingNodeType]="headingNodeType"
  iconClass="fas fa-gift-card"
>
  <div class="row">
    <div class="col-12">
      <div class="has-text-button">
        <div class="pb-2 pt-2">
          <mwe-form
            #codeForm
            (formIsInvalid)="onFormChange($event)"
            (formIsValid)="onFormChange($event)"
            [formModel]="voucherFormModel"
            [suppressValidityCheckOnSubmit]="true"
          ></mwe-form>
          <mwe-wl-voucher-form
            (checkPressed)="wlFormCheckPressed($event)"
            (formValid)="wlFormValidityChanged($event)"
            *ngIf="showWLFields"
            [aktionsArt]="aktionsArt"
            [config]="localConfig"
            [loading]="loading"
            [prozesstyp]="prozesstyp"
          ></mwe-wl-voucher-form>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="localConfig.infoText" class="row pb-2">
    <mwe-info-text-card [hasBoxStyles]="false" [noBoxShadow]="true" [text]="localConfig.infoText"></mwe-info-text-card>
  </div>
</mwe-basic-card>

<div *ngIf="!!codeCheck" class="mt-5">
  <mwe-alert
    *ngIf="codeCheck === 'invalid'"
    [messageKey]="showWLFields ? localConfig.invalidCodeWLMsgKey : localConfig.invalidCodeMsgKey"
    titleKey="voucher.invalidTitle"
    type="danger"
  ></mwe-alert>
  <mwe-alert
    *ngIf="codeCheck === 'error'"
    [messageKey]="errorMessageKey"
    [titleKey]="errorTitleKey || 'voucher.invalidTitle'"
    [type]="errorAlertStyle"
  ></mwe-alert>
  <mwe-alert
    (onLinkClick)="handleWarningLinkClicked()"
    *ngIf="codeCheck === 'warning'"
    [infoText]="isTariffNotValidError ? tariffNotValidInfoText : null"
    [link]="isTariffNotValidError ? 'voucher.validity.tariffNotValid.link' : ''"
    [messageKey]="errorMessageKey"
    type="warning"
  ></mwe-alert>
  <mwe-basic-card
    *ngIf="codeCheck === 'valid'"
    cardTitle="voucher.validity.success.title"
    headingNodeType="h4"
    iconClass="fas fa-gift-card"
  >
    <mwe-green-check-list [labels]="uiSuccessInfos"></mwe-green-check-list>
  </mwe-basic-card>
</div>

<div *ngIf="hasFaq" class="mt-5" data-test="voucher-form-faq">
  <h3 class="h5" translate="voucher.faq.title"></h3>
  <mwe-link-list-group [items]="localConfig.faq"></mwe-link-list-group>
</div>
