import { Component, inject, OnInit, Signal } from '@angular/core';
import { UiComponentsModule } from '@mwe/ui/components';
import { RouterModule } from '@angular/router';
import {
  homeRoutePrefix,
  ROUTE_FAQ,
  ROUTE_INVOICES,
  ROUTE_METER_POINTS,
  ROUTE_PROFILE_DATA,
  ROUTE_STARTPAGE,
  ROUTE_TERMS_OF_USE,
} from '@ikp/app.constants';
import { UserAccessService } from '@ikp/service/user-access/user-access.service';

@Component({
  selector: 'app-ikp-footer',
  standalone: true,
  imports: [RouterModule, UiComponentsModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  private userAccessService = inject(UserAccessService);

  protected readonly homeRoutePrefix = homeRoutePrefix;
  protected readonly ROUTE_METER_POINTS = ROUTE_METER_POINTS;
  protected readonly ROUTE_INVOICES = ROUTE_INVOICES;
  protected readonly ROUTE_PROFILE_DATA = ROUTE_PROFILE_DATA;
  protected readonly ROUTE_FAQ = ROUTE_FAQ;
  protected readonly ROUTE_STARTPAGE = ROUTE_STARTPAGE;
  protected readonly ROUTE_TERMS_OF_USE = ROUTE_TERMS_OF_USE;

  protected readonly canAccessMeterPoints = this.hasRouteAccess(homeRoutePrefix(ROUTE_METER_POINTS));
  protected readonly canAccessInvoices = this.hasRouteAccess(homeRoutePrefix(ROUTE_INVOICES));
  protected readonly canAccessProfileData = this.hasRouteAccess(homeRoutePrefix(ROUTE_PROFILE_DATA));
  protected readonly canAccessFaq = this.hasRouteAccess(homeRoutePrefix(ROUTE_FAQ));
  protected readonly canAccessStartpage = this.hasRouteAccess(ROUTE_STARTPAGE);

  currentYear: number;
  links = {
    defaultRoute: `/`,
  };

  ngOnInit(): void {
    this.setCurrentYear();
  }

  setCurrentYear(): void {
    this.currentYear = new Date().getFullYear();
  }

  hasRouteAccess(path: string): Signal<boolean> {
    return this.userAccessService.getUserAccessAsSignal(path);
  }
}
