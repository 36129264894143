<mwe-basic-card
  iconClass="fa-solid fa-file-user"
  [cardTitle]="cardTitle"
  data-test="products-summary-personalBilling"
  [hasBackground]="true"
>
  <div data-test="products-summary-personalData-email" *ngIf="email">
    <strong class="has-text-blue d-inline-block mb-2"><span translate="summary.invoiceCard.customerData.title"></span></strong><br />
    <strong class="d-inline-block mb-3"><span translate="summary.invoiceCard.customerData.email"></span>: </strong> {{ email }}
    <mwe-info-text-card
      iconClass="fa-info-circle"
      [text]="emailMessageInfoKey"
      *ngIf="emailMessageInfoKey"
      [textParams]="{ email: email }"
      class="d-block mb-3"
    ></mwe-info-text-card>
  </div>
  <div data-test="products-summary-personalBilling-payment">
    <strong class="has-text-blue"><span translate="summary.paymentData"></span></strong>
    <div class="row mb-1 mt-1">
      <div class="col col-auto">
        <div class="payment-badge">
          <img class="img-fluid" src="/assets/icons/payment/{{ paymentIcon }}.svg" [alt]="paymentText" />
        </div>
      </div>
      <div class="col">
        <p class="text--big mb-0" [innerHTML]="paymentText"></p>
      </div>
    </div>
  </div>
  <hr />
  <div data-test="products-summary-personalBilling-eBilling">
    <p class="text--big mb-0">
      <strong class="has-text-blue d-inline-block mb-2"><span translate="summary.invoiceShipping"></span></strong><br />
      <strong><span translate="summary.eBilling"></span>: </strong>
      <span class="d-inline-block mb-2" data-test="products-summary-personalBilling-invoice-address"> {{ formattedInvoiceAddress }}</span
      ><br />
      <ng-container *ngIf="eRechnungInfo?.isERechnungsEmpfaenger">
        <strong>
          <span translate="summary.invoiceAddress.shipping.emailPrefix"></span>
        </strong>
        <span data-test="products-summary-personalBilling-email"> {{ eRechnungInfo?.rechnungEmail }}</span>
      </ng-container>
      <span
        *ngIf="!eRechnungInfo?.isERechnungsEmpfaenger"
        translate="summary.invoiceAddress.shipping.post"
        data-test="products-summary-personalBilling-post"
      ></span>
    </p>
  </div>
  <ng-container *ngIf="additionalBulletPoints">
    <ul class="list-separated spacing-4 mt-5">
      <li *ngFor="let point of additionalBulletPoints" [attr.data-test]="point.id">
        <p class="text--big mb-0">
          <strong><span [translate]="point.title"></span></strong><br />
          {{ point.text | translate }}
        </p>
      </li>
    </ul>
  </ng-container>
  @if (areBankAccountInfoTextsVisible && !showBankAccountInfoTextAsAlert) {
    <div class="mt-3">
      <mwe-new-bank-account-info-texts useTemplate="info-text" [productCategories]="productCategories"></mwe-new-bank-account-info-texts>
    </div>
  }
  <mwe-info-text-card
    iconClass="fa-info-circle"
    [text]="infoTextCardLabel"
    *ngIf="infoTextCardLabel"
    class="d-block mt-5"
  ></mwe-info-text-card>
</mwe-basic-card>
<mwe-alert
  *ngIf="invoiceInfoAlertMessage"
  [messageKey]="invoiceInfoAlertMessage"
  class="d-block mt-4"
  data-test="products-summary-personalBilling-eBillingAlert"
></mwe-alert>
@if (areBankAccountInfoTextsVisible && showBankAccountInfoTextAsAlert) {
  <div class="mt-4 mb-4">
    <mwe-new-bank-account-info-texts [productCategories]="productCategories"></mwe-new-bank-account-info-texts>
  </div>
}
