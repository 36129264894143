<div class="form-floating">
  <div
    [ngClass]="{ 'is-invalid': parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine }"
    class="form-control form-control-meta d-flex"
  >
    <input
      #hiddenInput
      [attr.id]="inputElem.name"
      [attr.name]="inputElem.name"
      [formControl]="parentForm.controls[inputElem.name]"
      [name]="inputElem.name"
      [ngClass]="{ 'is-invalid': parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine }"
      class="form-control"
      type="hidden"
    />
    <div class="position-relative z-1">
      <mwe-form
        #dateInputForm
        (formIsInvalid)="onDateInputFormChanged()"
        (formIsValid)="onDateInputFormChanged()"
        [formModel]="dateInputFormModel"
        [isInline]="true"
      ></mwe-form>
    </div>
    <mwe-form #datePickerForm (formIsValid)="onDatePickerFormChanged()" [formModel]="datePickerFormModel" [isInline]="true"></mwe-form>
    <label
      *ngIf="inputElem.labelKey"
      [attr.for]="dateInputFormFields.day"
      [ngClass]="inputElem.labelCss"
      [translate]="inputElem.labelKey"
      class="form-label"
    ></label>
  </div>
</div>
