import { Component, computed, input, Input, OnChanges, OnInit, signal, SimpleChanges } from '@angular/core';
import { Sparte, TextNodeType } from '@mwe/models';
import { translateCategory, translateCategoryInstant } from '@mwe/utils';
import { TranslateService } from '@ngx-translate/core';
import { HeadlineService } from '../headline/headline.service';

type ProductIconsSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';

@Component({
  selector: 'mwe-product-icons',
  templateUrl: './product-icons.component.html',
  providers: [HeadlineService],
})
export class ProductIconsComponent implements OnInit {
  categories = input<Sparte[]>([]);
  size = input<ProductIconsSize>('md');
  textNodeType = input<TextNodeType>('h6');
  isDisabled = input<boolean>(false);
  alignment = input<'start' | 'center' | 'end'>('start');
  maxWidth = computed<string>(() => {
    switch (this.size()) {
      case '2xs': {
        return '.75rem';
      }
      case 'xs': {
        return '1rem';
      }
      case 'sm': {
        return '1.25rem';
      }
      case 'md': {
        return '1.5rem';
      }
      case 'lg': {
        return '2rem';
      }
      case 'xl': {
        return '2.5rem';
      }
      case '2xl': {
        return '3.5rem';
      }
      case '3xl': {
        return '4rem';
      }
      case '4xl': {
        return '4.5rem';
      }
    }
  });
  fontSize = computed<string>(() => {
    switch (this.size()) {
      case '2xs': {
        return '.75rem';
      }
      case 'xs': {
        return '.75rem';
      }
      case 'sm': {
        return '.75rem';
      }
      case 'md': {
        return '1.125rem';
      }
      case 'lg': {
        return '1.125rem';
      }
      case 'xl': {
        return '1.125rem';
      }
      case '2xl': {
        return '1.125rem';
      }
      case '3xl': {
        return '1.125rem';
      }
      case '4xl': {
        return '1.125rem';
      }
    }
  });
  heading: string;
  translatedProductCategories = signal<string[]>([]);
  joinedLabels = computed<string>(() => {
    return this.translatedProductCategories().join(' & ');
  });
  rowClass = computed<string>(() => {
    switch (this.alignment()) {
      case 'start': {
        return 'justify-content-start';
      }
      case 'center': {
        return 'justify-content-center';
      }
      case 'end': {
        return 'justify-content-end';
      }
    }
  });
  textClass = computed<string>(() => {
    switch (this.alignment()) {
      case 'start': {
        return 'text-start';
      }
      case 'center': {
        return 'text-center';
      }
      case 'end': {
        return 'text-end';
      }
    }
  });

  constructor(
    private translateService: TranslateService,
    private headlineService: HeadlineService,
  ) {}

  async ngOnInit() {
    this.headlineService.setReferenceHeadline(this.textNodeType());
    const translatedProductCategories = await Promise.all(
      this.categories().map(category => translateCategory(category, this.translateService)),
    );
    this.translatedProductCategories.set(translatedProductCategories);
  }
}
