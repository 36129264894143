interface IFelder {
  id: string;
  text: string;
}

export interface IGruppe {
  groupId: string;
  gruppenName: string;
  anzeigeText: string;
  darstellungsTyp: 'Überschrift' | 'Text' | 'Schieberegler' | 'Schalter';
  enthaeltCheckbox: boolean;
  defaultZustand: string;
  reihenfolge: number;
  istVerpflichtend: boolean;
  felder?: IFelder[];
}

export interface GruppeAnzeigeText {
  type: 'popup' | 'expander';
  text: string;
  popup?: GruppeAnzeigeTextPopup[];
}

export interface GruppeAnzeigeTextPopup {
  id: string;
  title: string;
  text: string;
}

export interface ICheckBoxesModel {
  zustimmungscode: string;
  gruppen: IGruppe[];
}

export interface IDsgvoStatus {
  wert: string;
  id: string;
  text: string;
}

export interface IDsgvoAdditionalData {
  dsgvoAccepted: string;
}

export interface DsgvoSliderUpdate {
  reihe: number;
  value: string;
}
