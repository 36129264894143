export function forceFocus(element: HTMLElement, once?: boolean) {
  if (!element) return;
  element.classList.add('force-focus');
  element.setAttribute('tabindex', '-1');
  element.focus();

  if (!once) return;
  element.addEventListener(
    'blur',
    ev => {
      element.removeAttribute('tabindex');
      element.classList.remove('force-focus');
    },
    { once: true },
  );
}

export const forceFocusNgbAutofocus = (once?: boolean): void => {
  const el = document.querySelector(`[ngbAutofocus]`) as HTMLElement;
  if (!el) return;
  forceFocus(el, once);
};
