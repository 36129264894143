<div [ngClass]="{ 'd-none': isFlipped() }">
  <div>
    <ng-content select="[slot='front']"></ng-content>
  </div>
</div>
<div [ngClass]="{ 'd-none': !isFlipped(), 'd-block': isFlipped() }">
  <div>
    <ng-content select="[slot='back']"></ng-content>
  </div>
</div>
<div class="text-center mt-3">
  <mwe-button-link [label]="flipBtnLabel()" (click)="flip()" color="blue" customCss="d-inline-block" dataTest="flipping-content-btn">
    <div class="row justify-content-center gx-2 mb-2">
      <div class="col-auto">
        <div
          [ngClass]="{ 'bg-gray': isFlipped(), 'bg-secondary-light': !isFlipped() }"
          class="rounded-pill transition-all"
          style="width: 0.75rem; height: 0.75rem"
        ></div>
      </div>
      <div class="col-auto">
        <div
          [ngClass]="{ 'bg-gray': !isFlipped(), 'bg-secondary-light': isFlipped() }"
          class="rounded-pill transition-all"
          style="width: 0.75rem; height: 0.75rem"
        ></div>
      </div>
    </div>
  </mwe-button-link>
</div>
