<div class="pt-2 border-bottom" data-test="tariff-selection-back-card-item-container">
  <div class="row gx-2 mb-2">
    <div class="col" data-test="tariff-selection-back-card-item-description">
      <div class="text--big fw-semibold text-secondary">{{ item().name }}</div>
    </div>
    <div class="col" [ngClass]="{ 'w-100': !item().langWert }" data-test="tariff-selection-back-card-item-value">
      <div class="text--big">
        @if (item().altWert) {
          <span class="fw-semibold text-secondary">{{ item().kurzWert }}</span>
          <br />
          {{ item().altWert }}
        } @else {
          {{ item().kurzWert }}
        }
      </div>
    </div>
    <div class="col-auto">
      <button
        (click)="toggleCollapse()"
        [attr.disabled]="!item().langWert || null"
        [ngClass]="{ 'text-gray': !item().langWert }"
        class="btn btn-clean text-secondary"
      >
        <i class="fa fa-chevron-down" [ngClass]="{ 'rotate-180': !isCollapsed() }"></i>
      </button>
    </div>
  </div>
  <div #collapse="ngbCollapse" [ngbCollapse]="isCollapsed()">
    <div [innerHTML]="item().langWert"></div>
  </div>
</div>
