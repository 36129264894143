import {
  Abschlagsplan,
  BezeichnungEnum,
  InvoiceTitleData,
  Preisbestandteil,
  Rechnung,
  RechnungDetails,
  Sparte,
  Teilbetrag,
} from '@mwe/models';
import dayjs from 'dayjs';
import { isArrayWithMinOneItem } from '../../common/mwe-util';
import { isProductCategoryEmobility } from '../../products/product-categoriy.utils';

export const findPreisbestandteil = (code: BezeichnungEnum, list: Preisbestandteil[]): Preisbestandteil => {
  return list.find(pb => pb.bezeichnung.toLowerCase() === code.toLowerCase());
};

export const getPreisbestandteil = (code: BezeichnungEnum, invoiceDetails: RechnungDetails): Preisbestandteil => {
  return findPreisbestandteil(code, invoiceDetails.preisbestandteil);
};

export const getPreisbestandteilWert = (code: BezeichnungEnum, invoiceDetails: RechnungDetails): number => {
  return getPreisbestandteil(code, invoiceDetails)?.preisbestandteilWert;
};

export const getNextPartialData = (data: Abschlagsplan): Teilbetrag => {
  if (!data) {
    return null;
  }

  const today = new Date();
  return data?.teilbetraege.find(t => !dayjs(t.faelligkeitsdatum).isBefore(today));
};

export const getNextPartialDataByIndex = (data: Abschlagsplan, indexOffset: number): Teilbetrag => {
  if (!data) {
    return null;
  }

  const today = new Date();
  const partialAmounts = data?.teilbetraege.filter(t => t.nummer !== '00');
  const nextAmountIndex = partialAmounts?.findIndex(t => !dayjs(t.faelligkeitsdatum).isBefore(today));
  const indexOfInterest = isNaN(nextAmountIndex) || nextAmountIndex === -1 ? undefined : nextAmountIndex + indexOffset;
  if (indexOfInterest < data?.teilbetraege.length - 1) {
    return data.teilbetraege[indexOfInterest];
  }
  return undefined;
};

export const getNextPartialAmount = (data: Abschlagsplan): number => {
  return getNextPartialData(data)?.betrag;
};

export const getPaymentExtensionDate = (data: Teilbetrag): string => {
  if (!isArrayWithMinOneItem(data.produktspezifischerTeilbetrag)) {
    return '';
  }

  const item = data.produktspezifischerTeilbetrag.find(i => i.stundungsDatum);

  return item?.stundungsDatum ?? '';
};

export function getInvoiceTitle(invoice: Rechnung): InvoiceTitleData {
  let label = '';
  let date = invoice.verrechnungszeitraumBis;

  switch (invoice.rechnungsgrund) {
    case '01':
      label =
        invoice.rechnungsperiode === 'MONTH'
          ? 'interactive-invoices.invoice-cards.title.month'
          : 'interactive-invoices.invoice-cards.title.year';
      break;
    case '02':
      label = 'interactive-invoices.invoice-cards.title.interimBill';
      break;
    case '03':
    case '04':
      label = 'interactive-invoices.invoice-cards.title.finalBill';
      break;
    case '06':
      label = 'interactive-invoices.invoice-cards.title.specialBill';
      date = invoice.belegdatum;
      break;
    default:
      label = 'interactive-invoices.invoice-cards.title.default';
      date = invoice.belegdatum;
      break;
  }

  if (isProductCategoryEmobility(invoice.sparte?.[0])) {
    date = invoice.belegdatum;
  }

  return {
    label,
    translationParams: {
      year: dayjs(date).format('YYYY'),
      month: dayjs(date).format('M'),
    },
  };
}
