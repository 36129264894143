import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { v4 } from 'uuid';

@Component({
  selector: 'mwe-expander',
  templateUrl: 'expander.component.html',
})
export class ExpanderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('collapsible') collapsibleRef: ElementRef;
  @Input() textKey: string;
  @Input() textCss: string;
  @Input() showMoreKey = 'global.expander.showMore';
  @Input() showLessKey = 'global.expander.showLess';
  @Input() marginTopCss = 'mt-4';
  showMoreText = true;
  uuid: string;

  constructor() {}

  ngOnInit(): void {
    this.uuid = v4();
  }

  ngAfterViewInit(): void {
    // this is to undo any change in scroll position by tab-focusing inside while collapsed, see MWE-12458
    this.collapsibleRef.nativeElement.addEventListener('focusout', this.onFocusout.bind(this));
  }
  onFocusout(e): void {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.collapsibleRef.nativeElement.scrollTop = 0;
    }
  }
  toggleText(): void {
    this.showMoreText = !this.showMoreText;
    this.collapsibleRef.nativeElement.scrollTop = 0;
  }
  ngOnDestroy() {
    this.collapsibleRef.nativeElement.removeEventListener('focusout', this.onFocusout);
  }
}
