<div class="mb-4">
  <mwe-basic-card>
    <h3 class="text-center" translate="products.unpaidInvoices.addressInfo"></h3>
    <p class="text-center text-danger h1" role="mark" data-test="unpaid-invoice-notification-amount">{{ this.amount | euroCurrency }}</p>
    @if (isNavigateBtnVisible) {
      <div class="text-center">
        <mwe-button
          (clicked)="showUnpaidInvoices()"
          labelKey="products.unpaidInvoices.showBtn"
          buttonId="unpaid-invoice-notification-show-unpaid-invoices-btn"
        ></mwe-button>
      </div>
    }
  </mwe-basic-card>
</div>
