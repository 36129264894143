import { Component, OnInit, signal } from '@angular/core';
import { CHARGING_CARD_ORDER_ROUTE, NEW_CLIENT_NEW_ADDRESS_ROUTE, NEW_CLIENT_ROUTE, SUPERSCHNELL_ROUTE } from '@mwe/constants';
import { Router } from '@angular/router';
import { getCompanyCorrelationFromCategory } from '@mwe/utils';
import { Sparte } from '@mwe/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mwe-order-products-popup',
  // todo refactor CardWithIconAndButtonComponent to use BasicCard instead of profile-card.scss, use template from here for Refactoring
  // refactor this template with CardWithIconAndButtonComponent
  templateUrl: './order-products-popup.component.html',
})
export class OrderProductsPopupComponent implements OnInit {
  // is being passed by popupService
  loadedData: boolean;

  Sparte = Sparte;

  hasAllProducts = signal(false);
  stromCompany = signal('');
  waermeCompany = signal('');
  internetCompany = signal('');
  emobilityCompany = signal('');

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.hasAllProducts.set(this.loadedData);
    this.stromCompany.set(getCompanyCorrelationFromCategory(Sparte.Strom));
    this.waermeCompany.set(getCompanyCorrelationFromCategory(Sparte.Fernwaerme));
    this.internetCompany.set(getCompanyCorrelationFromCategory(Sparte.Internet));
    this.emobilityCompany.set(getCompanyCorrelationFromCategory(Sparte.Emobility));
  }

  startNewClientProcess(): void {
    this.closePopup();
    this.router.navigateByUrl(`/${NEW_CLIENT_ROUTE}/${NEW_CLIENT_NEW_ADDRESS_ROUTE}`);
  }

  startSuperSchnellProcess(): void {
    this.closePopup();
    this.router.navigateByUrl(`/${SUPERSCHNELL_ROUTE}/${NEW_CLIENT_NEW_ADDRESS_ROUTE}`);
  }

  orderChargingCard(): void {
    this.closePopup();
    this.router.navigateByUrl(`/${CHARGING_CARD_ORDER_ROUTE}`);
  }

  closePopup() {
    this.activeModal.close();
  }
}
