import { Component, computed, inject, input, OnInit } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { TextNodeType } from '@mwe/models';
import { HeadlineService } from '../../headline/headline.service';

interface TariffPriceCardTheme {
  cardClass: string;
}

interface TariffPriceCardThemes {
  loading: TariffPriceCardTheme;
  strom: TariffPriceCardTheme;
  gas: TariffPriceCardTheme;
  emobility: TariffPriceCardTheme;
  internet: TariffPriceCardTheme;
  iptv: TariffPriceCardTheme;
  voip: TariffPriceCardTheme;
  nachtstrom: TariffPriceCardTheme;
  stromrueck: TariffPriceCardTheme;
  discount: TariffPriceCardTheme;
  fernwaerme: TariffPriceCardTheme;
}

const cardThemes: TariffPriceCardThemes = {
  loading: {
    cardClass: 'bg-gray',
  },
  strom: {
    cardClass: 'bg-brand-green',
  },
  gas: {
    cardClass: 'bg-secondary-light',
  },
  emobility: {
    cardClass: 'bg-secondary-light',
  },
  internet: {
    cardClass: 'bg-primary',
  },
  iptv: {
    cardClass: 'bg-primary',
  },
  voip: {
    cardClass: 'bg-primary',
  },
  nachtstrom: {
    cardClass: 'bg-secondary-light',
  },
  stromrueck: {
    cardClass: 'bg-brand-green',
  },
  discount: {
    cardClass: 'bg-warning',
  },
  fernwaerme: {
    cardClass: 'bg-primary',
  },
};

@Component({
  selector: 'mwe-tariff-card-template',
  templateUrl: './tariff-card-template.component.html',
  providers: [HeadlineService],
})
export class TariffCardTemplateComponent implements OnInit {
  category = input<string>();
  title = input<string>();
  subTitle = input<string>();
  hasDiscount = input<boolean>();
  isDiscountKnoedlVisible = input<false>();
  hasActions = input<boolean>();
  hasContent = input<boolean>(true);
  noBoxShadow = input<boolean>();
  state = input<ServiceStateEnum>();
  headlineReference = input<TextNodeType>('p');
  isSpot = input(false);
  spotUrl = input('');

  iconName = computed<string>(() => `productDetails/${this.category().toLowerCase()}.svg`);
  theme = computed<TariffPriceCardTheme>(() => {
    // !this.hasError && isArrayWithMinOneItem(filterActiveContractDiscounts(this.discounts))
    if (this.state() === ServiceStateEnum.LOADING) {
      return cardThemes.loading;
    }
    if (this.hasDiscount()) {
      return cardThemes.discount;
    }

    return cardThemes[this.category()?.toLowerCase()] || cardThemes.loading;
  });

  headlineService = inject(HeadlineService);

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference());
  }
}
