import { Component, computed, inject, input, OnInit } from '@angular/core';
import { Product, TextNodeType, translateTariffClasses } from '@mwe/models';
import { TranslateService } from '@ngx-translate/core';
import { HeadlineService } from '../../../headline/headline.service';

@Component({
  selector: 'mwe-tariff-card-waerme',
  templateUrl: './tariff-card-waerme.component.html',
  providers: [HeadlineService],
})
export class TariffCardWaermeComponent implements OnInit {
  product = input.required<Product>();
  headlineReference = input<TextNodeType>('h3');
  tariffClasses = computed<Promise<string[]>>(() => {
    return translateTariffClasses(this.product().category, this.product().tariffClasses, this.translateService);
  });

  headlineService = inject(HeadlineService);
  private translateService = inject(TranslateService);

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference());
  }
}
