import { ChangeDetectionStrategy, Component, computed, ElementRef, Input, signal, ViewChild } from '@angular/core';

@Component({
  selector: 'mwe-flipping-content-animated',
  templateUrl: './flipping-content-animated.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlippingContentAnimatedComponent {
  @ViewChild('flipContent') flipContentRef: ElementRef<HTMLElement>;
  @Input() frontCardLabel: string;
  @Input() backCardLabel: string;

  isFlipped = signal(false);
  flipBtnLabel = computed(() => (this.isFlipped() ? this.backCardLabel : this.frontCardLabel));

  flip(): void {
    this.isFlipped.update(value => !value);
    const el = this.flipContentRef.nativeElement;
    const sideHeights = this.getSideHeights();
    if (new Set(sideHeights).size === 1) {
      return;
    }
    const startHeight = this.isFlipped() ? sideHeights[0] : sideHeights[1];
    const targetHeight = this.isFlipped() ? sideHeights[1] : sideHeights[0];
    el.style.height = startHeight + 'px';
    el.classList.add('flip-height-transition');
    el.addEventListener('transitionend', this.onTransitionend.bind(this));
    setTimeout(() => {
      el.style.height = targetHeight + 'px';
    });
  }

  onTransitionend($event) {
    const el = this.flipContentRef.nativeElement;
    if ($event.target !== el) {
      return;
    }
    el.removeEventListener('transitionend', this.onTransitionend.bind(this));
    const sideHeights = this.getSideHeights();
    const targetHeight = this.isFlipped() ? sideHeights[1] : sideHeights[0];
    el.classList.remove('flip-height-transition');
    if (targetHeight === Math.max(...sideHeights) || Math.max(...sideHeights) === Math.min(...sideHeights)) {
      el.removeAttribute('style');
    }
  }

  getSideHeights(): number[] {
    return Array.from(this.flipContentRef.nativeElement.children[0].children).map(value => {
      return (value.children[0] as HTMLElement).offsetHeight;
    });
  }
}
