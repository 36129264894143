import { Component, computed, input, output } from '@angular/core';
import { OptionGroups, PriceIndicationCampaignTextList, TariffOptionSap, TariffSelectionCardData } from '@mwe/models';

@Component({
  selector: 'mwe-tariff-selection-card-front-ui',
  templateUrl: './tariff-selection-card-front-ui.component.html',
})
export class TariffSelectionCardFrontUiComponent {
  selectedTariff = input.required<TariffSelectionCardData>();
  groups = input<OptionGroups[]>();
  activeCampaignTexts = input<PriceIndicationCampaignTextList[]>();
  advantages = input<string[]>();
  readonly tariffUpdated = output<TariffOptionSap>();

  isSpotTariff = computed<boolean>(() => {
    return (
      !!this.selectedTariff().priceIndication?.arbeitspreisSpotDurchschnitt &&
      !!this.selectedTariff().priceIndication?.arbeitspreisSpotMin &&
      !!this.selectedTariff().priceIndication?.arbeitspreisSpotMax
    );
  });

  spotTariffPrice = computed<string>(
    () =>
      `${this.selectedTariff().priceIndication.arbeitspreisSpotMin} - ${this.selectedTariff().priceIndication.arbeitspreisSpotMax} ${this.selectedTariff().priceIndication?.arbeitspreisUnit}`,
  );

  infoText = computed(() => {
    return {
      infoTextCode: 'rechnungen.interaktiveRechnung.produktDetails.tarifKarte.spot',
      customLinkTarget: this.selectedTariff().priceIndication?.produktUrl ?? '',
    };
  });

  getTestId(type: string): string {
    return `tariff-selection-card-${type}`;
  }

  onOptionSelected(item: OptionGroups, optionId: string): void {
    // update userSelection with new selected sap option
    const newSapOption = this.selectedTariff().tariffOptionsSap.find(o => o.option === optionId);
    item.selectedTariffOptionId = optionId;

    this.tariffUpdated.emit(newSapOption);
  }
}
