<div class="mb-4">
  <mwe-address [address]="address" [isAddressDetailsBodyVisible]="true" [isArrowDownHidden]="false"></mwe-address>
</div>
<div class="mb-4" *ngIf="stromOrGasProducts?.length">
  <mwe-headline [headlineLevelIncrement]="0" cssClass="h3 mt-6" [text]="registerStromGasTitle"></mwe-headline>

  <span
    [innerHTML]="registerStromGasText | translate: { registrationAt: registrationAt }"
    class="text--big"
    data-test="products-summary-strom-gas-register-text"
  ></span>
  @if (selectedTariffs?.length > 0) {
    <mwe-headline [headlineLevelIncrement]="1" cssClass="h4 mt-6" text="summary.productsFromWEV"></mwe-headline>
    <div class="row mt-3 mb-0 gap-5 gap-md-6" data-test="products-summary-strom-gas-products">
      <mwe-tariff-selection-container
        [selectedTariffs]="selectedTariffs"
        [isReadOnly]="true"
        [headlineReference]="headlineService.getHeadline(2)"
      />
    </div>
  } @else {
    <mwe-headline [headlineLevelIncrement]="1" cssClass="h4 mt-6" text="summary.productsFromWE"></mwe-headline>
    <div class="row mt-3 mb-0" data-test="products-summary-strom-gas-products">
      @for (product of stromOrGasProducts; track product) {
        <div class="col-12 col-lg-6 mb-4">
          <mwe-basic-card>
            <mwe-wizard-product-card
              [product]="product"
              [showTariffDetailsLink]="showTariffDetailsLink"
              [showTariffDetails]="showStromGasTarifDetails && showTariffDetails"
              [isAvailable]="true"
              [tariffClasses]="product.tariffClasses"
              [tarifKey]="product.tarifKey"
              [productSelection]="productSelection"
              [grundpreisLabelOverwrite]="grundpreisLabelOverwrite"
              [attr.data-test]="'products-summary-card-' + product.category?.toLowerCase()"
            ></mwe-wizard-product-card>
          </mwe-basic-card>
        </div>
      }
    </div>
  }
</div>
<div *ngIf="showBenefitsCard" class="mb-4">
  <mwe-basic-card [hasBackground]="true">
    <mwe-basic-card-title
      [cardTitle]="'summary.yourBenefits'"
      [iconClass]="'fa-gift'"
      [headingNodeType]="headlineService.getHeadline(1)"
    ></mwe-basic-card-title>
    <div *ngIf="voucherInfo">
      <p class="text--big mb-0" data-test="products-summary-additionalDetails-voucher">
        <strong class="text-blue d-inline-block pb-2">
          <span [translateParams]="{ voucherCode: voucherInfo.aktionscode }" translate="summary.voucherInfo.title"></span>
        </strong>
        <br />
        <span
          *ngIf="voucherStrom?.length > 0"
          [innerHTML]="'&lt;strong>' + ('products.category.strom' | translate) + ':&lt;/strong> ' + voucherStrom + '&lt;br/>'"
          class="d-inline-block pb-2"
        ></span>
        <span
          *ngIf="voucherGas?.length > 0"
          [innerHTML]="'&lt;strong>' + ('products.category.gas' | translate) + ':&lt;/strong> ' + voucherGas + '&lt;br/>'"
          class="d-inline-block pb-2"
        ></span>
      </p>
    </div>
    <hr *ngIf="isEligableForSepaBenefits && voucherInfo" />
    <div *ngIf="isEligableForSepaBenefits">
      <p class="text--big mb-0">
        <strong [translate]="sepaBenefitsTitleKey | translate" class="text-blue d-inline-block pb-2"></strong>
        <br />
        <span [innerHTML]="sepaBenefitsTextKey | translate"></span>
      </p>
    </div>
  </mwe-basic-card>
</div>

<div class="mb-4" *ngIf="fernwaermeProducts?.length">
  <mwe-headline [headlineLevelIncrement]="0" [text]="registerFernwaermeTitle" cssClass="h3 mt-6"></mwe-headline>
  <span
    [innerHTML]="registerFernwaermeText | translate: { address: address.getGroupFormatted() }"
    class="text--big"
    data-test="products-summary-register-fernwaerme-text"
  ></span>
  <mwe-headline [headlineLevelIncrement]="1" text="summary.productsFromWE" cssClass="h4 mt-6"></mwe-headline>
  <p class="text--big" *ngIf="registerFernwaermeSubTitle" [translate]="registerFernwaermeSubTitle"></p>
  <div class="row mt-3">
    @for (product of fernwaermeProducts; track product) {
      <div class="col-12 col-lg-6 mb-4">
        <mwe-tariff-card-waerme [headlineReference]="headlineService.getHeadline(2)" [product]="product"></mwe-tariff-card-waerme>
      </div>
    }
  </div>
</div>

<div class="mb-4">
  <mwe-headline [headlineLevelIncrement]="0" cssClass="h3 mt-6 mb-4" text="summary.yourContractDetails"></mwe-headline>
  <mwe-basic-card [hasBackground]="true">
    <mwe-basic-card-title
      [cardTitle]="'summary.contractDataCardTitle'"
      [iconClass]="'fa-file-user'"
      [headingNodeType]="headlineService.getHeadline(1)"
    ></mwe-basic-card-title>
    <strong
      [translate]="customerDataType !== 'Businesskunde' ? 'summary.personalData' : 'summary.businessData'"
      class="text-blue d-inline-block mb-2"
    ></strong>
    <ul class="list-separated spacing-4" data-test="products-summary-customerData">
      <li data-test="products-summary-customerData-name">
        <p [classList]="!fernwaermeProducts?.length && 'mb-0'" class="text--big">
          <span *ngIf="!!customerDataType && !isKundeWerden">{{ customerDataType }} <br /></span>
          <ng-container *ngIf="customerDataType === 'Businesskunde'">
            <span *ngIf="businessData?.companyName">
              <span translate="newClient.summary.companyName"></span>: {{ businessData.companyName }}
            </span>
            <span *ngIf="businessData?.uidNumber">
              <br /><span translate="newClient.summary.uidNumber"></span>: {{ businessData.uidNumber }}
            </span>
            <span *ngIf="businessData?.corporateRegisterNumber">
              <br /><span translate="newClient.summary.corporateRegisterNumber"></span>: {{ businessData.corporateRegisterNumber }}
            </span>
          </ng-container>
          <span data-test="products-summary-customer-name">
            {{ salutation ? salutation + ' ' : '' }}{{ title ? title + ' ' : '' }}{{ firstname }} {{ lastname }}
          </span>
          <span
            *ngIf="!customerDataType || (!!customerDataType && customerDataType === 'Privatkunde')"
            data-test="products-summary-customer-birthdate"
          >
            <br />{{ birthDate }}</span
          >

          <br *ngIf="telefonNumber" />
          <span *ngIf="telefonNumber" data-test="products-summary-customer-phonenumber">
            {{ telefonNumber }}
          </span>
          <br />
          <span data-test="products-summary-customer-email">
            {{ email }}
          </span>
          <ng-content select="[slot='products-summary-customerData-name-additionalContent']"></ng-content>
        </p>
        @if (fernwaermeProducts?.length) {
          <mwe-info-text-card text="newClient.summary.districtHeatingInfo" />
        }
      </li>
      <li *ngIf="idCard && idCard.art === 'Ausweis'" data-test="products-summary-customerData-idCard">
        <p class="text--big mb-0">
          <strong><span translate="summary.idData"></span></strong>
          <br />
          {{ idCard.type }}, {{ idCard.number }}, {{ idCard.issuer }}
        </p>
      </li>
    </ul>
    <hr />
    <mwe-products-summary-invoice-old
      [additionalBulletPoints]="additionalBulletPointsStromGas"
      [areBankAccountInfoTextsVisible]="areBankAccountInfoTextsVisible"
      [contactDataEmailAddress]="contactDataEmailAddress"
      [eRechnungInfo]="eRechnungInfo"
      [formattedAddress]="formattedAddress"
      [invoiceInfoAlertMessage]="invoiceInfoAlertMessage"
      [isPaymentInfoVisible]="isPaymentInfoVisible"
      [paymentIcon]="paymentIcon"
      [paymentText]="paymentText"
      [personalBillingTitle]=""
      [productCategories]="productCategories"
      billingAddressTitleText="summary.eBillingAddress"
      contactDataEmailAddressLabelClass="text-blue d-inline-block pb-2"
      eBillingLabelClass="text-blue d-inline-block pb-2"
      paymentDataLabelClass="text-blue d-inline-block pb-2"
      shippingEmailTitleText="summary.invoiceAddress.shipping.emailPrefix"
      summaryBillingTitleText="summary.invoiceShipping"
    ></mwe-products-summary-invoice-old>
  </mwe-basic-card>
</div>
<ng-content select=".after-invoice"></ng-content>

<div *ngIf="showAdditionalInfo" class="mb-4">
  <mwe-basic-card>
    <mwe-basic-card-title
      [cardTitle]="'summary.additionalInformation'"
      [iconClass]="'fa-lightbulb-on'"
      [headingNodeType]="headlineService.getHeadline(1)"
    ></mwe-basic-card-title>
    <div class="row w-100">
      <div class="col-12">
        <ul class="list-separated spacing-4" data-test="products-summary-additionalDetails">
          <li *ngIf="showMeterReading" data-test="products-summary-additionalDetails-showMeterReading">
            <p class="text--big mb-0">
              <strong class="text-blue d-inline-block pb-2"><span [innerHTML]="meterReadingTitleKey | translate"></span></strong><br />
              <span [innerHTML]="meterReadingTextKey | translate"></span>
            </p>
          </li>
          <li *ngIf="mgmCode" data-test="products-summary-additionalDetails-mgmCode">
            <p class="text--big mb-0">
              <strong class="text-blue d-inline-block pb-2"><span translate="newClient.mgm.summaryTitle"></span></strong><br />
              <span> {{ mgmCode }}</span>
            </p>
          </li>
          <li *ngIf="showFormCardInfo" data-test="products-summary-additionalDetails-showFormCardInfo">
            <p class="text--big mb-0">
              <strong class="text-blue d-inline-block pb-2"><span translate="summary.formCardInfo.title"></span></strong><br />
              <span translate="summary.formCardInfo.description"></span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </mwe-basic-card>
</div>
<div *ngIf="registerStromGasInfoMessage" class="w-100 mt-4 mb-4">
  <mwe-alert [messageKey]="registerStromGasInfoMessage" type="info"></mwe-alert>
</div>
