import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Abschlagsplan, IAddressProducts, ILSystem, IPhysischePerson, parseAddress } from '@mwe/models';

const abschlagsplanKey = 'partialPayment_abschlagsplan';
const addressProductsKey = 'partialPayment_addressProducts';
const systemIdKey = 'partialPayment_systemId';
const accountIdKey = 'partialPayment_accountId';
const hasOnlyOneSelectionItemKey = 'partialPayment_hasOnlyOneSelectionItem';
const newAmountKey = 'partialPayment_newAmount';
const wantedAmountKey = 'partialPayment_wantedAmount';
const newStartDateKey = 'partialPayment_newStartDate';
const oldAmountKey = 'partialPayment_oldAmount';
const lastOrderIdKey = 'partialPayment_lastOrderId';
const retriedOnErrorKey = 'partialPayment_retriedOnError';
const submitIdKey = 'partialPayment_submitId';
const physischePersonKey = 'partialPayment_physischePerson';
const numberOfOpenChangesKey = 'partialPayment_numberOfOpenChanges';
@Injectable({ providedIn: 'root' })
export class PartialPaymentStateService {
  keepSessionStorageOnce = false;

  @SessionStorage(abschlagsplanKey)
  private _abschlagsplan: Abschlagsplan;

  @SessionStorage(addressProductsKey)
  private _addressProducts: IAddressProducts;

  @SessionStorage(accountIdKey)
  private _accountId: string;

  @SessionStorage(systemIdKey)
  private _systemId: ILSystem;

  @SessionStorage(hasOnlyOneSelectionItemKey)
  private _hasOnlyOneSelectionItem: boolean;

  @SessionStorage(newAmountKey)
  private _newAmount: number;

  @SessionStorage(wantedAmountKey)
  private _wantedAmount: number;

  @SessionStorage(oldAmountKey)
  private _oldAmount: number;

  @SessionStorage(newStartDateKey)
  private _newStartDate: string;

  @SessionStorage(lastOrderIdKey)
  private _lastOrderId: string;

  @SessionStorage(retriedOnErrorKey)
  private _retriedOnError: boolean;

  @SessionStorage(submitIdKey)
  private _submitId: number;

  @SessionStorage(physischePersonKey)
  private _physischePerson: IPhysischePerson;

  @SessionStorage(numberOfOpenChangesKey)
  private _numberOfOpenChanges: number;

  constructor(private storage: SessionStorageService) {}

  clear(): void {
    this.storage.clear(retriedOnErrorKey);
    this.storage.clear(submitIdKey);
    this.storage.clear(newAmountKey);
    this.storage.clear(wantedAmountKey);
    this.storage.clear(oldAmountKey);
    this.storage.clear(newStartDateKey);

    if (this.keepSessionStorageOnce) {
      this.keepSessionStorageOnce = false;
      return;
    }

    this.storage.clear(lastOrderIdKey);
    this.storage.clear(abschlagsplanKey);
    this.storage.clear(physischePersonKey);
    this.storage.clear(addressProductsKey);
    this.storage.clear(accountIdKey);
    this.storage.clear(systemIdKey);
    this.storage.clear(hasOnlyOneSelectionItemKey);
  }

  get abschlagsplan(): Abschlagsplan {
    return this._abschlagsplan;
  }

  set abschlagsplan(value: Abschlagsplan) {
    if (!value) {
      this.storage.clear(abschlagsplanKey);
    } else {
      this._abschlagsplan = value;
    }
  }

  get addressProducts(): IAddressProducts {
    if (!this._addressProducts) {
      return undefined;
    }

    this._addressProducts.address = parseAddress(this._addressProducts.address);
    return this._addressProducts;
  }

  set addressProducts(addressProducts: IAddressProducts) {
    if (!addressProducts) {
      this.storage.clear(addressProductsKey);
    } else {
      this._addressProducts = addressProducts;
    }
  }

  get accountId(): string {
    return this._accountId;
  }

  set accountId(accountId: string) {
    this._accountId = accountId;
  }

  get systemId(): ILSystem {
    return this._systemId;
  }

  set systemId(systemId: ILSystem) {
    this._systemId = systemId;
  }

  get hasOnlyOneSelectionItem(): boolean {
    return this._hasOnlyOneSelectionItem;
  }

  set hasOnlyOneSelectionItem(noNeedForBackButton: boolean) {
    this._hasOnlyOneSelectionItem = noNeedForBackButton;
  }

  get newAmount(): number {
    return this._newAmount;
  }

  // new amount calculated from IL
  set newAmount(newAmount: number) {
    this._newAmount = newAmount;
  }

  get wantedAmount(): number {
    return this._wantedAmount;
  }

  // user input
  set wantedAmount(wantedAmount: number) {
    this._wantedAmount = wantedAmount;
  }

  get oldAmount(): number {
    return this._oldAmount;
  }

  set oldAmount(oldAmount: number) {
    this._oldAmount = oldAmount;
  }

  get newStartDate(): string {
    return this._newStartDate;
  }

  set newStartDate(startDate: string) {
    this._newStartDate = startDate;
  }

  get submitId(): number {
    return this._submitId || undefined;
  }

  set submitId(submitId: number) {
    if (submitId) {
      this._submitId = submitId;
    } else {
      this.storage.clear(submitIdKey);
    }
  }

  get retriedOnError(): boolean {
    return this._retriedOnError || undefined;
  }

  set retriedOnError(retriedOnError: boolean) {
    if (retriedOnError) {
      this._retriedOnError = retriedOnError;
    } else {
      this.storage.clear(retriedOnErrorKey);
    }
  }

  get lastOrderId(): string {
    return this._lastOrderId || undefined;
  }

  set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this._lastOrderId = lastOrderId;
    } else {
      this.storage.clear(lastOrderIdKey);
    }
  }

  get physischePerson(): IPhysischePerson {
    return this._physischePerson || undefined;
  }

  set physischePerson(physischePerson: IPhysischePerson) {
    if (physischePerson) {
      this._physischePerson = physischePerson;
    } else {
      this.storage.clear(physischePersonKey);
    }
  }

  get numberOfOpenChanges(): number {
    return this._numberOfOpenChanges || undefined;
  }

  set numberOfOpenChanges(numberOfOpenChanges: number) {
    if (numberOfOpenChanges >= 0) {
      this._numberOfOpenChanges = numberOfOpenChanges;
    } else {
      this.storage.clear(numberOfOpenChangesKey);
    }
  }
}
