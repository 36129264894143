import { HttpClient } from '@angular/common/http';
import {
  HTTPError,
  ICAInformationRequestDTO,
  ICAInformationRequestStatusResponse,
  parseCAInformationRequestDTOFromOrderStatusDetail,
  parseAddress,
} from '@mwe/models';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../../environment/environment.service';
import { lastValueFrom } from 'rxjs';
import { CAInformationRequestStateService } from './ca-information-request-state.service';

@Injectable({ providedIn: 'root' })
export class CAInformationRequestService {
  private resourceUrl: string;

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
    private informationRequestStateService: CAInformationRequestStateService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/verrechnungskonten/informationrequest/';
  }

  async confirm(dataDTO: ICAInformationRequestDTO): Promise<ICAInformationRequestStatusResponse> {
    try {
      return await lastValueFrom(this.httpClient.post<ICAInformationRequestStatusResponse>(this.resourceUrl + 'confirm', dataDTO));
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<ICAInformationRequestStatusResponse> {
    const url = this.resourceUrl + 'confirmStatus?submitId=' + submitId;
    return lastValueFrom(this.httpClient.get<ICAInformationRequestStatusResponse>(url));
  }

  async getOrderStatusDetails(): Promise<ICAInformationRequestDTO> {
    const response = await lastValueFrom(
      this.httpClient.get(this.resourceUrl + 'orderStatusDetails/' + this.informationRequestStateService.lastOrderId),
    );
    const result = parseCAInformationRequestDTOFromOrderStatusDetail(response);
    this.informationRequestStateService.submitId = Number(this.informationRequestStateService.lastOrderId);
    this.informationRequestStateService.customerId = result.mappingData[0].customerNumber;
    this.informationRequestStateService.accountId = result.mappingData[0].accountNumber;
    this.informationRequestStateService.categories = result.mappingData[0].categories;
    this.informationRequestStateService.address = parseAddress(result.address);
    if (result.customerDataChangeData?.email) {
      this.informationRequestStateService.updateEmail = true;
    }
    result.createdAt = (response as any).createdAt;
    result.processStatus = (response as any).processStatus;
    return result;
  }
}
