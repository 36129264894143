<table [ngClass]="classes" class="table-custom-striped">
  <thead *ngIf="description?.headerItems">
    <tr>
      <th *ngFor="let item of description.headerItems; let i = index" [ngClass]="[i] ? cellClasses[i] : ''">
        <mwe-info-text *ngIf="item.infoTextCode" [infoTextCode]="item.infoTextCode">
          {{ item.label | translate }}
        </mwe-info-text>
        <ng-container *ngIf="!item.infoTextCode">
          {{ item.label | translate }}
        </ng-container>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of description?.dataItems">
      <td *ngFor="let column of item; let i = index" [ngClass]="cellClasses[i] ? cellClasses[i] : ''">{{ column }}</td>
    </tr>
    <ng-content select="[slot='table-rows']"></ng-content>
  </tbody>
  <tfoot>
    <ng-content select="[slot='table-footer']"></ng-content>
  </tfoot>
  <ng-content></ng-content>
</table>
