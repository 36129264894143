import { ChangeDetectionStrategy, Component, input, model, OnInit, output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { PRODUCTS_ROUTE } from '@mwe/constants';
import { NavigationLogic, NavigationStateService } from '@mwe/services';

@Component({
  selector: 'mwe-button-back',
  templateUrl: './button-back.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBackComponent implements OnInit {
  route = model<string>();
  onlyEmitClick = input<boolean>(false);
  label = input<string>('global.back');
  backBtnClicked = output();

  private routeFromWizardCancelUrl = signal<boolean>(false);
  private routeFromNavigateBackList = signal<boolean>(false);

  constructor(
    private navigationLogic: NavigationLogic,
    private navigationStateService: NavigationStateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.setRoute();
  }

  onButtonClicked(): void {
    if (this.onlyEmitClick()) {
      this.backBtnClicked.emit();
      return;
    }

    if (this.routeFromWizardCancelUrl()) {
      this.navigationStateService.wizardCancelUrl = null;
    } else if (this.routeFromNavigateBackList()) {
      this.navigationStateService.removeLatestNavigatingBackTo();
    }

    this.router.navigateByUrl(this.route());
  }

  private setRoute(): void {
    if (this.onlyEmitClick()) {
      return;
    }

    this.checkIfNavigationItemCanBeRemovedBecauseOfBrowserBack();

    if (this.route()) {
      return;
    }

    if (
      this.navigationStateService.wizardCancelUrl !== '/' &&
      this.navigationStateService.wizardCancelUrl !== this.router.url // check if there was a browser-back
    ) {
      this.routeFromWizardCancelUrl.set(true);
      this.route.set(this.navigationStateService.wizardCancelUrl);
      return;
    }

    if (!this.navigationStateService.getLatestNavigatingBackTo()) {
      this.route.set('/');
      return;
    }

    this.routeFromNavigateBackList.set(true);
    this.checkIfNavigationItemCanBeRemovedBecauseOfBrowserBack();
    this.route.set(this.navigationLogic.getRouteFromLatestNavigatingBackItem());
    this.navigationLogic.checkSpecialRouteHandling();

    if (!this.route()) {
      this.route.set(`/${PRODUCTS_ROUTE}`);
    }
  }

  private checkIfNavigationItemCanBeRemovedBecauseOfBrowserBack(): void {
    if (this.router.url === this.navigationLogic.getRouteFromLatestNavigatingBackItem()) {
      this.navigationStateService.removeLatestNavigatingBackTo();
    }
  }
}
