import { Injectable } from '@angular/core';
import { IDsgvoAdditionalData, ISaveConsentModel, Product, UserJournal } from '@mwe/models';
import dayjs from 'dayjs';
import { FeatureToggleEnum, PROFILE_DSGVO_CHECK_ROUTE, PROFILE_ROUTE, TriggeringProcessEnum } from '@mwe/constants';
import { convertUserJournalDateToDayJsObj, isArrayWithMinOneItem } from '@mwe/utils';
import { AccountLogic, DsgvoService, DsgvoStateService, LoggingService, ProfileService, UserJournalService } from '@mwe/services';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DsgvoLogic {
  DSGVO_SUCCESS = 'dsgvoSuccess';
  DSGVO_ERROR = 'dsgvoFail';
  DSGVO_SUCCESS_365 = 'dsgvoSuccess365';

  constructor(
    private service: DsgvoService,
    private stateService: DsgvoStateService,
    private userJournalService: UserJournalService,
    private profileService: ProfileService,
    private loggingService: LoggingService,
    private router: Router,
    private accountLogic: AccountLogic,
  ) {}

  async checkForRedirectToDsgvoSettings(products: Product[]): Promise<boolean> {
    if (
      this.accountLogic.isImpersonate() ||
      !isArrayWithMinOneItem(products) ||
      this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.DSGVO_CHECK_ENABLED)
    ) {
      return false;
    }

    try {
      const dsgvoEvents = await this.getAllDSGVOJournalEvents();
      const cancelCondition = !this.checkIfDSGVOShouldBeShown(dsgvoEvents) || this.stateService.hasCheckedIfDsgvoCheckShouldBeOpened;
      if (!this.stateService.shouldCheckDsgvoAtNextPossibleMoment() && cancelCondition) {
        return false;
      }
      this.redirectToDsgvo();
      return true;
    } catch (error) {
      this.loggingService.logError(error, `DsgvoLogic/checkForRedirectToDsgvoSettings`);
      return false;
    }
  }

  async triggerDsgvoForAssociations(gpn: string, systemId: string): Promise<void> {
    const dsgvoData = {
      gruppen: [],
      gpList: [
        {
          geschaeftsPartnerId: gpn,
          sparte: systemId,
        },
      ],
      ausloesenderProzess: TriggeringProcessEnum.INITIAL,
    } as ISaveConsentModel;

    try {
      await this.service.copyConsent(dsgvoData);
    } catch (reason) {
      if (reason.status == 404 && reason.error.code == 1001) {
        this.stateService.activateDsgvoCheckAtNextPossibleMoment();
      }
    }
  }

  checkIfDSGVOShouldBeShown(dsgvoEvents: UserJournal[]): boolean {
    if (!dsgvoEvents || dsgvoEvents?.length === 0) {
      return true;
    }

    const today = dayjs();
    const latestDsgvoJournalEntry = dsgvoEvents?.reduce((a, b) =>
      convertUserJournalDateToDayJsObj(a.modifiedAt).isAfter(convertUserJournalDateToDayJsObj(b.modifiedAt)) ? a : b,
    );
    return this.isCapableToShowDSGVO(latestDsgvoJournalEntry, today);
  }

  isCapableToShowDSGVO(event: UserJournal, today: dayjs.Dayjs): boolean {
    const beginPeriod = convertUserJournalDateToDayJsObj(event.modifiedAt);
    const endPeriod = this.DSGVO_SUCCESS_365 === event.event ? beginPeriod.add(365, 'day') : beginPeriod.add(90, 'day');
    const additionalData: IDsgvoAdditionalData = JSON.parse(event.additionalData);
    return !today.isBefore(endPeriod) && additionalData?.dsgvoAccepted !== 'fully_accepted';
  }

  async getAllDSGVOJournalEvents(): Promise<UserJournal[]> {
    const result = await this.userJournalService.getEvents([this.DSGVO_SUCCESS, this.DSGVO_ERROR, this.DSGVO_SUCCESS_365]);
    this.stateService.showOptionToSaveDecisionFor365Days = result?.length > 0;
    return result;
  }

  async updateDsgvoEvent(success: boolean, fieldStatus: string, saveDecisionFor365Days: boolean): Promise<void> {
    try {
      const messageEntry = success && saveDecisionFor365Days ? this.DSGVO_SUCCESS_365 : success ? this.DSGVO_SUCCESS : this.DSGVO_ERROR;
      await this.userJournalService.updateEvent(messageEntry, fieldStatus);
    } catch (e) {
      this.loggingService.logError(e, `could not save dsgvo event (success=${success})`);
    }
  }

  private redirectToDsgvo(): void {
    if (this.stateService.wasDsgvoCheckOpened && !this.stateService.shouldCheckDsgvoAtNextPossibleMoment()) {
      return;
    }

    this.stateService.resetDsgvoCheckAtNextPossibleMoment();
    this.stateService.wasDsgvoCheckOpened = true;
    this.stateService.hasCheckedIfDsgvoCheckShouldBeOpened = true;
    this.router.navigateByUrl(`/${PROFILE_ROUTE}/${PROFILE_DSGVO_CHECK_ROUTE}`);
  }
}
