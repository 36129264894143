import { inject, Injectable } from '@angular/core';
import { IProduktSelektion, PreisIndikation, TariffOptionSap, TariffSelectionCardData } from '@mwe/models';
import { LoggingService, TariffSelectionLogic } from '@mwe/services';
import { getTariffOptionsIdList, isArrayWithMinOneItem } from '@mwe/utils';
import { tariffSelectionStoreUtils } from './tariff-selection-store.utils';
import { ServiceStateEnum } from '@mwe/constants';
import { VoucherFormComponent } from '../../voucher/voucher-form/voucher-form.component';
import { concatMap, from, mergeAll, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TariffSelectionStore } from './tariff-selection.store';
import { TariffSelectionStoreInitParams } from './tariff-selection-store.model';

@Injectable({ providedIn: 'root' })
export class TariffSelectionCardLogic {
  // helper method, after every tariff-option-change we have to validate the voucher...
  // todo refactor VoucherFormComponent so we can reuse logic/service for voucher-validation instead of VoucherFormComponent
  doSomeVoucherFormMagic: (selection: IProduktSelektion) => VoucherFormComponent;

  private store = inject(TariffSelectionStore);
  private selectionLogic = inject(TariffSelectionLogic);
  private loggingService = inject(LoggingService);

  reset(): void {
    this.doSomeVoucherFormMagic = null;
    this.store.reset();
  }

  initializeStoreData(data: TariffSelectionStoreInitParams): void {
    if (!isArrayWithMinOneItem(data.allProducts)) {
      return;
    }

    this.store.initializeStore(data);

    let newSelectedTariffs = [] as TariffSelectionCardData[];
    // user selection in session storage
    if (isArrayWithMinOneItem(data.userSelectedItems)) {
      newSelectedTariffs = [
        ...newSelectedTariffs,
        ...this.getUtils().restoreSelectedTariffsFromSessionStorage(
          data.allProducts,
          data.allPriceIndications,
          data.userSelectedItems,
          data.userSelectedPriceIndications,
        ),
      ];
    }
    if (data.defaultProducts) {
      newSelectedTariffs = [
        ...newSelectedTariffs,
        ...this.getUtils().getMissingItemsAsDefault(
          data.allProducts,
          data.allPriceIndications,
          data.userSelectedItems,
          data.defaultProducts,
        ),
      ];
    }
    const sortedTariffs = this.getUtils().sortTariffs(newSelectedTariffs);
    this.store.initializeSelectedTariffs(sortedTariffs);
  }

  async checkInitialVoucherCode(): Promise<void> {
    const selection: IProduktSelektion = this.getProductSelectionFromSelectedTariffs();
    await this.getVoucherCode(selection, true);
    await this.handleVoucherCodeUpdate();
  }

  async handleVoucherCodeUpdate(): Promise<void> {
    this.updateAllStates(ServiceStateEnum.LOADING);

    from(this.store.selectedTariffs())
      .pipe(
        map(tariff => {
          return from(this.getNewPriceIndicationForUpdatedTariffOptionAfterVoucherUpdate(tariff, tariff.userSelection));
        }),
        mergeAll(),
        concatMap((data: { indication: PreisIndikation; tariff: TariffSelectionCardData; state: ServiceStateEnum }) => {
          this.updateCardData({
            ...data.tariff,
            priceIndication: data.indication,
            state: ServiceStateEnum.SUCCESS,
          });
          return of();
        }),
      )
      .subscribe();
  }

  async handleTariffOptionChange(tariffData: TariffSelectionCardData, newSelectedOption: TariffOptionSap): Promise<void> {
    this.store.updateCardLoadingState(tariffData, ServiceStateEnum.LOADING);

    const everySelectionItemNotUpdated = tariffData.userSelection.filter(u => u.art !== newSelectedOption.art);
    const userSelection = [...everySelectionItemNotUpdated, newSelectedOption];

    try {
      const priceIndication = await this.getNewPriceIndicationForUpdatedTariffOption(tariffData, userSelection, true);
      this.updateCardData({
        ...tariffData,
        userSelection,
        priceIndication,
        state: ServiceStateEnum.SUCCESS,
      });
    } catch (error) {
      this.loggingService.logError(error, 'TariffSelectionCardLogic/handleTariffOptionChange');
      this.updateCardData({
        ...tariffData,
        userSelection,
        state: ServiceStateEnum.ERROR,
      });
    }
  }

  updateCardData(newTariff: TariffSelectionCardData): void {
    this.store.updateCardData(newTariff);
    this.store.updatePriceIndication(newTariff.priceIndication);
  }

  async getNewPriceIndicationForUpdatedTariffOption(
    selectedTariff: TariffSelectionCardData,
    userSelection: TariffOptionSap[],
    withVoucherCodeValidation: boolean,
  ): Promise<PreisIndikation> {
    // tariff-change can have tariffs without options, so you don't need an update-request for other prices
    if (!isArrayWithMinOneItem(selectedTariff.priceIndication.tarif.tarifOptionen)) {
      const copyPriceIndication = { ...selectedTariff.priceIndication } as PreisIndikation;
      if (selectedTariff.anlageId) {
        copyPriceIndication.anlageId = selectedTariff.anlageId;
      }
      return copyPriceIndication;
    }

    const updatedTariff = {
      ...selectedTariff,
      userSelection,
    };

    // create temp selection for voucher code validation
    // first step: create selection with values before update
    const selection: IProduktSelektion = this.getProductSelectionFromSelectedTariffs();
    const updatedProduct = this.getUtils().createNewProduct(this.store.allProducts(), updatedTariff);
    // second step: update specific selection with new tariff-options
    if (selectedTariff.anlageId) {
      // tariff change
      const unaffectedAnlagen = selection.anlagen.filter(a => a.anlageId !== selectedTariff.anlageId);
      selection.anlagen = [...unaffectedAnlagen, updatedProduct];
    } else {
      // new client
      selection[selectedTariff.category.toLowerCase()] = updatedProduct;
    }

    const voucherCode = await this.getVoucherCode(selection, withVoucherCodeValidation);
    const response = await this.selectionLogic.getUpdatedPriceIndications(
      this.store.zipCode(),
      selectedTariff.category,
      selectedTariff.tariffKey,
      getTariffOptionsIdList(userSelection),
      voucherCode,
    );

    // tariff-change
    if (selectedTariff.anlageId) {
      response.anlageId = selectedTariff.anlageId;
    }

    return response;
  }

  async getVoucherCode(selection: IProduktSelektion, withVoucherCodeValidation: boolean): Promise<string> {
    const voucherFormRef = this.doSomeVoucherFormMagic?.(selection);
    if (voucherFormRef?.code && withVoucherCodeValidation) {
      await voucherFormRef.checkCode();
    }

    // wl voucher initialized todo refactor VoucherFormComponent to have a better way
    if (voucherFormRef?.showWLFields && !voucherFormRef.hasValidWLFormData()) {
      return undefined;
    }

    return voucherFormRef?.hasInvalidCode() ? undefined : voucherFormRef?.code;
  }

  getProductSelectionFromSelectedTariffs(): IProduktSelektion {
    return this.store.getProductSelection();
  }

  updateAllStates(state: ServiceStateEnum): void {
    this.store.updateAllCardLoadingStates(state);
  }

  updateAdditionalOptionGroupName(additionalOptionGroupName: string): void {
    this.store.updateAdditionalOptionGroupName(additionalOptionGroupName);
  }

  // helper function for rxjs-construct
  private async getNewPriceIndicationForUpdatedTariffOptionAfterVoucherUpdate(
    tariff: TariffSelectionCardData,
    userSelection: TariffOptionSap[],
  ): Promise<{ indication: PreisIndikation; tariff: TariffSelectionCardData; state: ServiceStateEnum }> {
    try {
      const indication = await this.getNewPriceIndicationForUpdatedTariffOption(tariff, userSelection, false);
      return { indication, tariff, state: ServiceStateEnum.SUCCESS };
    } catch (error) {
      this.loggingService.logError(error, 'TariffSelectionCardLogic/handleVoucherCodeUpdate');
      return { indication: tariff.priceIndication, tariff, state: ServiceStateEnum.ERROR };
    }
  }

  // used for unit tests, to mock utils
  private getUtils() {
    return tariffSelectionStoreUtils;
  }
}
