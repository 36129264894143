import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { IBusinessData, IdCard } from '@mwe/models';
import { EnvironmentService } from '@mwe/services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mwe-personal-data-summary-card',
  templateUrl: './personal-data-summary-card.component.html',
})
export class PersonalDataSummaryCardComponent {
  @Input() isKundeWerden = false;

  @Input() salutation: string;
  @Input() title: string;
  @Input() firstname: string;
  @Input() lastname: string;
  @Input() email: string;
  @Input() birthDate: string;
  @Input() telefonNumber: string;
  @Input() idCard: IdCard;
  @Input() businessData: IBusinessData;

  customerDataType = signal<string | undefined>('Privatkunde');

  constructor(protected environmentService: EnvironmentService) {
    // TODO refactor this whole getPortalCustomerType() stuff. Too much magic strings and too much logic decentralized
    this.customerDataType.set(this.environmentService.getPortalCustomerType() === 'business' ? 'Businesskunde' : 'Privatkunde');
  }
}
