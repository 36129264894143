<div class="mb-4">
  <mwe-basic-card>
    <h6 [translate]="customerDataType() !== 'Businesskunde' ? 'summary.personalData' : 'summary.businessData'"></h6>
    <div class="row w-100">
      <div class="col-12">
        <ul class="list-separated spacing-4" data-test="products-summary-customerData">
          <li data-test="products-summary-customerData-name">
            <p class="text--big mb-0">
              <strong><span translate="summary.customerData"></span></strong><br />
              <span *ngIf="!!customerDataType() && !isKundeWerden">{{ customerDataType() }} <br /></span>
              <ng-container *ngIf="customerDataType() === 'Businesskunde'">
                <span *ngIf="businessData?.companyName">
                  <span translate="newClient.summary.companyName"></span>: {{ businessData.companyName }}
                </span>
                <span *ngIf="businessData?.uidNumber">
                  <br /><span translate="newClient.summary.uidNumber"></span>: {{ businessData.uidNumber }}
                </span>
                <span *ngIf="businessData?.corporateRegisterNumber">
                  <br /><span translate="newClient.summary.corporateRegisterNumber"></span>:
                  {{ businessData.corporateRegisterNumber }}
                </span>
              </ng-container>
              <span data-test="products-summary-customer-name">
                {{ salutation ? salutation + ' ' : '' }}{{ title ? title + ' ' : '' }}{{ firstname }} {{ lastname }}
              </span>
              <span
                *ngIf="!customerDataType() || (!!customerDataType() && customerDataType() === 'Privatkunde')"
                data-test="products-summary-customer-birthdate"
              >
                <br />{{ birthDate }}</span
              >

              <br *ngIf="telefonNumber" />
              <span *ngIf="telefonNumber" data-test="products-summary-customer-phonenumber">
                {{ telefonNumber }}
              </span>
              <br />
              <span data-test="products-summary-customer-email">
                {{ email }}
              </span>
              <ng-content select="[slot='products-summary-customerData-name-additionalContent']"></ng-content>
            </p>
          </li>
          <li *ngIf="idCard && idCard.art === 'Ausweis'" data-test="products-summary-customerData-idCard">
            <p class="text--big mb-0">
              <strong><span translate="summary.idData"></span></strong>
              <br />
              {{ idCard.type }}, {{ idCard.number }}, {{ idCard.issuer }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </mwe-basic-card>
</div>
