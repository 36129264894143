import { ChangeDetectionStrategy, Component, input, output, TemplateRef } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { AlertType } from '@mwe/models';

@Component({
  selector: 'mwe-pre-load-data',
  templateUrl: './pre-load-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreLoadDataComponent {
  state = input<ServiceStateEnum>();
  alertTitleKey = input<string>('newClient.status.loadError.key');
  alertMessageKey = input<string>('newClient.status.loadError.text');
  alertType = input<AlertType>('danger');
  errorPlaceholderMap = input<Map<string, string>>();
  successTemplate = input<TemplateRef<any>>();
  errorTemplate = input<TemplateRef<any>>();
  loadingTemplate = input<TemplateRef<any>>();
  specialAlertError = input<boolean>(false);
  loadingSpinnerType = input<'normal' | 'normal-without-box' | 'extended' | 'extended-white-background'>('normal');
  loadingSpinnerText = input<string>();
  reloadButtonKey = input<string>();
  reloadButtonClicked = output();

  ServiceStateEnum = ServiceStateEnum;
}
