<mwe-alert
  *ngIf="unknownAddress"
  type="danger"
  titleKey="address-input.unknownAddress.title"
  messageKey="address-input.unknownAddress.description"
></mwe-alert>

<div class="sr-only" aria-live="assertive">
  <ng-container *ngIf="suggestions.length === 1">{{ 'address-input.result.singleResult' | translate }}</ng-container>
  <ng-container *ngIf="suggestions.length > 1"
    >{{ suggestions.length }} {{ 'address-input.result.multipleResults' | translate }}</ng-container
  >
</div>
<form>
  <div *ngIf="hasSelectionEntries">
    <label translate="address-input.selection"></label>

    <div class="row gx-2">
      <div class="col-auto" *ngFor="let pill of selectionEntries; let last = last">
        <mwe-pill-badge
          [text]="pill.text"
          [state]="pill.state"
          [isLast]="last"
          [closable]="true"
          type="light"
          (unselect)="unselect($event, pill.state)"
          #pills
        ></mwe-pill-badge>
      </div>
    </div>
  </div>

  <div class="mb-3 dropdown" *ngIf="selectionState !== 'complete'" (keyup)="keyboardNavigation($event)">
    <div class="row flex-nowrap">
      <div class="col">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            [ngClass]="{ 'is-invalid': showErrorMsg }"
            id="mwe-address-input-field"
            name="address"
            (keydown)="limitChars($event)"
            (keyup)="onInput($event)"
            (focus)="_hideDropdown = false"
            (blur)="_hideDropdown = true"
            [formControl]="input"
            required
            autocomplete="off"
            [placeholder]="inputPlaceholder"
            #inputElement
            role="combobox"
            aria-autocomplete="both"
            aria-owns="address-input-dropdown"
            [attr.aria-activedescendant]="selectedOption?.id"
            [attr.aria-description]="inputDescription || null"
            [attr.aria-describedby]="showErrorMsg ? 'mwe-address-input-field-desc' : null"
          />
          <label for="mwe-address-input-field"
            >{{ inputLabel }}
            <span class="has-text-gray-500" *ngIf="isAlreadyValidAddress" translate="address-input.inputLabel.optional"></span
          ></label>
          <div class="form-floating-icon" *ngIf="isSearching">
            <span class="loading-spinner d-flex" style="--bs-loading-spinner-color: #949494; width: 1.5rem; height: 1.5rem"></span>
          </div>
        </div>
        <div
          class="font-size-11 text-danger mt-1"
          role="alert"
          data-test="address-inpout-error-msg"
          *ngIf="showErrorMsg"
          id="mwe-address-input-field-desc"
        >
          <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
          <span translate="address-input.error"></span>
        </div>
      </div>
    </div>
    <div
      class="dropdown-menu w-100 d-block overflow-auto"
      id="address-input-dropdown"
      #dropdown
      style="max-height: 230px"
      *ngIf="suggestions.length > 0"
      role="listbox"
      [ngClass]="{ 'sr-only-focusable': _hideDropdown }"
    >
      <h6 class="dropdown-header" translate="address-input.searchResults"></h6>
      <div
        id="{{ 'mwe-address-input-field-result-' + idx }}"
        class="dropdown-item"
        *ngFor="let suggestion of suggestions; let idx = index"
        (mousedown)="selectSuggestion(suggestion, $event)"
        [attr.data-suggestion-index]="idx"
        role="option"
      >
        <i class="far fa-map-marker-alt mr-2"></i>
        {{ getSelectionText(suggestion.docs[0]) }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="hasNoResults">
    <mwe-alert type="info" titleKey="address-input.hasNoResults.title" messageKey="address-input.hasNoResults.description"></mwe-alert>
    <div class="row justify-content-center" *ngIf="noResultsButtonText">
      <mwe-button (clicked)="noResultsButtonClicked.emit()" [labelKey]="noResultsButtonText"></mwe-button>
    </div>
  </ng-container>
</form>
