import { ValidatorFn } from '@angular/forms';

export interface IFormModel {
  inputs: (IFormInput | IFormInputGroup)[];
  isSubmitButtonVisible?: boolean;
  submitLabelKey?: string;
  isResetButtonVisible?: boolean;
  resetLabelKey?: string;
  updateOn?: 'change' | 'blur' | 'submit';
}

export interface IFormInput {
  name: string;
  initialValue: string | boolean;
  validators?: ValidatorFn[];
  inputPrependLabelKey?: string;
  labelKey?: string;
  labelCss?: string;
  inputInfoHTML?: string;
  helpLabelKey?: string;
  validationErrorLabelKey?: string;
  validationErrorLabelParams?: unknown;
  validationErrors?: FormInputErrorItem[];
  placeholder?: string;
  inputClasses?: string;
  validate?: boolean;
  validateSuccess?: boolean;
  maxLength?: number;
  // <input [attr.type]="dataType"
  dataType?: 'email' | 'text' | 'password';
  // which component is used for form-input. default is 'input' -> FormInputComponent
  componentType?: FormInputComponentType;
  // to use stuff from form.directive.ts. use ; for multiple form-directives
  directiveType?: string;
  avoidCopyPaste?: boolean;
  toggleIcon?: boolean;
  updateOn?: 'change' | 'blur' | 'submit';
  datepickerConfig?: any;
  options?: IFormSelect[];
  isHidden?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  noAutocomplete?: boolean;
  ariaLabel?: string;
  ariaLabelledby?: string;
  typeaheadOptions?: TypeaheadOptions;
  emailConfirmationOptions?: EmailConfirmationOptions;
  loadingSpinnerOptions?: FormInputLoadingSpinnerOptions;
  infoTextCode?: string;
  uuid?: string;
  inputStyle?: string;
  inputCssClass?: string;
  content?: {
    before?: string;
    after?: string;
  };
  radioValue?: string;
}

export type FormInputComponentType =
  | 'input'
  | 'checkbox'
  | 'radio'
  | 'typeahead'
  | 'emailToConfirm'
  | 'emailConfirmationField'
  | 'date'
  | 'datepicker'
  | 'birthdate'
  | 'select'
  | 'textarea'
  | 'selectable-card-check'
  | 'selectable-card-radio';

export interface FormInputErrorItem {
  key: string;
  label: string;
}

export interface FormInputLoadingSpinnerOptions {
  submitLabelKey?: string | ((value: any, isValid: boolean) => string);
  asyncLoadingFunc: (value) => Promise<void>;
  disabledWhileInvalid?: boolean;
  disabledWhileEmpty?: boolean; // allows to disable without using validators
  disabled?: boolean | ((value: any, isValid: boolean) => boolean);
  enterInInputTriggersSpinnerButton?: boolean;
  suppressValidityCheckOnSubmit?: boolean;
}

export interface TypeaheadOptions {
  regex?: string;
  initialSuggestions?: IFormSelect[];
}

export interface EmailConfirmationOptions {
  isPartlyEqualErrorVisible?: boolean;
  referenceEmailIsInitialValue?: boolean;
}

export interface IFormInputGroup {
  name: string;
  labelKey?: string;
  inputs: IFormInput[];
  isHidden?: boolean;
  rowClass?: string;
}

export interface IFormSelect {
  value: string;
  text: string;
}

export interface AddFormInput {
  addBegin?: boolean;
  addEnd?: boolean;
  addBeforeFieldName?: string;
  addAfterFieldName?: string;
  formInput: IFormInput | IFormInputGroup;
}

export interface DropdownInputSelectChanged {
  option: IFormSelect;
  name: string;
}
