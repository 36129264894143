<form #formComponent="ngForm" (ngSubmit)="submit(form.value)" *ngIf="formModel" [formGroup]="form" class="form-horizontal text-start">
  <div *ngFor="let inputElem of formModel.inputs" [attr.data-test]="inputElem.name + '-group'" class="mb-3">
    <ng-container *ngIf="!inputElem.isHidden">
      <div *ngIf="inputElem.inputInfoHTML" [innerHTML]="inputElem.inputInfoHTML | safeCMSContent: 'html'"></div>
      <ng-template [ngIf]="inputElem.hasOwnProperty('initialValue')">
        <ng-container *ngIf="!inputElem.componentType || inputElem.componentType === 'input'">
          <mwe-form-input
            (inputBlurred)="inputBlurred.emit()"
            [inputElem]="inputElem"
            [isInline]="isInline"
            [isLoading]="isLoading"
            [parentForm]="form"
          ></mwe-form-input>
        </ng-container>
        <ng-container *ngIf="inputElem.componentType === 'checkbox'">
          <mwe-checkbox-input [inputElem]="inputElem" [isLoading]="isLoading" [parentForm]="form"></mwe-checkbox-input>
        </ng-container>
        <ng-container *ngIf="inputElem.componentType === 'radio'">
          <mwe-radio-input [inputElem]="inputElem" [isLoading]="isLoading" [parentForm]="form"></mwe-radio-input>
        </ng-container>
        <mwe-typeahead-input
          *ngIf="inputElem.componentType === 'typeahead'"
          [formControlName]="inputElem.name"
          [formInputOptions]="inputElem"
          [isLoading]="isLoading"
        ></mwe-typeahead-input>
        <ng-container *ngIf="inputElem.componentType === 'emailToConfirm'">
          <mwe-email-confirmation
            (setControlVisibility)="setControlVisibility($event.controlName, $event.isVisible)"
            [allFormInputs]="formModel.inputs"
            [isLoading]="isLoading"
            [parentForm]="form"
          ></mwe-email-confirmation>
        </ng-container>

        <ng-container *ngIf="inputElem.componentType === 'date'">
          <mwe-date-input [inputElem]="inputElem" [isLoading]="isLoading" [parentForm]="form"></mwe-date-input>
        </ng-container>

        <ng-container *ngIf="inputElem.componentType === 'birthdate'">
          <mwe-birthdate-input [inputElem]="inputElem" [parentForm]="form"></mwe-birthdate-input>
        </ng-container>

        <ng-container *ngIf="inputElem.componentType === 'datepicker'">
          <mwe-datepicker-input [inputElem]="inputElem" [isLoading]="isLoading" [parentForm]="form"></mwe-datepicker-input>
        </ng-container>

        <ng-container *ngIf="inputElem.componentType === 'textarea'">
          <mwe-form-textarea [inputElem]="inputElem" [isLoading]="isLoading" [parentForm]="form"></mwe-form-textarea>
        </ng-container>

        <mwe-form-select
          (selectChanged)="onSelectChange($event.option, $event.name)"
          *ngIf="inputElem.componentType === 'select'"
          [inputElem]="inputElem"
          [isLoading]="isLoading"
          [parentForm]="form"
          [selectedValue]="selectedValue"
        ></mwe-form-select>

        <ng-container *ngIf="inputElem.componentType === 'selectable-card-radio'">
          <mwe-selectable-card-radio-input
            [inputElem]="inputElem"
            [isLoading]="isLoading"
            [parentForm]="form"
          ></mwe-selectable-card-radio-input>
        </ng-container>

        <mwe-form-input-error
          *ngIf="!isInputEmailConfirmation(inputElem.componentType)"
          [errorId]="inputElem.uuid"
          [inputElem]="inputElem"
          [parentForm]="form"
        ></mwe-form-input-error>
      </ng-template>
      <ng-template [ngIf]="inputElem.hasOwnProperty('inputs')">
        <div [class]="inputElem.rowClass" [ngClass]="{ 'g-3': !inputElem.rowClass }" class="row">
          <div *ngFor="let inputElemInner of inputElem.inputs" [class]="inputElemInner.inputClasses">
            <ng-container *ngIf="!inputElemInner.isHidden">
              <ng-container *ngIf="!inputElemInner.componentType || inputElemInner.componentType === 'input'">
                <mwe-form-input
                  (inputBlurred)="inputBlurred.emit()"
                  [inputElem]="inputElemInner"
                  [isInline]="isInline"
                  [isLoading]="isLoading"
                  [parentForm]="form"
                ></mwe-form-input>
              </ng-container>

              <ng-container *ngIf="inputElemInner.componentType === 'checkbox'">
                <mwe-checkbox-input [inputElem]="inputElemInner" [isLoading]="isLoading" [parentForm]="form"></mwe-checkbox-input>
              </ng-container>

              <ng-container *ngIf="inputElemInner.componentType === 'radio'">
                <mwe-radio-input [inputElem]="inputElemInner" [isLoading]="isLoading" [parentForm]="form"></mwe-radio-input>
              </ng-container>

              <ng-container *ngIf="inputElemInner.componentType === 'selectable-card-check'">
                <mwe-selectable-card-checkbox-input
                  [inputElem]="inputElemInner"
                  [isLoading]="isLoading"
                  [parentForm]="form"
                ></mwe-selectable-card-checkbox-input>
              </ng-container>

              <ng-container *ngIf="inputElemInner.componentType === 'selectable-card-radio'">
                <mwe-selectable-card-radio-input
                  [inputElem]="inputElemInner"
                  [isLoading]="isLoading"
                  [parentForm]="form"
                ></mwe-selectable-card-radio-input>
              </ng-container>

              <ng-container *ngIf="inputElemInner.componentType === 'date'">
                <mwe-date-input [inputElem]="inputElemInner" [isLoading]="isLoading" [parentForm]="form"></mwe-date-input>
              </ng-container>

              <mwe-typeahead-input
                *ngIf="inputElemInner.componentType === 'typeahead'"
                [formControlName]="inputElemInner.name"
                [formInputOptions]="inputElemInner"
                [isLoading]="isLoading"
              ></mwe-typeahead-input>

              <mwe-form-select
                (selectChanged)="onSelectChange($event.option, $event.name)"
                *ngIf="inputElemInner.componentType === 'select'"
                [inputElem]="inputElemInner"
                [isLoading]="isLoading"
                [parentForm]="form"
                [selectedValue]="selectedValue"
              ></mwe-form-select>

              <mwe-form-input-error [inputElem]="inputElemInner" [parentForm]="form"></mwe-form-input-error>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div
    *ngIf="formModel.isResetButtonVisible || formModel.isSubmitButtonVisible"
    class="mt-5 submit text-end form-buttons"
    id="form-buttons"
  >
    <mwe-button
      (clicked)="reset()"
      *ngIf="formModel.isResetButtonVisible"
      [disabled]="isLoading || form.pristine"
      [labelKey]="formModel.resetLabelKey"
      buttonId="reset"
    ></mwe-button>
    <mwe-button
      *ngIf="formModel.isSubmitButtonVisible"
      [disabled]="isLoading || form.invalid"
      [labelKey]="formModel.submitLabelKey"
      [showHoverEffect]="true"
      [showSpinner]="isLoading"
      buttonId="mwe-form-submit"
      buttonType="submit"
    ></mwe-button>
  </div>
</form>
