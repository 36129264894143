import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { RadioButtonListItem } from './radio-button-list-item.model';

@Component({
  selector: 'mwe-radio-button-list',
  templateUrl: './radio-button-list.component.html',
})
export class RadioButtonListComponent {
  @Input() cardTitle: string;
  @Input() items: RadioButtonListItem[];
  @Input() groupName: string;
  @Input() choiceItemTemplateRef: TemplateRef<any>;
  @Input() isDisabled: boolean;
  @Input() selectedOption: unknown;
  @Input() hasBackground: boolean = false;
  @Output() selectedOptionChange = new EventEmitter<unknown>();

  updateSelectedOption($event): void {
    this.selectedOptionChange.emit($event.target.value);
  }
}
