<div [ngClass]="CssClasses()" class="p-3 border border-2 rounded" [attr.aria-describedby]="uuid()" [attr.role]="!!role() ? role() : null">
  <div class="row flex-nowrap g-2">
    <div class="col-auto text-center">
      <i [ngClass]="iconClasses()" class="{{ colorScheme().iconColorClass }}" style="font-size: 1.5rem"></i>
    </div>
    <div class="col align-self-center">
      @if (showTitle()) {
        <mwe-headline
          [headlineNodeType]="headingNodeType()"
          cssClass="h5"
          [text]="titleKey()"
          [textParams]="translateValues()"
          [id]="uuid()"
          data-test="inline-notification-headline"
        />
      }
      @if (infoText()) {
        <p class="text--big mb-0">
          <span data-test="inline-notification-message" [innerHTML]="messageKey() | translate: translateValues() | safeHtml"></span>
          <mwe-info-text-icon
            [infoTextTitle]="infoText().title | translate"
            [infoTextDescription]="infoText().description | translate"
            colorClass=""
          />
        </p>
      }
      @if (!infoText() && messageKey()) {
        <p [id]="uuid()" class="text--big" [innerHTML]="messageKey() | translate: translateValues() | safeHtml"></p>
      }
      @if (messageKeys()) {
        @for (key of messageKeys(); track key) {
          <p class="text--big" [innerHTML]="key | translate: translateValues() | safeHtml"></p>
        }
      }
      @if (link()) {
        <p class="text--big mt-1">
          <a
            id="inline-notification-link"
            data-test="inline-notification-link"
            href="#"
            mweClickPreventDefault
            (click)="fireLinkClickEvent()"
            [translate]="link()"
          ></a>
        </p>
      }
      <ng-content />
    </div>
    @if (showCloseButton()) {
      <div class="col-auto">
        <button aria-label="Close" class="btn btn-icon btn-sm btn-neutral" type="button" (click)="isVisible.set(false)">
          <i class="fas fa-xmark" aria-hidden="true"></i>
        </button>
      </div>
    }
  </div>
  @if (ctaLabelKey()) {
    <div class="text-end">
      <mwe-button
        color="blue"
        category="tertiary"
        size="md"
        [prependIconClass]="ctaPrependIconClass()"
        [labelKey]="ctaLabelKey()"
        (click)="onCTAClick.emit()"
      />
    </div>
  }
</div>
