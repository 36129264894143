<div
  *ngIf="text | translate: textParams as translatedText"
  [ngStyle]="noBoxShadow ? { 'box-shadow': 'none' } : ''"
  class="{{ hasBoxStyles ? 'info-box bg-white' : '' }}"
>
  <div class="row g-3">
    <div class="col col-auto">
      <i [ngClass]="iconClass" class="fa fs-6 text-secondary" aria-hidden="true"></i>
    </div>
    <div class="col">
      <p [attr.data-test]="dataTest" [innerHTML]="translatedText | safeHtml" class="text--large mb-0"></p>
    </div>
  </div>
</div>
