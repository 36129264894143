import { AfterViewInit, Directive, Input } from '@angular/core';
import DotLottiePlayer from '@aarsteinmedia/dotlottie-player-light';

@Directive({
  selector: '[mweDotLottiePlayerSrc]',
})
export class DotlottiePlayerSrcDirective implements AfterViewInit {
  @Input() mweDotLottiePlayerSrc: string;
  @Input() playerId: string;

  ngAfterViewInit(): void {
    const lottieEl = document.querySelector(`#${this.playerId}`);
    if (!lottieEl) return;
    (lottieEl as DotLottiePlayer).load(this.mweDotLottiePlayerSrc);
  }
}
