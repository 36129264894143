import { InjectionToken } from '@angular/core';
import { ApplicationPaths, EnvironmentServiceInterface } from '@mwe/environment';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');
export const WINDOW_TOKEN = new InjectionToken<Window>('Window object');
export const APPLICATION_NAME = new InjectionToken<string>('Application name');
export const APPLICATION_PATHS = new InjectionToken<ApplicationPaths>('Application paths');

// prevent circular dependencies
export const DEFAULT_POPUP_COMPONENT_TOKEN = new InjectionToken<any>('default popup component');

export const LOGGING_SERVICE_TOKEN = new InjectionToken<any>('logging service');
export const ENVIRONMENT_SERVICE_TOKEN = new InjectionToken<EnvironmentServiceInterface>('environment service');
