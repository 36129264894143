<mwe-tariff-card-template
  [category]="product().category"
  [hasActions]="hasActions()"
  [hasContent]="hasContent()"
  [hasDiscount]="hasDiscount()"
  [headlineReference]="headlineReference()"
  [isSpot]="isSpot()"
  [spotUrl]="spotUrl()"
  [state]="state()"
  [subTitle]="isWaerme() ? '' : (product().category | translateCategory | async)"
  [title]="isWaerme() ? (product().category | translateCategory | async) : (product() | translateTariffClass | async)"
>
  <ng-content></ng-content>
  <ng-content select="[slot='actions']" slot="actions"></ng-content>
</mwe-tariff-card-template>
