@if (!useRedesignedCard()) {
  @if (address()) {
    <mwe-tooltip-card
      [cardClass]="tooltipClass()"
      [iconClass]="'fas fa-map-marker-alt text-secondary-light'"
      [textNodeType]="textNodeType()"
      [header]="address().addressGroup.groupName"
      [content]="formattedAddress()"
      [hasTooltipBody]="hasTooltipBody()"
    >
      @if (featuredButtons().length) {
        <div slot="buttons" class="col-md-auto order-md-0 order-1">
          <div class="row align-items-center justify-content-center gx-3">
            @for (item of featuredButtons(); track $index) {
              <div class="col-auto">
                <button
                  id="{{ dropdownEditId() + '-option-' + $index }}"
                  [attr.data-test]="dropdownEditId() + '-option-' + item.id"
                  (click)="executeItemAction(item.id)"
                  class="btn btn-sm btn-ghost-outline"
                  [disabled]="disabled()"
                >
                  {{ item.label | translate }}
                </button>
              </div>
            }
          </div>
        </div>
      }
      @if (dropdownType() === 'custom') {
        <div slot="dropdown" class="col-auto text-end align-self-center">
          <ng-content select="[slot='icon-right']"></ng-content>
        </div>
      }
      @if (dropdownType() === 'dropdown' && dropdownItems().length) {
        <div slot="dropdown" class="col-auto text-end align-self-center">
          <div ngbDropdown placement="bottom-right top-right">
            <button
              type="button"
              id="{{ dropdownEditId() }}"
              [attr.data-test]="dropdownEditId()"
              (click)="$event.currentTarget.blur()"
              class="btn btn-icon"
              ngbDropdownToggle
              [ngClass]="{ 'pointer-events-auto': address().addressGroup.detailsLinkType }"
            >
              {{ dropdownEditKey() | translate }}
              <i class="ml-2 fas fa-chevron-down"></i>
            </button>

            <div
              ngbDropdownMenu
              class="dropdown-menu dropdown-menu-right"
              style="background-color: white !important"
              [ngClass]="{ 'pointer-events-auto': address().addressGroup.detailsLinkType }"
            >
              @for (item of dropdownItems(); track $index) {
                <button
                  id="{{ dropdownEditId() + '-option-' + $index }}"
                  [attr.data-test]="dropdownEditId() + '-option-' + item.id"
                  (click)="executeItemAction(item.id)"
                  ngbDropdownItem
                >
                  {{ item.label | translate }}
                </button>
              }
            </div>
          </div>
          <!-- old code -->
        </div>
      }

      @if (dropdownType() === 'dots' && dropdownItems().length) {
        <div slot="dropdown" class="col-auto text-end">
          <div ngbDropdown placement="bottom-right top-right">
            <button
              type="button"
              id="{{ dropdownEditId() }}"
              [attr.data-test]="dropdownEditId()"
              (click)="$event.currentTarget.blur()"
              class="btn btn-icon btn-ghost-transparent btn-sm"
              ngbDropdownToggle
              [ngClass]="{ 'pointer-events-auto': address().addressGroup.detailsLinkType }"
              [attr.title]="'products.addresGroupItems.label' | translate"
            >
              <i class="fas fa-ellipsis-vertical" aria-hidden="true"></i>
            </button>

            <div
              ngbDropdownMenu
              class="dropdown-menu dropdown-menu-right"
              style="background-color: white !important"
              [ngClass]="{ 'pointer-events-auto': address().addressGroup.detailsLinkType }"
            >
              @for (item of dropdownItems(); track $index) {
                <button
                  id="{{ dropdownEditId() + '-option-' + $index }}"
                  [attr.data-test]="dropdownEditId() + '-option-' + item.id"
                  (click)="executeItemAction(item.id)"
                  ngbDropdownItem
                >
                  {{ item.label | translate }}
                </button>
              }
            </div>
          </div>
          <!-- old code -->
        </div>
      }
      @if (hasTooltipBody()) {
        <div slot="tooltip-body" class="card-basic-body" data-test="mwe-address-details-body">
          <div class="row tooltip-body" data-test="mwe-address-details-body">
            <div class="col-8">
              @if (address().addressGroup.detailsLinkType === detailsLinkTypeEnum.RELOCATION && isAddressDetailsBodyVisible()) {
                <ng-container>
                  <p class="mb-0" translate="products.address.relocationInProgress"></p>
                </ng-container>
              }
              @if (address().addressGroup.detailsLinkType === detailsLinkTypeEnum.MOVEOUT && isAddressDetailsBodyVisible()) {
                <ng-container>
                  <p class="mb-0 fw-bold text-secondary" translate="products.address.moveOutInProgress"></p>
                </ng-container>
              }
              @if (address().addressGroup.detailsLinkType === detailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR && isAddressDetailsBodyVisible()) {
                <ng-container>
                  <div class="row gx-3">
                    <div class="col-auto">
                      <div
                        class="has-gradient-red text-white rounded-pill d-flex align-items-center justify-content-center"
                        style="width: 20px; height: 20px"
                      >
                        <i class="fas fa-exclamation-triangle" style="font-size: 8px" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="col">
                      <p class="text-big mb-0" translate="products.address.moveOutErrorInProgress"></p>
                    </div>
                  </div>
                </ng-container>
              }
              @if (orderDateFormatted()?.length > 0) {
                <ng-container>
                  <p
                    data-test="mwe-address-recent-order-msg"
                    class="text--big fw-semibold mb-0"
                    [translate]="recentOrderMsgKey()"
                    [translateParams]="{ orderDate: orderDateFormatted() }"
                  ></p>
                </ng-container>
              }
            </div>
            <div class="col-4 ml-auto pl-3 text-end">
              @if (address().addressGroup.detailsLinkType && isAddressDetailsBodyVisible()) {
                <ng-container>
                  <mwe-button-link
                    color="blue"
                    customCss="btn-nopadding float-end {{ address().addressGroup.detailsLinkType ? 'pointer-events-auto' : '' }}"
                    (clicked)="executeItemAction(detailsLinkItemKey())"
                    dataTest="mwe-address-details-link"
                    label="products.address.detailsLink"
                  ></mwe-button-link>
                </ng-container>
              }
              @if (orderDateFormatted()?.length > 0) {
                <ng-container>
                  <mwe-button-link
                    color="blue"
                    customCss="btn-nopadding float-end"
                    (clicked)="executeItemAction('order.status_' + address().getHashCode())"
                    dataTest="mwe-address-detailsLink"
                    label="products.recentOrders.detailsLink"
                  ></mwe-button-link>
                </ng-container>
              }
            </div>
          </div>
        </div>
      }
    </mwe-tooltip-card>
  }
}

@if (useRedesignedCard()) {
  <mwe-selection-card [header]="address().addressGroup.groupName" [content]="formattedAddress()" />
}
