import { IOlavEntry } from '../address/address.model';
import { ZusatzInformationen } from '../integrationlayer/api.model';

export interface OfferRequestDTO {
  offerType: string;
  offerOrigin: string;
  offerData: string;
  email: string;
  endsAt: string; // ISO8601 format
}

export interface OfferResponseDTO {
  offerId: string;
  offerType: string;
  offerConfirmationUrl: string;
  additionalData?: string; // any additional data as json string
}

export interface OfferStatusDTO {
  offerId: string;
  offerType: string;
  status: string;
  processStatus: string;
}

export interface MACTariff {
  category: string;
  tariffKey: string;
  anlageId: string;
  userSelection: string[]; // array of tariff option designators
}

export interface MACVoucherInfo {
  voucherCode: string;
  processCode: string;
  zusatzInformationen: ZusatzInformationen[];
}

export interface MACBankDetails {
  iban: string;
}

export interface NCOfferData {
  gpId: string;
  registrationDate: string;
  selectedTariffs: MACTariff[];
  newAddress: IOlavEntry;
  voucherInfo: MACVoucherInfo;
  bankDetails: MACBankDetails;
  invoiceDispatchMethod: 'pdf' | 'postal';
  providerChange: boolean;
}
