export type NavigatingBackTo =
  | 'ProductDetails'
  | 'InvoiceDetails'
  | 'TariffChange'
  | 'Relocation'
  | 'InvoiceOverview'
  | 'PdfInvoiceChange'
  | 'BankAccountChange'
  | 'InvoiceAddressChange'
  | 'CustomerDataChange'
  | 'MoveOut'
  | 'VoucherRedeem'
  | 'Newsletter'
  | 'Consumption'
  | 'Goodies'
  | 'UnpaidInvoicesSelection'
  | 'UnpaidInvoicesOptions'
  | 'PartialPayment'
  | 'Billinfo'
  | 'SecureOffer'
  | 'ClearingAccountInformationRequest';

export interface NavbarConfig {
  profile: {
    portalSwitchRoute: string;
    portalSwitchRouteLabel: string;
    menuTagNameLabel: string;
    menuIconClass: string;
  };
  goodies: {
    isRouteEnabled: boolean;
  };
}
