<div class="form-floating-icon">
  <input
    type="text"
    class="form-control datetimepicker-input"
    hidden
    [ngClass]="{
      'is-invalid':
        inputElem.datepickerConfig.readonly === false &&
        parentForm.controls[inputElem.name].errors &&
        !parentForm.controls[inputElem.name].pristine,
      'bg-transparent': inputElem.datepickerConfig.readonly,
    }"
    [formControl]="parentForm.controls[inputElem.name]"
    [attr.id]="inputElem.name"
    [attr.data-test]="'form-input-' + inputElem.name"
    [attr.name]="inputElem.name"
    [attr.placeholder]="inputElem.placeholder | translate"
    [attr.avoidCopyPaste]="inputElem.avoidCopyPaste || false"
    [mweBlockPasteEvent]="inputElem.avoidCopyPaste"
    [mweInputKeyDirective]="inputElem.directiveType"
    [attr.disabled]="isLoading ? '' : null"
    [attr.aria-describedby]="ariaDescribedBy || null"
    [attr.aria-invalid]="!isValid"
    #d
  />
  <button
    class="btn btn-clean"
    (click)="openDatepicker(d, inputElem.datepickerConfig)"
    [attr.aria-label]="'global.datepicker.toggleButton' | translate"
  >
    <i class="far fa-calendar-alt"></i>
  </button>
</div>
