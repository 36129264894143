export const capitalizeString = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const isString = (value: any): boolean => {
  return typeof value === 'string';
};

export const removeAllSpaces = (str: string): string => {
  return str?.replace(/\s+/g, '');
};

export const escapeRegExp = (str: string): string => {
  return str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const replaceAll = (str: string, search: string, replace: string): string => {
  return str?.split(search).join(replace);
};

export const padLeadingZeros = (value: number, size: number): string => {
  let s = value + '';

  while (s.length < size) {
    s = '0' + s;
  }

  return s;
};

export const removeLeadingZero = (value: string): string => {
  if (!value) {
    return '';
  }

  return '' + parseInt(value, 10);
};

export const reverseString = (str: string): string => {
  return str?.split('').reverse().join('');
};

export const sortStrings = (items: string[], sortOrder: string[]): string[] => {
  return items.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
};

// in case backend includes weird html/css/linebreaks stuff in their response, use these function to get just the text version
export const sanitizeBackendStrings = (text: string): string => {
  if (!text) {
    return '';
  }
  const textWithoutHTML = text.replace(/<[^>]*>/g, '');
  return textWithoutHTML.replace(/(\r\n|\n|\r)/gm, ''); // remove linebreaks
};

/**
 * May replace variables in a template string i.E
 * applyVariablesToTemplateString('Hello {{identity}}. How {{friendliness}} to see you', { 'identity': 'World', 'friendliness: 'nice' })
 * will return 'Hello World. How nice to see you'
 * @param template The template string to use
 * @param variables An object holding the values to use
 * @param placeholderPattern Optionally define a RegExp used to find and replace placeholders. By default, looks for {{PlaceholderName}}. First matching group will be replaced second is the placeholder name
 */
export const applyVariablesToTemplateString = (
  template: string,
  variables: Record<string, string>,
  placeholderPattern: RegExp = /(\{{2}([^\}]+)\}{2})/g,
) => {
  return template.replace(placeholderPattern, (...args): string => {
    const [, token, placeholderName] = args;
    return placeholderName in variables ? variables[placeholderName] : `${token}`;
  });
};
