import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { v4 } from 'uuid';

@Component({
  selector: 'mwe-expander-basic',
  templateUrl: 'expander-basic.component.html',
})
export class ExpanderBasicComponent implements OnInit {
  @ViewChild('collapsible') collapsibleRef: ElementRef;
  @Input() textKey: string;
  @Input() textCss: string;
  @Input() showMoreKey = 'global.expander.showMore';
  @Input() showLessKey = 'global.expander.showLess';
  @Input() hideTeaser: boolean;
  isCollapsed = true;
  uuid: string;

  constructor() {}

  ngOnInit(): void {
    this.uuid = v4();
  }
}
