@if (selectedTariffs?.length) {
  <mwe-tariff-selection-container
    [selectedTariffs]="selectedTariffs"
    [isReadOnly]="true"
    class="d-block mb-4"
    [cardCategoryTitleKeyPrefix]="cardCategoryTitleKeyPrefix"
  />
} @else {
  <div *ngFor="let product of products" class="col-12 col-lg-6 mb-4">
    <mwe-basic-card>
      <mwe-wizard-product-card
        [product]="product"
        [showTariffDetailsLink]="showTariffDetailsLink"
        [showTariffDetails]="showTariffDetails"
        [isAvailable]="true"
        [tariffClasses]="product.tariffClasses"
        [tarifKey]="product.tarifKey"
        [productSelection]="productSelection"
        [grundpreisLabelOverwrite]="grundpreisLabelOverwrite"
        [attr.data-test]="'products-summary-card-' + product.category?.toLowerCase()"
      ></mwe-wizard-product-card>
    </mwe-basic-card>
  </div>
}
