<div
  (click)="isClickable ? showProductDetails($event) : false"
  class="card-basic mb-4"
  [ngClass]="{ 'has-notch': isClickable, 'card-has-focus': buttonIsFocused }"
  [ngStyle]="{
    cursor: isClickable ? 'pointer' : 'initial',
  }"
>
  <button
    type="button"
    class="btn btn-bubble btn-primary button-in-notch card-focus-effect-trigger"
    [ngClass]="{ 'bg-gray-500 border-gray-500': iconExtendedStyle == 'grau' }"
    *ngIf="isClickable"
    (click)="showProductDetails($event)"
    [attr.title]="'products.toDetailPage' | translate: { product: product.category | translateCategory | async }"
    (focus)="buttonIsFocused = true"
    (blur)="buttonIsFocused = false"
  >
    <i class="fas fa-chevron-right"></i>
  </button>
  <div *ngIf="isClickable" class="button-notch">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
  <ng-container *ngIf="!isClickable">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
</div>

<ng-template #content>
  <ng-container *ngIf="!isDetailsState">
    <div class="card-basic-body text-center">
      <img src="/assets/icons/products/icon-{{ categoryIcon }}.png" alt width="75" />
      <h3 class="h6 f-16 mt-2 mb-0">{{ categoryLabel$ | async }}</h3>
      <p class="text--legal product-info mt-2 mb-0" *ngIf="showCompanyCorrelation">
        {{ product.category | correlatedCompanyReference }}
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="isDetailsState">
    <div class="card-basic-body">
      <div class="row gx-4 align-items-center">
        <div class="col-auto text-center">
          <img src="/assets/icons/products/icon-{{ categoryIcon }}.png" alt width="75" />
          <h3 class="h6 f-16 mt-2 mb-0" data-test="product-category-text">{{ categoryLabel$ | async }}</h3>
        </div>
        <div class="col">
          <ng-container *ngIf="onlineNotPossibleWarningLabel">
            <p [translate]="onlineNotPossibleWarningLabel"></p>
          </ng-container>
          <ng-container *ngIf="!onlineNotPossibleWarningLabel">
            <h4 class="h5">{{ product | translateTariffClass: null : (categoryLabel$ | async) | async }}</h4>
            <p class="text--legal product-info" *ngIf="showCompanyCorrelation">
              {{ product.category | correlatedCompanyReference }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="!isActive" class="card-basic-body card-basic-body-slim bg-gray-500" data-test="products-card-inactive-info">
      <p class="text--large text-white mb-0" translate="products.card.inactive"></p>
    </div>
    <div
      *ngIf="unpaidInvoices?.offeneZahlungen?.length"
      class="card-basic-body border-top py-2"
      data-test="product-card-show-unpaid-invoices-btn"
    >
      <img class="align-text-bottom mr-2" src="assets/icons/icon-warning.svg" alt width="22" />
      <span class="text-danger text--extra-large fw-semibold" translate="products.unpaidInvoices.duePayment"></span>
    </div>
  </ng-container>
</ng-template>
