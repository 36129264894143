<header class="align-items-center cover header-bg minimized px-3 px-lg-4 rounded-top">
  <div class="row h-100 align-items-center justify-content-between">
    <div class="col">
      <div class="d-flex align-items-center">
        <mwe-header-logo-image></mwe-header-logo-image>
        <span [translate]="popupTitle" class="header-logo" data-test="custom-popup-title"></span>
      </div>
    </div>
    <div *ngIf="isDismissible" class="col col-auto">
      <a
        (click)="dismissClicked.emit()"
        aria-label="Close"
        class="btn btn-clean text-white"
        data-dismiss="modal"
        data-test="mwe-custom-popup-close"
        href="javascript:"
      >
        <i aria-hidden="true" class="fas fa-times"></i>
      </a>
    </div>
  </div>
</header>
<div class="modal-body scrollbar-thin bg-gray--200 p-4 p-md-5 rounded-bottom">
  <ng-content></ng-content>
</div>
